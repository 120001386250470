import * as React from 'react'
import { SVGProps } from 'react'
const SvgUserEdit = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
		<path
			fill="currentColor"
			d="M2 17v3h8v-1.89H3.9V17c0-.64 3.13-2.1 6.1-2.1.96.01 1.91.14 2.83.38l1.52-1.52c-1.4-.47-2.85-.73-4.35-.76-2.67 0-8 1.33-8 4m8-13C7.79 4 6 5.79 6 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4m0 6c-1.1 0-2-.89-2-2s.9-2 2-2 2 .9 2 2-.89 2-2 2m11.7 3.35-1 1-2.05-2 1-1a.55.55 0 0 1 .77 0l1.28 1.28c.21.21.21.56 0 .77M12 18.94l6.06-6.06 2.05 2-6 6.07H12v-2.01"
		/>
	</svg>
)
export default SvgUserEdit
