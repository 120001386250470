import React from 'react'
import Footer from 'components/Footer/Footer'
import basicHero from 'assets/images/pages/crashed-car.jpg'
import './MaintenancePage.scss'

const MaintenancePage: React.FC = () => {
	return (
		<div className="MaintenancePage">
			<div className="row">
				<div className="col-md-6">
					<div className="hero">
						<img className="main-image" src={basicHero} alt="hero" />
					</div>
				</div>
				<div className="col-md-6 space-placer">
					<div className="text-wrapper">
						<h1>Under Maintenance</h1>
						<p>The page you’re looking for is currently under maintenance and will be back soon.</p>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default MaintenancePage
