import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getOilBrands, getOilEngines, getOilModels, getOils, getOilVehicleTypes } from 'utils/api/oilSearchAPI'
import { createResultSelector } from 'utils/helper'
import { loggedOut } from 'utils/redux/commonActions'
import { statusHandler, thunkActions } from 'utils/utils'

export const searchOils = createAsyncThunk('oilSearch/search', async (criteria) => await getOils(criteria))
export const fetchOilVehicleTypes = createAsyncThunk('oilSearch/getVehicleTypes', async () => await getOilVehicleTypes())
export const fetchOilBrands = createAsyncThunk('oilSearch/getOilBrands', async (payload) => await getOilBrands(payload))
export const fetchOilModels = createAsyncThunk('oilSearch/getOilModels', async (payload) => await getOilModels(payload))
export const fetchOilEngines = createAsyncThunk('oilSearch/getOilEngines', async (payload) => await getOilEngines(payload))

const actions = thunkActions(searchOils, fetchOilVehicleTypes, fetchOilBrands, fetchOilModels, fetchOilEngines)

const initialCriteria = {
	articleText: '',
	vehicleType: null,
	brand: null,
	model: null,
	engine: null,
	tecDocId: null,
}

const initialState = {
	status: 'idle',
	result: null,
	filterIds: null,
	filterState: null,
	sortBy: null,
	ascending: null,
	vehicleTypes: null,
	brands: null,
	models: null,
	engines: null,
	criteria: initialCriteria,
}

const oilSearchSlice = createSlice({
	name: 'oilSearch',
	initialState,
	reducers: {
		setCriteria: (state, action) => {
			state.criteria[action.payload.name] = action.payload.value
		},
		clearCriteria: (state, action) => {
			const propertyName = action.payload

			// Clear the specified property
			state.criteria[propertyName] = initialCriteria[propertyName]

			// Upon clearing of certain properties,
			// clear certain other properties as well.
			switch (propertyName) {
				case 'vehicleType':
					state.criteria.brand = initialCriteria.brand
					state.criteria.model = initialCriteria.model
					state.criteria.engine = initialCriteria.engine
					break
				case 'brand':
					state.criteria.model = initialCriteria.model
					state.criteria.engine = initialCriteria.engine
					break
				case 'model':
					state.criteria.engine = initialCriteria.engine
					break
				default:
					break
			}
		},
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		},
		resetVehicles: (state) => {
			state.criteria.vehicleType = null
			state.criteria.brand = null
			state.criteria.model = null
			state.criteria.engine = null
			state.brands = null
			state.models = null
			state.engines = null
		}
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.result = null
				state.filterIds = null
				state.criteria = {
					articleText: '',
					accessoryType: ''
				}
			})
			.addCase(searchOils.pending, state => {
				state.filterIds = null
				state.filterState = null
				state.result = null
			})
			.addCase(searchOils.fulfilled, (state, action) => {
				state.result = action.payload
			})

			.addCase(fetchOilVehicleTypes.pending, state => {
				state.vehicleTypes = null
			})
			.addCase(fetchOilVehicleTypes.fulfilled, (state, action) => {
				state.vehicleTypes = action.payload
			})

			.addCase(fetchOilBrands.pending, state => {
				state.brands = null
			})
			.addCase(fetchOilBrands.fulfilled, (state, action) => {
				state.brands = action.payload
				state.criteria.tecDocId = null
			})

			.addCase(fetchOilModels.pending, state => {
				state.models = null
			})
			.addCase(fetchOilModels.fulfilled, (state, action) => {
				state.models = action.payload
			})

			.addCase(fetchOilEngines.pending, state => {
				state.engines = null
			})
			.addCase(fetchOilEngines.fulfilled, (state, action) => {
				state.engines = action.payload
			})

			.addMatcher(actions, statusHandler)
	}
})

export const selectCriteria = state => state.search.oil.oil.criteria
export const selectStatus = state => state.search.oil.oil.status
export const selectResult = state => state.search.oil.oil.result
export const selectFilterIds = state => state.search.oil.oil.filterIds
export const selectFilterState = state => state.search.oil.oil.filterState
export const selectSelectedGroupName = state => state.search.oil.oil.filterState?.selected?.groupName[0]
export const selectSortBy = state => state.search.oil.oil.sortBy
export const selectAscending = state => state.search.oil.oil.ascending
export const selectVehicleTypes = state => state.search.oil.oil.vehicleTypes
export const selectBrands = state => state.search.oil.oil.brands
export const selectModels = state => state.search.oil.oil.models
export const selectEngines = state => state.search.oil.oil.engines

export const {
	setCriteria,
	clearCriteria,
	setFilter,
	sortResult,
	resetVehicles,
} = oilSearchSlice.actions
export const selectProcessedResult = createResultSelector(selectResult, selectFilterIds, selectSortBy, selectAscending)

export default oilSearchSlice.reducer
