import * as React from 'react'
import { SVGProps } from 'react'
const SvgCreditIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		fillRule="evenodd"
		strokeLinejoin="round"
		strokeMiterlimit={2}
		clipRule="evenodd"
		viewBox="0 0 100 100"
		width="1em"
		height="1em"
		{...props}
	>
		<path fill="none" d="M0 0h100v100H0z" />
		<path d="M33.723 59.652h6.625c0 3.577 4.538 6.625 9.937 6.625 5.399 0 9.937-3.048 9.937-6.625 0-3.644-3.445-4.968-10.732-6.724-7.022-1.756-15.767-3.942-15.767-13.15 0-5.929 4.869-10.964 11.593-12.653v-7.22h9.937v7.22c6.724 1.69 11.593 6.724 11.593 12.653H60.22c0-3.577-4.538-6.625-9.937-6.625-5.399 0-9.937 3.048-9.937 6.625 0 3.644 3.445 4.968 10.732 6.724 7.022 1.756 15.767 3.942 15.767 13.15 0 5.929-4.869 10.964-11.593 12.653v7.22h-9.937v-7.22c-6.724-1.69-11.593-6.724-11.593-12.653z" />
		<path d="M49.999.665C77.228.665 99.334 22.77 99.334 50S77.228 99.335 49.999 99.335C22.772 99.335.666 77.229.666 50 .666 22.77 22.772.665 49.999.665zm0 9.799C71.82 10.464 89.535 28.18 89.535 50S71.82 89.536 49.999 89.536c-21.82 0-39.534-17.715-39.534-39.536 0-21.82 17.715-39.536 39.534-39.536z" />
	</svg>
)
export default SvgCreditIcon
