import './Header.scss'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsMobile, } from 'features/app/documentInfo/documentInfoSlice'
import MobileHeader from './MobileHeader/MobileHeader'
import DesktopHeader from './DesktopHeader/DesktopHeader'

const Header = () => {
	const isMobile = useSelector(selectIsMobile)

	if (!isMobile) {
		return (
			<DesktopHeader />
		)
	} else {
		return (
			<MobileHeader />
		)
	}
}

export default Header
