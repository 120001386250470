const sliceStatus = {
	idle: 'idle',
	loading: 'loading',
	success: 'success',
	failed: 'failed'
} as const

export default sliceStatus

export type SliceStatus = keyof typeof sliceStatus
