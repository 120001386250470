import './ContentEditButton.scss'
import SvgUserEdit from 'assets/svgs/svgr/UserEdit'
import React, { FC, useRef, useState } from 'react'

interface Props {
	children: JSX.Element,
	active: boolean,
	onClick: React.MouseEventHandler<HTMLButtonElement>
}

const ContentEditButton: FC<Props> = ({
	children,
	active,
	onClick
}) => {
	const [showButton, setShowButton] = useState<boolean>(false)
	const timer = useRef<NodeJS.Timeout>()

	const startTimer = () => {
		stopTimer()

		timer.current = setTimeout(() => {
			setShowButton(false)
		}, 500)
	}

	const stopTimer = () => {
		if (timer.current)
			clearTimeout(timer.current)
	}

	const onMouseEnter = () => {
		stopTimer()
		setShowButton(true)
	}

	const onMouseLeave = () => {
		startTimer()
	}


	if (!active)
		return children

	return (
		<span
			className='ContentEditButton'
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{showButton && (
				<span
					className='edit-button'
					onClick={onClick}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
				>
					<SvgUserEdit />
				</span>
			)}
			{children}
		</span>
	)
}

export default ContentEditButton