import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getTractorTyreSizes } from 'utils/api/dimensionsAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchTractorTyreSizes = createAsyncThunk('tractorTyreSizes/getTractorTyreSizes', async (multi = false) => await getTractorTyreSizes(multi))
const fetchTractorTyreSizesAction = thunkActions(fetchTractorTyreSizes)

const tractorTyreSizeSlice = createSlice({
	name: 'tractorTyreSizes',
	initialState: {
		status: 'idle',
		sizes: null
	},
	extraReducers: builder => {
		builder
			.addCase(fetchTractorTyreSizes.fulfilled, (state, action) => {
				state.sizes = action.payload
			})
			.addMatcher(fetchTractorTyreSizesAction, statusHandler)
	}
})

export const selectTractorTyreSizesStatus = state => state.dimensions.tractorTyreSizes.status
export const selectTractorTyreSizes = state => state.dimensions.tractorTyreSizes.sizes

export default tractorTyreSizeSlice.reducer
