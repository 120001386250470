import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
	getTickets,
	getTicketDeliveryAddresses,
} from 'utils/api/ticketAPI'
import { statusHandler, thunkActions } from 'utils/utils'
import { RootState } from 'app/store'
import { DeliveryAddress } from 'types/Responses'
import sliceStateBases, { SliceState } from 'utils/enums/sliceBaseStates'
import { TicketListRow } from 'pages/myPages/Tickets/TicketListView/TicketListView'
import dayjs from 'dayjs'
import { TicketsQuery } from 'features/tickets/ticket/TicketInterface'

export const fetchTicketRows = createAsyncThunk('tickets/getTickets', async (criteria: TicketsQuery) => getTickets(criteria))
export const fetchTicketDeliveryAddresses = createAsyncThunk('tickets/getTicketDeliveryAddresses', async (deliveryAddressId: number) => await getTicketDeliveryAddresses(deliveryAddressId))

export const ticketAction = thunkActions(
	fetchTicketRows,
)

export interface TicketsState {
	data: TicketListRow[] | null
	criteria: TicketsQuery
	ticketEvents: unknown | null
	initiatalData: unknown | null
	deliveryAddresses: DeliveryAddress[] | null
}

export const initialCriteria: TicketsQuery = {
	search: '',
	fromDate: null,
	toDate: dayjs(new Date()).toISOString(), // Default to today
	status: '',
	deliveryAddress: '',
	type: null,
	width: null,
	aspectRatio: null,
	diameter: null,
}

export const initialTicketsState: SliceState<'FEATURE', TicketsState> = {
	...sliceStateBases.FEATURE,
	data: null,
	criteria: initialCriteria,
	ticketEvents: null,
	initiatalData: null,
	deliveryAddresses: null,
}

const ticketsSlice = createSlice({
	name: 'tickets',
	initialState: initialTicketsState,
	reducers: {
		setTicketsCriteria: (state, action) => {
			state.criteria = {
				...state.criteria,
				[action.payload.name]: action.payload.value,
			}
		},
		resetTicketsCriteria: (state) => {
			state.criteria = initialCriteria
		}
	},
	extraReducers: (builder) => {
	 	builder
	 		.addCase(fetchTicketRows.fulfilled, (state, action) => {
	 			state.data = action.payload
	 		})
	 		.addCase(fetchTicketRows.rejected, (state) => {
	 			state.data = null
	 		})
			.addCase(fetchTicketDeliveryAddresses.fulfilled, (state, action) => {
				state.deliveryAddresses = action.payload
			})
			.addCase(fetchTicketDeliveryAddresses.rejected, (state) => {
				state.deliveryAddresses = null
			})
	 		.addMatcher(ticketAction, statusHandler)
	 }
})

export const selectTicketRows = (state: RootState) => state.tickets.tickets.data
export const selectTicketsCriteria = (state: RootState) => state.tickets.tickets.criteria
export const selectTicketsStatus = (state: RootState) => state.tickets.tickets.status

export const { 
	setTicketsCriteria, 
	resetTicketsCriteria 
} = ticketsSlice.actions

export default ticketsSlice.reducer
