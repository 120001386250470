import React, { useEffect } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
	selectBatterySearchConfig,
	selectCarSearchConfig,
	selectEnableQuickSearch,
	selectIndustrySearchConfig,
	selectOilSearchConfig,
	selectSettings,
	selectStartPageType,
	selectTinyTyreSearchConfig
} from 'features/app/settings/settingsSlice'
import { selectHasWheelPackages } from 'features/user/userInfo/userInfoSlice'
import { selectLoggedIn } from 'features/user/login/loginSlice'
import { useUserRoles } from 'utils/hooks/useUserRoles'
import { isLoginPortalType, webStartPageType } from 'utils/enums/webStartPageType'
import PageWrapper from 'utils/wrappers/PageWrapper'
import TicketTypes from 'utils/enums/ticketTypes'

// Import layouts
import CartLayout from 'layouts/CartLayout'
import DefaultLayout from 'layouts/DefaultLayout'
import LoginPageLayout from 'layouts/LoginPageLayout'
import PageLayout from 'layouts/PageLayout'
import ProductLayout from 'layouts/ProductLayout'
import SearchLayout from 'layouts/SearchLayout/SearchLayout'
import ShopLayout from 'layouts/ShopLayout/ShopLayout'
import SidebarLayout from 'layouts/SidebarLayout/SidebarLayout'
import StartpageLayout from 'layouts/StartpageLayout'
import SidebarMenus from 'utils/enums/SidebarMenus'

// Import pages and features dynamically
const CarWheelPackageSearch = React.lazy(() => import('features/search/wheelPackage/CarWheelPackageSearch/CarWheelPackageSearch'))
const PremadeWheelPackageSearch = React.lazy(() => import('features/search/wheelPackage/PremadeWheelPackageSearch/PremadeWheelPackageSearch'))
const WheelPackage = React.lazy(() => import('pages/shop/WheelPackage/WheelPackage'))
const CarAccessorySearch = React.lazy(() => import('features/search/accessory/CarAccessorySearch/CarAccessorySearch'))
const CarAluminiumRimSearch = React.lazy(() => import('features/search/aluminiumRim/CarAluminiumRimSearch/CarAluminiumRimSearch'))
const CarRimSearch = React.lazy(() => import('features/search/rim/CarRimSearch/CarRimSearch'))
const CarSteelRimSearch = React.lazy(() => import('features/search/steelRim/CarSteelRimSearch/CarSteelRimSearch'))
const CarTubesSearch = React.lazy(() => import('features/search/tube/CarTubesSearch/CarTubesSearch'))
const CarTyreSearch = React.lazy(() => import('features/search/tyre/CarTyreSearch/CarTyreSearch'))
const CarTpmsSearch = React.lazy(() => import('features/search/tpms/CarTpmsSearch/CarTpmsSearch'))
const TruckTyreSearch = React.lazy(() => import('features/search/tyre/TruckTyreSearch/TruckTyreSearch'))
const TruckRimSearch = React.lazy(() => import('features/search/rim/TruckRimSearch/TruckRimSearch'))
const TruckTpmsSearch = React.lazy(() => import('features/search/tpms/TruckTpmsSearch/TruckTpmsSearch'))
const TruckAccessorySearch = React.lazy(() => import('features/search/accessory/TruckAccessorySearch/TruckAccessorySearch'))
const McTyreSearch = React.lazy(() => import('features/search/tyre/McTyreSearch/McTyreSearch'))
const AtvTyreSearch = React.lazy(() => import('features/search/tyre/AtvTyreSearch/AtvTyreSearch'))
const MotoTubesSearch = React.lazy(() => import('features/search/tube/MotoTubesSearch/MotoTubesSearch'))
const IndustryTyreSearch = React.lazy(() => import('features/search/tyre/IndustryTyreSearch/IndustryTyreSearch'))
const IndustryRimSearch = React.lazy(() => import('features/search/rim/IndustryRimSearch/IndustryRimSearch'))
const IndustryAccessorySearch = React.lazy(() => import('features/search/accessory/IndustryAccessorySearch/IndustryAccessorySearch'))
const IndustryTubesSearch = React.lazy(() => import('features/search/tube/IndustryTubesSearch/IndustryTubesSearch'))
const BatteryAccessorySearch = React.lazy(() => import('features/search/accessory/BatteryAccessorySearch/BatteryAccessorySearch'))
const BatterySearch = React.lazy(() => import('features/search/battery/BatterySearch/BatterySearch'))
const OilAccessorySearch = React.lazy(() => import('features/search/accessory/OilAccessorySearch/OilAccessorySearch'))
const OilSearch = React.lazy(() => import('features/search/oil/OilSearch/OilSearch'))
const OtherAccessorySearch = React.lazy(() => import('features/search/accessory/OtherAccessorySearch/OtherAccessorySearch'))
const TinyTyreSearch = React.lazy(() => import('features/search/tyre/TinyTyreSearch/TinyTyreSearch'))
const AllProductsSearch = React.lazy(() => import('features/search/allProducts/AllProductsSearch/AllProductsSearch'))
const Cart = React.lazy(() => import('features/cart/checkout/Cart/Cart'))
const SingleProduct = React.lazy(() => import('pages/products/SingleProduct/SingleProduct'))
const TyreCalculator = React.lazy(() => import('pages/infoPages/TyreCalculator/TyreCalculator'))
const RimCalculator = React.lazy(() => import('pages/infoPages/RimCalculator/RimCalculator'))
const Downloads = React.lazy(() => import('pages/infoPages/Downloads/Downloads'))
const RimInfo = React.lazy(() => import('pages/infoPages/RimInfo/RimInfo'))
const TermsOfPurchasePage = React.lazy(() => import('pages/infoPages/TermsOfPurchasePage/TermsOfPurchasePage'))
const PrivacyPolicy = React.lazy(() => import('pages/infoPages/PrivacyPolicy/PrivacyPolicy'))
const ExtraPage = React.lazy(() => import('pages/infoPages/ExtraPage/ExtraPage'))
const TyreInfo = React.lazy(() => import('pages/infoPages/TyreInfo/TyreInfo'))
const Quotations = React.lazy(() => import('pages/myPages/Quotations/Quotations'))
const RetailMarkUps = React.lazy(() => import('pages/myPages/RetailMarkUps/RetailMarkUps'))
const DeliveryAddresses = React.lazy(() => import('pages/myPages/DeliveryAddresses/DeliveryAddresses'))
const OrderHistory = React.lazy(() => import('pages/myPages/OrderHistory/OrderHistory'))
const Invoices = React.lazy(() => import('pages/myPages/Invoices/Invoices'))
const Users = React.lazy(() => import('pages/myPages/Users/Users'))
const NewSubCustomerPage = React.lazy(() => import('pages/myPages/NewSubCustomerPage/NewSubCustomerPage'))
const EditSubCustomerPricingPage = React.lazy(() => import('pages/myPages/EditSubCustomerPricingPage/EditSubCustomerPricingPage'))
const EditSubCustomerPage = React.lazy(() => import('pages/myPages/EditSubCustomerPage/EditSubCustomerPage'))
const SubCustomersPage = React.lazy(() => import('pages/myPages/SubCustomersPage/SubCustomersPage'))
const SubCustomerOrdersPage = React.lazy(() => import('pages/myPages/SubCustomerOrdersPage/SubCustomerOrdersPage'))
const ArticleExport = React.lazy(() => import('pages/myPages/ArticleExport/ArticleExport'))
const PriceTemplatePage = React.lazy(() => import('pages/myPages/PriceTemplatePage/PriceTemplatePage'))
const InvoicePage = React.lazy(() => import('pages/myPages/InvoicePage/InvoicePage'))
const ResellerSettingsPage = React.lazy(() => import('pages/myPages/ResellerSettingsPage/ResellerSettingsPage'))
const SwitchAccount = React.lazy(() => import('pages/myPages/SwitchAccount/SwitchAccount'))
const CustomerInfo = React.lazy(() => import('pages/myPages/CustomerInfo/CustomerInfo'))
const CustomerStock = React.lazy(() => import('pages/myPages/CustomerStock/CustomerStock'))
const CampaignPage = React.lazy(() => import('pages/CampaignPage/CampaignPage'))
const ContactPage = React.lazy(() => import('pages/ContactPage/ContactPage'))
const AboutPage = React.lazy(() => import('pages/AboutPage/AboutPage'))
const RimCatalog = React.lazy(() => import('pages/RimCatalog/RimCatalog'))
const CookieGDPR = React.lazy(() => import('pages/policyPages/CookieGDPR/CookieGDPR'))
const NewsPage = React.lazy(() => import('pages/NewsPage/NewsPage'))
const ResetPasswordPage = React.lazy(() => import('pages/ResetPasswordPage/ResetPasswordPage'))
const ResetPasswordSuccess = React.lazy(() => import('pages/ResetPasswordSuccess/ResetPasswordSuccess'))
const BasicPage = React.lazy(() => import('pages/startpages/BasicPage/BasicPage'))
const LoginOnly = React.lazy(() => import('pages/startpages/LoginOnly/LoginOnly'))
const NotFound = React.lazy(() => import('pages/NotFound/NotFound'))
const NewTicketSubmit = React.lazy(() => import('pages/myPages/Tickets/NewTicket/NewTicketSubmit/NewTicketSubmit'))
const NewTicketGroupedArticles = React.lazy(() => import('pages/myPages/Tickets/NewTicketGroupedArticles/NewTicketGroupedArticles'))
const NewTicket = React.lazy(() => import('pages/myPages/Tickets/NewTicket/NewTicket'))
const NewTicketSelectArticles = React.lazy(() => import('pages/myPages/Tickets/NewTicket/NewTicketSelectArticles/NewTicketSelectArticles'))
const Tickets = React.lazy(() => import('pages/myPages/Tickets/Tickets'))
const Ticket = React.lazy(() => import('pages/myPages/Tickets/Ticket/Ticket'))
const Dashboard = React.lazy(() => import('pages/contentManagementSystem/Dashboard/Dashboard'))
const Banners = React.lazy(() => import('pages/contentManagementSystem/Banners/Banners'))
const CreateBannersPostPage = React.lazy(() => import('pages/contentManagementSystem/Banners/CreateBannersPostPage/CreateBannersPostPage'))
const BannersEditorPage = React.lazy(() => import('pages/contentManagementSystem/Banners/BannersEditorPage/BannersEditorPage'))
const CreateNewsPostPage = React.lazy(() => import('pages/contentManagementSystem/News/CreateNewsPostPage/CreateNewsPostPage'))
const NewsEditorPage = React.lazy(() => import('pages/contentManagementSystem/News/NewsEditorPage/NewsEditorPage'))
const WebTextEditorPage = React.lazy(() => import('pages/contentManagementSystem/WebTextEditorPage/WebTextEditorPage'))
const WebTexts = React.lazy(() => import('pages/contentManagementSystem/WebTexts/WebTexts'))
const News = React.lazy(() => import('pages/contentManagementSystem/News/News'))

interface RouterProps {
	onLayoutChange: (layout: string) => void
}

const Router: React.FC<RouterProps> = ({ 
	onLayoutChange 
}) => {
	// Selectors
	const carSearchConfig = useSelector(selectCarSearchConfig)
	const hasPremadeWheelPackages = useSelector(selectHasWheelPackages)
	const isQuickSearchEnabled = useSelector(selectEnableQuickSearch)
	const industrySearchConfig = useSelector(selectIndustrySearchConfig)
	const batterySearchConfig = useSelector(selectBatterySearchConfig)
	const oilSearchConfig = useSelector(selectOilSearchConfig)
	const tinytyreSearchConfig = useSelector(selectTinyTyreSearchConfig)
	const startPageType = useSelector(selectStartPageType)
	const loggedin = useSelector(selectLoggedIn)

	// hooks
	const isLoginPortal = isLoginPortalType(startPageType as number)
	const settings = useSelector(selectSettings)
	const navigate = useNavigate()
	const location = useLocation()

	const {
		hasCMSEdit,
		hasCustomerAdminRole
	} = useUserRoles()

	// Define variables
	const pageConfig = settings?.pageConfig
	const siteConfig = settings?.siteConfig
	const featureConfig = settings?.featureConfig
	const infoPage = pageConfig?.informationPage

	useEffect(() => {
		// Allowed loggedout pages
		const publicPaths = [
			'/policy-gdpr',
			'/news',
			'/resetpasswordlink',
			'/reset-password-success',
			'/contact',
			'/about',
			'/policy',
			'/rimCatalog',
		]

		if (!loggedin && isLoginPortal && !publicPaths.includes(location.pathname)) {
			navigate('/')
		}

		if (loggedin && (location.pathname === '/' || location.pathname === '/rimCatalog')) {
			navigate(siteConfig?.newsPage ? '/news' : '/shop/car/tyre')
		}
	}, [navigate, location.pathname, loggedin, isLoginPortal, settings, siteConfig?.newsPage])

	// Track page views for analytics
	useEffect(() => {
		// @ts-ignore
		window.gtag!('event', 'page_view', { page_path: location.pathname, page_title: document.title })
	}, [location])

	// Define data for sidebar menus
	const infoData = { menu: SidebarMenus.info }
	const myPagesData = { menu: SidebarMenus.myPages }
	const cmsData = { menu: SidebarMenus.cms }

	// Render routes with layouts and pages
	return (
		<Routes>

			{/* Only allowed when loggedin*/}
			{loggedin && (
				<>
					<Route path="/cart/:result?/:paymentId?" element={<PageWrapper layout={CartLayout} page={Cart} onChange={onLayoutChange} />} />
					
					{/* Shop Routes */}
					{!carSearchConfig?.onlyCompletedPackages && (
						<Route path="/shop/car/wheelPackage/custom/:view?" element={<PageWrapper layout={ShopLayout} page={CarWheelPackageSearch} />} />
					)}

					{hasPremadeWheelPackages && (
						<Route path="/shop/car/wheelPackage/premade" element={<PageWrapper layout={ShopLayout} page={PremadeWheelPackageSearch} />} />
					)}
					
					<Route path="/shop/car/wheelPackage" element={<PageWrapper layout={ShopLayout} page={WheelPackage} />} />
					<Route path="/shop/car/accessories/:accessoryType" element={<PageWrapper layout={ShopLayout} page={CarAccessorySearch} />} />
					<Route path="/shop/car/accessories" element={<PageWrapper layout={ShopLayout} page={CarAccessorySearch} />} />
					<Route path="/shop/wheelPackage/product/:id" element={<PageWrapper layout={ProductLayout} page={SingleProduct} />} />
					
					{carSearchConfig?.alloyRims && (
						<Route path="/shop/car/aluminiumRim" element={<PageWrapper layout={ShopLayout} page={CarAluminiumRimSearch} />} />
					)}

					{carSearchConfig?.rims && (
						<Route path="/shop/car/rim" element={<PageWrapper layout={ShopLayout} page={CarRimSearch} />} />
					)}
					
					{carSearchConfig?.steelRims && (
						<Route path="/shop/car/steelRim" element={<PageWrapper layout={ShopLayout} page={CarSteelRimSearch} />} />
					)}

					{carSearchConfig?.tubes && (
						<Route path="/shop/car/tubes" element={<PageWrapper layout={ShopLayout} page={CarTubesSearch} />} />
					)}

					<Route path="/shop/car/tyre" element={<PageWrapper layout={ShopLayout} page={CarTyreSearch} />} />
					<Route path="/shop/car/tpms" element={<PageWrapper layout={ShopLayout} page={CarTpmsSearch} />} />
					<Route path="/shop/truck/tyre" element={<PageWrapper layout={ShopLayout} page={TruckTyreSearch} />} />
					<Route path="/shop/truck/rim" element={<PageWrapper layout={ShopLayout} page={TruckRimSearch} />} />
					<Route path="/shop/truck/tpms" element={<PageWrapper layout={ShopLayout} page={TruckTpmsSearch} />} />
					<Route path="/shop/truck/accessories/:accessoryType" element={<PageWrapper layout={ShopLayout} page={TruckAccessorySearch} />} />
					<Route path="/shop/truck/accessories" element={<PageWrapper layout={ShopLayout} page={TruckAccessorySearch} />} />
					<Route path="/shop/moto/mcTyre" element={<PageWrapper layout={ShopLayout} page={McTyreSearch} />} />
					<Route path="/shop/moto/atvTyre" element={<PageWrapper layout={ShopLayout} page={AtvTyreSearch} />} />
					<Route path="/shop/moto/tubes" element={<PageWrapper layout={ShopLayout} page={MotoTubesSearch} />} />
					
					{industrySearchConfig?.tyres && (
						<Route path="/shop/industry/tyre" element={<PageWrapper layout={ShopLayout} page={IndustryTyreSearch} />} />
					)}

					{industrySearchConfig?.rims && (
						<Route path="/shop/industry/rim" element={<PageWrapper layout={ShopLayout} page={IndustryRimSearch} />} />
					)}

					<Route path="/shop/industry/accessories/:accessoryType" element={<PageWrapper layout={ShopLayout} page={IndustryAccessorySearch} />} />
					<Route path="/shop/industry/accessories" element={<PageWrapper layout={ShopLayout} page={IndustryAccessorySearch} />} />
					<Route path="/shop/industry/tubes" element={<PageWrapper layout={ShopLayout} page={IndustryTubesSearch} />} />
					
					{batterySearchConfig && (
						<>
							{batterySearchConfig?.accessories && (
								<>
									<Route path="/shop/battery/accessories/:accessoryType" element={<PageWrapper layout={ShopLayout} page={BatteryAccessorySearch} />} />
									<Route path="/shop/battery/accessories" element={<PageWrapper layout={ShopLayout} page={BatteryAccessorySearch} />} />
								</>
							)}
							<Route path="/shop/battery" element={<PageWrapper layout={ShopLayout} page={BatterySearch} />} />
						</>
					)}

					{oilSearchConfig && (
						<>
							{oilSearchConfig?.accessories && (
								<>
									<Route path="/shop/oil/accessories/:accessoryType" element={<PageWrapper layout={ShopLayout} page={OilAccessorySearch} />} />
									<Route path="/shop/oil/accessories" element={<PageWrapper layout={ShopLayout} page={OilAccessorySearch} />} />
								</>	
							)}
							<Route path="/shop/oil/oil" element={<PageWrapper layout={ShopLayout} page={OilSearch} />} />
						</>
					)}

					<Route path="/shop/other/other/:accessoryType" element={<PageWrapper layout={ShopLayout} page={OtherAccessorySearch} />} />
					<Route path="/shop/other/other" element={<PageWrapper layout={ShopLayout} page={OtherAccessorySearch} />} />

					{tinytyreSearchConfig?.tyres &&
						<Route path="/shop/tinyTyre/tyre" element={<PageWrapper layout={ShopLayout} page={TinyTyreSearch} />} />
					}

					{/* Search Results Page */}
					{isQuickSearchEnabled && (
						<Route path="/search" element={<PageWrapper layout={SearchLayout} page={AllProductsSearch} />} />
					)}

					{/* Single Product Page */}
					<Route path="/product/:id" element={<PageWrapper layout={ProductLayout} page={SingleProduct} />} />

					{/* Information Pages */}
					{infoPage && (
						<>
							{pageConfig?.tyreCalculation && (	
								<Route path="/info/tyreCalculator" element={<PageWrapper layout={SidebarLayout} page={TyreCalculator} data={infoData} />} />
							)}

							{pageConfig?.rimCalculation && (
								<Route path="/info/rimCalculator" element={<PageWrapper layout={SidebarLayout} page={RimCalculator} data={infoData} />} />
							)}

							{pageConfig?.webDownloads && (
								<Route path="/info/downloads" element={<PageWrapper layout={SidebarLayout} page={Downloads} data={infoData} />} />
							)}

							{pageConfig?.rimInfo && (
								<Route path="/info/rimInfo" element={<PageWrapper layout={SidebarLayout} page={RimInfo} data={infoData} />} />
							)}
							
							<Route path="/info" element={<PageWrapper layout={SidebarLayout} page={TyreInfo} data={infoData} />} />
						</>
					)}

					{pageConfig?.termsOfPurchase && (
						<Route path="/info/terms-of-purchase" element={<PageWrapper layout={SidebarLayout} page={TermsOfPurchasePage} data={infoData} />} />
					)}
					
					<Route path="/info/page/:name" element={<PageWrapper layout={SidebarLayout} page={ExtraPage} data={infoData} />} />


					{/* My Pages */}
					{!siteConfig?.Offers && (
						<Route path="/my-pages/quotations" element={<PageWrapper layout={SidebarLayout} page={Quotations} data={myPagesData} />} />
					)}
					<Route path="/my-pages/retail-markups" element={<PageWrapper layout={SidebarLayout} page={RetailMarkUps} data={myPagesData} />} />
					<Route path="/my-pages/delivery-addresses" element={<PageWrapper layout={SidebarLayout} page={DeliveryAddresses} data={myPagesData} />} />
					<Route path="/my-pages/order-history" element={<PageWrapper layout={SidebarLayout} page={OrderHistory} data={myPagesData} />} />
					<Route path="/my-pages/invoices" element={<PageWrapper layout={SidebarLayout} page={Invoices} data={myPagesData} />} />
					
					{siteConfig?.webUsers && (
						<Route path="/my-pages/users" element={<PageWrapper layout={SidebarLayout} page={Users} data={myPagesData} />} />
					)}

					{/* New ticket preseason */}
					<Route path="/my-pages/tickets/new-preseason/submit" element={<PageWrapper layout={SidebarLayout} page={NewTicketSubmit} data={{ menu: myPagesData.menu, ticketType: TicketTypes.Preseason }} />} />
					<Route path="/my-pages/tickets/new-preseason" element={<PageWrapper layout={SidebarLayout} page={NewTicketGroupedArticles} data={{ menu: myPagesData.menu, ticketType: TicketTypes.Preseason }} />} />

					{/* New ticket realization */}
					<Route path="/my-pages/tickets/new-realization/submit" element={<PageWrapper layout={SidebarLayout} page={NewTicketSubmit} data={{ menu: myPagesData.menu, ticketType: TicketTypes.Realization }} />} />
					<Route path="/my-pages/tickets/new-realization" element={<PageWrapper layout={SidebarLayout} page={NewTicketGroupedArticles} data={{ menu: myPagesData.menu, ticketType: TicketTypes.Realization }} />} />

					{/* New Ticket */}
					<Route path="/my-pages/tickets/new-ticket/:orderId?/select-articles" element={<PageWrapper layout={SidebarLayout} page={NewTicketSelectArticles} data={myPagesData} />} />
					<Route path="/my-pages/tickets/new-ticket/:orderId?/submit" element={<PageWrapper layout={SidebarLayout} page={NewTicketSubmit} data={{ menu: myPagesData.menu }} />} />
					<Route path="/my-pages/tickets/new-ticket/:orderId?" element={<PageWrapper layout={SidebarLayout} page={NewTicket} data={myPagesData} />} />

					{/* Ticket */}
					<Route path="/my-pages/tickets/ticket/:id" element={<PageWrapper layout={SidebarLayout} page={Ticket} data={myPagesData} />} />
					<Route path="/my-pages/tickets" element={<PageWrapper layout={SidebarLayout} page={Tickets} data={myPagesData} />} />

					{/* Sub customer pages */}
					<Route path="/my-pages/new-customer" element={<PageWrapper layout={SidebarLayout} page={NewSubCustomerPage} data={myPagesData} />} />
					<Route path="/my-pages/customers/:id/pricing" element={<PageWrapper layout={SidebarLayout} page={EditSubCustomerPricingPage} data={myPagesData} />} />
					<Route path="/my-pages/customers/:id" element={<PageWrapper layout={SidebarLayout} page={EditSubCustomerPage} data={myPagesData} />} />
					<Route path="/my-pages/customers" element={<PageWrapper layout={SidebarLayout} page={SubCustomersPage} data={myPagesData} />} />
					<Route path="/my-pages/orders" element={<PageWrapper layout={SidebarLayout} page={SubCustomerOrdersPage} data={myPagesData} />} />
					<Route path="/my-pages/article-export" element={<PageWrapper layout={SidebarLayout} page={ArticleExport} data={myPagesData} />} />
					<Route path="/my-pages/price-template" element={<PageWrapper layout={SidebarLayout} page={PriceTemplatePage} data={myPagesData} />} />
					<Route path="/my-pages/invoice" element={<PageWrapper layout={SidebarLayout} page={InvoicePage} data={myPagesData} />} />
					<Route path="/my-pages/settings" element={<PageWrapper layout={SidebarLayout} page={ResellerSettingsPage} data={myPagesData} />} />
					<Route path="/my-pages/switch-account" element={<PageWrapper layout={SidebarLayout} page={SwitchAccount} data={myPagesData} />} />
			
					<Route path="/my-pages/customer-info" element={<PageWrapper layout={SidebarLayout} page={CustomerInfo} data={myPagesData} />} />

					{siteConfig?.customerStock && (
						<Route path="/my-pages/customer-stock" element={<PageWrapper layout={SidebarLayout} page={CustomerStock} data={myPagesData} />} />
					)}

					<Route path="/my-pages" element={<PageWrapper layout={SidebarLayout} page={CustomerInfo} data={myPagesData} />} />

					{/* CMS */}
					{hasCMSEdit && (
						<>
							<Route path="/cms/text/:namespace/:property?" element={<PageWrapper layout={SidebarLayout} page={WebTextEditorPage} data={cmsData} />} />
							
							{hasCustomerAdminRole && (
								<Route path="/cms/texts" element={<PageWrapper layout={SidebarLayout} page={WebTexts} data={cmsData} />} />
							)}
							
							<Route path="/cms/news/edit/:id" element={<PageWrapper layout={SidebarLayout} page={NewsEditorPage} data={cmsData} />} />
							<Route path="/cms/news/create" element={<PageWrapper layout={SidebarLayout} page={CreateNewsPostPage} data={cmsData} />} />
							<Route path="/cms/news" element={<PageWrapper layout={SidebarLayout} page={News} data={cmsData} />} />
							<Route path="/cms/banners/edit/:id" element={<PageWrapper layout={SidebarLayout} page={BannersEditorPage} data={cmsData} />} />
							<Route path="/cms/banners/create" element={<PageWrapper layout={SidebarLayout} page={CreateBannersPostPage} data={cmsData} />} />
							<Route path="/cms/banners" element={<PageWrapper layout={SidebarLayout} page={Banners} data={cmsData} />} />
							
							{hasCustomerAdminRole && (
								<Route path="/cms" element={<PageWrapper layout={SidebarLayout} page={Dashboard} data={cmsData} />} />
							)}
						</>
					)}

					{/* Campaigns */}
					<Route path="/campaigns/:campaign/:subPage?" element={<PageWrapper layout={DefaultLayout} page={CampaignPage} />} />
				</>
			)}

			{/* public info pages*/}
			{pageConfig?.privacyPolicy && (
				<Route path="/info/policy" element={<PageWrapper layout={SidebarLayout} page={PrivacyPolicy} data={infoData} />} />
			)}

			{/* Pages */}
			{pageConfig?.contactPage && (
				<Route path="/contact" element={<PageWrapper layout={PageLayout} page={ContactPage} />} />
			)}
			
			{pageConfig?.aboutPage && (
				<Route path="/about" element={<PageWrapper layout={PageLayout} page={AboutPage} />} />
			)}
			
			{featureConfig?.showLoggedOutRimSearch && (
				<Route path="/rimCatalog" element={<PageWrapper layout={ShopLayout} page={RimCatalog} />} />
			)}

			<Route path="/policy-gdpr" element={<PageWrapper layout={DefaultLayout} page={CookieGDPR} />} />
			<Route path="/policy" element={<PageWrapper layout={PageLayout} page={PrivacyPolicy}	/>} />
			<Route path="/news" element={<PageWrapper layout={PageLayout} page={NewsPage} />} />
			<Route path="/resetpasswordlink" element={<PageWrapper layout={LoginPageLayout} page={ResetPasswordPage} />} />
			<Route path="/reset-password-success" element={<PageWrapper layout={PageLayout} page={ResetPasswordSuccess} />} />

			{/* Startpage conditional rendering */}
			{startPageType === webStartPageType.startPage && (
				<Route path="/" element={<PageWrapper layout={StartpageLayout} page={BasicPage} onChange={onLayoutChange} />} />
			)}
			{startPageType === webStartPageType.loginPortalRightSide && (
				<Route path="/" element={<PageWrapper layout={LoginPageLayout} page={LoginOnly} onChange={onLayoutChange} />} />
			)}
			{startPageType === webStartPageType.loginPortalCenter && (
				<Route path="/" element={<PageWrapper layout={LoginPageLayout} page={LoginOnly} onChange={onLayoutChange} />} />
			)}

			{/* Fallback for 404 */}
			<Route path="*" element={<PageWrapper layout={DefaultLayout} page={NotFound} onChange={onLayoutChange} />} />
		</Routes>
	)
}

export default Router
