import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getBrands, getBrandsLtk } from 'utils/api/vehicleAPI'
import { loggedOut } from 'utils/redux/commonActions'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchBrands = createAsyncThunk('brands/getBrands', async () => await getBrands())
export const fetchBrandsLtk = createAsyncThunk('brands/getBrandsLtk', async () => await getBrandsLtk())
export const vehicleAction = thunkActions(fetchBrands, fetchBrandsLtk)

const initialState = {
	status: 'idle',
	items: null
}

const brandsSlice = createSlice({
	name: 'brands',
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(loggedOut, () => initialState)
			.addCase(fetchBrands.fulfilled, (state, action) => {
				state.items = action.payload
			})
			.addCase(fetchBrands.rejected, (state) => {
				state.items = null
			})
			.addCase(fetchBrandsLtk.fulfilled, (state, action) => {
				state.items = action.payload
			})
			.addCase(fetchBrandsLtk.rejected, (state) => {
				state.items = null
			})
			.addMatcher(vehicleAction, statusHandler)
	}
})

export const selectBrandsStatus = state => state.vehicle.brands.status
export const selectBrands = state => state.vehicle.brands.items?.filter(i => i.id > 0)
export const selectIncludingNonBrands = state => state.vehicle.brands.items

export default brandsSlice.reducer