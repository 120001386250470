
const validationRules = (t) => ({
	newPassword: {
		required: true,
		message: t('userPage.passwordInvalidLength').replace('{length}', '5'),
		minLength: 5,
		maxLength: 30,
	}
})

export default validationRules