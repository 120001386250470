import { selectMaxQuantityShown } from 'features/app/settings/settingsSlice'
import { selectDisplayFullStockQuantity } from 'features/user/userInfo/userInfoSlice'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Availability from 'utils/enums/Availability'

const useStockQuantity = (
	article: any
) => {
	const { t } = useTranslation()
	const displayFullStockQuantity = useSelector(selectDisplayFullStockQuantity)
	const maxQuantityShown = useSelector(selectMaxQuantityShown)

	const [
		stockType,
		quantityLabel,
		quantity,
	] = useMemo(() => {
		let stockType = 'external'
		let quantityText = t('common.nonStockItem')
		let quantity = -1

		let availabilityClass

		if (article.availability === Availability.Immediate) {
			availabilityClass = 'inStock'
		} else {
			availabilityClass = 'delayed'
		}

		if (article.quantityCustomerStock) {
			quantity = article.quantityCustomerStock
			stockType = availabilityClass
		}
		else if (article.quantityIncoming && article.quantityIncoming > 0 && article.quantityAvailable <= 0) {
			quantity = article.quantityIncoming
			stockType = 'external'
		}
		else if (article.quantityAvailable && article.quantityAvailable > 0) {
			quantity = article.quantityAvailable
			if (article.extendedDeliveryTime)
				stockType = 'delayed'
			else
				stockType = availabilityClass
		}
		else if (article.quantityExternal && article.quantityExternal > 0) {
			quantity = article.quantityExternal
			stockType = 'external'
		}
		else if (article.IsExpired) {
			quantityText = t('articleInfo.expired')
			stockType = 'expired'
		}

		if (!displayFullStockQuantity && maxQuantityShown && quantity >= maxQuantityShown) {
			quantityText = String(quantity) + '+'
		} else if (quantity !== -1) {
			quantityText = String(quantity)
		}

		return [stockType, quantityText, quantity]
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [article])

	return {
		stockType,
		quantityLabel,
		quantity,
	}
}

export { useStockQuantity }