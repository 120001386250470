import apiClient from './apiClient'

/**
 * Gets Brands names for battery
 * @param {object} payload Form data for application
 * @returns 
 */
export const getBrands = async () => await apiClient.get('/api/batteryCarBrands')

/**
 * Gets Fuels names for battery
 * @param {*} brand 
 * @returns 
 */
export const getFuelTypes = async (brand) => await apiClient.get('/api/batteryCarFuelType', { brand })

/**
 * Gets Models names for battery
 * @param {*} brand 
 * @param {*} fuelType 
 * @returns 
 */
export const getModels = async (brand, fuelType) => await apiClient.get('/api/batteryCarModels', { brand, fuelType })

/**
 * Gets Engines names for battery
 * @param {*} brand 
 * @param {*} fuelType 
 * @param {*} model 
 * @param {*} engineType 
 * @returns 
 */
export const getEngines = async (brand, fuelType, model, engineType) => await apiClient.get('/api/batteryCarVehicles', {
	brand,
	fuelType,
	model,
	engineType
})

/**
 * Gets Years names for battery
 * @param {*} brand 
 * @param {*} fuelType 
 * @param {*} model 
 * @param {*} engineType 
 * @param {*} engine 
 * @returns 
 */
export const getYears = async (brand, fuelType, model, engineType, engine) => await apiClient.get('/api/batteryCarYear', {
	brand,
	fuelType,
	model,
	engineType,
	engine
})