import * as React from 'react'
import { SVGProps } from 'react'
const SvgEmail = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
		<path
			fill="currentColor"
			d="m20 8-8 5-8-5V6l8 5 8-5m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z"
		/>
	</svg>
)
export default SvgEmail
