import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { FilterIds } from 'types/GlobalTypes'
import { 
	getAllProductsSearch, 
	QuickSearchPayload,
	AllProductsSearchPayload, 
} from 'utils/api/articleSearchAPI'
import sliceStateBases, { SliceState } from 'utils/enums/sliceStateBases'
import { loggedOut } from 'utils/redux/commonActions'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchAllProductsSearch = createAsyncThunk('allProductsSearch/fetchAllProductsSearch', async (payload: AllProductsSearchPayload) => await getAllProductsSearch(payload))

const searchAllProductsActions = thunkActions(
	fetchAllProductsSearch,
)

export interface AllProductsSearchState {
	criteria: QuickSearchPayload | AllProductsSearchPayload
	filterIds?: FilterIds
	filterState?: any
}

export const initialAllProductsSearchState: SliceState<'FEATURE', AllProductsSearchState> = {
	...sliceStateBases.FEATURE,
	data: null,
	filterIds: null,
	criteria: {
		searchQuery: '',
		languageCode: '',
	},
}

export const allProductsSearchSlice = createSlice({
	name: 'allProductsSearch',
	initialState: initialAllProductsSearchState,
	reducers: {
		setAllProductsCriteria: (state, action) => {
			state.criteria = {
				...state.criteria,
				...action.payload,
			}

			if (action.payload.searchQuery === '') {
				state.data = null
				state.noResults = false
			}
		},
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
		},
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				Object.assign(state, initialAllProductsSearchState)
			})
			.addCase(fetchAllProductsSearch.pending, state => {
				state.data = null
			})
			.addCase(fetchAllProductsSearch.fulfilled, (state, action) => {
				state.data = action.payload
				state.noResults = !state.data.length
			})
			.addCase(fetchAllProductsSearch.rejected, state => {
				state.data = null
				state.noResults = false
			})
			.addMatcher(
				searchAllProductsActions,
				statusHandler,
			)
	},
})
export const selectAllProductsCriteria = (state: RootState) => state.search.allProducts.allProductsSearch.criteria
export const selectAllProductsSearch = (state: RootState) => state.search.allProducts.allProductsSearch
export const selectAllProductsFilterIds = (state: RootState) => state.search.allProducts.allProductsSearch.filterIds
export const selectAllProductsSearchData = (state: RootState) => state.search.allProducts.allProductsSearch.data
export const selectAllProductsIsLoading = (state: RootState) => state.search.allProducts.allProductsSearch.isLoading
export const selectAllProductsNoResults = (state: RootState) => state.search.allProducts.allProductsSearch.noResults

export const {
	setAllProductsCriteria,
	setFilter,
} = allProductsSearchSlice.actions

export default allProductsSearchSlice.reducer
