import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { getTyresByProperties } from 'utils/api/articleSearchAPI'
import { createResultSelector, isPreviousSearchCategory, isSearchCategory } from 'utils/helper'
import { statusHandler, thunkActions } from 'utils/utils'
import { loggedOut } from 'utils/redux/commonActions'
import sliceStatus from 'utils/enums/sliceStatus'

export const searchTyres = createAsyncThunk('tyreSearch/search', async (criteria) => await getTyresByProperties(
	criteria.width,
	criteria.aspectRatio,
	criteria.diameter,
	criteria.secondaryWidth,
	criteria.secondaryAspectRatio,
	criteria.secondaryDiameter,
	criteria.articleText,
	'',
	null,
	'',
	criteria.vehicleId,
	criteria.tyreType,
	criteria.languageCode
))

export const setCriteria = createAction('tyreSearch/setCriteria', (payload) => {
	const criteria = {
		[payload.name]: payload.value
	}

	// Reset preset value if tyretype change
	if (payload.name === 'tyreType')
		criteria.presetSize = ''

	return {
		payload: criteria
	}
})

const searchTyresActions = thunkActions(searchTyres)

const initialState = {
	status: 'idle',
	result: null,
	filterIds: null,
	filterState: null,
	sortBy: null,
	ascending: null,
	showCombination: false,
	criteria: {
		tyreType: null,
		width: '',
		aspectRatio: '',
		diameter: '',
		secondaryWidth: '',
		secondaryAspectRatio: '',
		secondaryDiameter: '',
		articleText: '',
		presetSize: '',
		vehicleId: null,
		license: null
	},
	searchedCriteria: null
}

const carTyreSearch = createSlice({
	name: 'carTyre',
	initialState,
	reducers: {
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		setVehicle: (state, action) => {
			state.criteria.vehicleId = action.payload.vehicleId
			state.criteria.license = action.payload.license
			state.criteria.width = ''
			state.criteria.aspectRatio = ''
			state.criteria.diameter = ''
		},
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		},
		setShowCombination: (state, action) => {
			state.showCombination = action.payload
		},
		setPresetSize: (state, action) => {
			state.criteria.presetSize = action.payload.key
			state.criteria.width = action.payload.width
			state.criteria.aspectRatio = action.payload.aspectRatio
			state.criteria.diameter = action.payload.diameter
		},
		setTyreType: (state, action) => {
			state.criteria.tyreType = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, () => initialState)
			.addCase(searchTyres.pending, (state, action) => {
				state.filterIds = null
				state.filterState = null
				state.result = null
				state.sortBy = null
				state.ascending = null
				state.searchedCriteria = {
					...action.meta.arg
				}
			})
			.addCase(searchTyres.fulfilled, (state, action) => {
				state.result = action.payload
				state.criteria = {
					...state.criteria,
					width: '',
					aspectRatio: '',
					diameter: '',
					secondaryWidth: '',
					secondaryAspectRatio: '',
					secondaryDiameter: '',
					articleText: '',
					presetSize: '',
				}
			})
			.addCase(setCriteria, (state, action) => {
				state.criteria = {
					...state.criteria,
					...action.payload
				}
			})
			.addMatcher(isPreviousSearchCategory('car', 'tyre'), (state) => {
				state.status = sliceStatus.idle
			})
			.addMatcher(isSearchCategory('car', 'tyre'), (state, action) => {
				state.status = sliceStatus.success
				state.result = action.payload
			})
			.addMatcher(searchTyresActions, statusHandler)
	}
})

export const selectCritiera = state => state.search.tyre.carTyre.criteria
export const selectSearchedCriteria = state => state.search.tyre.carTyre.searchedCriteria
export const selectStatus = state => state.search.tyre.carTyre.status
export const selectResult = state => state.search.tyre.carTyre.result
export const selectFilterIds = state => state.search.tyre.carTyre.filterIds
export const selectFilterState = state => state.search.tyre.carTyre.filterState
export const selectSortBy = state => state.search.tyre.carTyre.sortBy
export const selectAscending = state => state.search.tyre.carTyre.ascending
export const selectShowCombination = state => state.search.tyre.carTyre.showCombination
export const selectTyreType = state => state.search.tyre.carTyre.criteria.tyreType

export const selectProcessedResult = createResultSelector(
	selectResult, 
	selectFilterIds, 
	selectSortBy, 
	selectAscending, 
	selectShowCombination
)

export const { 
	setFilter, 
	sortResult, 
	setShowCombination, 
	setPresetSize, 
	setVehicle,
	setTyreType 
} = carTyreSearch.actions

export default carTyreSearch.reducer
