import { combineReducers } from 'redux'
import carAccessory from './CarAccessorySearch/carAccessorySearchSlice'
import carAccessoryTypes from './CarAccessoryTypes/carAccessoryTypesSlice'
import industryAccessory from './IndustryAccessorySearch/industryAccessorySearchSlice'
import industryAccessoryTypes from './IndustryAccessoryTypes/industryAccessoryTypesSlice'
import batteryAccessory from './BatteryAccessorySearch/batteryAccessorySearchSlice'
import batteryAccessoryType from './BatteryAccessoryTypes/batteryAccessoryTypesSlice'
import otherAccessoryTypes from './OtherAccessoryTypes/otherAccessoryTypesSlice'
import otherAccessory from './OtherAccessorySearch/otherAccessorySearchSlice'
import oilAccessory from './OilAccessorySearch/oilAccessorySearchSlice'
import oilAccessoryTypes from './OilAccessoryTypes/oilAccessoryTypesSlice'
import truckAccessoryTypes from './TruckAccessoryTypes/truckAccessoryTypesSlice'
import truckAccessory from './TruckAccessorySearch/TruckAccessorySearchSlice' 

export default combineReducers({
	carAccessory,
	carAccessoryTypes,
	industryAccessory,
	industryAccessoryTypes,
	batteryAccessory,
	batteryAccessoryType,
	otherAccessory,
	otherAccessoryTypes,
	oilAccessory,
	oilAccessoryTypes,
	truckAccessoryTypes,
	truckAccessory
})