import { 
	createSlice, 
	createAsyncThunk, 
	ActionReducerMapBuilder, 
	//PayloadAction 
} from '@reduxjs/toolkit'
import {
	getShopSettings as _getShopSettings,
} from 'utils/api/shopSettingsAPI'
import { statusHandler, thunkActions } from 'utils/utils'
import { RootState } from 'app/store'
import {
	getSearchConfig as _getSearchConfig,
	getSearchConfigDefaults as _getSearchConfigDefaults,
} from 'utils/api/searchConfigAPI'
import {
	getResellerSettings as _getResellerSettings,
	postResellerSettings as _postResellerSettings,
} from 'utils/api/resellerSettingsAPI'
import sliceStateBases, { SliceState } from 'utils/enums/sliceBaseStates'
import { ResellerSettingsModel, SearchConfigModel, ShopSettingsModel } from 'types/api'

export const getShopSettings = createAsyncThunk(
	'settings/getShopSettings',
	async () => await _getShopSettings(),
)
export const getSearchConfig = createAsyncThunk(
	'settings/getSearchConfig',
	async () => await _getSearchConfig(),
)
export const getSearchConfigDefaults = createAsyncThunk(
	'settings/getSearchConfigDefaults',
	_getSearchConfigDefaults,
)
export const getResellerSettings = createAsyncThunk(
	'settings/getResellerSettings',
	_getResellerSettings,
)
export const postResellerSettings = createAsyncThunk(
	'settings/postResellerSettings',
	_postResellerSettings,
)

export const actions = thunkActions(
	getShopSettings,
	getSearchConfig,
	getSearchConfigDefaults,
	getResellerSettings,
	postResellerSettings,
)

export interface SettingsState {
	shopSettings: ShopSettingsModel | null
	searchConfig: SearchConfigModel | null
	searchConfigDefaults: SearchConfigModel | null
	resellerSettings: ResellerSettingsModel | null
}

export const initialSettingsState: SliceState<'FEATURE', SettingsState> = {
	...sliceStateBases.FEATURE,
	shopSettings: null,
	searchConfig: null,
	searchConfigDefaults: null,
	resellerSettings: null,
}

const settingsSlice = createSlice({
	name: 'settings',
	initialState: initialSettingsState,
	reducers: {},
	extraReducers: (builder: ActionReducerMapBuilder<SettingsState>) => builder
		.addCase(getShopSettings.fulfilled, (state: SettingsState, action: any) => {
			state.shopSettings = action.payload
		})
		.addCase(getShopSettings.rejected, (state: SettingsState) => {
			state.shopSettings = initialSettingsState.shopSettings
		})
		.addCase(getSearchConfig.fulfilled, (state: SettingsState, action: any) => {
			state.searchConfig = action.payload
		})
		.addCase(getSearchConfig.rejected, (state: SettingsState) => {
			state.searchConfig = initialSettingsState.searchConfig
		})
		.addCase(getSearchConfigDefaults.fulfilled, (state: SettingsState, action: any) => {
			state.searchConfigDefaults = action.payload
		})
		.addCase(getSearchConfigDefaults.rejected, (state: SettingsState) => {
			state.searchConfigDefaults = initialSettingsState.searchConfigDefaults
		})
		.addCase(getResellerSettings.fulfilled, (state: SettingsState, action: any) => {
			state.resellerSettings = action.payload
		})
		.addCase(getResellerSettings.rejected, (state: SettingsState) => {
			state.resellerSettings = initialSettingsState.resellerSettings
		})
		.addMatcher(actions, statusHandler),
})

export const selectSettingsStatus = (state: RootState) => state.app.settings.status
export const selectSettings = (state: RootState) => state.app.settings.shopSettings
export const selectSiteConfig = (state: RootState) => state.app.settings.shopSettings?.siteConfig
export const selectPageConfig = (state: RootState) => state.app.settings.shopSettings?.pageConfig
export const selectFeatureConfig = (state: RootState) => state.app.settings.shopSettings?.featureConfig
export const selectShortDateFormat = (state: RootState) => state.app.settings.shopSettings?.shortDateFormat
export const selectMarketId = (state: RootState) => state.app.settings.shopSettings?.marketId
export const selectLongDateShortTimeFormat = (state: RootState) => state.app.settings.shopSettings?.longDateShortTimeFormat
export const selectDefaultTyreType = (state: RootState) => state.app.settings.shopSettings?.defaultTyreType
export const selectMaxQuantityShown = (state: RootState) => state.app.settings.shopSettings?.maxQuantityShown
export const selectCountryCode = (state: RootState) => state.app.settings.shopSettings?.countryCode
export const selectTheme = (state: RootState) => state.app.settings.shopSettings?.theme
export const selectHasLogo = (state: RootState) => state.app.settings.shopSettings?.hasLogo
export const selectLogoText = (state: RootState) => state.app.settings.shopSettings?.logoText
export const selectResellerConfig = (state: RootState) => state.app.settings.shopSettings?.resellerConfig
export const selectLanguageConfig = (state: RootState) => state.app.settings.shopSettings?.languageConfig
export const selectDefaultLanguage = (state: RootState) => state.app.settings.shopSettings?.languageConfig?.defaultLanguage
export const selectDomain = (state: RootState) => state.app.settings.shopSettings?.domain
export const selectSymbol = (state: RootState) => state.app.settings.shopSettings?.symbol
export const selectAssemblyPrice = (state: RootState) => state.app.settings.shopSettings?.assemblyPrice
export const selectProgrammingPrice = (state: RootState) => state.app.settings.shopSettings?.programmingPrice
export const selectStuddingPrice = (state: RootState) => state.app.settings.shopSettings?.studdingPrice
export const selectTrackingUrls = (state: RootState) => state.app.settings.shopSettings?.trackingUrls
// export const selectVatPercent = (state: RootState) => state.app.settings.shopSettings?.vatPercent OBS: Never use this
export const selectHandlingAddedDays = (state: RootState) => state.app.settings.shopSettings?.handlingAddedDays
export const selectShowTPMSCarModel = (state: RootState) => state.app.settings.shopSettings?.featureConfig?.showTPMSCarModel
export const selectFilterConfig = (state: RootState) => state.app.settings.shopSettings?.filterConfig
export const selectCustomerLoginType = (state: RootState) => state.app.settings.shopSettings?.featureConfig?.customerLoginType
export const selectHideEngineSelect = (state: RootState) => state.app.settings.shopSettings?.featureConfig?.hideEngineSelect
export const selectShowOnlyOnePolicy = (state: RootState) => state.app.settings.shopSettings?.featureConfig?.showOnlyOnePolicy
export const selectEnableNotification = (state: RootState) => state.app.settings.shopSettings?.featureConfig?.enableNotification
export const selectEnableWayOfDelivery = (state: RootState) => state.app.settings.shopSettings?.featureConfig?.enableWayOfDelivery
export const selectEnableQuickSearch = (state: RootState) => state.app.settings.shopSettings?.featureConfig?.enableQuickSearch
export const selectShowWarehousesOnWeb = (state: RootState) => state.app.settings.shopSettings?.featureConfig?.showWarehousesOnWeb
export const selectBuyButtonPerRow = (state: RootState) => state.app.settings.shopSettings?.featureConfig?.buyButtonPerRow
export const selectStartPageType = (state: RootState) => state.app.settings.shopSettings?.startPageType
export const selectIsSettingsLoading = (state: RootState) => state.app.settings.isLoading

export const selectPriceCalculationMethod = (state: RootState) => state.app.settings.shopSettings?.resellerConfig?.priceCalculationMethod
export const selectHasConsumerOption = (state: RootState) => state.app.settings.shopSettings?.resellerConfig?.consumerOption
export const selectIsCustomTyreGroupsEnabled = (state: RootState) => state.app.settings.shopSettings?.resellerConfig?.customTyreGroups
export const selectIsCustomSearchConfigEnabled = (state: RootState) => state.app.settings.shopSettings?.resellerConfig?.customSearchConfig

export const selectSearchConfig = (state: RootState) => state.app.settings.searchConfig
export const selectSearchConfigDefaults = (state: RootState) => state.app.settings.searchConfigDefaults
export const selectCarSearchConfig = (state: RootState) => state.app.settings.searchConfig?.car
export const selectTruckSearchConfig = (state: RootState) => state.app.settings.searchConfig?.truck
export const selectMotoSearchConfig = (state: RootState) => state.app.settings.searchConfig?.moto
export const selectBatterySearchConfig = (state: RootState) => state.app.settings.searchConfig?.battery
export const selectIndustrySearchConfig = (state: RootState) => state.app.settings.searchConfig?.industry
export const selectOilSearchConfig = (state: RootState) => state.app.settings.searchConfig?.oil
export const selectOtherSearchConfig = (state: RootState) => state.app.settings.searchConfig?.other
export const selectTinyTyreSearchConfig = (state: RootState) => state.app.settings.searchConfig?.tinyTyre

export const selectResellerSettings = (state: RootState) => state.app.settings.resellerSettings

export default settingsSlice.reducer
