import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import errors from 'utils/redux/errorMiddleware'
import idleCheck from 'utils/redux/idlecheck'
import searchType from 'utils/redux/searchTypeMiddleware'

import app from 'features/app/appReducer'
import user from 'features/user/userReducer'
import cart from 'features/cart/cartSlice'
import customerStock from 'features/customerStock/customerStockSlice'
import search from 'features/search/searchReducer'
import resellerApplication from 'features/resellerApplication/resellerApplicationSlice'
import countries from 'features/countries/countriesSlice'
import mainGroups from 'features/mainGroups/mainGroupsSlice'
import articles from 'features/articles/articlesSlice'
import notifications from 'features/notifications/notificationSlice'
import accessoryTypes from 'features/accessoryTypes/accessoryTypesSlice'
import vehicle from 'features/vehicle/vehicleSlice'
import kitset from 'features/kitset/kitsetSlice'
import warehouses from 'features/warehouses/warehousesSlice'
import dimensions from 'features/dimensions/dimensionsSlice'
import news from 'features/news/newsSlice'
import banners from 'features/banners/bannersSlice'
import downloads from 'features/downloads/downloadsSlice'
import cms from 'features/cms/cmsReducer'
import shipping from 'features/shipping/shippingReducer'
import reseller from 'features/reseller/resellerReducer'
import articleExport from 'features/user/myPages/articleExport/articleExportSlize'
import siteData from 'features/siteData/siteDataSlice'
import tickets from 'features/tickets/ticketsReducer'
import campaigns from 'features/campaigns/campaignsSlice'
import contactInformation from 'features/contactInformation/contactInformationSlice'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	stateTransformer: state => {
		const transformedState = {
			cart: state.cart.checkout.data
		}
		return transformedState 
	}
})

const store = configureStore({
	reducer: {
		app,
		articleExport,
		articles,
		accessoryTypes,
		cart,
		customerStock,
		countries,
		siteData,
		cms,
		dimensions,
		mainGroups,
		notifications,
		resellerApplication,
		search,
		shipping,
		user,
		vehicle,		
		kitset,
		warehouses,
		news,
		banners,
		downloads,
		reseller,
		tickets,
		campaigns,
		contactInformation,
	},
	
	middleware: (getDefaultMiddleware) => 
		getDefaultMiddleware()
			.concat(errors)
			.concat(idleCheck)
			.concat(searchType),

	enhancers: (getDefaultEnhancers) => 
		getDefaultEnhancers()
			.concat(sentryReduxEnhancer)
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
