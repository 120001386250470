import { RootState } from 'app/store'
import SettingsTypes from 'utils/enums/settingsTypes'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	[SettingsTypes.displayPriceIncludingVat]: false,
	[SettingsTypes.autoScrollToResult]: true,
	[SettingsTypes.compactMode]: true,
	[SettingsTypes.displayCalculator]: false,
	[SettingsTypes.hideCreditInfo]: false,
	[SettingsTypes.hideNetPrice]: false,
	[SettingsTypes.retailPrice]: false,
	[SettingsTypes.retailExclVat]: false,
	[SettingsTypes.displayRetailMargins]: false,
	[SettingsTypes.bulkAdd]: true,
	[SettingsTypes.goDirectlyToCart]: false,
	[SettingsTypes.fullScreen]: false,
}

const searchSettingsSlice = createSlice({
	name: 'searchSettings',
	initialState,
	reducers: {
		setDisplayPriceIncludingVat: (state, action) => {
			state[SettingsTypes.displayPriceIncludingVat] = action.payload
		},
		setAutoScrollToResult: (state, action) => {
			state[SettingsTypes.autoScrollToResult] = action.payload
		},
		setCompactMode: (state, action) => {
			state[SettingsTypes.compactMode] = action.payload
		},
		setDisplayCalculator: (state, action) => {
			state[SettingsTypes.displayCalculator] = action.payload
		},
		setHideCreditInfo: (state, action) => {
			state[SettingsTypes.hideCreditInfo] = action.payload
		},
		setHideNetPrice: (state, action) => {
			state[SettingsTypes.hideNetPrice] = action.payload
		},
		setRetailPrice: (state, action) => {
			state[SettingsTypes.retailPrice] = action.payload
		},
		setRetailExclVat: (state, action) => {
			state[SettingsTypes.retailExclVat] = action.payload
		},
		setDisplayRetailMargins: (state, action) => {
			state[SettingsTypes.displayRetailMargins] = action.payload
		},
		setBulkAdd: (state, action) => {
			state[SettingsTypes.bulkAdd] = action.payload
		},
		setGoDirectlyToCart: (state, action) => {
			state[SettingsTypes.goDirectlyToCart] = action.payload
		},
		setFullScreen: (state, action) => {
			state[SettingsTypes.fullScreen] = action.payload
		},
	},
})

export const {
	setDisplayPriceIncludingVat,
	setAutoScrollToResult,
	setCompactMode,
	setDisplayCalculator,
	setHideCreditInfo, 
	setHideNetPrice,
	setRetailPrice,
	setRetailExclVat,
	setDisplayRetailMargins,
	setBulkAdd,
	setGoDirectlyToCart,
	setFullScreen
} = searchSettingsSlice.actions

export const selectDisplayPriceIncludingVat = (state: RootState) => state.search.searchSettings[SettingsTypes.displayPriceIncludingVat]
export const selectAutoScrollToResult = (state: RootState) => state.search.searchSettings[SettingsTypes.autoScrollToResult]
export const selectCompactMode = (state: RootState) => state.search.searchSettings[SettingsTypes.compactMode]
export const selectDisplayCalculator = (state: RootState) => state.search.searchSettings[SettingsTypes.displayCalculator]
export const selectHideCreditInfo = (state: RootState) => state.search.searchSettings[SettingsTypes.hideCreditInfo]
export const selectHideNetPrice = (state: RootState) => state.search.searchSettings[SettingsTypes.hideNetPrice]
export const selectRetailPrice = (state: RootState) => state.search.searchSettings[SettingsTypes.retailPrice]
export const selectRetailExclVat = (state: RootState) => state.search.searchSettings[SettingsTypes.retailExclVat]
export const selectDisplayRetailMargins = (state: RootState) => state.search.searchSettings[SettingsTypes.displayRetailMargins]
export const selectBulkAdd = (state: RootState) => state.search.searchSettings[SettingsTypes.bulkAdd]
export const selectGoDirectlyToCart = (state: RootState) => state.search.searchSettings[SettingsTypes.goDirectlyToCart]
export const selectFullScreen = (state: RootState) => state.search.searchSettings[SettingsTypes.fullScreen]

export default searchSettingsSlice.reducer
