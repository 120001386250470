import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AcessoriesQuery, getAccessoriesByProperties } from 'utils/api/articleSearchAPI'
import { createResultSelector } from 'utils/helper'
import { statusHandler, thunkActions } from 'utils/utils'
import { loggedOut } from 'utils/redux/commonActions'
import mainGroups from 'utils/enums/MainGroups'
import sliceStatus, { SliceStatus } from 'utils/enums/sliceStatus'
import { RootState } from 'app/store'

export const searchAccessories = createAsyncThunk('truckAccessorySearch/search', async (payload: AcessoriesQuery) =>
	await getAccessoriesByProperties(
		payload.articleText,
		payload.accessoryType,
		mainGroups.accessoriesTruck,
		null,
		null,
		null
	))

const searchAccessoriesActions = thunkActions(searchAccessories)


interface Criteria {
	articleText: string | null,
	accessoryType: number | null
}

interface TruckAccessoryState {
	status: SliceStatus
	result: object | null,
	filterIds: any | null,
	filterState: any | null,
	sortBy: string | null,
	ascending: boolean | null,
	criteria: Criteria
}

const initialState: TruckAccessoryState = {
	status: sliceStatus.idle,
	result: null,
	filterIds: null,
	filterState: null,
	sortBy: null,
	ascending: null,
	criteria: {
		articleText: '',
		accessoryType: null
	}
}

const truckAccessorySearch = createSlice({
	name: 'truckAccessorySearch',
	initialState,
	reducers: {
		setCriteria: (state, action: PayloadAction<any>) => {
			let propName: keyof Criteria = action.payload.name
			state.criteria[propName] = action.payload.value
		},
		setFilter: (state, action: PayloadAction<any>) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		sortResult: (state, action: PayloadAction<any>) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		}
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.result = null
				state.filterIds = null
				state.criteria = {
					articleText: '',
					accessoryType: null
				}
			})
			.addCase(searchAccessories.pending, state => {
				state.filterIds = null
				state.filterState = null
				state.result = null
			})
			.addCase(searchAccessories.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(searchAccessoriesActions, statusHandler)
	}
})

export const selectCriteria = (state: RootState) => state.search.accessory.truckAccessory.criteria
export const selectStatus = (state: RootState) => state.search.accessory.truckAccessory.status
export const selectResult = (state: RootState) => state.search.accessory.truckAccessory.result
export const selectFilterIds = (state: RootState) => state.search.accessory.truckAccessory.filterIds
export const selectFilterState = (state: RootState) => state.search.accessory.truckAccessory.filterState
export const selectSortBy = (state: RootState) => state.search.accessory.truckAccessory.sortBy
export const selectAscending = (state: RootState) => state.search.accessory.truckAccessory.ascending

export const selectProcessedResult = createResultSelector(selectResult, selectFilterIds, selectSortBy, selectAscending)

export const { setCriteria, setFilter, sortResult } = truckAccessorySearch.actions

export default truckAccessorySearch.reducer
