import * as React from 'react'
import { SVGProps } from 'react'
const SvgChangeAccountIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		{...props}
	>
		<path
			fill="#444"
			d="M17.6 7.6h-4l1.488-1.488A6.393 6.393 0 0 0 10 3.6a6.398 6.398 0 0 0-5.088 10.28C5.888 12.76 7.72 12 10 12c2.28 0 4.12.76 5.088 1.88A6.356 6.356 0 0 0 16.4 10H18c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8a7.98 7.98 0 0 1 6.224 2.976L17.6 3.6v4ZM10 6c1.328 0 2.4 1.072 2.4 2.4 0 1.328-1.072 2.4-2.4 2.4a2.397 2.397 0 0 1-2.4-2.4C7.6 7.072 8.672 6 10 6Z"
		/>
	</svg>
)
export default SvgChangeAccountIcon
