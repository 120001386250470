import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	status: 'idle',
	data: null,
	showMiniCart: false
}

const miniCartSlice = createSlice({
	name: 'miniCartSlice',
	initialState,
	reducers: {
		miniCartWindow: (state, action) => {
			state.showMiniCart = action.payload
		}
	}
})

export const selectShowMiniCart = state => state.cart.miniCart.showMiniCart
export const selectStatus = state => state.cart.miniCart.status
export const { miniCartWindow } = miniCartSlice.actions
export default miniCartSlice.reducer
