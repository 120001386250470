import sliceStatus from 'utils/enums/sliceStatus'
import React from 'react'

/**
 * A custom hook to track the previous value of a state or prop.
 * @param {T} value - The current value to track.
 * @returns {T | undefined} The previous value.
 */
export const usePrevious = <T,>(value: T): T | undefined => {
	const ref = React.useRef<T>()
	React.useEffect(() => {
		ref.current = value
	})
	return ref.current
}

/**
 * Checks if a string starts with a given word.
 * @param {string} str - The string to check.
 * @param {string} word - The word to check for at the start of the string.
 * @returns {boolean} True if the string starts with the word, otherwise false.
 */
export const startsWith = (str: string, word: string): boolean => {
	return str.lastIndexOf(word, 0) === 0
}

/**
 * Checks if a string ends with a given suffix.
 * @param {string} str - The string to check.
 * @param {string} suffix - The suffix to check for at the end of the string.
 * @returns {boolean} True if the string ends with the suffix, otherwise false.
 */
export const endsWith = (str: string, suffix: string): boolean => {
	return str.indexOf(suffix, str.length - suffix.length) !== -1
}

/**
 * Generates a secure random number using the Web Crypto API.
 * @returns {number} A securely generated random number.
 */
export const ranomizerNumber = (): number => {
	const crypto = window.crypto || (window as any).msCrypto
	const array = new Uint32Array(1)
	crypto.getRandomValues(array)
	return array[0] as number
}

/**
 * Creates a function that matches an action against the provided thunks.
 * @param {...any} thunks - The thunks to match against.
 * @returns {(action: { type: string }) => boolean} A function that returns true if the action matches any of the thunk action types.
 */
export const thunkActions = (...thunks: any[]): ((action: { type: string }) => boolean) => {
	const types: string[] = []

	thunks.forEach(thunk => {
		types.push(thunk.pending().type)
		types.push(thunk.fulfilled().type)
		types.push(thunk.rejected().type)
	})

	return (action: { type: string }) => {
		return types.indexOf(action.type) >= 0
	}
}

/**
 * Updates the common status properties in Redux state based on the action type.
 * @param {any} state - The Redux state to update.
 * @param {any} action - The Redux action that triggered the state update.
 */
export const statusHandler = (state: any, action: any): void => {
	// Reset short-hand status properties
	state.isLoading = false
	state.hasSucceeded = false
	state.hasFailed = false

	if (action.type.endsWith('pending')) {
		state.isLoading = true
		state.status = sliceStatus.loading
		state.error = null
	} else if (action.type.endsWith('fulfilled')) {
		state.hasSucceeded = true
		state.status = sliceStatus.success
	} else if (action.type.endsWith('rejected')) {
		state.hasFailed = true
		state.status = sliceStatus.failed
		state.error = action.payload || (action.error && action.error.message) || action.error
	}
}

/**
 * If the provided value is null or undefined, return an empty string instead.
 * @param {any} value - The value to check.
 * @returns {string | any} The original value if not null or undefined, otherwise an empty string.
 */
export const ifNullBeEmptyString = (value: any): string | any => {
	if (value == null) {
		return ''
	}
	return value
}

/**
 * Returns a date object set to midnight of the provided day.
 * @param {Date | string} day - The date for which to get midnight.
 * @returns {Date} A Date object set to midnight.
 */
export const getMidnight = (day: Date | string): Date => {
	const date = new Date(day)
	date.setMilliseconds(0)
	date.setSeconds(0)
	date.setMinutes(0)
	date.setHours(0)
	return date
}

/**
 * Returns a date object representing midnight of the next day.
 * @returns {Date} A Date object set to midnight tomorrow.
 */
export const getTomorrow = (): Date => {
	const oneDay = 1000 * 60 * 60 * 24
	const midnightTonight = getMidnight(new Date())
	const midnightTomorrow = new Date(midnightTonight.getTime() + oneDay)

	return midnightTomorrow
}

/**
 * Checks if the provided date is today.
 * @param {Date | string} date - The date to check.
 * @returns {boolean} True if the date is today, otherwise false.
 */
export const isToday = (date: Date | string): boolean => {
	return new Date(date).toDateString() === new Date().toDateString()
}

/**
 * Checks if the provided date is tomorrow.
 * @param {Date | string} date - The date to check.
 * @returns {boolean} True if the date is tomorrow, otherwise false.
 */
export const isTomorrow = (date: Date | string): boolean => {
	return new Date(date).toDateString() === getTomorrow().toDateString()
}

/**
 * Filters an array to only include unique values.
 * @param {any} value - The current value being processed in the array.
 * @param {number} index - The index of the current value in the array.
 * @param {any[]} self - The array to filter.
 * @returns {boolean} True if the value is unique, otherwise false.
 */
export const filterOnlyUnique = (value: any, index: number, self: any[]): boolean => {
	return self.indexOf(value) === index
}

/**
 * Filters an array of objects to only include objects with unique values for a specific key.
 * @param {any[]} array - The array of objects to filter.
 * @param {string} keyToCheck - The key to check for uniqueness.
 * @returns {any[]} A new array with objects that have unique values for the specified key.
 */
export const filterOnlyUniqueObjs = (array: any[], keyToCheck: string): any[] => {
	const newArray: any[] = []
	for (let index = 0; index < array.length; index++) {
		if (!newArray.some(item => item[keyToCheck] === array[index][keyToCheck])) {
			newArray.push(array[index])
		}
	}
	return newArray
}

/**
 * Sorts two values in ascending order.
 * @param {any} a - The first value to compare.
 * @param {any} b - The second value to compare.
 * @returns {number} -1 if a < b, 1 if a > b, 0 if equal.
 */
export const sortAscending = (a: any, b: any): number => {
	if (a < b) { return -1 }
	if (a > b) { return 1 }
	return 0
}


/**
 * Sets a localStorage item with the provided name and value.
 * @param {string} name - The name of the item to set.
 * @param {string} value - The value of the item to set.
 */
export const setLocalStorageItem = (name: string, value: string): void => {
	try {
		localStorage.setItem(name, value)
	} catch (e) {
		let errName = (e as Error).name
		if (typeof errName === 'string' && errName.includes('NS_ERROR')) {
			localStorage.clear()
			sessionStorage.clear()
			window.location.reload()
		} else {
			throw e
		}
	}
}

/**
 * Retrieves a localStorage item by name.
 * @param {string} name - The name of the item to retrieve.
 * @returns {string | null} The retrieved item value, or null if not found.
 */
export const getLocalStorageItem = (name: string): string | null | undefined => {
	try {
		return localStorage.getItem(name)
	} catch (e) {
		let errName = (e as Error).name
		if (typeof errName === 'string' && errName.includes('NS_ERROR')) {
			localStorage.clear()
			sessionStorage.clear()
			window.location.reload()
		} else {
			throw e
		}
	}
	
	// Add a default return statement
	return null
}

/**
 * Adds or updates an item in a collection based on a predicate.
 * @param {T[]} collection - The collection to modify.
 * @param {T} data - The item to add or update.
 * @param {(item: T) => boolean} predicate - The predicate to find an existing item.
 */
export const addOrUpdate = <T>(collection: T[], data: T, predicate: (item: T) => boolean): void => {
	const existingIndex = collection.findIndex(predicate)
	if (existingIndex === -1) {
		collection.push(data)
	} else {
		collection[existingIndex] = data
	}
}

/**
 * Converts newlines in a string to <br> tags.
 * @param {string} str - The string to convert.
 * @param {boolean} replaceMode - Whether to replace or append <br> tags.
 * @param {boolean} isXhtml - Whether to use XHTML-style <br /> tags.
 * @returns {string} The string with <br> tags replacing newlines.
 */
export const nl2br = (str: string, replaceMode: boolean, isXhtml: boolean): string => {
	const breakTag = isXhtml ? '<br />' : '<br>'
	const replaceStr = replaceMode ? '$1' + breakTag : '$1' + breakTag + '$2'
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr)
}

/**
 * Converts a string to a regular expression.
 * @param {string} s - The string to convert.
 * @param {RegExpMatchArray | null} m - A match array used to extract the regular expression.
 * @returns {RegExp} The generated regular expression.
 */
export const stringToRegex = (s: string, m?: RegExpMatchArray | null): RegExp => {
	m = s.match(/^([/~@;%#'])(.*?)\1([gimsuy]*)$/)
	return m ? new RegExp(m[2] || '', m[3] || '') : new RegExp(s)
}

/**
 * Capitalizes the first letter of a string.
 * @param {string} string - The string to capitalize.
 * @returns {string} The string with the first letter capitalized.
 */
export const capitalizeFirstLetter = (string: string): string => {
	if (typeof string !== 'string' || string.length === 0) return string
	let firstChar = string.charAt(0)
	return string.replace(firstChar, firstChar.toUpperCase())
}

/**
 * Gets the current date and time as an ISO 8601 string.
 * @returns {string} The current date and time in ISO 8601 format.
 */
export const getCurrentISOString = (): string => {
	const dayjs = require('dayjs')
	return dayjs(Date.now()).toISOString()
}

/**
 * Ensures that the provided value is an object. If not, returns an empty object.
 * @param {any} object - The value to check.
 * @returns {object} The object if it is valid, or an empty object.
 */
export const ensureObject = (object: any): object => {
	return typeof object === 'object' && object !== null ? object : {}
}

/**
 * Converts a string into a number or,
 * if it can't, turns it into `null` instead of `NaN`.
 * 
 * Ignores empty strings, returning them back.
 * @param {string | number} value - The value to convert.
 * @returns {number | string | null} The converted number, the original string if empty, or `null` if conversion fails.
 */
export const stringToNumOrNull = (value: string | number): number | string | null => {
	if (typeof value === 'number') return value
	else if (typeof value !== 'string') return null
	else if (value === '') return value

	const numValue = Number(value)
	return isNaN(numValue) ? null : numValue
}

/**
 * Determines whether value is nullish (null or undefined).
 * @param {any} value - The value to check.
 * @returns {boolean} True if the value is nullish, otherwise false.
 */
export const isNullish = (value: any): boolean => {
	return value === null || value === undefined
}

/**
 * Smoothly scrolls the window to the top of the page.
 */
export const scrollToTop = (): void => {
	window.scrollTo({ top: 0, behavior: 'smooth' })
}

/**
 * Smoothly scrolls the window to a specified vertical position.
 * @param {number} top - The vertical scroll position in pixels.
 */
export const scrollTo = (top: number): void => {
	window.scrollTo({ top, behavior: 'smooth' })
}

/**
 * Smoothly scrolls the window by a specified amount vertically.
 * @param {number} top - The amount to scroll vertically in pixels.
 */
export const scrollBy = (top: number): void => {
	window.scrollBy({ top, behavior: 'smooth' })
}