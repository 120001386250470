import './Price.scss'
import React, { useMemo } from 'react'
import classNames from 'classnames'
import { isUndefined } from 'lodash'
import settingsTypes from 'utils/enums/settingsTypes'
import usePrice, { priceSettings } from 'utils/hooks/usePrice'
import { useSettings } from 'utils/hooks/useSettings'
import { useTranslation } from 'react-i18next'
import { selectFeatureConfig, selectSiteConfig } from 'features/app/settings/settingsSlice'
import { useSelector } from 'react-redux'
import { selectAlwaysDisplayRetailPrices } from 'features/user/userInfo/userInfoSlice'
import { isNullish } from 'utils/helpers'

interface PriceProps {
	article: any,
	showSymbol?: boolean,
	showRetailText?: boolean,
	showVatText?: boolean,
	retailPrice?: number | undefined,
	netPrice?: number | undefined,
}


/**
 * Price component
 * @returns Price component
 */

const Price = ({
	article,
	showSymbol = false,
	showRetailText = false,
	showVatText = false,
	retailPrice,
	netPrice,
}: PriceProps) => {
	const { t } = useTranslation()
	const { 
		getPrice,
		showRetailPrice
	 } = usePrice()
	const { getSetting } = useSettings()

	// Get config from redux store
	const featureConfig = useSelector(selectFeatureConfig)
	const siteConfig = useSelector(selectSiteConfig)
	const alwaysDisplayRetailPrices = useSelector(selectAlwaysDisplayRetailPrices)

	// Get settings from useSettings hook
	const isRetailExclVat = getSetting(settingsTypes.retailExclVat)
	const isDisplayPriceIncludingVat = getSetting(settingsTypes.displayPriceIncludingVat)
	
	// Memoize settings
	const settings = useMemo(() => {
		var results: priceSettings = {}

		if (!isUndefined(retailPrice)) results.retailPrice = retailPrice
		if (!isUndefined(netPrice)) results.netPrice = netPrice
		
		return results
	}, [retailPrice, netPrice])
	
	// Allow nullable price
	if (Object.keys(settings).length === 0 && isNullish(article)) {
		return <span className="Price">-</span>	
	}
	
	// Render component
	return (
		<span className={classNames('Price', {
			'retail-price': showRetailPrice, 
			'always-retail-price': alwaysDisplayRetailPrices,
			'incl-excl-vat-enabled': ((featureConfig?.showPriceIncludingVatDefault || siteConfig?.priceIncludingVat) && !showRetailPrice),
			'excl-vat-retail': isRetailExclVat,
			'excl-vat':  (!isDisplayPriceIncludingVat && !alwaysDisplayRetailPrices && !showRetailPrice)
		})}>
			{showRetailText && showRetailPrice && 
				<span className="retail-text">
					{t('common.retailPrice') + ': '}
				</span>
			}

			{getPrice(article, showSymbol, settings)}
			
			{showVatText &&
				<div className="vat-text">
					{((showRetailPrice && isRetailExclVat) || (!isDisplayPriceIncludingVat && !showRetailPrice)) ? (
						t('common.exclVat')
					) : (
						t('common.inclVat')
					)}
				</div>
			}

		</span>
	)
}

export default Price