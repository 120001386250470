import React from 'react'
import { selectForcePasswordChange } from 'features/user/userInfo/userInfoSlice'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PasswordChangeForm from '../PasswordChangeForm/PasswordChangeForm'

const ForcePasswordChange = () => {
	const { t } = useTranslation()
	const forcePasswordChange = useSelector(selectForcePasswordChange)

	return (
		<div className="ForcePasswordChange">	
			<PasswordChangeForm force={forcePasswordChange} label={t('userPage.changePassword')} description={t('base.hasTemporaryPassword')} />
		</div>
	)
}

export default ForcePasswordChange