import './SubMenuItem.scss'
import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { MenuSubItem } from 'utils/hooks/useMenu'

const SubMenuItem = ({
	to,
	name,
	active,
}: MenuSubItem) => {
	return (
		<div className={classNames('SubMenuItem', { 'active': active })}>
			<Link className="menu-item" to={to}>
				{name}
			</Link>
		</div>
	)
}

export default SubMenuItem