export interface LoginValidationRules {
	username: {
			required: boolean;
	}
	password: {
			required: boolean;
	}
}

const validationRules = (): LoginValidationRules => ({
	username: {
		required: true
	},
	password: {
		required: true
	}
})

export default validationRules

