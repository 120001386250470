import { selectCountryCode } from 'features/app/settings/settingsSlice'
import { selectCurrency } from 'features/user/userInfo/userInfoSlice'
import { useSelector } from 'react-redux'
import { 
	priceFormatter as priceFormatterUtil, 
	PriceFormatterSettings 
} from 'utils/priceFormatter'

export const usePriceFormatter = () => {
	const currencyCode = useSelector(selectCurrency)
	const countryCode = useSelector(selectCountryCode)

	const priceFormatter = (
		price: number | null | undefined, 
		settings: PriceFormatterSettings = {}
	): string | number => {

		if (!currencyCode) {
			return price as number
		}

		return priceFormatterUtil(price, currencyCode, countryCode, settings)
	}

	return priceFormatter
}