import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getWarehouses } from 'utils/api/warehousesAPI'
import { statusHandler, thunkActions } from 'utils/utils'


export const fetchWarehouses = createAsyncThunk('warehouses/fetchWarehouses', async () => await getWarehouses())

const searchWarehousesAction = thunkActions(fetchWarehouses)

const warehouses = createSlice({
	name: 'warehousesSearch',
	initialState: {
		status: 'idle',
		result: [],
	},
	extraReducers: builder => {
		builder
			.addCase(fetchWarehouses.pending, state => {
				state.result = []
			})
			.addCase(fetchWarehouses.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(searchWarehousesAction, statusHandler)
	}
})

export const selectStatus = state => state.warehouses.status
export const selectWarehousesResult = state => state.warehouses.result

export default warehouses.reducer