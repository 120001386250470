import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAlternativeDimensionsByProperties } from 'utils/api/articleSearchAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const searchDimensions = createAsyncThunk('alternativeDimensions/search', async (criteria) => await getAlternativeDimensionsByProperties(
	criteria.width,
	criteria.aspectRatio,
	criteria.diameter,
	criteria.tyreType
))

const searchActions = thunkActions(searchDimensions)

const initialState = {
	status: 'idle',
	result: null,
	showResult: true,
}

const alternativeDimensionsSlice = createSlice({
	name: 'alternativeDimensions',
	initialState,
	reducers: {
		toggleShowResult: (state) => {
			state.showResult = !state.showResult
		},
		resetResult: (state) => {
			state.result = null
			state.showResult = false
		}
	},
	extraReducers: builder => {
		builder
			.addCase(searchDimensions.pending, state => {
				state.result = null
				state.showResult = false
			})
			.addCase(searchDimensions.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(searchActions, statusHandler)
	}
})

export const selectResult = state => state.search.alternativeDimensions.result
export const selectShowResult = state => state.search.alternativeDimensions.showResult

export const {
	toggleShowResult,
	resetResult,
} = alternativeDimensionsSlice.actions

export default alternativeDimensionsSlice.reducer
