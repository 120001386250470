enum shopSearchType {
	car = 'car',
	truck = 'truck',
	industry = 'industry',
	moto = 'moto',
	oil = 'oil',
	wheelPackage = 'wheelPackage',
	battery = 'battery',
	tinyTyre = 'tinyTyre',
	other	= 'other',
	search = 'search',
}
export default shopSearchType

