import apiClient from './apiClient'
import IResellerSettings from 'types/IResellerSettings'

/**
 * @description Get reseller settings
 * @returns 
 */
export const getResellerSettings = (): Promise<IResellerSettings> => apiClient.get('/api/ResellerSettings')

/**
 * @description Post reseller settings
 * @param payload 
 * @returns 
 */
export const postResellerSettings = (payload: IResellerSettings): Promise<unknown> => apiClient.post('/api/ResellerSettings', payload)
