import apiClient from './apiClient'

/**
 * Get rims for matching
 * @returns 
 */
export const fetchCartMatchArticle = async () => await apiClient.get('/api/CartMatchArticle')

/**
 * Post rims for matching
 * @returns 
 */
export const postCartMatchArticle = async (payload) => await apiClient.post('/api/CartMatchArticle', payload)
