import * as React from 'react'
import { SVGProps } from 'react'
const SvgLoginIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		{...props}
	>
		<path
			fill="#444"
			d="M17 1H3c-1.11 0-2 .89-2 2v4h2V3h14v14H3v-4H1v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM8.08 13.58 9.5 15l5-5-5-5-1.42 1.41L10.67 9H1v2h9.67l-2.59 2.58Z"
		/>
	</svg>
)
export default SvgLoginIcon
