import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getInchTyreSizes } from 'utils/api/dimensionsAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchInchTyreSizes = createAsyncThunk('inchTyreSizes/getInchTyreSizes', async () => await getInchTyreSizes())

const actions = thunkActions(fetchInchTyreSizes)

const initialState = {
	status: 'idle',
	sizes: null
}

const inchTyreSizeSlice = createSlice({
	name: 'inchTyreSizes',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchInchTyreSizes.fulfilled, (state, action) => {
				state.sizes = action.payload
			})
			.addMatcher(actions, statusHandler)
	}
})

export const selectStatus = state => state.dimensions.inchTyreSizes.status
export const selectSummerSizes = state => state.dimensions.inchTyreSizes.sizes?.summer
export const selectWinterSizes = state => state.dimensions.inchTyreSizes.sizes?.winter

export default inchTyreSizeSlice.reducer
