import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { deleteQuotation, getQuotationHistory, getQuotationRows, postQuotationsToCart } from 'utils/api/quotationAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const addQuotationsToCart = createAsyncThunk('quotationHistory/addQuotationRows', async (id: number) => postQuotationsToCart(id))
export const removeQuotation = createAsyncThunk('quotationHistory/removeQuotation', async (id: number) => deleteQuotation(id))
export const fetchQuotationRows = createAsyncThunk('quotationHistory/fetchQuotationRows', async (payload) => getQuotationRows(payload))
export const fetchQuotationHistory = createAsyncThunk('quotationHistory/fetchQuotationHistory', async (payload: WebOfferSearchModel) => getQuotationHistory(payload))
export const quotationAction = thunkActions(fetchQuotationHistory)

const quotationHistorySlice = createSlice({
	name: 'quotationHistory',
	initialState: {
		status: 'idle',
		data: null
	},
	extraReducers: (builder) => {
	 	builder
	 		.addCase(fetchQuotationHistory.fulfilled, (state, action) => {
	 			state.data = action.payload
	 		})
	 		.addCase(fetchQuotationHistory.rejected, (state) => {
	 			state.data = null
	 		})
	 		.addMatcher(quotationAction, statusHandler)
	 }
})
export const selectQuotationHistoryStatus = state => state.user.quotationHistory.status
export const selectQuotationHistory = state => state.user.quotationHistory.data

export default quotationHistorySlice.reducer