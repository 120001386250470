import './MobileMainMenu.scss'
import React from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router'
import SvgClose from 'assets/svgs/svgr/Close'
import SvgArrowLeft from 'assets/svgs/svgr/ArrowLeft'
import { selectLoggedIn } from 'features/user/login/loginSlice'
import { useSelector } from 'react-redux'
import Button from '@barnsten-it/gummidata-react-components/dist/Inputs/Button/Button'
import { useTranslation } from 'react-i18next'
import CreditInfo from 'features/user/CreditInfo/CreditInfo/CreditInfo'
import { useUserRoles } from 'utils/hooks/useUserRoles'
import MobileMenuItems from './MobileMenuItems/MobileMenuItems'
import { MenuItem } from 'utils/hooks/useMenu'
import { selectSiteConfig } from 'features/app/settings/settingsSlice'
import { useAppSelector } from 'app/useAppSelector'

interface MobileMainMenuProps {
	menu: MenuItem[]
	onClose: () => void
	isOpen: boolean
	onClickNewCustomer: () => void
	onClickLogin: () => void
	onClickLogout: () => void
}

const MobileMainMenu = ({
	menu,
	onClose,
	isOpen,
	onClickNewCustomer,
	onClickLogin,
	onClickLogout
}: MobileMainMenuProps) => {
	const { t } = useTranslation()
	const location = useLocation()
	const loggedIn = useSelector(selectLoggedIn)
	const siteConfig = useAppSelector(selectSiteConfig)
	const [currentStep, setCurrentStep] = React.useState(0)

	const {
		hasCreditLimitRole,
		hasCustomerAdminRole,
	} = useUserRoles()

	const handleStepChange = (step: number) => {
		setCurrentStep(step)
	}

	const goBack = () => {
		setCurrentStep(currentStep - 1)
	}

	const style = {
		transform: 'translateX(-' + (currentStep * 100) + '%)'
	}

	return (
		<div className={classNames('MobileMainMenu', { 'open': isOpen })}>
			<div className="mobile-menu-cover" onClick={onClose}></div>
			<div className="mobile-menu-wrapper">
				<div className="mobile-menu-navigation">
					{currentStep > 0 &&
						<div className="back-button" onClick={goBack}>
							<SvgArrowLeft />
						</div>
					}
					<div className="close-icon" onClick={onClose}>
						<SvgClose />
					</div>
				</div>

				<div className="sliding-wrapper" style={style}>
					<MobileMenuItems
						location={location}
						menu={menu}
						onStepChange={handleStepChange}
						step={0}
					/>

					{loggedIn ? (
						<div className="my-pages-info">
							{(hasCustomerAdminRole && hasCreditLimitRole) &&
								<div>
									<CreditInfo mobileView={true} />
								</div>
							}
							<div className="logout-button" onClick={onClickLogout}>
								{t('common.logout')}
							</div>
						</div>
					) : (
						<div className="login">
							<div className="login-button" onClick={onClickLogin}>{t('common.login')}</div>
							{siteConfig?.customerApplication && (
								<>
									<div className="text-spacer">{t('common.or').toLowerCase()}</div>
									<Button label={t('common.becomeCustomer')} wideSize={false} onClick={onClickNewCustomer} />
								</>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}


export default MobileMainMenu