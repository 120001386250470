import { Button } from '@barnsten-it/gummidata-react-components'
import Price from 'components/Price/Price'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const MiniCartSummary = ({cartData, onClose, articleCount}) => {
	const { t } = useTranslation()

	return (
		<div className="MiniCartSummary gray-bkg">
			<div className="flex-row cart-info">
				<div className="flex-col article-amount">
					{articleCount}<span>{' '}{t('shopPage.articles')}</span>
				</div>
				<div className="flex-col article-prices">
					<div className="flex-row">
						<div className="flex-col total-sum">
							{t('common.total')}:
						</div>
						<div className="flex-col">
							<Price retailPrice={cartData.amountRetailPrice} netPrice={cartData.amountExcludingVat} showSymbol={true} />							
						</div>
					</div>
				</div>
			</div>

			<Link to="/cart">
				<Button label={t('common.goToCart')} onClick={onClose} wideSize={true} />
			</Link>
		</div>
	)
}

export default MiniCartSummary