import * as React from 'react'
import { SVGProps } from 'react'
const SvgBriefcasePlusIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
		<path
			fill="currentColor"
			d="M10 2h4a2 2 0 0 1 2 2v2h4a2 2 0 0 1 2 2v5.53a5.72 5.72 0 0 0-2-1.19V8H4v11h8.08c.12.72.37 1.39.72 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4V4a2 2 0 0 1 2-2m4 4V4h-4v2h4m0 11h3v-3h2v3h3v2h-3v3h-2v-3h-3v-2Z"
		/>
	</svg>
)
export default SvgBriefcasePlusIcon
