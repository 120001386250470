import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { usePrevious } from 'utils/utils'
import { fetchDueInvoices, selectDueInvoices, selectShowDueInvoices, toggleWindow } from '../dueInvoicesSlices'
import { selectLoggedIn } from 'features/user/login/loginSlice'
import { selectShortDateFormat } from 'features/app/settings/settingsSlice'
import Modal from '@barnsten-it/gummidata-react-components/dist/Common/Modal/Modal'
import ListView from '@barnsten-it/gummidata-react-components/dist/List/ListView/ListView'
import ScrollBox from 'components/ScrollBox/ScrollBox'
import './DueInvoicesWindow.scss'
import Button from '@barnsten-it/gummidata-react-components/dist/Inputs/Button/Button'
import { useTranslation } from 'react-i18next'
import { usePriceFormatter } from 'utils/hooks/usePriceFormatter'

const DueInvoicesWindow = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const priceFormatter = usePriceFormatter()
	const shortDateFormat = useSelector(selectShortDateFormat)
	const showWindow = useSelector(selectShowDueInvoices)
	const dueInvoices = useSelector(selectDueInvoices)
	const loggedIn = useSelector(selectLoggedIn)
	const prevLoggedIn = usePrevious(loggedIn)

	const onClose = () => {
		dispatch(toggleWindow())
	}

	useEffect(() => {
		if (!prevLoggedIn && loggedIn) {
			dispatch(fetchDueInvoices())
		}

	}, [loggedIn, prevLoggedIn, dispatch])

	const columns = useMemo(() => [
		{
			name: 'invoiceNumber',
			text: t('dueInvoice.invoiceNumber'),
			align: 'left',
			width: '33'
		},
		{
			name: 'dueDate',
			text: t('dueInvoice.dueDate'),
			align: 'left',
			width: '33'
		},
		{
			name: 'balance',
			text: t('dueInvoice.amount'),
			align: 'right',
			width: '33'
		},
	], [t])

	if (!showWindow) return null

	return (
		<div className="DueInvoicesWindow">
			<Modal onClose={onClose} closeOutside={false} closeModalButton={false} label={t('dueInvoice.dueInvoices')}>
				<div className="intro">
					{t('dueInvoice.dueInvoiceNumbers')}
				</div>
				<ScrollBox>
					<ListView columns={columns}>
						{
							({ columns }) => (
								dueInvoices.map(i => (
									<ListView.Item key={i.invoiceNumber}>
										<ListView.Columns>
											<ListView.Column column={columns.invoiceNumber}>{i.invoiceNumber}</ListView.Column>
											<ListView.Column column={columns.dueDate}>{dayjs(i.dueDate).format(shortDateFormat)}</ListView.Column>
											<ListView.Column column={columns.balance}>{priceFormatter(i.balance, {symbol: false})}</ListView.Column>
										</ListView.Columns>
									</ListView.Item>
								))
							)
						}
					</ListView>
				</ScrollBox>
				<div className="total">
					{t('dueInvoice.dueTotal').replace('{total}', priceFormatter(dueInvoices.reduce((p, c) => p + c.balance, 0), {symbol: false}))}
				</div>
				<div className="flex-row flex-center">
					<Button label={t('common.close')} onClick={onClose} />
				</div>
			</Modal>
		</div>
	)
}

export default DueInvoicesWindow
