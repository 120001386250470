import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { ShippingCostInfo } from '../../../types/Responses'
import apiClient from '../../../utils/api/apiClient'
import sliceStateBases, { SliceState } from '../../../utils/enums/sliceBaseStates'
import { statusHandler, thunkActions } from '../../../utils/utils'

export const getShippingCost = createAsyncThunk<ShippingCostInfo, {
	quantity: number
	notifyReceiver: boolean
	zipCode: string
}>(
	'shippingCostInfo/getShippingCost',
	async ({
		quantity,
		notifyReceiver,
		zipCode,
	}) => {
		return await apiClient.get('/api/ShippingCostRequest', {
			quantity,
			notifyReceiver,
			zipCode,
		})
	},
)

const actions = thunkActions(
	getShippingCost,
)

export interface ShippingCostInfoState {
	data: ShippingCostInfo | null
	showWindow: boolean
}

export const initialShippingCostInfoState: SliceState<'FEATURE', ShippingCostInfoState> = {
	...sliceStateBases.FEATURE,
	data: null,
	showWindow: false,
}

const shippingCostInfoSlice = createSlice({
	name: 'shippingCostInfo',
	initialState: initialShippingCostInfoState,
	reducers: {
		toggleShippingCostWindow(state) {
			state.showWindow = !state.showWindow
		},
	},
	extraReducers: builder => builder
		.addCase(getShippingCost.fulfilled, (state, action) => {
			state.data = action.payload
		})
		.addMatcher(actions, statusHandler)
})

export const {
	toggleShippingCostWindow,
} = shippingCostInfoSlice.actions

export const selectStatus = (state: RootState) => state.shipping.shippingCostInfo.status
export const selectShowWindow = (state: RootState) => state.shipping.shippingCostInfo.showWindow
export const selectShippingCostInfoData = (state: RootState) => state.shipping.shippingCostInfo.data
export const selectIsShippingCostInfoLoading = (state: RootState) => state.shipping.shippingCostInfo.isLoading

export default shippingCostInfoSlice.reducer
