import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { IMultiUserLink } from 'types/IMultiUserLinks'
import { getMultiUserLinks } from 'utils/api/MultiUserLinksAPI'
import sliceStateBases, { SliceState } from 'utils/enums/sliceBaseStates'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchMultiUserLinks = createAsyncThunk('multiUserLinks/fetchMultiUserLinks', async () => await getMultiUserLinks())
export const cartActions = thunkActions(fetchMultiUserLinks)

export interface SubCustomerOrdersState {
	data: IMultiUserLink[] | null
}

const initialMultiUserLinksState: SliceState<'FEATURE', SubCustomerOrdersState> = {
	...sliceStateBases.FEATURE,
	data: null
}

const multiUserLinks = createSlice({
	name: 'notificationsSlice',
	initialState: initialMultiUserLinksState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchMultiUserLinks.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(fetchMultiUserLinks.rejected, (state) => {
				state.data = null
			})
			.addMatcher(cartActions, statusHandler)
	}
})

export const selectMultiUserLinks = (state: RootState) => state.user.multiUserLinks.data
export const selectMultiUserLinksStatus = (state: RootState) => state.user.multiUserLinks.status

export default multiUserLinks.reducer