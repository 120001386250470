import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAccessoriesByProperties } from 'utils/api/articleSearchAPI'
import { createResultSelector } from 'utils/helper'
import { statusHandler, thunkActions } from 'utils/utils'

export const searchTubes = createAsyncThunk('industryTubesSearch/search', async (criteria) =>
	await getAccessoriesByProperties(
		criteria.articleText,
		0,
		criteria.mainGroupId,
		criteria.diameter, 
		criteria.width,
		criteria.aspectRatio
	))

const tubesActions = thunkActions(searchTubes)

const initialState = {
	status: 'idle',
	result: null,
	filterIds: null,
	filterState: null,
	sortBy: null,
	ascending: null,
	criteria: {
		selectedSize: '',
		width: '',
		aspectRatio: '',
		diameter: '',
		articleText: ''
	}
}

const industryTubesSearchSlice = createSlice({
	name: 'industryTubesSearch',
	initialState,
	reducers: {
		setCriteria: (state, action) => {
			state.criteria[action.payload.name] = action.payload.value
		},
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		}
	},
	extraReducers: builder => {
		builder
			.addCase(searchTubes.pending, state => {
				state.filterIds = null
				state.filterState = null
				state.result = null
			})
			.addCase(searchTubes.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(tubesActions, statusHandler)
	}
})

export const selectCriteria = state => state.search.tube.industryTubesSearch.criteria
export const selectStatus = state => state.search.tube.industryTubesSearch.status
export const selectResult = state => state.search.tube.industryTubesSearch.result
export const selectFilterIds = state => state.search.tube.industryTubesSearch.filterIds
export const selectFilterState = state => state.search.tube.industryTubesSearch.filterState
export const selectSortBy = state => state.search.tube.industryTubesSearch.sortBy
export const selectAscending = state => state.search.tube.industryTubesSearch.ascending

export const selectProcessedResult = createResultSelector(selectResult, selectFilterIds, selectSortBy, selectAscending)

export const { setCriteria, setFilter, sortResult } = industryTubesSearchSlice.actions

export default industryTubesSearchSlice.reducer
