import './LayoutWrapper.scss'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Header from 'components/Header/Header'
import { useDispatch, useSelector } from 'react-redux'
import { selectStartPageType } from 'features/app/settings/settingsSlice'
import { selectLoggedIn } from 'features/user/login/loginSlice'
import classNames from 'classnames'
import { 
	selectDocumentInfo, 
	setShowQuickSearch
} from 'features/app/documentInfo/documentInfoSlice'
import { isLoginPortalType } from 'utils/enums/webStartPageType'

const LayoutWrapper = ({
	children,
}) => {
	const loggedin = useSelector(selectLoggedIn)
	const dispatch = useDispatch()
	const startPageType = useSelector(selectStartPageType)
	const isLoginPortal = isLoginPortalType(startPageType)

	const { pathname } = useLocation()
	const {
		showAllProductsSearch,
	} = useSelector(selectDocumentInfo)

	useEffect(() => {
		// Close search whenever user navigates somewhere
		dispatch(setShowQuickSearch(false))
	}, [dispatch, pathname])

	return (
		<div className='LayoutWrapper'>
			<div
				className={classNames({
					'dark-bg hideable-with-transition': true,
					'hidden': !showAllProductsSearch,
				})}
				onClick={() => dispatch(setShowQuickSearch(false))}
			/>
			<div className='header'>
				{(!isLoginPortal || loggedin) && // No header on login page
					<Header />
				}
			</div>
			<div className='content'>
				{children}
			</div>
		</div>
	)
}

export default LayoutWrapper
