import './MultiplySelectPicker.scss'
import React, { useMemo } from 'react'
import { SelectPicker } from '@barnsten-it/gummidata-react-components'
import ListViewSelectPicker from '@barnsten-it/gummidata-react-components/dist/List/ListViewSelectPicker/ListViewSelectPicker'

interface MultiplySelectPickerProps {
	onChange: (value: any, name: string) => void
	name: string
	value: any
	count: number
	multiplyBy: number
	optionLabel: string
	isListViewPicker: boolean
}

const MultiplySelectPicker: React.FC<MultiplySelectPickerProps> = ({
	onChange,
	name,
	value,
	count,
	multiplyBy,
	optionLabel,
	isListViewPicker,
}) => {

	const options = useMemo(() => {
		let items = []

		for (let i = 1; i <= count; i++) {
			const value = i * multiplyBy
			items.push({ id: value, name: `${value} ${optionLabel}` })
		}

		return items
	}, [count, multiplyBy, optionLabel])

	const Picker = isListViewPicker
		? ListViewSelectPicker
		: SelectPicker

	return (
		<div className='MultiplySelectPicker'>
			<Picker
				name={name}
				value={value}
				onChange={onChange}
			>
				{options.map(o =>
					<Picker.Option
						key={o.id}
						name={o.name}
						value={o.id}
					/>
				)}
			</Picker>
		</div>
	)
}

export default MultiplySelectPicker