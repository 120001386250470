import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Filter } from '@barnsten-it/gummidata-react-components'
import { filterOnlyUnique, sortAscending } from 'utils/utils'
import { getAllArticles, isAllSeasonTyre, isCTyre, isInchTyre, isStuddableTyre, isStuddedTyre, isSummerTyre, isWinterFrictionTyre, isWinterTyre } from 'utils/helper'
import mainGroups from 'utils/enums/MainGroups'
import frictionRegion from 'utils/enums/frictionRegion'
import { useSelector } from 'react-redux'
import { selectSettings, selectFeatureConfig } from 'features/app/settings/settingsSlice'
import FilterContainer from '@barnsten-it/gummidata-react-components/dist/Filter/FilterContainer/FilterContainer'
import tyreType from 'utils/enums/tyreType'
import { useCommonFilterSettings } from 'utils/hooks/useCommonFilterSettings'

const TyreFilter = ({
	result,
	onFilterChange,
	showDimensions,
	showOriginalFit,
	showTyreTypeFilter,
	filterState
}) => {
	const [filterSettings, setFilterSettings] = useState(null)
	const [articles, setArticles] = useState(null)
	const settings = useSelector(selectSettings)
	const featureConfig = useSelector(selectFeatureConfig)
	const filterConfig = settings.filterConfig
	const { t } = useTranslation()
	const commonFilterSettings = useCommonFilterSettings()

	const createFilter = useCallback(() => {
		if (!result)
			return

		let settings = []

		const allArticles = getAllArticles(result)
		const showFrictionAsNonStudded = featureConfig.showFrictionAsStudFree
		const hasOutletArticles = allArticles.some(a => a.isOutlet)

		const brandNameSort = allArticles
			.filter(article => !article.isHouseBrand)
			.map(article => article.brandName)
			.filter(filterOnlyUnique)
			.sort()

		const sortedBrands = allArticles
			.filter(article => article.isHouseBrand)
			.map(article => article.brandName)
			.sort()
			.concat(brandNameSort)
			.filter(filterOnlyUnique)

		const oeValues = allArticles
			.filter(i => i.oeMarking && i.oeMarking !== '')
			.flatMap(i => i.oeMarking.split(/\s|\)\(/))
			.map(t => t.replace(/\(|\)/g, ''))
			.filter(filterOnlyUnique)
			.sort(sortAscending)

		let loadIndexes = []
		allArticles.map(i => i.loadIndex)
			.filter(i => i !== null && i !== '')
			.forEach(m => {
				const indexes = m.split('/')
				indexes.forEach(i => loadIndexes.push(i))
			})

		loadIndexes = loadIndexes
			.filter(filterOnlyUnique)
			.sort(sortAscending)

		// If 'All tyres' selected,
		// make it possible to filter out by winter, summer etc.
		if (showTyreTypeFilter) {
			settings = [
				...settings,
				{
					name: t('common.tyreType'),
					property: 'tyreType2',
					type: Filter.type.multipleSelect,
					items: [
						{
							value: tyreType.summer,
							text: t('common.summerTire'),
						},
						{
							value: tyreType.allSeason,
							text: t('common.allSeasonTires'),
						},
						{
							value: tyreType.winter,
							text: t('common.winterTire'),
						},
					],
					condition: (item, selectedValues) => {
						const { mainGroupId } = item

						if (
							selectedValues.includes(tyreType.summer) &&
							isSummerTyre(mainGroupId)
						) {
							return true
						} else if (
							selectedValues.includes(tyreType.allSeason) &&
							(isAllSeasonTyre(mainGroupId))
						) {
							return true
						} else if (
							selectedValues.includes(tyreType.winter) &&
							isWinterTyre(mainGroupId)
						) {
							return true
						}

						return false
					},
				},
			]
		}

		if (hasOutletArticles) {
			settings = [
				...settings,
				...commonFilterSettings.outlet
			]
		}

		// Add winter tyre filters
		if (allArticles.some(a => isWinterTyre(a.mainGroupId)) && !showFrictionAsNonStudded) {
			settings = [
				...settings,
				{
					name: t('common.winterTire'),
					property: 'tyreType',
					type: Filter.type.multipleSelect,
					items: [
						{
							value: 1,
							text: t('searchFilter.nordicFriction')
						},
						{
							value: 2,
							text: t('searchFilter.europeFriction')
						},
						{
							value: 3,
							text: t('searchFilter.studded')
						},
						{
							value: 4,
							text: t('searchFilter.studdable')
						},
					],
					condition: (item, selected) => {
						const frictionNordic = selected.includes(1)
							&& isWinterFrictionTyre(item.mainGroupId)
							&& item.frictionRegion === frictionRegion.Nordic

						const frictionEuropean = selected.includes(2)
							&& isWinterFrictionTyre(item.mainGroupId)
							&& item.frictionRegion === frictionRegion.European

						const studdedCondition = selected.includes(3)
							&& isStuddedTyre(item.mainGroupId)

						const studdedableCondition = selected.includes(4)
							&& isStuddableTyre(item.mainGroupId)

						return studdedCondition || frictionNordic || frictionEuropean || studdedableCondition
					}
				}
			]
		}

		if (allArticles.some(a => isWinterTyre(a.mainGroupId)) && showFrictionAsNonStudded) {
			settings = [
				...settings,
				{
					name: t('common.winterTire'),
					property: 'tyreType',
					type: Filter.type.multipleSelect,
					items: [
						{
							value: 1,
							text: t('searchFilter.studFree')
						},
						{
							value: 2,
							text: t('searchFilter.studded')
						},
						{
							value: 3,
							text: t('searchFilter.studdable')
						},
					],
					condition: (item, selected) => {
						const friction = selected.includes(1)
							&& isWinterFrictionTyre(item.mainGroupId)

						const studdedCondition = selected.includes(2)
							&& isStuddedTyre(item.mainGroupId)

						const studdedableCondition = selected.includes(3)
							&& isStuddableTyre(item.mainGroupId)

						return studdedCondition || friction || studdedableCondition
					}
				}
			]
		}

		/**
		 * Properties
		 */
		const properties = {
			name: t('searchFilter.properties'),
			group: [
				{
					name: t('searchFilter.extraLoad'),
					property: 'extraLoad',
					type: Filter.type.checkbox
				},
				{
					name: t('searchFilter.runFlat'),
					property: 'runFlat',
					type: Filter.type.checkbox
				},
				{
					name: t('searchFilter.electric'),
					property: 'electric',
					type: Filter.type.checkbox
				},
				{
					name: t('searchFilter.sealInside'),
					property: 'sealInside',
					type: Filter.type.checkbox
				},
				{
					name: t('searchFilter.noiseCancelling'),
					property: 'noiseCancelling',
					type: Filter.type.checkbox
				},
				{
					name: t('searchFilter.threePMSF'),
					property: 'treePMSF',
					type: Filter.type.checkbox
				},
				{
					name: t('searchFilter.camping'),
					property: 'camping',
					type: Filter.type.checkbox
				}
			]
		}

		if (showOriginalFit) {
			properties.group.push({
				name: t('shopPage.originalFit'),
				property: 'isOE',
				type: Filter.type.checkbox
			})
		}

		if (filterConfig.tyreCombination && allArticles.some(a => a.axle !== null)) {
			properties.group.push({
				name: t('searchFilter.rimCombination'),
				property: 'combination',
				type: Filter.type.checkbox,
				condition: (item) => item.axle !== null,
				preSelected: filterConfig.selectCombination
			})
		}

		// Add C-Tyre filter
		if (allArticles.some(a => isCTyre(a.mainGroupId))) {
			properties.group.push({
				name: t('searchFilter.tyreTypeC'),
				property: 'cTyre',
				type: Filter.type.checkbox,
				condition: (item, selected) => {
					return selected && isCTyre(item.mainGroupId)
				}
			})
		}

		// Mud and snow filter & 3PMSF
		if (allArticles.some(a => a.mainGroupId === mainGroups.lvTyre)) {
			properties.group.push({
				name: t('searchFilter.mudAndSnow'),
				property: 'mudAndSnow',
				type: Filter.type.checkbox,
				condition: (item, selected) => {
					return selected && item.mainGroupId === mainGroups.lvTyre && item.mudAndSnow
				}
			})

			properties.group.push({
				name: t('searchFilter.threePMSF'),
				property: 'threePMSF',
				type: Filter.type.checkbox,
				condition: (item, selected) => {
					return selected && item.mainGroupId === mainGroups.lvTyre && item.treePMSF
				}
			})
		}

		if (allArticles.some(a => isWinterTyre(a.mainGroupId) || isSummerTyre(a.mainGroupId))) {
			properties.group.push({
				name: t('searchFilter.mudAndSnow'),
				property: 'mudAndSnow',
				type: Filter.type.checkbox
			})
		}
		settings.push(properties)

		if (showDimensions) {
			const inchTyres = allArticles.some(a => isInchTyre(a.mainGroupId))

			settings = [
				...settings,
				{
					name: t('searchFilter.diameter'),
					property: 'diameter',
					type: Filter.type.multipleSelect
				},
				{
					name: t('common.width'),
					property: 'width',
					type: Filter.type.range,
					step: inchTyres ? 0.5 : 5
				},
				{
					name: t('common.aspectRatio'),
					property: 'aspectRatio',
					type: Filter.type.range,
					step: inchTyres ? 1 : 5
				},
			]
		}

		settings = [
			...settings,
			{
				name: t('searchFilter.brand'),
				property: 'brandName',
				type: Filter.type.multipleSelect,
				items: sortedBrands,
				enableLimit: true,
				sortEnabled: true,
				showMoreText: t('common.showMore')
			},
			{
				name: t('searchFilter.pattern'),
				property: 'patternName',
				type: Filter.type.multipleSelect,
				enableLimit: true,
				sortEnabled: true,
				showMoreText: t('common.showMore'),
			},
			{
				name: t('searchFilter.oeMarking'),
				property: 'OEMarking',
				items: oeValues,
				type: Filter.type.multipleSelect,
				enableLimit: true,
				showMoreText: t('common.showMore'),
				condition: (item, selected) => {
					if (!item.oeMarking)
						return false

					const markings = item.oeMarking
						.split(/\s|\)\(/)
						.map(t => t.replace(/\(|\)/g, ''))

					return selected.some(s => markings.includes(s))
				}
			}
		]

		if (allArticles.some(a => a.position !== null && a.mainGroupId === mainGroups.tyreMC)) {
			settings = [
				...settings,
				{
					name: t('searchFilter.position'),
					property: 'position',
					type: Filter.type.multipleSelect,
					items: [
						{
							value: 1,
							text: t('tyrePosition.universal')
						},
						{
							value: 2,
							text: t('tyrePosition.front')
						},
						{
							value: 3,
							text: t('tyrePosition.rear')
						},
					],
					condition: (item, selected) => {
						const universal = selected.includes(1) && item.position === null
						const front = selected.includes(2) && item.position === 6
						const rear = selected.includes(3) && item.position === 7
						return universal || front || rear
					}
				}
			]
		}

		if (allArticles.some(a => a.position !== null && a.mainGroupId !== mainGroups.tyreMC)) {
			settings = [
				...settings,
				{
					name: t('searchFilter.position'),
					property: 'position',
					type: Filter.type.multipleSelect,
					items: [
						{
							value: 1,
							text: t('tyrePosition.allPosition')
						},
						{
							value: 2,
							text: t('tyrePosition.steer')
						},
						{
							value: 3,
							text: t('tyrePosition.drive')
						},
						{
							value: 4,
							text: t('tyrePosition.trailer')
						},
						{
							value: 5,
							text: t('tyrePosition.steerAndTrailer')
						},
					],
					condition: (item, selected) => {
						const allPosition = selected.includes(1) && item.position === 1
						const steer = selected.includes(2) && item.position === 2
						const drive = selected.includes(3) && item.position === 3
						const trailer = selected.includes(4) && item.position === 4
						const steerAndTrailer = selected.includes(5) && item.position === 5
						return allPosition || steer || drive || trailer || steerAndTrailer
					}
				}
			]
		}

		if (allArticles.some(a => a.usageTypeText !== '')) {
			settings = [
				...settings,
				{
					name: t('common.usageType'),
					property: 'usageTypeText',
					type: Filter.type.multipleSelect
				}
			]
		}


		/** 
		 * Label filters
		 */
		if (allArticles.some(a => a.fuelEffiency !== '')) {
			settings = [
				...settings,
				{
					name: t('searchFilter.fuelEffiency'),
					property: 'fuelEffiency',
					type: Filter.type.multipleSelect,
					defaultHidden: true
				}
			]
		}
		if (allArticles.some(a => a.wetGrip !== '')) {
			settings = [
				...settings,
				{
					name: t('searchFilter.wetGrip'),
					property: 'wetGrip',
					type: Filter.type.multipleSelect,
					defaultHidden: true
				}
			]
		}
		if (allArticles.some(a => a.speedIndex !== '')) {
			settings = [
				...settings,
				{
					name: t('common.speedIndex'),
					property: 'speedIndex',
					type: Filter.type.multipleSelect,
					defaultHidden: true
				}
			]
		}
		if (allArticles.some(a => a.noiceClass !== null)) {
			settings = [
				...settings,
				{
					name: t('searchFilter.noiceClass'),
					property: 'noiceClass',
					type: Filter.type.multipleSelect,
					defaultHidden: true
				}
			]
		}
		if (allArticles.some(a => a.noiceValue !== null)) {
			settings = [
				...settings,
				{
					name: t('searchFilter.noiceValue'),
					property: 'noiceValue',
					type: Filter.type.range,
					defaultHidden: true
				}
			]
		}
		if (allArticles.some(a => a.loadIndex !== '')) {
			settings = [
				...settings,
				{
					name: t('common.loadIndex'),
					property: 'loadIndex',
					type: Filter.type.range,
					defaultHidden: true,
					items: loadIndexes,
					condition: (item, selected) => {
						if (!item.loadIndex)
							return false

						const loadIndexes = item.loadIndex.split('/')
						return loadIndexes.some(loadIndex => loadIndex >= selected[0] && loadIndex <= selected[1])
					}
				}
			]
		}

		setArticles(allArticles)
		setFilterSettings(settings)
	}, [t, result, showDimensions, filterConfig, showOriginalFit, featureConfig, showTyreTypeFilter, commonFilterSettings.outlet])

	useEffect(() => {
		if (result)
			createFilter()
	}, [result, createFilter])

	if (!filterSettings || !articles || !result)
		return null

	return (
		<div className="TyreFilter">
			<FilterContainer hasResult={!!result}>
				<Filter
					identifier="id"
					settings={filterSettings}
					label={t('common.searchFilter')}
					selected={filterState?.selected}
					items={articles}
					onFilterChange={onFilterChange} />
			</FilterContainer>
		</div>
	)
}

export default TyreFilter
