import apiClient from './apiClient'

/**
 * Fetch posts
 * @returns Multiple posts
 */
export const getPosts = async (payload) => await apiClient.get('/api/WebPosts', payload)

/**
 * Fetch post by Id
 * @returns Single post
 */
export const getPost = async (id) => await apiClient.get('/api/WebPost', id)

/**
 * Fetch regions
 * @returns list of regions
 */
export const getRegions = async () => await apiClient.get('/api/WebPostRegions')

/**
 * Save edited post
 * @param {*} payload
 * @returns
 */
export const putPost = async (id, payload) => await apiClient.put(`/api/WebPost/${id}`, payload)

/**
 * Create new post
 * @param {*} payload
 * @returns
 */
export const postPost = async (payload) => await apiClient.post('/api/WebPost', payload)


/**
 * Delete post
 * @param {*} payload
 * @returns
 */
export const deletePost = async (id) => await apiClient.delete(`/api/WebPost/${id}`)
