import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { InvoiceSearchCriteriaModel, InvoiceSearchResultModel } from 'types/api'
import apiClient from 'utils/api/apiClient'
import { statusHandler, thunkActions } from 'utils/utils'

export const getInvoiceHistory = createAsyncThunk<InvoiceSearchResultModel, InvoiceSearchCriteriaModel>('invoiceHistory/getInvoiceHistory', async (payload: InvoiceSearchCriteriaModel) => 
	await apiClient.get('/api/Invoices', payload)
)

export const invoiceAction = thunkActions(getInvoiceHistory)

interface InvoiceHistoryState {
	status: string
	data: InvoiceSearchResultModel | null
}

const initialState: InvoiceHistoryState = {
	status: 'idle',
	data: null,
}

const invoiceHistorySlice = createSlice({
	name: 'invoiceHistory',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getInvoiceHistory.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(getInvoiceHistory.rejected, (state) => {
				state.data = null
			})
			.addMatcher(invoiceAction, statusHandler)
	},
})

export const selectInvoiceHistoryStatus = (state: RootState) => state.user.invoiceHistory.status
export const selectInvoiceHistory = (state: RootState) => state.user.invoiceHistory.data

export default invoiceHistorySlice.reducer