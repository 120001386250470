import classNames from 'classnames'
import './MainLabel.scss'
import React from 'react'

interface MainLabelProps {
	text: string
	noTopMargin?: boolean
	noBottomMargin?: boolean
}

export const MainLabel: React.FC<MainLabelProps> = ({
	text,
	noTopMargin = false,
	noBottomMargin = false
}) => {
	return (
		<label className={classNames('MainLabel', {
			'no-top-margin': noTopMargin,
			'no-bottom-margin': noBottomMargin
		})}>
			{text}
		</label>
	)
}

export default MainLabel