import * as React from 'react'
import { SVGProps } from 'react'
const SvgPdfIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		viewBox="0 0 481.882 481.882"
		width="1em"
		height="1em"
		{...props}
	>
		<path
			d="M60.235 28.913C60.235 12.943 73.178 0 89.148 0h212.028l120.471 101.195v351.774c0 15.97-12.943 28.913-28.913 28.913H89.148c-15.97 0-28.913-12.943-28.913-28.913V28.913z"
			style={{
				fill: '#ef5350',
			}}
		/>
		<path
			d="M301.176 0v72.282c0 15.97 12.943 28.913 28.913 28.913h91.558"
			style={{
				fill: '#d32f2f',
			}}
		/>
		<path
			d="M150.904 305.536H139.61v34.643h-25.005v-104.38h32.986c28.469 0 44.732 11.144 44.732 34.04-.007 24.561-16.722 35.697-41.419 35.697zm-3.011-51.968h-8.282v33.89h9.186c11.294 0 17.769-5.429 17.769-17.626 0-10.986-6.174-16.264-18.673-16.264zM231.77 340.179h-28.318v-104.38h26.511c26.82 0 52.412 8.584 52.412 51.674.001 42.451-24.553 52.706-50.605 52.706zm1.8-86.31h-5.12v68.239h5.12c13.704 0 22.889-7.379 22.889-34.65.015-27.851-9.78-33.589-22.889-33.589zM325.888 253.568v27.422h36.45v17.92h-36.45v41.269h-25.156v-104.38h68.834l-2.56 17.769h-41.118z"
			style={{
				fill: '#fff',
			}}
		/>
	</svg>
)
export default SvgPdfIcon
