import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sendResellerApplication } from 'utils/api/contactAPI'

export const sendApplication = createAsyncThunk('contanct/sendApplication', async (payload) => await sendResellerApplication(payload))

const resellerApplicationSlice = createSlice({
	name: 'contact',
	initialState: {
		status: 'idle',
		showWindow: false,
		applicationForm: {
			name: '',
			companyId: '',
			contactPerson: '',
			address: '',
			zipCode: '',
			city: '',
			country: '',
			telephone: '',
			telefax: '',
			email: '',
			emailInvoice: '',
			hasTaxNote: false,
			hasMountingMachine: false,
			hasBalancingMachine: false
		}
	},
	reducers: {
		openApplicationWindow: state => {
			state.showWindow = true
			state.status = 'idle'
		},
		closeApplicationWindow: state => {
			state.showWindow = false
			state.status = 'idle'
		},
		setApplicationForm: (state, action) => {
			state.applicationForm[action.payload.name] = action.payload.value
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(sendApplication.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(sendApplication.fulfilled, (state) => {
				state.status = 'success'
				state.applicationForm = {
					name: '',
					companyId: '',
					contactPerson: '',
					address: '',
					zipCode: '',
					city: '',
					country: '',
					telephone: '',
					telefax: '',
					email: '',
					emailInvoice: '',
					hasTaxNote: false,
					hasMountingMachine: false,
					hasBalancingMachine: false
				}
			})
			.addCase(sendApplication.rejected, (state) => {
				state.status = 'failed'
			})
	}
})

export const { openApplicationWindow, closeApplicationWindow, setApplicationForm } = resellerApplicationSlice.actions

export const selectShowWindow = state => state.resellerApplication.showWindow
export const selectApplicationForm = state => state.resellerApplication.applicationForm
export const selectStatus = state => state.resellerApplication.status

export default resellerApplicationSlice.reducer