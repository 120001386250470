import { Notification } from '@barnsten-it/gummidata-react-components'
import { TFunction } from 'react-i18next'

export type NotificationId =
	| 'saved'
	| 'errorTryAgain'
	| 'pleaseFixErrors'
	| 'pleaseHoldOn'
	| 'addedToCart'
	| 'cartUnableToChange'
	| 'cantRemoveStandardAddress'
	| 'newPasswordSaved'
	| 'customerNotFound'

/**
 * Configurations for notifications.
 */
export const notifications: {
	[k in NotificationId]: (t: TFunction<'translation'>) => Notification
} = {
	saved: t => ({
		id: 'saved',
		type: 'success',
		text: t('common.saved'),
	}),
	errorTryAgain: t => ({
		id: 'errorTryAgain',
		type: 'error',
		text: t('common.errorTryAgain'),
	}),
	pleaseFixErrors: t => ({
		id: 'pleaseFixErrors',
		type: 'error',
		text: t('common.pleaseFixErrors'),
	}),
	pleaseHoldOn: t => ({
		id: 'pleaseHoldOn',
		type: 'message',
		text: t('common.pleaseHoldOn'),
	}),
	addedToCart: t => ({
		id: 'addedToCart',
		type: 'success',
		text: t('common.addedToCart'),
	}),
	cartUnableToChange: t => ({
		id: 'cartUnableToChange',
		type: 'error',
		text: t('cartPage.unableToChange'),
	}),
	cantRemoveStandardAddress: t => ({
		id: 'cantRemoveStandardAddress',
		type: 'error',
		text: t('common.cantRemoveStandardAddress'),
	}),
	newPasswordSaved: t => ({
		id: 'newPasswordSaved',
		type: 'success',
		text: t('userPage.newPasswordSaved'),
	}),
	customerNotFound: t => ({
		id: 'customerNotFound',
		type: 'error',
		text: t('adminPage.customerNotFound')
	})
	// TEMPLATE: t => ({}),
} as const
