import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectResult, setFilter, selectFilterState } from '../motoTubesSearchSlice'
import TubeFilter from 'components/filters/TubeFilter/TubeFilter'

const MotoTubeSearchFilter = () => {
	const dispatch = useDispatch()
	const result = useSelector(selectResult)
	const filterState = useSelector(selectFilterState)
	const handleFilterChange = (articleIds, filterState) => {
		dispatch(setFilter({ articleIds, filterState }))
	}

	return (
		<div className="MotoTubeSearchFilter">
			{
				result &&
				<TubeFilter 
					result={result} 
					filterState={filterState}
					onFilterChange={handleFilterChange} />
			}
		</div>
	)
}

export default MotoTubeSearchFilter