import * as React from 'react'
import { SVGProps } from 'react'
const SvgTpmsIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 19 19"
		width="1em"
		height="1em"
		{...props}
	>
		<path d="M8.67 10.207h1.833v1.834H8.669v-1.834Zm0-7.333h1.833v5.5H8.669v-5.5Zm5.5-.22c1.704 1.31 2.75 3.53 2.75 5.72 0 2.75-1.531 5.17-3.796 6.417H6.048a7.333 7.333 0 0 1-3.795-6.417c0-2.19.999-4.428 2.75-5.72V.124H3.169v1.705A9.145 9.145 0 0 0 .42 8.374a9.145 9.145 0 0 0 2.75 6.545v3.538h1.834v-1.833h1.833v1.833H8.67v-1.833h1.834v1.833h1.833v-1.833h1.833v1.833h1.834V14.92a9.145 9.145 0 0 0 2.75-6.545 9.145 9.145 0 0 0-2.75-6.545V.124h-1.834v2.53Z" />
	</svg>
)
export default SvgTpmsIcon
