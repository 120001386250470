import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getTruckTyreSizes } from 'utils/api/dimensionsAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchTruckTyreSizes = createAsyncThunk('truckTyreSizes/getTruckTyreSizes', async (multi = false) => await getTruckTyreSizes(multi))

const fetchTruckTyreSizesAction = thunkActions(fetchTruckTyreSizes)

const truckTyreSizeSlice = createSlice({
	name: 'truckTyreSizes',
	initialState: {
		status: 'idle',
		sizes: null
	},
	extraReducers: builder => {
		builder
			.addCase(fetchTruckTyreSizes.fulfilled, (state, action) => {
				state.sizes = action.payload
			})
			.addMatcher(fetchTruckTyreSizesAction, statusHandler)
	}
})

export const selectTruckTyreSizesStatus = state => state.dimensions.truckTyreSizes.status
export const selectTruckTyreSizes = state => state.dimensions.truckTyreSizes.sizes

export default truckTyreSizeSlice.reducer
