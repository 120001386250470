import './IconValueRow.scss'
import React from 'react'
import Icon from '@mdi/react'

export interface IconValueRowProps {
	icon: string
	value: string | JSX.Element | undefined | null
}

/**
 * IconValueRow
 * @param props
 */
const IconValueRow: React.FC<IconValueRowProps> = ({
	icon,
	value
}) => {
	// Return if value is null or undefined
	if (!value || value === undefined) {
		return null
	}

	return (
		<div className="IconValueRow">
			<span className="icon">
				<Icon path={icon} size={'1.25rem'} />
			</span>
			<span>
				{value}
			</span>
		</div>
	)
}

export default IconValueRow