import * as React from 'react'
import { SVGProps } from 'react'
const SvgLogoutIconSquare = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		{...props}
	>
		<path
			fill="#444"
			d="m11.733 12.992 2.159-2.159H5.833V9.167h8.059l-2.159-2.159 1.184-1.175L17.083 10l-4.166 4.167-1.184-1.175Zm4.1-10.492A1.666 1.666 0 0 1 17.5 4.167v3.891l-1.667-1.666V4.167H4.167v11.666h11.666v-2.225l1.667-1.666v3.891a1.666 1.666 0 0 1-1.667 1.667H4.167c-.925 0-1.667-.75-1.667-1.667V4.167A1.66 1.66 0 0 1 4.167 2.5h11.666Z"
		/>
	</svg>
)
export default SvgLogoutIconSquare
