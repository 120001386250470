import MainGroups from './enums/MainGroups'
import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import { searchCategoryChange } from './redux/commonActions'

/**
 * check if mainGroupId is summer
 * @param {MainGroups} mainGroupId
 */
export const isSummerTyre = (mainGroupId: MainGroups) => {
	const summer = [
		MainGroups.summerTyrePassengerCar,
		MainGroups.summerTyreInch,
		MainGroups.summerTyreC,
	]
	return summer.includes(mainGroupId)
}

/**
 * Check if mainGroupId is winter
 * @param {MainGroups} mainGroupId
 */
export const isWinterTyre = (mainGroupId: MainGroups) => {
	const winter = [
		MainGroups.winterTyreFriction,
		MainGroups.winterTyreFrictionC,
		MainGroups.winterTyreCStuddable,
		MainGroups.winterTyreCStudded,
		MainGroups.winterTyreInch,
		MainGroups.winterTyreStuddable,
		MainGroups.winterTyreStudded,
	]
	return winter.includes(mainGroupId)
}

/**
 * Check if mainGroupId is all season
 * @param {MainGroups} mainGroupId
 */
export const isAllSeasonTyre = (mainGroupId: MainGroups) => {
	const allSeason = [MainGroups.allSeasonTyre, MainGroups.allSeasonLightTruck]
	return allSeason.includes(mainGroupId)
}

/**
 * Check for friction winter tyre
 * @param {MainGroups} mainGroupId
 * @returns
 */
export const isWinterFrictionTyre = (mainGroupId: MainGroups) => {
	const tyres = [
		MainGroups.winterTyreFriction,
		MainGroups.winterTyreFrictionC,
		MainGroups.winterTyreInch,
	]

	return tyres.includes(mainGroupId)
}

/**
 * Check for studded tyre
 * @param {MainGroups} mainGroupId
 * @returns
 */
export const isStuddedTyre = (mainGroupId: MainGroups) => {
	return (
		mainGroupId === MainGroups.winterTyreStudded ||
		mainGroupId === MainGroups.winterTyreCStudded
	)
}

/**
 * Check for studdable tyre
 * @param {MainGroups} mainGroupId
 * @returns
 */
export const isStuddableTyre = (mainGroupId: MainGroups) => {
	return (
		mainGroupId === MainGroups.winterTyreStuddable ||
		mainGroupId === MainGroups.winterTyreCStuddable
	)
}

/**
 * Check for C-tyretype
 * @param {MainGroups} mainGroupId
 * @returns
 */
export const isCTyre = (mainGroupId: MainGroups) => {
	const tyres = [
		MainGroups.summerTyreC,
		MainGroups.winterTyreFrictionC,
		MainGroups.winterTyreCStuddable,
		MainGroups.winterTyreCStudded,
	]
	return tyres.includes(mainGroupId)
}

/**
 * Check for inch tyres
 * @param {MainGroups} mainGroupId
 * @returns
 */
export const isInchTyre = (mainGroupId: MainGroups) => {
	const tyres = [MainGroups.summerTyreInch, MainGroups.winterTyreInch]

	return tyres.includes(mainGroupId)
}

/**
 * Check if maingroup is rim
 * @param {MainGroups} mainGroupId
 * @returns
 */
export const isRim = (mainGroupId: MainGroups) => {
	const groups = [
		MainGroups.steelRim,
		MainGroups.steelRimLV,
		MainGroups.aluminumRim,
	]

	return groups.includes(mainGroupId)
}

/**
 * Check if maingroup is accessory
 * @param {MainGroups} mainGroupId
 * @returns
 */
export const isAccessory = (mainGroupId: MainGroups) => {
	const groups = [
		MainGroups.accessoriesCar,
		MainGroups.accessoriesIndustry,
		MainGroups.accessoriesBattery,
		MainGroups.accessoriesOil,
		MainGroups.tpmsPcr,
		MainGroups.tpmsTbr,
		MainGroups.tubes,
		MainGroups.tubesIndustry,
		MainGroups.tubesMoto,
	]
	return groups.includes(mainGroupId)
}

/**
 * Check if mangroup is battery
 * @param {MainGroups} mainGroupId
 * @returns
 */
export const isBattery = (mainGroupId: MainGroups) => {
	return mainGroupId === MainGroups.battery
}

export const isOil = (mainGroupId: MainGroups) => {
	return mainGroupId === MainGroups.oil
}

/**
 * Check if maingroup is tyre
 * @param {MainGroups} mainGroupId
 * @returns
 */
export const isTyre = (mainGroupId: MainGroups) => {
	return mainGroupId < 100
}

/**
 * Get articles from all collections in an object or from an array.
 * @param {Array<any> | object} collection - The collection to extract articles from.
 * @returns {any[]} An array of articles.
 */
export const getAllArticles = (collection: any[] | object): any[] => {
	if (Array.isArray(collection)) {
		return getArticles(collection)
	} else {
		return getArticlesFromObject(collection as Record<string, any[]>)
	}
}

/**
 * Get articles from a collection, e.g., from the ToOrder property in a result.
 * @param {Array<any>} collection - The collection to extract articles from.
 * @returns {any[]} An array of articles.
 */
export const getArticles = (collection: any[]): any[] => {
	const articles: any[] = []
	if (!collection) return articles

	for (const value of collection) {
		if (Array.isArray(value.articles)) {
			articles.push(...value.articles)
		} else {
			articles.push(value)
		}
	}

	return articles
}

/**
 * Get all articles from an object, e.g., an object with InStock, ToOrder, and so on.
 * @param {object} object - The object containing various collections of articles.
 * @returns {any[]} An array of articles.
 */
export const getArticlesFromObject = (object: Record<string, any[]>): any[] => {
	let articles: any[] = []

	if (!object) return articles

	Object.keys(object).forEach((key) => {
		if (object[key] && Array.isArray(object[key])) {
			articles = articles.concat(
				object[key].reduce((acc: any[], value: any) => {
					if (value && value.articles) {
						return acc.concat(value.articles)
					}
					return acc
				}, []),
			)
		}
	})

	return articles
}

/**
 * Filter a collection by IDs.
 * @param {any[] | Record<string, any>} collection - The collection to filter.
 * @param {number[]} articleIds - An array of numbers representing the IDs that should be visible.
 * @returns {any[] | Record<string, any> | null} The filtered collection or null if input is invalid.
 */
export const filterArticles = (
	collection: any[] | Record<string, any>,
	articleIds: number[],
): any[] | Record<string, any> | null => {
	if (!collection || !articleIds) return null

	const clonedCollection = cloneDeep(collection)

	if (Array.isArray(clonedCollection)) {
		clonedCollection.forEach((group: any) => {
			group.articles =
				group.articles?.filter((a: any) => articleIds.includes(a.id)) ??
				group.articles
		})
	} else {
		Object.keys(clonedCollection).forEach((key) => {
			if (clonedCollection[key] && Array.isArray(clonedCollection[key])) {
				clonedCollection[key].forEach((group: any) => {
					group.articles =
						group.articles?.filter((a: any) => articleIds.includes(a.id)) ??
						group.articles
				})
			}
		})
	}

	return clonedCollection
}

/**
 * Check if userRoles has any of the provided roles.
 * @param {Array<string>} userRoles - Array of user roles.
 * @param {Array<string>} roles - Roles to check against.
 * @returns {boolean} True if any role matches, otherwise false.
 * @deprecated Use `useUserRoles` instead.
 */
export const hasAnyRole = (
	userRoles: string[],
	...roles: string[]
): boolean => {
	if (!userRoles || !roles) return false

	return userRoles.some((r) => roles.indexOf(r) !== -1)
}

/**
 * Sort result by property.
 * @param {any[]} collection - The collection to sort.
 * @param {string} property - The property to sort by.
 * @param {boolean} ascending - Sort direction, true for ascending.
 * @returns {any[] | null} The sorted collection or null if input is invalid.
 */
export const sortArticles = (
	collection: any[],
	property: string,
	ascending: boolean = false,
): any[] | null => {
	if (!collection || !property) return null

	const sortDirection = ascending ? 1 : -1
	const clonedCollection = cloneDeep(collection)

	Object.keys(clonedCollection).forEach((key: any) => {
		if (clonedCollection[key] && Array.isArray(clonedCollection[key])) {
			if (property === 'name') {
				clonedCollection[key].forEach((group) => {
					group.articles = sortBy(group.articles, [
						(a: any) => a.brandName,
						(a: any) => a.patternName,
						(a: any) => a.modelName,
					])
				})
			} else if (property === 'stock') {
				clonedCollection[key].forEach((group) => {
					group.articles = sortBy(group.articles, [
						(a: any) => a.quantityCustomerStock * sortDirection,
						(a: any) => a.quantityAvailable * sortDirection,
						(a: any) =>
							(a.quantityExternal &&
							a.externalDeliveryTime &&
							a.externalDeliveryTime.length > 0
								? 4
								: a.quantityExternal) < 4,
						(a: any) => {
							if (!a.externalDeliveryTime) return 0
							const day = a.externalDeliveryTime.split('-')
							return Number(day[0])
						},
						(a: any) => a.quantityExternal * sortDirection,
					])
				})
			} else {
				clonedCollection[key].forEach((group) => {
					group.articles = orderBy(
						group.articles,
						[property],
						[ascending ? 'asc' : 'desc'],
					)
				})
			}
		}
	})

	return clonedCollection
}

/**
 * Sort premade wheel packages.
 * @param {any[]} collection - The collection to sort.
 * @param {string} property - The property to sort by.
 * @param {boolean} ascending - Sort direction, true for ascending.
 * @returns {any[]} The sorted collection or null if input is invalid.
 */
export const sortPremadeWheelCollection = (
	collection: any[],
	property: string,
	ascending: boolean = false,
): any[] | null => {
	if (!collection || !property) return null

	let clonedCollection = cloneDeep(collection)
	if (property === 'name') property = 'carTypeName'

	clonedCollection = orderBy(
		clonedCollection,
		[property],
		[ascending ? 'asc' : 'desc'],
	)

	return clonedCollection
}

/**
 * Get sorting settings based on the provided criteria.
 * @param {string} sortBy - The property to sort by.
 * @param {boolean} showRetailPrice - Whether to show retail price.
 * @param {boolean} ascendingBy - Current sort direction.
 * @param {string} selectedSort - The currently selected sort.
 * @returns {{ name: string, property: string, ascending: boolean }} The sort settings.
 */
export const getSortSettings = (
	sortBy: string,
	showRetailPrice: boolean,
	ascendingBy: boolean = true,
	selectedSort: string = '',
): { name: string; property: string; ascending: boolean } => {
	let sortName = sortBy
	let ascending = ascendingBy

	const priceHeaders = ['netPrice', 'price', 'priceIncludingVat']

	if (priceHeaders.indexOf(sortBy) >= 0) sortName = 'price'

	if (sortName === selectedSort) {
		ascending = !ascending
	} else {
		ascending = sortName.indexOf('High') < 0 && sortBy !== 'stock'
	}

	return {
		name: sortName,
		property: getSortByProperty(sortName, showRetailPrice),
		ascending: ascending,
	}
}

/**
 * Get the property name to sort by based on the sort criteria.
 * @param {string} sortBy - The property to sort by.
 * @param {boolean} showRetailPrice - Whether to show retail price.
 * @returns {string} The property name to sort by.
 */
export const getSortByProperty = (
	sortBy: string,
	showRetailPrice: boolean,
): string => {
	if (sortBy.indexOf('price') >= 0 && showRetailPrice) return 'retailPrice'
	else if (sortBy.indexOf('price') >= 0) return 'netPrice'
	else return sortBy
}

/**
 * Check if search result has only empty collections.
 * @param {object} object - The search result object to check.
 * @returns {boolean} True if all collections are empty, otherwise false.
 */
export const hasOnlyEmptyCollections = (
	object: Record<string, any[]>,
): boolean => {
	let result = true
	Object.keys(object).forEach((key) => {
		if (object[key] && Array.isArray(object[key])) {
			const hasResult = object[key].some((c) => c.articles.length > 0)

			if (hasResult) result = false
		}
	})

	return result
}

/**
 * Create a combination result from articles.
 * @param {any[]} articles - The articles to group.
 * @returns {any[]} The grouped and sorted collections.
 */
export const createCombinationResult = (articles: any[]): any[] => {
	const allArticles = getAllArticles(articles)
	const grouped = groupBy(allArticles, (r) => r.modelId || r.patternName)

	let groupedCollections: any[] = []

	for (let key of Object.keys(grouped)) {
		let groupedArticles = grouped[key]

		groupedArticles = sortBy(groupedArticles, [
			'modelName',
			'patternName',
			'axle',
		])

		groupedCollections.push({
			modelId: key,
			modelName: groupedArticles[0].modelName || groupedArticles[0].patternName,
			articles: groupedArticles,
		})
	}

	return groupedCollections
}

type SelectorFunction = (state: any) => any

/**
 * Create a result selector.
 * @param {SelectorFunction} selectResult - The result selector.
 * @param {SelectorFunction} selectFilterIds - The filter selector.
 * @param {SelectorFunction} [selectSortBy] - Optional: sort by selector.
 * @param {SelectorFunction} [selectAscending] - Optional: sort by ascending selector.
 * @param {SelectorFunction} [selectShowCombination] - Optional: create combination of result.
 * @returns {SelectorFunction} The result selector.
 */
export const createResultSelector = (
	selectResult: SelectorFunction,
	selectFilterIds: SelectorFunction,
	selectSortBy: SelectorFunction = () => null,
	selectAscending: SelectorFunction = () => null,
	selectShowCombination: SelectorFunction = () => null,
): SelectorFunction => {
	return (state: any) => {
		const result = selectResult(state)
		const filterIds = selectFilterIds(state)
		const sortBy = selectSortBy(state)
		const ascending = selectAscending(state)
		const showCombination = selectShowCombination(state)

		const processedResult = !filterIds
			? result
			: filterArticles(result, filterIds)

		if (showCombination) return createCombinationResult(processedResult)
		return !sortBy
			? processedResult
			: sortArticles(processedResult, sortBy, ascending)
	}
}

/**
 * A lighter version of `createResultSelector` that only requires articles.
 * @param {SelectorFunction} selectData - The data selector.
 * @param {SelectorFunction} selectFilterIds - The filter ID selector.
 * @returns {SelectorFunction} The filtered data selector.
 */
export const createFilteredDataSelector = (
	selectData: SelectorFunction,
	selectFilterIds: SelectorFunction,
): SelectorFunction => {
	return (state: any) => {
		const data = selectData(state)
		const filterIds = selectFilterIds(state)

		if (filterIds === null) return data
		return data?.filter((article: any) => filterIds.includes(article.id))
	}
}

/**
 * Get tracking information from an order.
 * @param {Array<any>} trackingUrls - The tracking URLs.
 * @param {any} order - The order object.
 * @returns {any} The tracking information or undefined if not found.
 */
export const getTracking = (trackingUrls: any[], order: any): any => {
	return (
		trackingUrls &&
		trackingUrls.find((tracking) => {
			return order.trackingUrlId
				? tracking.trackingUrlId === order.trackingUrlId
				: tracking.wayOfDeliveryId === order.wayOfDeliveryId
		})
	)
}

/**
 * Check if an order has tracking information.
 * @param {Array<any>} trackingUrls - The tracking URLs.
 * @param {any} order - The order object.
 * @returns {boolean} True if tracking is available, otherwise false.
 */
export const hasTracking = (trackingUrls: any[], order: any): boolean => {
	const tracking = getTracking(trackingUrls, order)
	return (
		tracking &&
		tracking.url.indexOf('[[number]]') !== -1 &&
		(order.parcelNumber || order.parcelNumbers)
	)
}

/**
 * Generate a tracking URL for an order.
 * @param {Array<any>} trackingUrls - The tracking URLs.
 * @param {any} order - The order object.
 * @param {string} currentLanguage - The current language code.
 * @returns {string} The tracking URL.
 */
export const trackingUrl = (
	trackingUrls: any[],
	order: any,
	currentLanguage: string,
): string => {
	const tracking = getTracking(trackingUrls, order)
	return tracking.url
		.replace('[[number]]', order.parcelNumber || order.parcelNumbers)
		.replace('[[lang]]', currentLanguage)
}

/**
 * Check for category change.
 * @param {string} vehicleType - The type of vehicle.
 * @param {string} category - The category.
 * @returns {Function} A function to check for the category change action.
 */
export const isSearchCategory = (
	vehicleType: string,
	category: string,
): Function => {
	const typeChange = searchCategoryChange()
	return (action: any) => {
		return (
			action.type === typeChange.type &&
			action.payload.category === category &&
			action.payload.vehicleType === vehicleType
		)
	}
}

/**
 * Check for previous category change.
 * @param {string} vehicleType - The type of vehicle.
 * @param {string} category - The previous category.
 * @returns {Function} A function to check for the previous category change action.
 */
export const isPreviousSearchCategory = (
	vehicleType: string,
	category: string,
): Function => {
	const typeChange = searchCategoryChange()
	return (action: any) => {
		return (
			action.type === typeChange.type &&
			action.payload.previousCategory === category &&
			action.payload.previousVehicleType === vehicleType
		)
	}
}

/**
 * Translates kitset text based on the provided translation function.
 * @param {string} text - The text to translate.
 * @param {Function} t - The translation function.
 * @returns {string} The translated text.
 */
export const translateKitsetText = (
	text: string,
	t: (key: string) => string,
): string => {
	return text
		.replace('CenterRing', t('kitsets.centerRing'))
		.replace('NoRings', t('kitsets.noRings'))
		.replace('FlexBolt', t('kitsets.flexBolt'))
		.replace('NoBoltNut', t('kitsets.noBoltNut'))
		.replace('Nut', t('kitsets.nut'))
		.replace('Bolt', t('kitsets.bolt'))
}

/**
 * Filters out hidden filters.
 * @param {any} filter - The filter to check.
 * @returns {boolean} True if the filter is not hidden, otherwise false.
 */
export const filterHidden = (filter: any): boolean => {
	return !filter.hide
}

/**
 * Determines the order status based on item properties.
 * @param {any} item - The order item.
 * @param {Function} t - The translation function.
 * @returns {string} The translated order status.
 */
export const orderStatus = (item: any, t: (key: string) => string): string => {
	if (item.isPrepayment) return t('common.prepayment')
	if (item.isPreSeasonOrder) return t('orderPage.preSeason')
	if (item.realization) return t('orderPage.realization')

	switch (item.subStatus) {
		case 'B':
			return t('orderSubStatus.blanket')
		case 'O':
			return t('orderSubStatus.offer')
		case 'S':
			return t('orderSubStatus.selfBilling')
		case 'R':
			return t('orderSubStatus.reservation')
		default:
			return '-'
	}
}

/**
 * Converts a language configuration object into an array of active languages.
 * @param {Record<string, boolean>} languageConfig - The language configuration.
 * @returns {string[]} The array of active language codes.
 */
export const languageArray = (
	languageConfig: Record<string, boolean>,
): string[] => {
	const newData: string[] = []
	const activeKeys = Object.keys(languageConfig).filter(
		(key) => languageConfig[key],
	)
	for (const key of activeKeys) {
		if (key.indexOf('display') === 0) {
			newData.push(key.replace('display', '').toLowerCase())
		}
	}
	return newData
}

/**
 * Cleans the criteria object by removing properties with the value "all".
 * @param {Record<string, any>} criteria - The criteria to clean.
 * @returns {Record<string, any>} The cleaned criteria.
 */
export const cleanCriteria = (
	criteria: Record<string, any>,
): Record<string, any> => {
	const cleanCriteria: Record<string, any> = {}
	for (const key in criteria) {
		cleanCriteria[key] = criteria[key] !== 'all' ? criteria[key] : ''
	}
	return cleanCriteria
}

/**
 * Gets the stock quantity from an article.
 * @param {any} article - The article object.
 * @returns {number | null} The stock quantity, or null if not available.
 */
export const getStockQuantity = (article: any): number | null => {
	if (article.quantityCustomerStock) {
		return article.quantityCustomerStock
	} else if (
		article.quantityIncoming &&
		article.quantityIncoming > 0 &&
		article.quantityAvailable <= 0
	) {
		return article.quantityIncoming
	} else if (article.quantityAvailable && article.quantityAvailable > 0) {
		return article.quantityAvailable
	} else if (article.quantityExternal && article.quantityExternal > 0) {
		return article.quantityExternal
	}
	return null
}

/**
 * Checks if a country code belongs to Latakko.
 * @param {string} countryCode - The country code.
 * @returns {boolean} True if the country code is Latakko, otherwise false.
 */
export const isLatakko = (countryCode: string): boolean => {
	return ['LT', 'LV', 'EE', 'ET'].includes(countryCode)
}

/**
 * Gets the article image URL by its ID.
 * @param {any} article - The article object.
 * @returns {string | null} The article image URL, or null if not available.
 */
export const getArticleImageId = (article: any): string | null => {
	return article.imageId
		? `${process.env['REACT_APP_API_URL']}/api/ArticleImages/${article.imageId}`
		: null
}

/**
 * Creates the payload for adding an article to the cart.
 * @param {any} article - The article object.
 * @param {number} quantity - The quantity to add.
 * @returns {object} The payload to send to the API.
 */
export const createAddToCartPayload = (
	article: any,
	quantity: number,
): object => {
	const data = {
		licensePlate: null,
		tecDocId: null,
		articleId: article.id ?? null,
		isMatched: article.isMatched ?? null,
		kitset: article.kitset ?? null,
		brandId: article.brandId ?? null,
		carModel: article.carModel ?? null,
		quantity,
	}

	const payload = {
		inStock: [data],
		incoming: [],
		toOrder: [],
	}

	return payload
}

/**
 * Creates an input handler that handles text input, checkbox input, and custom input parameters.
 * @param {Function} onFormValueChange - The function to call when an input value changes.
 * @returns {Function} The input handler function.
 */
export const createFormInputEventHandler = (
	onFormValueChange: (name: string, value: any) => void,
): ((e: any, inputName?: string) => void) => {
	const onFormInput = (e: any, inputName?: string) => {
		// If the provided parameters do not resemble a browser event,
		// then assume that the provided parameters are: (value, name).
		if (!e.target) {
			const value = e
			const name = inputName
			onFormValueChange(name as string, value)
			return
		}

		const { checked, type, name } = e.target
		let value = type === 'checkbox' ? checked : e.target.value

		onFormValueChange(name, value)
	}

	return onFormInput
}

/**
 * Checks if specified fields in an object are empty.
 * @param {object} object - The object to check.
 * @param {string[]} propertyNames - The properties to check.
 * @returns {boolean} True if any specified fields are empty, otherwise false.
 */
export const isFieldsEmpty = (
	object: Record<string, any>,
	propertyNames: string[],
): boolean => {
	for (let propertyName of propertyNames) {
		if (object[propertyName]) return false
	}

	return true
}

/**
 * Determines whether `priceCalculationMethod` is the simple pricing method.
 * @param {number} priceCalculationMethod - The price calculation method.
 * @returns {boolean} True if the method is simple pricing, otherwise false.
 */
export const isSimplePricingMethod = (
	priceCalculationMethod: number,
): boolean => {
	return priceCalculationMethod !== 1
}

/**
 * Validates cart data for studding.
 * @param {any} cartData - The cart data.
 * @returns {boolean} True if the studding data is valid, otherwise false.
 */
export const isStuddingValid = (cartData: any): boolean => {
	if (!Array.isArray(cartData.rows)) return false

	for (const { articles } of cartData.rows) {
		if (!Array.isArray(articles)) continue

		for (const { isStuddable, studding } of articles) {
			// If studding is not set...
			if (isStuddable && studding === null) return false
		}
	}

	return true
}

/**
 * Get backend URL depending on development or production environment.
 * @param {string} url - The URL to append.
 * @returns {string} The full backend URL.
 */
export const getBackendUrl = (url: string): string => {
	if (process.env['NODE_ENV'] === 'development')
		return `http://localhost:63540${url}`
	else return url
}
