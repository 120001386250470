import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WebTextInfo } from 'utils/api/webTextsNamespaceAPI/WebTextsNamespaceResponses'
import sliceStateBases, { SliceState } from 'utils/enums/sliceBaseStates'
import { getWebTexts, postWebTexts } from 'utils/api/webTextsNamespaceAPI/webTextsNamespaceAPI'
import { statusHandler, thunkActions } from 'utils/utils'
import { RootState } from 'app/store'
import { WebTextNamespaceEditPayload, WebTextsNamespacePayload } from 'utils/api/webTextsNamespaceAPI/WebTextsNamespaceRequests'

export const fetchWebTexts = createAsyncThunk(
	'webTextEditor/fetchWebTexts',
	async (payload: WebTextsNamespacePayload) => await getWebTexts(payload),
)

export const updateWebTexts = createAsyncThunk(
	'webTextEditor/saveWebTexts',
	async (payload: WebTextNamespaceEditPayload) => await postWebTexts(payload),
)

const actions = thunkActions(fetchWebTexts, updateWebTexts)

export interface WebTextEditorState {
	items: WebTextInfo[] | null
	criteria: WebTextsNamespacePayload | null
}

export interface WebTextPropertyValue {
	propertyName: string
	propertyValue: string
}

const initialState: SliceState<'FEATURE', WebTextEditorState> = {
	...sliceStateBases.FEATURE,
	items: null,
	criteria: null
}

const webTextEditorSlice = createSlice({
	name: 'webTextEditor',
	initialState,
	reducers: {
		updatePropertyValue: (state, action: PayloadAction<WebTextPropertyValue>) => {
			var item = state.items?.find(i => i.propertyName === action.payload.propertyName)
			if (item != null)
				item.propertyValue = action.payload.propertyValue
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchWebTexts.pending, (state) => {
				state.items = null
			})
			.addCase(fetchWebTexts.fulfilled, (state, action) => {
				state.items = action.payload
			})
			.addMatcher(actions, statusHandler)
	}
})

export const selectStatus = (state: RootState) => state.cms.webTexts.editor.status
export const selectWebTexts = (state: RootState) => state.cms.webTexts.editor.items
export const selectCriteria = (state: RootState) => state.cms.webTexts.editor.criteria

export const {
	updatePropertyValue
} = webTextEditorSlice.actions

export default webTextEditorSlice.reducer