import './AppNotificationsOverlay.scss'
import React, { FunctionComponent } from 'react'
import { NotificationsOverlay } from '@barnsten-it/gummidata-react-components'
import { selectCurrentMessages, removeNotification } from '../notificationSlice'
import { useAppDispatch } from '../../../app/useAppDispatch'
import { useAppSelector } from '../../../app/useAppSelector'

interface Props {
	
}

export const AppNotificationsOverlay: FunctionComponent<Props> = () => {
	const dispatch = useAppDispatch()
	const messages = useAppSelector(selectCurrentMessages)
	
	return (
		<NotificationsOverlay
			messages={messages}
			onMessageRemoved={id => id && dispatch(removeNotification(id))}
			transitionTime={400}
		/>
	)
}
