import apiClient from '../apiClient'
import { WebTextNamespaceEditPayload, WebTextsNamespacePayload } from './WebTextsNamespaceRequests'
import { WebTextInfo } from './WebTextsNamespaceResponses'

/**
 * Get webtexts for specific namespace and language
 * @param payload
 * @returns 
 */
export const getWebTexts = async (payload: WebTextsNamespacePayload): Promise<WebTextInfo[]> =>
	await apiClient.get('/api/WebTextsNamespace', { ...payload })

/**
 * Update webtexts for a specific namespace and language
 * @param payload 
 * @returns 
 */
export const postWebTexts = async (payload: WebTextNamespaceEditPayload): Promise<string> =>
	await apiClient.post('/api/WebTextsNamespace', { ...payload })