import { getAllArticles } from 'utils/helper'
import {
	searchCategoryChange
} from './commonActions'

const middleWare = ({
	dispatch,
	getState
}) => next => action => {
	const {
		meta,
		payload
	} = action

	if (meta?.arg?.checkCategory && meta?.requestStatus === 'fulfilled') {
		const category = meta.arg.checkCategory
		const vehicleType = meta.arg.checkVehicleType
		const articles = getAllArticles(payload)
		const hasOtherCategory = articles.some(a => a.category !== category || a.vehicleType !== vehicleType)

		if (hasOtherCategory) {
			const displaySteelRims = getState().app?.settings?.searchConfig?.car?.steelRims

			let newCategory = articles[0].category
			const newVehicleType = articles[0].vehicleType

			if (newCategory === 'steelRim' && newVehicleType === 'car' && !displaySteelRims) {
				newCategory = 'rim'
			}

			dispatch(searchCategoryChange({
				...payload,
				category: newCategory,
				vehicleType: newVehicleType,
				previousCategory: category,
				previousVehicleType: vehicleType
			}))
		} else {
			return next(action)
		}
	} else
		return next(action)
}

export default middleWare
