import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import articleSearchType from 'utils/enums/articleSearchType'
import shopSearchType from 'utils/enums/shopSearchType'

const useUrlInterpreter = () => {
	const { pathname } = useLocation()
	const [path, setPath] = React.useState<string[]>([])
	const [rootUrl, setRootUrl] = React.useState<string | null>(null)
	const [searchType, setSearchType] = React.useState<shopSearchType | null>(null)
	const [articleType, setArticleType] = React.useState<articleSearchType | null>(null)
	const [myPagesType, setMyPagesType] = React.useState<string | null>(null)
	

	/**
	 * Interprets the url and sets the state
	 * 
	 * Note: Make sure to set states to null when not in use
	 * 
	 * @param path The path to interpret
	 * @returns void
	 */
	const interpretUrl = (path: string[]) => {
		// Set the root part of the url
		setRootUrl(path[0] as string)

		/** 
		 * Shop url logic
		 * 	-	/shop
		 * 		-	/shop/car
		 * 			-	/shop/car/tyre
		 * 			-	/shop/car/rim
		 * 			-	/shop/car/tubes
		 * 			-	/shop/car/accessories
		 */ 
		if (path[0] === 'shop') {
			setSearchType(path[1] as shopSearchType)
					
			// If the article type is not defined, set it to the first menu item
			if ((typeof path[2] === 'undefined') && ['battery', 'oil', 'other'].includes(path[1] as string)) {
				setArticleType(path[1] as articleSearchType)
			} else {
				setArticleType(path[2] as articleSearchType)
			}
		} else {
			articleType && setArticleType(null)
			searchType && setSearchType(null)
		}
 		
		
		/**
		 * My pages url logic
		 * 	-	/my-pages
		 * 		-	/my-pages/customer-info
		 * 		-	/my-pages/orders-history
		 * 		-	/my-pages/invoces
		 * 		-	/my-pages/...
		 */
		if (path[0] === 'my-pages') {
			setMyPagesType(path[1] as string)
		} else {
			myPagesType && setMyPagesType(null)
		}
	}


	/**
	 * Makes sure to only update the state if the path has changed
	 */
	useEffect(() => {
		// If the path is empty, return
		if (path.length === 0) return
		
		// Start the interpretation of the url
		interpretUrl(path)

	// Disabling eslint just for the reset state fix. As we dont want to reset to null on everything al the time. As it can trigger unwanted rerenders 
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [path])


	/**
	 * Makes sure to only update path if the path has changed
	 */
	useEffect(() => {
		// splits the URL path into an array
		const matchArray = pathname.split('/')
		const pathArray = matchArray.filter(String)

		// Make sure it wont trigger the state update if the path is the same
		if (JSON.stringify(path) !== JSON.stringify(pathArray)) {
			setPath(pathArray)
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname])

	return { 
		url: pathname,
		path,
		rootUrl, 
		searchType, 
		articleType,
		myPagesType
	}
}

export default useUrlInterpreter