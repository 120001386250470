import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Filter } from '@barnsten-it/gummidata-react-components'
import { getAllArticles } from 'utils/helper'
import FilterContainer from '@barnsten-it/gummidata-react-components/dist/Filter/FilterContainer/FilterContainer'

const TubeFilter = ({ 
	result, 
	onFilterChange, 
	filterState
}) => {
	const [filterSettings, setFilterSettings] = useState(null)
	const [articles, setArticles] = useState(null)
	const { t } = useTranslation()

	const createFilter = useCallback(() => {
		if (!result)
			return

		const allArticles = getAllArticles(result)

		var settings = [
			{
				name: t('searchFilter.rimSize'),
				property: 'diameter',
				type: Filter.type.range
			}
		]

		setArticles(allArticles)
		setFilterSettings(settings)
	}, [t, result])

	useEffect(() => {
		if (result)
			createFilter()
	}, [result, createFilter])

	if (!filterSettings || !articles || !result)
		return null

	return (
		<div className="TubeFilter">
			<FilterContainer hasResult={!!result}>
				<Filter
					identifier="id"
					settings={filterSettings}
					items={articles}
					label={t('common.searchFilter')}
					selected={filterState?.selected}
					onFilterChange={onFilterChange} />
			</FilterContainer>
		</div>
	)
}

export default TubeFilter