import { selectIdleCheck } from 'features/app/documentInfo/documentInfoSlice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePrevious } from 'utils/utils'
import { checkLogin } from '../login/loginSlice'
import { selectUserId } from '../userInfo/userInfoSlice'

const UserLoginChecker = () => {
	const dispatch = useDispatch()
	const userId = useSelector(selectUserId)
	const idleCheck = useSelector(selectIdleCheck)
	const prevIdleCheck = usePrevious(idleCheck)
	const timer = React.useRef(null)

	/**
   * Check user function
 	 */
	const checkUser = async (id) => {
		const result = await dispatch(checkLogin())
		if (result && result.payload && result.payload.id !== id) {
			window.location.reload() // Reload if user id mismatch
		}
	}

	/**
 	 * Check user timer
 	 */
	React.useEffect(() => {
		if (userId && !idleCheck) {
			timer.current = setInterval(() => {
				checkUser(userId)
			}, 60000 * 2) // 2min
		} else {
			clearInterval(timer.current)
		}

		// clear on component unmount
		return () => {
			clearInterval(timer.current)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idleCheck, userId])

	/**
	 * Check login on idle stop
	 */
	React.useEffect(() => {
		if (userId && !idleCheck && prevIdleCheck)
			checkUser(userId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idleCheck, prevIdleCheck, userId])

	return null
}

export default UserLoginChecker