import { selectUserInfo } from 'features/user/userInfo/userInfoSlice'
import './ContactInformationButton.scss'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowContactInformationModal } from 'features/contactInformation/contactInformationSlice'
import { selectSiteDataCompany } from 'features/siteData/siteDataSlice'
import { mdiFaceAgent } from '@mdi/js'
import Icon from '@mdi/react'
import { useTranslation } from 'react-i18next'

interface ContactInformationButtonProps {}

/**
 * ContactInformationButton
 * @param props
 */
const ContactInformationButton: React.FC<ContactInformationButtonProps> = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const userInfo = useSelector(selectUserInfo)
	const companyInfo = useSelector(selectSiteDataCompany)

	const onClickHandler = () => {
		dispatch(setShowContactInformationModal(true))
	}

	// Validate is ther is a reason to even show button
	const contactValue = 
		userInfo?.salesPersonTelephone || 
		userInfo?.salesPersonEmailAddress || 
		companyInfo?.telephone ||
		companyInfo?.emailaddress

	if (typeof contactValue !== 'string') {
		return null
	}

	return (
		<button className="ContactInformationButton" onClick={onClickHandler}>
			<span>{t('common.contactInformation')}</span>
			<span><Icon path={mdiFaceAgent} size="1.25rem" /></span>
		</button>
	)
}

export default ContactInformationButton