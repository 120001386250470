import * as React from 'react'

function ArrowCollapseIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="1.5625rem"
			height="1.25rem"
			viewBox="0 0 25 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M13.095 20V0h2.417v20h-2.417zm-3.571 0V0h2.417v20H9.524zM3.57 5l4.798 5-4.798 5v-3.75H0v-2.5h3.571V5zM21.43 15l-4.762-5 4.762-5v3.75H25v2.5h-3.571V15z"
				fill="#444"
			/>
		</svg>
	)
}

export default ArrowCollapseIcon