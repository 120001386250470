import apiClient from './apiClient'

/**
 * Get forklift sizes
 * @param {*} multi 
 * @returns 
 */
export const getTyreSizes = async (multi, mainGroupId) => await apiClient.get('/api/TyreDimensions', { multi, mainGroupId })

/**
 * Get truckRimSizes 
 * @returns <KeyValuePair<string, string>
 */
export const getTruckRimSizes = async () => await apiClient.get('/api/TruckRimSizes')

/**
 * Get IndustryAgroRimSizes 
 * @returns <KeyValuePair<string, string>
 */
export const getIndustryAgroRimSizes = async () => await apiClient.get('/api/IndustryAgroRimSizes')

/**
 * Get truckTyreDimensions 
 * @returns <KeyValuePair<string, string> or array of object
 */
export const getTruckTyreSizes = async (multi) => await apiClient.get('/api/TruckTyreDimensions', { multi })

/**
 * Get tractorTyreDimensions 
 * @returns <KeyValuePair<string, string> or array of object
 */
export const getTractorTyreSizes = async (multi) => await apiClient.get('/api/TractorTyreDimensions', { multi })

/**
 * Get loaderTyreDimensions 
 * @returns <KeyValuePair<string, string> or array of object
 */
export const getLoaderTyreSizes = async (multi) => await apiClient.get('/api/LoaderTyreDimensions', { multi })

/**
 * Get all industry tyre dimensons
 * @param {boolean} multi 
 * @returns <KeyValuePair<string, string> or array of object
 */
export const getIndustryAgroTyreSizes = async (multi) => await apiClient.get('/api/IndustryAgroTyreDimensions', { multi })

/**
 * Get inch tyre dimensions
 * @returns Summer and winter tyres
 */
export const getInchTyreSizes = async () => await apiClient.get('/api/InchTyreDimensions')

/**
 * Get industry tube sizes
 * @param {*} multi 
 * @returns Tube sizes
 */
export const getTubeSizes = async (multi, mainGroupId) => await apiClient.get('/api/TubeDimensions', {multi, mainGroupId})

