import * as React from 'react'
import { SVGProps } from 'react'
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
		<path
			fill="currentColor"
			d="M21 7 9 19l-5.5-5.5 1.41-1.41L9 16.17 19.59 5.59 21 7Z"
		/>
	</svg>
)
export default SvgCheck
