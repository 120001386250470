import React from 'react'
import * as Sentry from '@sentry/react'

import './ErrorBoundary.scss'

const FallbackComponent = () => {
	return (
		<div className="FallbackComponent">An error has occured</div>
	)
}

interface ErrorBoundaryProps {
	children: React.ReactNode
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
	if (process.env['NODE_ENV'] === 'development') {
		return children
	} else {
		return (
			<Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
				{children}
			</Sentry.ErrorBoundary>
		)
	}
}

export default ErrorBoundary
