import './ResellerApplicationWindow.scss'

import { Button, Checkbox, Form, Loading, Modal, TextInput } from '@barnsten-it/gummidata-react-components'
import React, { useState } from 'react'
import { closeApplicationWindow, selectApplicationForm, selectShowWindow, selectStatus, sendApplication, setApplicationForm } from '../resellerApplicationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { validate, validateObject } from '@barnsten-it/object-validator'

import CountryPicker from 'features/countries/CountryPicker/CountryPicker'
import ScrollBox from 'components/ScrollBox/ScrollBox'
import WebText from 'components/WebText/WebText'
import { selectCountryCode } from 'features/app/settings/settingsSlice'
import { useTranslation } from 'react-i18next'
import validationRules from './validationRules'

const ResellerApplicationWindow = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const showNewCustomerWindow = useSelector(selectShowWindow)
	const status = useSelector(selectStatus)
	const countryCode = useSelector(selectCountryCode)

	const values = useSelector(selectApplicationForm)
	const [errors, setErrors] = useState({})

	const onClose = () => {
		dispatch(closeApplicationWindow())
	}

	const validateProperty = (name, value) => {
		if (errors[name]) {
			const validation = validate(validationRules()[name], value)
			if (validation.valid)
				setErrors({ ...errors, [name]: '' })
		}
	}

	const onChange = (e) => {
		const { name, value } = e.target
		validateProperty(name, value)
		dispatch(setApplicationForm({ name, value }))
	}

	const onNameValueChange = (e) => {
		const {
			name,
			checked
		} = e.target

		const value = checked
		validateProperty(name, value)
		dispatch(setApplicationForm({ name, value }))
	}

	const onCountryChange = (value, name) => {
		validateProperty(name, value)
		dispatch(setApplicationForm({ name, value }))
	}

	const handleOnClickButton = (e) => {
		e.preventDefault()

		const validation = validateObject(validationRules(), values)
		setErrors(validation.properties)

		if (!validation.valid)
			return

		dispatch(sendApplication(values))
	}

	React.useEffect(() => {
		dispatch(setApplicationForm({ name: 'country', value: countryCode }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (showNewCustomerWindow) {
		return (
			<div className="ResellerApplicationWindow">
				<Modal label={t('common.becomeCustomer')} description={<WebText namespace="application" property="becomeCustomer"/>} onClose={onClose} closeOutside={false}>

					<div className="container">
						<ScrollBox>
							<Form labelSpacer={false}>
								{status !== 'success' &&
									<div className="row">
										<div className="first group col-xs-12 col-md-6">
											<TextInput
												name="name"
												value={values.name}
												onChange={onChange}
												label={t('common.name')}
												labelOnTop={true}
												error={errors.name} />
											<TextInput
												name="companyId"
												value={values.companyId}
												onChange={onChange}
												label={t('common.companyId')}
												labelOnTop={true}
												error={errors.companyId} />
											<TextInput
												name="contactPerson"
												value={values.contactPerson}
												onChange={onChange}
												label={t('common.contactPerson')}
												labelOnTop={true}
												error={errors.contactPerson} />
											<TextInput
												name="address"
												value={values.address}
												onChange={onChange}
												label={t('common.address')}
												labelOnTop={true}
												error={errors.address} />
											<TextInput
												name="zipCode"
												value={values.zipCode}
												onChange={onChange}
												label={t('common.zip')}
												labelOnTop={true}
												error={errors.zipCode} />
											<TextInput
												name="city"
												value={values.city}
												onChange={onChange}
												label={t('common.city')}
												labelOnTop={true}
												error={errors.city} />
										</div>
										<div className="group col-xs-12 col-md-6">
											<CountryPicker
												name="country"
												value={values.country}
												onChange={onCountryChange}
												label={t('common.country')}
												labelOnTop={true}
												error={errors.country}>
											</CountryPicker>
											<TextInput
												name="telephone"
												value={values.telephone}
												onChange={onChange}
												label={t('common.phone')}
												labelOnTop={true}
												error={errors.telephone} />
											<TextInput
												name="telefax"
												value={values.telefax}
												onChange={onChange}
												label={t('common.telefax')}
												labelOnTop={true}
												error={errors.telefax} />
											<TextInput
												name="email"
												value={values.email}
												onChange={onChange}
												label={t('common.email')}
												labelOnTop={true}
												error={errors.email} />
											<TextInput
												name="emailInvoice"
												value={values.emailInvoice}
												onChange={onChange}
												label={t('resellerApplication.emailInvoice')}
												labelOnTop={true}
												error={errors.emailInvoice} />
											<div className="checkboxes">
												<Checkbox
													name="hasTaxNote"
													checked={values.hasTaxNote}
													label={t('resellerApplication.hasTaxNote')}
													onChange={onNameValueChange}
													error={errors.hasTaxNote} />
												<Checkbox
													name="hasMountingMachine"
													checked={values.hasMountingMachine}
													label={t('resellerApplication.hasMountingMachine')}
													onChange={onNameValueChange}
													error={errors.hasMountingMachine} />
												<Checkbox
													name="hasBalancingMachine"
													checked={values.hasBalancingMachine}
													label={t('resellerApplication.hasBalancingMachine')}
													onChange={onNameValueChange}
													error={errors.hasBalancingMachine} />
											</div>
										</div>
										<div className="col-xs-12 center-xs">
											{status === 'loading' && <Loading />}
											{status !== 'success' && status !== 'loading' && <Button label={t('resellerApplication.sendApplication')} onClick={handleOnClickButton} />}
										</div>
									</div>
								}

								{status === 'success' && (
									<div className="container">
										<div className="col-xs-12 center-xs">
											<div className="complete">{t('resellerApplication.applicationSent')}</div>
											<Button label={t('common.close')} onClick={onClose} />
										</div>
									</div>
								)}
							</Form>
						</ScrollBox>
					</div>
				</Modal>
			</div>
		)
	}
	return null
}

export default ResellerApplicationWindow
