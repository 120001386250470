import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { WebTextNamespace } from 'utils/api/webTextsNamespacesAPI/WebTextsNamespacesResponses'
import sliceStateBases, { SliceState } from 'utils/enums/sliceBaseStates'
import { getWebTextNamespaces } from 'utils/api/webTextsNamespacesAPI/webTextsNamespacesAPI'
import { statusHandler, thunkActions } from 'utils/utils'
import { RootState } from 'app/store'

export const fetchWebTextNamespaces = createAsyncThunk(
	'namespaceList/fetchWebTextNamespaces',
	async (companyId: number | null) => await getWebTextNamespaces(companyId),
)

const actions = thunkActions(fetchWebTextNamespaces)

export interface WebTextListState {
	items: WebTextNamespace[] | null
}

const initialState: SliceState<'FEATURE', WebTextListState> = {
	...sliceStateBases.FEATURE,
	items: null
}

const webTextListSlice = createSlice({
	name: 'namespaceList',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchWebTextNamespaces.fulfilled, (state, action) => {
				state.items = action.payload
			})
			.addMatcher(actions, statusHandler)
	}
})

export const selectStatus = (state: RootState) => state.cms.webTexts.namespaces.status
export const selectNamespaces = (state: RootState) => state.cms.webTexts.namespaces.items

export default webTextListSlice.reducer