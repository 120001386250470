import * as React from 'react'
import { SVGProps } from 'react'
const SvgTyreIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		{...props}
	>
		<path
			fill="#BDBDBD"
			d="m18.496 8.14-.36-.94 1.86-.7c-.92-2.12-2.56-3.82-4.62-4.86l-.8 1.78-.92-.42.8-1.8c-1.12-.44-2.34-.7-3.62-.7-1.06 0-2.08.22-3.04.5l.68 1.84-.94.36-.7-1.86c-2.12.92-3.82 2.56-4.86 4.62l1.78.8-.42.92-1.8-.8c-.44 1.12-.7 2.34-.7 3.62 0 1.06.22 2.08.5 3.04l1.84-.68.36.94-1.86.7c.92 2.12 2.56 3.82 4.62 4.86l.8-1.78.92.42-.8 1.8c1.12.44 2.34.7 3.62.7 1.06 0 2.08-.22 3.04-.5l-.68-1.84.94-.36.7 1.86c2.12-.92 3.82-2.56 4.86-4.62l-1.78-.8.42-.92 1.8.8c.44-1.12.7-2.34.7-3.62 0-1.06-.22-2.08-.5-3.04l-1.84.68Zm-5.36 7.9c-3.06 1.26-6.58-.18-7.84-3.24-1.26-3.06.18-6.58 3.24-7.84 3.06-1.26 6.58.18 7.84 3.24a5.986 5.986 0 0 1-3.24 7.84Z"
		/>
	</svg>
)
export default SvgTyreIcon
