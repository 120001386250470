import { cleanCriteria } from 'utils/helpers'

const queryCreator = (object: { [k: string]: any }): string => {
	var string = Object.keys(object)
		.map(function (k) {
			if (Array.isArray(object[k])) {
				var arr: string[] = []

				object[k].forEach(function (item: any) {
					arr.push(k + '[]=' + item)
				})

				return arr.join('&')
			}

			return encodeURIComponent(k) + '=' + encodeURIComponent(object[k])
		})
		.join('&')

	if (string.length > 0) string = '?' + string

	return string
}

/**
 * Fetch from API.
 */
export const apiClient = async (
	endpoint: string,
	{
		method,
		data,
		credentials,
		localstorage,
		isFile,
		noCache,
		disableApiURL,
	}: ApiClientSettings
) => {
	const headers: { [k: string]: string } = {}

	if (!isFile) {
		headers['content-type'] = 'application/json'
	}

	if (noCache) {
		headers['pragma'] = 'no-cache'
		headers['cache-control'] = 'no-cache'
	}

	// Default to 'include'
	credentials ??= 'include'

	let options: RequestInit = {
		headers,
		method,
		credentials,
	}

	if (data) {

		if (!isFile && method !== 'PATCH')
			data = cleanCriteria(data as { [k: string]: any })

		if (options.method === 'GET' || options.method === 'DELETE') {
			if (typeof data == 'object') endpoint = endpoint + queryCreator(data)
		} else if (isFile) {
			// @ts-ignore Let's assume correct data is given in case of file
			options.body = data
		} else {
			options.body = JSON.stringify(data)
		}
	}

	if (localstorage) {
		const storedData = localStorage.getItem(endpoint)

		if (storedData && storedData.length > 0) {
			return new Promise((resolve, _) => {
				resolve(JSON.parse(storedData))
			})
		}
	}

	const apiUrl = disableApiURL ? '' : process.env['REACT_APP_API_URL']

	const requestUrl = `${apiUrl}${endpoint}`

	return await fetch(requestUrl, options)
		.then((response) => {
			if (!response.ok) {
				throw new Error(String(response.status))
			}

			const contentType = response.headers.get('content-type')
			if (contentType && contentType.indexOf('application/json') !== -1) {
				return response.json()
			} else if (response.body) {
				const reader = response.body.getReader()
				return reader.read()
			} else {
				return null
			}
		})
		.then((data) => {
			if (localstorage) {
				localStorage.setItem(endpoint, JSON.stringify(data))
			}

			return data
		})
}

apiClient.get = async (
	endpoint: string,
	data?: ApiClientSettings['data'],
	settings?: ApiClientSettings
) => {
	return await apiClient(endpoint, { ...settings, data, method: 'GET' })
}

apiClient.post = async (
	endpoint: string,
	data?: ApiClientSettings['data'],
	settings?: ApiClientSettings
) => {
	return await apiClient(endpoint, { ...settings, data, method: 'POST' })
}

apiClient.put = async (
	endpoint: string,
	data?: ApiClientSettings['data'],
	settings?: ApiClientSettings
) => {
	return await apiClient(endpoint, { ...settings, data, method: 'PUT' })
}

apiClient.patch = async (
	endpoint: string,
	data?: ApiClientSettings['data'],
	settings?: ApiClientSettings
) => {
	return await apiClient(endpoint, { ...settings, data, method: 'PATCH' })
}

apiClient.delete = async (
	endpoint: string,
	data?: ApiClientSettings['data'],
	settings?: ApiClientSettings
) => {
	return await apiClient(endpoint, { ...settings, data, method: 'DELETE' })
}

export default apiClient

export interface ApiClientSettings {
	method: Method
	data?: RequestInit['body'] | { [k: string]: any }
	credentials?: RequestInit['credentials']
	localstorage?: boolean
	isFile?: boolean
	noCache?: boolean
	disableApiURL?: boolean
}

export type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'