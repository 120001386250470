import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAccessoriesByProperties } from 'utils/api/articleSearchAPI'
import { createResultSelector } from 'utils/helper'
import { statusHandler, thunkActions } from 'utils/utils'

export const searchTubes = createAsyncThunk('motoTubesSearch/search', async (criteria) =>
	await getAccessoriesByProperties(
		criteria.articleText,
		0,
		criteria.mainGroupId
	))

const tubesActions = thunkActions(searchTubes)

const defaultCriteria = {
	articleText: ''
}

const initialState = {
	status: 'idle',
	result: null,
	filterIds: null,
	filterState: null,
	sortBy: null,
	ascending: null,
	criteria: defaultCriteria,
}

const motoTubesSearchSlice = createSlice({
	name: 'motoTubesSearch',
	initialState,
	reducers: {
		setCriteria: (state, action) => {
			state.criteria[action.payload.name] = action.payload.value
		},
		clearCriteria: (state) => {
			state.criteria = defaultCriteria
		},
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		}
	},
	extraReducers: builder => {
		builder
			.addCase(searchTubes.pending, state => {
				state.filterIds = null
				state.filterState = null
				state.result = null
			})
			.addCase(searchTubes.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(tubesActions, statusHandler)
	}
})

export const selectCriteria = state => state.search.tube.motoTubesSearch.criteria
export const selectStatus = state => state.search.tube.motoTubesSearch.status
export const selectResult = state => state.search.tube.motoTubesSearch.result
export const selectFilterIds = state => state.search.tube.motoTubesSearch.filterIds
export const selectFilterState = state => state.search.tube.motoTubesSearch.filterState
export const selectSortBy = state => state.search.tube.motoTubesSearch.sortBy
export const selectAscending = state => state.search.tube.motoTubesSearch.ascending

export const selectProcessedResult = createResultSelector(selectResult, selectFilterIds, selectSortBy, selectAscending)

export const { setCriteria, clearCriteria, setFilter, sortResult } = motoTubesSearchSlice.actions

export default motoTubesSearchSlice.reducer
