import * as React from 'react'
import { SVGProps } from 'react'
const SvgDocIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
		<path
			fill="currentColor"
			d="M13 9h5.5L13 3.5V9M6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2m9 16v-2H6v2h9m3-4v-2H6v2h12Z"
		/>
	</svg>
)
export default SvgDocIcon
