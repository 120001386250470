import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import sliceStatus from 'utils/enums/sliceStatus'
import { getTyresByProperties } from 'utils/api/articleSearchAPI'
import { createResultSelector } from 'utils/helper'
import { statusHandler, thunkActions } from 'utils/utils'
import { loggedOut } from 'utils/redux/commonActions'
import { isPreviousSearchCategory, isSearchCategory } from 'utils/helper'
import tyreType from 'utils/enums/tyreType'

export const searchTyres = createAsyncThunk('industryTyreSearch/searchTyres', async (criteria) => await getTyresByProperties(
	criteria.width,
	criteria.aspectRatio,
	criteria.diameter,
	criteria.secondaryWidth,
	criteria.secondaryAspectRatio,
	criteria.secondaryDiameter,
	criteria.articleText,
	null,
	null,
	null,
	null,
	criteria.tyreType,
	criteria.languageCode
))

const searchTyresActions = thunkActions(searchTyres)

const industryTyreSearch = createSlice({
	name: 'industryTyre',
	initialState: {
		status: 'idle',
		result: null,
		filterIds: null,
		filterState: null,
		sortBy: null,
		ascending: null,
		criteria: {
			tyreType: tyreType.industryAgro,
			selectedSize: '',
			width: '',
			aspectRatio: '',
			diameter: '',
			secondaryWidth: '',
			secondaryAspectRatio: '',
			secondaryDiameter: '',
			articleText: ''
		}
	},
	reducers: {
		setCriteria: (state, action) => {
			state.criteria[action.payload.name] = action.payload.value
		},
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState 
		},
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		}
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.result = null
				state.filterIds = null
				state.criteria = {
					tyreType: tyreType.industryAgro,
					selectedSize: '',
					width: '',
					aspectRatio: '',
					diameter: '',
					secondaryWidth: '',
					secondaryAspectRatio: '',
					secondaryDiameter: '',
					articleText: ''
				}
			})
			.addCase(searchTyres.pending, state => {
				state.filterIds = null
				state.filterState = null
				state.result = null
				state.sortBy = null
				state.ascending = null
			})
			.addCase(searchTyres.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(isPreviousSearchCategory('industry', 'tyre'), (state) => {
				state.status = sliceStatus.idle
			})
			.addMatcher(isSearchCategory('industry', 'tyre'), (state, action) => {
				state.status = sliceStatus.success
				state.result = action.payload
			})
			.addMatcher(searchTyresActions, statusHandler)
	}
})

export const selectCritiera = state => state.search.tyre.industryTyre.criteria
export const selectStatus = state => state.search.tyre.industryTyre.status
export const selectResult = state => state.search.tyre.industryTyre.result
export const selectFilterIds = state => state.search.tyre.industryTyre.filterIds
export const selectFilterState = state => state.search.tyre.industryTyre.filterState
export const selectSortBy = state => state.search.tyre.industryTyre.sortBy
export const selectAscending = state => state.search.tyre.industryTyre.ascending

export const selectProcessedResult = createResultSelector(selectResult, selectFilterIds, selectSortBy, selectAscending)

export const { setCriteria, sortResult, setFilter } = industryTyreSearch.actions

export default industryTyreSearch.reducer
