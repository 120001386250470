import { Notification } from '@barnsten-it/gummidata-react-components'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

export interface NotificationState {
	current: Notification[]
}

export const notificationInitialState: NotificationState = {
	current: [],
}

const notificationSlice = createSlice({
	name: 'notifications',
	initialState: notificationInitialState,
	reducers: {
		addNotification(
			state,
			action: PayloadAction<Notification>,
		) {
			const {
				type='success',
				text='',
				...properties
			} = action.payload

			state.current = [
				...state.current,
				{
					type,
					text,
					id: String(Date.now()),
					showIcon: true,
					removeAfter: 3000,
					...properties,
				},
			]
		},
		removeNotification(
			state,
			action: PayloadAction<string>,
		) {
			const messageId = action.payload
			state.current = state.current.filter(message => message.id !== messageId)
		}
	}
})

export default notificationSlice.reducer

export const {
	addNotification,
	removeNotification,
} = notificationSlice.actions

export const selectCurrentMessages = (state: RootState) => state.notifications.current
