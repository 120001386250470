import { SliceStatus } from './sliceStatus'

const sliceStateBases: {
	FEATURE: {
		status: SliceStatus
		data: any | null
		error: string | null
		showResult: boolean
		sortBy: string | null
		ascending: boolean
		filterIds: string[] | null
		/**
		 * -  `null` — the response has not been sent or processed yet
		 * -  `true` — results have been received
		 * -  `false` — after processing response,
		 *              it has been determined that there are no results
		 */
		noResults: boolean | null
		isLoading: boolean
		hasSucceeded: boolean
		hasFailed: boolean
	}
} = {
	FEATURE: {
		status: 'idle',
		data: null,
		error: null,
		showResult: true,
		sortBy: null,
		ascending: true,
		filterIds: null,
		noResults: null,
		isLoading: false,
		hasSucceeded: false,
		hasFailed: false,
	},
}

export default sliceStateBases

export type SliceState<
	P extends keyof typeof sliceStateBases,
	T,
> = typeof sliceStateBases[P] & T
