import './ArticleText.scss'
import React, { FC } from 'react'
import classNames from 'classnames'
// Import your icons here
import { mdiPackageVariantClosed } from '@mdi/js'

interface ArticleTextProps {
	hasOriginalArticleId?: boolean
	customStyling?: boolean
	value: string
}

// Utility function to format article text
export const formatArticleText = (value: string) => {
	// Remove <icon> tags
	value = value.replace(/<icon=".*?"\/>/g, '')

	// Remove <warning> tags
	return value.replace(/<\/?warning>/g, '')
}

// Icon mapping
const iconMap: any = {
	box: mdiPackageVariantClosed,
	// Add more icons to this map as needed
}

const ArticleText: FC<ArticleTextProps> = ({
	hasOriginalArticleId = false,
	customStyling = false,
	value,
}) => {
	let formattedValue = value

	// Handling <warning> tags
	if (value?.includes('<warning>')) {
		formattedValue = formattedValue.replace(
			/<warning>(.*?)<\/warning>/g,
			'&nbsp;<span class="warning-color">$1</span>&nbsp;'
		)
	}

	// Handling <icon> tags
	Object.keys(iconMap).forEach((iconKey) => {
		if (value?.includes(`<icon="${iconKey}"/>`)) {
			const iconSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon"><path d="${iconMap[iconKey]}" /></svg>`
			formattedValue = formattedValue.replace(
				new RegExp(`<icon="${iconKey}"/>`, 'g'),
				iconSvg
			)
		}
	})

	return (
		<span
			className={classNames({
				ArticleText: true,
				'custom-styled': customStyling,
				hasOriginalArticleId: hasOriginalArticleId,
			})}
			dangerouslySetInnerHTML={{ __html: formattedValue }}
		/>
	)
}

export default ArticleText
