import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getTruckRimSizes } from 'utils/api/dimensionsAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchTruckRimSizes = createAsyncThunk('truckRimSizes/getTruckRimSizes', async () => await getTruckRimSizes())
const fetchTruckRimSizesAction = thunkActions(fetchTruckRimSizes)

const truckRimSizeSlice = createSlice({
	name: 'truckRimSizes',
	initialState: {
		status: 'idle',
		sizes: null
	},
	extraReducers: builder => {
		builder
			.addCase(fetchTruckRimSizes.fulfilled, (state, action) => {
				state.sizes = action.payload
			})
			.addMatcher(fetchTruckRimSizesAction, statusHandler)
	}
})

export const selectTruckRimSizesStatus = state => state.dimensions.truckRimSizes.status
export const selectTruckRimSizes = state => state.dimensions.truckRimSizes.sizes

export default truckRimSizeSlice.reducer
