import React from 'react'
import SubMenu from 'components/SubMenu/SubMenu'

const PageLayout = ({ children, ...rest }) => {
	return (
		<div>
			<SubMenu />
			<div className="content-wrapper">
				<section className="page-content">
					{children}
				</section>
			</div>
		</div>
	)
}

export default PageLayout
