import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchSingleProduct } from 'utils/api/SingleProductAPI'
import { statusHandler, thunkActions } from 'utils/utils'
import { loggedOut } from 'utils/redux/commonActions'

export const getSingleProduct = createAsyncThunk('singleProduct/getSingleProduct', async (productId) => await fetchSingleProduct(productId))

const getSingleProductActions = thunkActions(getSingleProduct)

const singleProductSlice = createSlice({
	name: 'singleProduct',
	initialState: {
		status: 'idle',
		data: null
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.data = null
			})
			.addCase(getSingleProduct.pending, (state) => {
				state.data = null
			})
			.addCase(getSingleProduct.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(getSingleProduct.rejected, (state) => {
				state.data = null
			})
			.addMatcher(getSingleProductActions, statusHandler)
	}
})

export const selectStatus = state => state.articles.singleProductSlice.status
export const selectSingleProduct = state => state.articles.singleProductSlice.data

export default singleProductSlice.reducer
