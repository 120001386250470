import './LanguageSelector.scss'
import React from 'react'
import { selectLanguageConfig } from 'features/app/settings/settingsSlice'
import { useSelector } from 'react-redux'
import { getLocalStorageItem, setLocalStorageItem } from 'utils/utils'
import LanguageCode from 'utils/enums/languageCode'
import LanguageName from 'utils/enums/languageName'
import { ListViewSelectPicker } from '@barnsten-it/gummidata-react-components'
import i18next from 'i18next'

function LanguageSelector() {
	const [selected, setSelected] = React.useState<LanguageCode>(
		getLocalStorageItem('languageCode') as LanguageCode
	)
	const languageConfig = useSelector(selectLanguageConfig)

	const languageSelectHandler = (languageCode: LanguageCode) => {
		setSelected(languageCode)
		setLocalStorageItem('languageCode', languageCode)
		i18next.changeLanguage(languageCode)
	}
	
	if (languageConfig) {
		return (
			<div className='LanguageSelector'> 
				<ListViewSelectPicker
					value={selected as LanguageCode} 
					onChange={languageSelectHandler} 
				>
					{languageConfig.displayEnglish &&
						<ListViewSelectPicker.Option value={LanguageCode.en} name={LanguageName.en} />
					}
					{languageConfig.displaySwedish &&
    				<ListViewSelectPicker.Option value={LanguageCode.sv} name={LanguageName.sv} />
					}
					{languageConfig.displayFinnish &&
    				<ListViewSelectPicker.Option value={LanguageCode.fi} name={LanguageName.fi} />
					}
					{languageConfig.displayLatvian &&
    				<ListViewSelectPicker.Option value={LanguageCode.lv} name={LanguageName.lv} />
					}
					{languageConfig.displayLithuanian &&
    				<ListViewSelectPicker.Option value={LanguageCode.lt} name={LanguageName.lt} />
					}
					{languageConfig.displayEstonian &&
   					<ListViewSelectPicker.Option value={LanguageCode.et} name={LanguageName.et} />
					}
					{languageConfig.displayNorwegian &&
    				<ListViewSelectPicker.Option value={LanguageCode.no} name={LanguageName.no} />
					}
					{languageConfig.displayRussian &&
    				<ListViewSelectPicker.Option value={LanguageCode.ru} name={LanguageName.ru} />
					}
				</ListViewSelectPicker>
			</div>
		)
	}
	
	return null
}

export default LanguageSelector
