import './WebText.scss'
import React, { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading } from '@barnsten-it/gummidata-react-components'
import { useSelector } from 'react-redux'
import { nl2br } from 'utils/utils'
import classNames from 'classnames'
import { selectWebTextPreview } from 'features/cms/cmsInfo/cmsInfoSlice'
import ContentEditButton from 'components/Buttons/ContentEditButton/ContentEditButton'
import { useNavigate } from 'react-router-dom'

interface WebTextProps {
	namespace: string
	property: any
	showLoading?: boolean
	html?: boolean
	translation?: string
	onHasContent?: (hasContent: boolean) => void
}

const WebText: React.FC<WebTextProps> = ({
	namespace,
	property,
	showLoading = false,
	html = false,
	translation,
	onHasContent,
}) => {
	const isPreview = useSelector(selectWebTextPreview)
	const navigate = useNavigate()

	const baseNamespace = 'webtext'
	const { t } = useTranslation(`${baseNamespace}.${namespace}`)
	const { t: t2 } = useTranslation()

	let text = t(`${baseNamespace}.${namespace}.${property}`)
	const isLoading = text.indexOf(baseNamespace) === 0

	// check if content exists, if not send back onHasContent
	const handleHasContent = useCallback((hasContent: boolean) => {
		if (onHasContent)
			onHasContent(hasContent)
	},[onHasContent])

	// Build the markup
	const markup = useCallback(() => {
		if (text.length === 0 && translation)
			return { __html: translation }

		let processedText = html ? text : nl2br(text)

		if (process.env['NODE_ENV'] === 'development') {
			processedText = text.replace(/\/content\//g, 'http://localhost:63540/content/')
		}

		// check if content exists, if not send back onHasContent
		if (onHasContent) handleHasContent(Boolean(text))

		if (!processedText && isPreview) {
			processedText = `<i>${t2('common.noTextSpecified')}...</i>`
		}

		if (processedText) {
			return { __html: processedText }
		}
		
		return { __html: '' }
	}, [text, translation, html, onHasContent, handleHasContent, isPreview, t2])

	// Build the title
	const title = useMemo(() => {
		let text = ''

		if (isPreview)
			text = `${baseNamespace}.${namespace}.${property}`

		if (isPreview && translation)
			text = text + `. But fallback to translation: ${translation}`

		return text
	}, [isPreview, namespace, property, translation])

	// Loading
	if (isLoading && showLoading) {
		return <Loading />
	} else if (isLoading) {
		return (
			<span
				className={classNames('WebText', { preview: isPreview })}
				title={title}
			/>
		)
	}

	// Edit button
	const onClickEdit = () => {
		navigate(`/cms/text/${namespace}/${namespace.toLowerCase()}.${property.toLowerCase()}`)
	}

	if (markup()) {
		return (
			<ContentEditButton
				active={isPreview}
				onClick={onClickEdit}>
				<span
					className={classNames('WebText', {
						preview: isPreview,
						'html-text': html,
						'translation-fallback': text.length === 0 && translation
					})}
					dangerouslySetInnerHTML={markup()}
					title={title} />
			</ContentEditButton>
		)
	}
	return null
}

export default WebText
