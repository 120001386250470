import './MenuItem.scss'
import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'


export interface MenuItemProps {
	imageSrc: string
	active: boolean
	label: string
	width: number
	url: string
}

/**
 * MenuItem component
 * 
 * @description
 * A single menu item for the category menu component. 
 * 
 * @interface MenuItemProps
 * 
 * @param {string} imageSrc - Image source for the menu item icon
 * @param {boolean} active - Is the menu item active
 * @param {string} label - Label for the menu item
 * @param {number} width - Width of the menu item in rem
 * @param {string} url - Url for the menu item
 * 
 * @returns React.FC<MenuItemProps>
 */
const MenuItem: React.FC<MenuItemProps> = ({
	imageSrc,
	active,
	label, 
	width,
	url,
}) => {
	return (
		<Link
			className={classNames('MenuItem', {'active': active})} 
			style={{width: width + 'rem'}}
			to={url}
		>
			<div className="img-wrapper">
				<img className="icon" src={imageSrc} alt="category menu icon"/>
			</div>

			<label className="label" title={label} style={{maxWidth: width - 0.625 + 'rem'}}>
				{label}
			</label>
		</Link>
	)
}

export default MenuItem