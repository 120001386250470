import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import sliceBaseStates, { SliceState } from 'utils/enums/sliceBaseStates'
import { statusHandler, thunkActions } from '../../../utils/utils'
import {
	getResellerInvoiceCount,
	getResellerNewInvoicesPrint,
	postResellerConfirmNewInvoices,
	postResellerEmailNewInvoices,
	SubCustomerInvoice,
	SubCustomerInvoiceAmounts,
} from 'utils/api/resellerInvoicesAPI'
import { RootState } from '../../../app/store'

export const getSubCustomerInvoiceAmounts = createAsyncThunk(
	'subCustomers/getSubCustomerInvoiceAmounts',
	async () => await getResellerInvoiceCount(),
)
export const getSubCustomerNewMailInvoices = createAsyncThunk(
	'subCustomers/getSubCustomerNewMailInvoices',
	async () => await getResellerNewInvoicesPrint(),
)
export const postConfirmSubCustomerNewInvoices = createAsyncThunk(
	'subCustomers/postConfirmSubCustomerNewInvoices',
	async (payload, { getState }) => {
		const state = getState() as RootState
		let invoices = state.reseller.subCustomerInvoices.subCustomerInvoices

		// Guarantee that invoices are present
		if (!invoices) invoices = await getResellerNewInvoicesPrint()

		await postResellerConfirmNewInvoices({
			invoices,
		})
	}
)
export const postEmailNewSubCustomerInvoices = createAsyncThunk(
	'subCustomers/postEmailNewSubCustomerInvoices',
	async () => await postResellerEmailNewInvoices(),
)

const subCustomerInvoicesActions = thunkActions(
	getSubCustomerInvoiceAmounts,
	getSubCustomerNewMailInvoices,
	postConfirmSubCustomerNewInvoices,
	postEmailNewSubCustomerInvoices,
)

export interface SubCustomerInvoicesState {
	subCustomerInvoiceAmounts: SubCustomerInvoiceAmounts | null
	subCustomerInvoices: SubCustomerInvoice[] | null
}

export const initialSubCustomerInvoicesState: SliceState<'FEATURE', SubCustomerInvoicesState> = {
	...sliceBaseStates.FEATURE,
	subCustomerInvoiceAmounts: null,
	subCustomerInvoices: null,
} as const

export const subCustomerInvoicesSlice = createSlice({
	name: 'subCustomerInvoices',
	initialState: initialSubCustomerInvoicesState,
	reducers: {},
	extraReducers: builder => builder
		.addCase(getSubCustomerInvoiceAmounts.pending, state => {
			state.subCustomerInvoiceAmounts = null
		})
		.addCase(getSubCustomerInvoiceAmounts.fulfilled, (state, action) => {
			state.subCustomerInvoiceAmounts = action.payload
		})
		.addCase(getSubCustomerNewMailInvoices.pending, (state) => {
			state.subCustomerInvoices = null
		})
		.addCase(getSubCustomerNewMailInvoices.fulfilled, (state, action) => {
			state.subCustomerInvoices = action.payload
		})
		.addMatcher(subCustomerInvoicesActions, statusHandler)
})

export default subCustomerInvoicesSlice.reducer

export const selectSubCustomerInvoices = (state: RootState) => state.reseller.subCustomerInvoices
