import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TyreFilter from 'components/filters/TyreFilter/TyreFilter'
import { 
	setFilter, 
	selectResult, 
	selectSearchedCriteria, 
	selectFilterState 
} from '../carTyreSearchSlice'
import tyreType from 'utils/enums/tyreType'

const CarTyreSearchFilter = () => {
	const dispatch = useDispatch()
	const result = useSelector(selectResult)
	const searchedCriteria = useSelector(selectSearchedCriteria)
	const filterState = useSelector(selectFilterState)

	const handleFilterChange = (articleIds, filterState) => {
		dispatch(setFilter({articleIds, filterState}))
	}

	const emptySearchResult = result &&
		(result.inStock.length || result.incoming.length || result.toOrder.length) ? false : true

	const vehicleIdSearch = searchedCriteria?.vehicleId !== ''
	const showTyreTypeFilter = searchedCriteria?.tyreType === tyreType.allTyres

	return (
		<div className="CarTyreSearchFilter">
			{
				!emptySearchResult &&
				<TyreFilter 
					result={result} 
					filterState={filterState}
					onFilterChange={handleFilterChange} 
					showDimensions={vehicleIdSearch} 
					showOriginalFit={vehicleIdSearch} 
					showTyreTypeFilter={showTyreTypeFilter} />
			}
		</div>
	)
}

export default CarTyreSearchFilter
