import * as React from 'react'
import { SVGProps } from 'react'
const SvgEprelLabel = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 18 18"
		width="1em"
		height="1em"
		{...props}
	>
		<path
			fill="#444"
			fillRule="evenodd"
			d="M13.119 15.64H3.364a1.047 1.047 0 0 1-1.048-1.048v-1.776c0-.578.47-1.047 1.048-1.047h9.755c.27 0 .53.102.73.285l1.795 1.65-1.795 1.65c-.2.184-.46.285-.73.285ZM5.37 14.08a.798.798 0 0 1-.268.574c-.162.14-.375.21-.64.21-.289 0-.516-.098-.682-.293-.166-.195-.249-.462-.249-.802v-.138c0-.217.038-.408.115-.573a.86.86 0 0 1 .327-.38.918.918 0 0 1 .496-.133c.26 0 .47.07.629.21.159.139.25.334.276.586h-.465c-.012-.145-.052-.25-.122-.316s-.176-.099-.318-.099c-.155 0-.271.056-.348.167-.077.11-.117.283-.119.517v.17c0 .244.037.422.11.535.075.112.191.168.35.168.144 0 .251-.032.322-.098.07-.066.111-.167.122-.304h.464Zm-2.006-3.158a1.047 1.047 0 0 1-1.048-1.047V8.1c0-.579.47-1.048 1.048-1.048h6.734c.27 0 .531.102.73.285l1.795 1.65-1.795 1.65c-.199.184-.46.285-.73.285H3.364Zm.25-.888V7.778h.79c.274 0 .482.052.623.157.142.105.212.259.212.461a.492.492 0 0 1-.322.477.476.476 0 0 1 .274.176c.066.088.1.195.1.322 0 .217-.07.381-.208.493-.138.111-.336.168-.592.17h-.877Zm.465-.982v.609h.398c.11 0 .195-.026.257-.078a.268.268 0 0 0 .092-.217c0-.206-.107-.311-.321-.314h-.426Zm0-.329h.344c.234-.004.352-.097.352-.28 0-.103-.03-.176-.09-.221-.059-.045-.153-.067-.28-.067h-.326v.568Zm-.715-6.401h3.713c.27 0 .531.101.73.284l1.795 1.65-1.795 1.651a1.08 1.08 0 0 1-.73.285H3.364a1.047 1.047 0 0 1-1.048-1.048V3.37c0-.579.47-1.047 1.048-1.047ZM4.859 4.92h-.815l-.155.465h-.494l.84-2.257h.43l.845 2.257h-.494l-.157-.465Zm-.69-.377h.565L4.45 3.7l-.28.844Z"
			clipRule="evenodd"
		/>
	</svg>
)
export default SvgEprelLabel
