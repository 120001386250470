import './DesktopHeader.scss'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import MiniCart from 'features/cart/MiniCart/MiniCart'
import MainMenu from 'components/Header/DesktopHeader/MainMenu/MainMenu'
import { Button } from '@barnsten-it/gummidata-react-components'
import { openLoginModal, selectLoggedIn } from 'features/user/login/loginSlice'
import { openApplicationWindow } from 'features/resellerApplication/resellerApplicationSlice'
import { useTranslation } from 'react-i18next'
import Logo from 'components/Logo/Logo'
import { selectEnableQuickSearch, selectSiteConfig } from 'features/app/settings/settingsSlice'
import { selectShowQuickSearch, setOverlay, setShowMenu, setShowQuickSearch } from 'features/app/documentInfo/documentInfoSlice'
import useMenu from 'utils/hooks/useMenu'
import classNames from 'classnames'
import { useUserRoles } from 'utils/hooks/useUserRoles'
import CreditInfo from 'features/user/CreditInfo/CreditInfo/CreditInfo'
import SearchButton from './SearchButton/SearchButton'
import LanguageSelector from 'components/LanguageSelector/LanguageSelector'
import QuickSearch from 'features/search/allProducts/QuickSearch/QuickSearch'
import QuickSettings from '../QucikSettings/QuickSettings'
import { useAppSelector } from 'app/useAppSelector'


const DesktopHeader = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { menu } = useMenu()

	const loggedIn = useSelector(selectLoggedIn)
	const showQuickSearch = useSelector(selectShowQuickSearch)
	const isQuickSearchEnabled = useSelector(selectEnableQuickSearch)
	const siteConfig = useAppSelector(selectSiteConfig)

	const {
		hasCustomerAdminRole,
		hasNetPricesRole,
	} = useUserRoles()

	const showQuickSearchHandler = () => {
		dispatch(setOverlay(true))
		dispatch(setShowQuickSearch(true))
	}

	const closeQuickSearchHandler = () => {
		dispatch(setOverlay(false))
		dispatch(setShowQuickSearch(false))
	}

	const onClickLogin = () => {
		dispatch(setShowMenu(false))
		dispatch(openLoginModal())
	}

	const onClickNewCustomer = () => {
		dispatch(setShowMenu(false))
		dispatch(openApplicationWindow())
	}

	return (
		<header className={classNames('DesktopHeader Header', { 'search-open': showQuickSearch })}>
			<div className="header-wrapper">
				<Link to="/">
					<Logo size="medium" />
				</Link>

				<MainMenu menu={menu} />

				{loggedIn ? (
					<div className="end-bar logged-in">
						<div className="link-panel">
							{isQuickSearchEnabled &&
								<SearchButton onClick={showQuickSearchHandler} />
							}

							{(hasCustomerAdminRole || hasNetPricesRole) &&
								<QuickSettings />
							}
							<LanguageSelector />
						</div>

						<CreditInfo />
						<span className="line-spacer" />
						<MiniCart />
					</div>
				) : (
					<div className="end-bar login">
						<LanguageSelector />
						<span className="line-spacer" />
						{siteConfig?.customerApplication && (
							<>
								<div className="becomecustomer-button" onClick={onClickNewCustomer}>
									{t('common.becomeCustomer')}
								</div>
								<div className="or-text">
									{t('common.or')}
								</div>
							</>
						)}
						<Button className="login-button" label={t('common.login')} wideSize={true} onClick={onClickLogin} />
					</div>
				)}

			</div>

			{showQuickSearch &&
				<QuickSearch onClose={closeQuickSearchHandler} />
			}
		</header>
	)
}

export default DesktopHeader