import { 
	createAsyncThunk, 
	createSelector, 
	createSlice 
} from '@reduxjs/toolkit'
import { 
	getWheelPackages, 
	getWheelPackageTyreTypes 
} from 'utils/api/articleSearchAPI'
import { sortPremadeWheelCollection } from 'utils/helper'
import { 
	statusHandler, 
	thunkActions 
} from 'utils/utils'

export const searchPackages = createAsyncThunk('premadeWheelPackage/searchPackages', async (criteria) => await getWheelPackages(criteria))
export const searchPackageTyreTypes = createAsyncThunk('premadeWheelPackage/wheelPackageTyreTypes', async () => await getWheelPackageTyreTypes())

const searchActions = thunkActions(searchPackages, searchPackageTyreTypes)

const initialState = {
	status: 'idle',
	result: null,
	tyreTypes: null,
	filterIds: null,
	filterState: null,
	sortBy: null,
	ascending: null,
	emailAddress: '',
}

const premadeWheelPackageSearchSlice = createSlice({
	name: 'premadeWheelPackage',
	initialState,
	reducers: {
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		reset: () => initialState,
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		},
	},
	extraReducers: builder => {
		builder
			.addCase(searchPackages.pending, (state) => {				
				state.result = null				
				state.filterIds = null
				state.filterState = null
			})
			.addCase(searchPackages.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addCase(searchPackageTyreTypes.fulfilled, (state, action) => {
				state.tyreTypes = action.payload
			})
			.addMatcher(searchActions, statusHandler)
	}
})

export const selectStatus = state => state.search.wheelPackage.premadeWheelPackage.status
export const selectResult = state => state.search.wheelPackage.premadeWheelPackage.result
export const selectPackageTyreTypes = state => state.search.wheelPackage.premadeWheelPackage.tyreTypes
export const selectFilterIds = state => state.search.wheelPackage.premadeWheelPackage.filterIds
export const selectFilterState = state => state.search.wheelPackage.premadeWheelPackage.filterState
export const selectSortBy = state => state.search.wheelPackage.premadeWheelPackage.sortBy
export const selectAscending = state => state.search.wheelPackage.premadeWheelPackage.ascending

export const selectProcessedResult = createSelector(
	[
		selectResult, 
		selectFilterIds, 
		selectSortBy, 
		selectAscending
	],
	(result, filterIds, sortBy, ascending) => {
		const processedResult = !filterIds
			? result
			: result.filter((r) => filterIds.indexOf(r.id) >= 0)
		return !sortBy
			? processedResult
			: sortPremadeWheelCollection(processedResult, sortBy, ascending)
	}
)

export const { 
	sortResult, 
	setFilter 
} = premadeWheelPackageSearchSlice.actions

export default premadeWheelPackageSearchSlice.reducer
