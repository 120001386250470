import { createSlice } from '@reduxjs/toolkit'
import { loggedOut } from 'utils/redux/commonActions'

const initialState = {
	license: '',
	brandId: null,
	modelId: null,
	bodyTypeId: null,
	vehicleId: null,
	selectedVehicle: null,
	models: null,
	bodyTypes: null,
	vehicles: null,
}

const vehicleSearchSlice = createSlice({
	name: 'vehicleSearch',
	initialState,
	reducers: {
		setLicense: (state, action) => {
			state.license = action.payload
			state.selectedVehicle = null
			state.brandId = null
			state.modelId = null
			state.models = null
			state.vehicleId = null
			state.vehicles = null
			state.bodyTypeId = null
			state.bodyTypes = null
		},
		setBrand: (state, action) => {
			state.license = ''
			state.brandId = action.payload
			state.modelId = null
			state.models = null
			state.vehicleId = null
			state.vehicles = null
			state.bodyTypeId = null
			state.bodyTypes = null
			state.selectedVehicle = null
		},
		setModel: (state, action) => {
			state.modelId = action.payload
			state.bodyTypeId = null
			state.vehicleId = null
			state.vehicles = null
			state.selectedVehicle = null
		},
		setBodyType: (state, action) => {
			state.bodyTypeId = action.payload
			state.vehicleId = null
			state.vehicles = null
			state.selectedVehicle = null
		},
		setVehicle: (state, action) => {
			state.vehicleId = action.payload
		},
		setSelectedVehicle: (state, action) => {
			state.selectedVehicle = action.payload
		},
		setModels: (state, action) => {
			state.models = action.payload
		},
		setBodyTypes: (state, action) => {
			state.bodyTypes = action.payload
		},
		setVehicles: (state, action) => {
			state.vehicles = action.payload
		},
		resetVehicleSearch: () => initialState
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, () => initialState)
	}
})

export const selectStatus = state => state.vehicle.search.status
export const selectLicense = state => state.vehicle.search.license
export const selectModels = state => state.vehicle.search.models
export const selectBodyTypes = state => state.vehicle.search.bodyTypes
export const selectVehicles = state => state.vehicle.search.vehicles
export const selectBrand = state => state.vehicle.search.brandId
export const selectModel = state => state.vehicle.search.modelId
export const selectBodyType = state => state.vehicle.search.bodyTypeId
export const selectVehicle = state => state.vehicle.search.vehicleId
export const selectSelectedVehicle = state => state.vehicle.search.selectedVehicle
export const selectSelectedVehicleId = state => state.vehicle.search.selectedVehicle?.vehicleId

export const {
	setLicense,
	setBrand,
	setModel,
	setBodyType,
	setVehicle,
	setModels,
	setBodyTypes,
	setVehicles,
	setSelectedVehicle,
	resetVehicleSearch
} = vehicleSearchSlice.actions

export default vehicleSearchSlice.reducer