import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getBatteryVoltage } from 'utils/api/articleSearchAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchBatteryVoltage = createAsyncThunk('batteryVoltage/get', async () => await getBatteryVoltage())

const batteryVoltageAction = thunkActions(fetchBatteryVoltage)

const initialState = {
	status: 'idle',
	result: null,
}

const batteryVoltageSlice = createSlice({
	name: 'batteryVoltage',
	initialState,
	extraReducers: builder => {
		builder
			.addCase(fetchBatteryVoltage.pending, state => {
				state.result = []
			})
			.addCase(fetchBatteryVoltage.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(batteryVoltageAction, statusHandler)
	}
})

export const selectStatus = state => state.search.battery.batteryVoltage.status
export const selectResult = state => state.search.battery.batteryVoltage.result

export default batteryVoltageSlice.reducer
