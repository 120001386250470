import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
	getCampaigns,
} from 'utils/api/campaignAPI'
import sliceStateBases, { SliceState } from 'utils/enums/sliceStateBases'
import { statusHandler, thunkActions } from 'utils/utils'
import { RootState } from 'app/store'

export const fetchCampaigns = createAsyncThunk('campaigns/getCampaigns', async () => await getCampaigns())

const campaignsActions = thunkActions(
	fetchCampaigns,
)

export interface CampaignModel {
	campaignId: number
	points: number
	pageLink: string
	listViewImage: string
	productCardImage: string
}

export interface CampaignsState {
	data: CampaignModel[] | null
}

export const initialCampaignsState: SliceState<'FEATURE', CampaignsState> = {
	...sliceStateBases.FEATURE,
	data: null
} as const

export const campaignsSlice = createSlice({
	name: 'campaigns',
	initialState: initialCampaignsState,
	reducers: {

	},
	extraReducers: builder => builder
		.addCase(fetchCampaigns.fulfilled, (state, action) => {
			state.data = action.payload
		})
		.addCase(fetchCampaigns.rejected, (state, action) => {
			state.data = null
		})
		.addMatcher(campaignsActions, statusHandler)
})

export const selectCampaigns = (state: RootState) => state.campaigns.data

export default campaignsSlice.reducer
