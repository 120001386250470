import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getBatteries } from 'utils/api/articleSearchAPI'
import { createResultSelector } from 'utils/helper'
import { loggedOut } from 'utils/redux/commonActions'
import { statusHandler, thunkActions } from 'utils/utils'

export const searchBatteries = createAsyncThunk('batterySearch/search', async (criteria) => await getBatteries(criteria))

const searchBatteryActions = thunkActions(searchBatteries)

const initialState = {
	status: 'idle',
	result: null,
	filterIds: null,
	filterState: null,
	sortBy: null,
	ascending: null,
	criteria: {
		articleText: '',
		voltage: 'all',
		brand: 'all',
		model: 'all',
		fuelType: 'all',
		engine: 'all',
		engineType: 'all',
		year: 'all',
	}
}

const batterySearchSlice = createSlice({
	name: 'battery',
	initialState,
	reducers: {
		setCriteria: (state, action) => {
			state.criteria = {
				...state.criteria,
				...action.payload
			}
		},
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		},
		reset: () => initialState,
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.result = null
				state.filterIds = null
				state.criteria = {
					articleText: '',
					accessoryType: ''
				}
			})
			.addCase(searchBatteries.pending, state => {
				state.filterIds = null
				state.filterState = null
				state.result = null
			})
			.addCase(searchBatteries.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(searchBatteryActions, statusHandler)
	}
})

export const selectCriteria = state => state.search.battery.battery.criteria
export const selectStatus = state => state.search.battery.battery.status
export const selectResult = state => state.search.battery.battery.result
export const selectFilterIds = state => state.search.battery.battery.filterIds
export const selectFilterState = state => state.search.battery.battery.filterState
export const selectSortBy = state => state.search.battery.battery.sortBy
export const selectAscending = state => state.search.battery.battery.ascending

export const { setCriteria, setFilter, sortResult, reset } = batterySearchSlice.actions
export const selectProcessedResult = createResultSelector(selectResult, selectFilterIds, selectSortBy, selectAscending)

export default batterySearchSlice.reducer