import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getTpmsCarBrands, getTpmsCarModels, getTpmsCarYears } from 'utils/api/tpmsCarAPI'
import sliceStatus, { SliceStatus } from 'utils/enums/sliceStatus'
import { statusHandler, thunkActions } from 'utils/utils'
import { RootState } from 'app/store'
import { TpmsCarYearPayload } from 'types/Requests'

export const fetchBrands = createAsyncThunk('tpms/fetchBrands', async () => await getTpmsCarBrands())
export const fetchModels = createAsyncThunk('tpms/fetchModels', async (brand: string) => await getTpmsCarModels(brand))
export const fetchYears = createAsyncThunk('tpms/fetchYears', async (payload: TpmsCarYearPayload) => await getTpmsCarYears(payload))

export const actions = thunkActions(fetchBrands, fetchModels, fetchYears)

interface TpmsState {
	status: SliceStatus
	brands: string[] | null,
	models: string[] | null,
	years: string[] | null,
	criteria: {
		brand: string | null,
		model: string | null,
		year: string | null,
	}
}

const initialState: TpmsState = {
	status: sliceStatus.idle,
	brands: null,
	models: null,
	years: null,
	criteria: {
		brand: null,
		model: null,
		year: null,
	}
}

const tpmsSlice = createSlice({
	name: 'tpms',
	initialState,
	reducers: {
		setSelectedBrand: (state, action: PayloadAction<string>) => {
			state.criteria = {
				...initialState.criteria,
				brand: action.payload,
			}
			state.models = null
		},
		setSelectedModel: (state, action: PayloadAction<string>) => {
			state.criteria.model = action.payload
			state.criteria.year = null
			state.years = null
		},
		setSelectedYear: (state, action: PayloadAction<string>) => {
			state.criteria.year = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchBrands.fulfilled, (state, action) => {
				state.brands = action.payload
			})
			.addCase(fetchModels.fulfilled, (state, action) => {
				state.models = action.payload
			})
			.addCase(fetchYears.fulfilled, (state, action) => {
				state.years = action.payload
			})
			.addMatcher(actions, statusHandler)
	}
})

export const selectStatus = (state: RootState) => state.vehicle.tpms.status
export const selectBrands = (state: RootState) => state.vehicle.tpms.brands
export const selectModels = (state: RootState) => state.vehicle.tpms.models
export const selectYears = (state: RootState) => state.vehicle.tpms.years
export const selectCriteria = (state: RootState) => state.vehicle.tpms.criteria

export const { 
	setSelectedBrand,
	setSelectedModel,
	setSelectedYear
} = tpmsSlice.actions

export default tpmsSlice.reducer