import LanguageCode from 'utils/enums/languageCode'
import apiClient from './apiClient'

/**
 * Post new password
 * @param {ChangePasswordPayload} payload password data
 * @returns 
 */
export const postChangePassword = async (payload: ChangePasswordPayload) => await apiClient.post('/api/changePassword', payload)

export interface ChangePasswordPayload {
	currentPassword: string
	newPassword: string
}

/**
 * Post reset password
 * @param {ResetPasswordPayload} payload password data
 * @returns 
 */
export const postResetPassword = async (payload: ResetPasswordPayload) => await apiClient.post('/api/resetPassword', payload)

export interface ResetPasswordPayload {
	CustomerId?: number | null
	OrganisationNumber: string
	Email: string
	LanguageCode: LanguageCode
}

/**
 * Check if password link is still valid
 * @param {number} customerId 
 * @param {string} token 
 * @returns 
 */
export const getChangePasswordLink = async (customerId: number, token: string) => await apiClient.get(`/api/resetPasswordLink?customerId=${customerId}&token=${token}`)


/**
 * Change password by link
 * @param {ResetPasswordLinkPayload} payload 
 * @returns 
 */
export const postChangePasswordLink = async(payload: ResetPasswordLinkPayload) => await apiClient.post('/api/resetPasswordLink', payload)

export interface ResetPasswordLinkPayload {
	CustomerId?: number | null
	Token: string
	Password?: string
}
