import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getMotoBrands } from 'utils/api/vehicleAPI'
import { loggedOut } from 'utils/redux/commonActions'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchMotoBrands = createAsyncThunk('brands/getMotoBrands', async () => await getMotoBrands())
export const vehicleAction = thunkActions(fetchMotoBrands)

const initialState = {
	status: 'idle',
	items: null
}

const motoBrandsSlice = createSlice({
	name: 'brands',
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(loggedOut, () => initialState)
			.addCase(fetchMotoBrands.fulfilled, (state, action) => {
				state.items = action.payload
			})
			.addCase(fetchMotoBrands.rejected, (state) => {
				state.items = null
			})
			.addMatcher(vehicleAction, statusHandler)
	}
})

export const selectMotoBrandsStatus = state => state.vehicle.motoBrands.status
//export const selectMotoBrands = state => state.vehicle.motoBrands.items?.filter(i => i.id > 0) //selectIncludingNonMotoBrands
export const selectMotoBrands = state => state.vehicle.motoBrands.items
export const selectMotoModels = state => state.vehicle.motoBrands.items

export default motoBrandsSlice.reducer