import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getTyreSizes } from 'utils/api/dimensionsAPI'
import mainGroups from 'utils/enums/MainGroups'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchForkliftTyreSize = createAsyncThunk('tractorTyreSizes/fetchForkliftTyreSize', async (multi = false) => await getTyreSizes(multi, mainGroups.forklift))
const fetchForkliftTyreSizeAction = thunkActions(fetchForkliftTyreSize)

const forkliftTyreSize = createSlice({
	name: 'forkliftTyreSize',
	initialState: {
		status: 'idle',
		sizes: null
	},
	extraReducers: builder => {
		builder
			.addCase(fetchForkliftTyreSize.fulfilled, (state, action) => {
				state.sizes = action.payload
			})
			.addMatcher(fetchForkliftTyreSizeAction, statusHandler)
	}
})

export const selectForkliftTyreSizesStatus = state => state.dimensions.forkliftTyreSize.status
export const selectForkliftTyreSizes = state => state.dimensions.forkliftTyreSize.sizes

export default forkliftTyreSize.reducer
