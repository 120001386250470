import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiClient from 'utils/api/apiClient'
import { 
	getPost, 
	getPosts, 
	getRegions,
	postPost, 
	putPost,
	deletePost 
} from 'utils/api/postAPI'
import { webPostVisibilityTypes } from 'utils/enums/webPostVisibilityTypes'
import { ensureObject, getCurrentISOString, statusHandler, thunkActions } from 'utils/utils'
import sliceStateBases from '../../../utils/enums/sliceBaseStates'
import postType from 'utils/enums/postTypes'

export const fetchPosts = createAsyncThunk('posts/getPosts', async (criteria) => await getPosts(criteria))
export const fetchPost = createAsyncThunk('posts/getPost', async (criteria) => await getPost(criteria))
export const createPost = createAsyncThunk('posts/postPost', async (payload) => await postPost(payload))
export const savePost = createAsyncThunk('posts/putPost', async ([id, payload]) => await putPost(id, payload))
export const removePost = createAsyncThunk('posts/deletePost', async (id) => await deletePost(id))
export const fetchRegions = createAsyncThunk('posts/getRegions', async () => await getRegions())

export const fetchCustomerGroups = createAsyncThunk('posts/getCustomerGroups', async () => await apiClient.get('/api/CustomerGroups'))
export const fetchCompanies = createAsyncThunk('posts/getCompanies', async () => await apiClient.get('/api/Companies'))


const actions = thunkActions(fetchPosts, fetchPost, createPost)

const initialPostsCriteria = {
	type: 0,
	skip: 0,
	take: 200,
}

const initialPostState = {
	name: '',
	active: false,
	createDate: getCurrentISOString(),
	startDate: getCurrentISOString(),
	endDate: null,
	lastEditedBy: 'AD',
	lastChanged: getCurrentISOString(),
	regionIds: [],
	customerIds: [],
	customerGroupIds: [],
	sellerCompanyIds: [],
	customerVisibilityType: webPostVisibilityTypes.exclude,
	customerGroupsVisibilityType: webPostVisibilityTypes.include,
	regionsVisibilityType: webPostVisibilityTypes.exclude,
	contents: [],
	banners: []
}

const initialState = {
	...sliceStateBases.FEATURE,
	postsCriteria: initialPostsCriteria,
	post: initialPostState,
	posts: null,
	banners: null,
	postsAmount: null,
	customerGroups: null,
	regions: null,
	companies: null,
}

const postSlice = createSlice({
	name: 'posts',
	initialState,
	reducers: {
		setPostsCriteria(state, action) {
			const criteria = action.payload
			state.postsCriteria = {
				...state.postsCriteria,
				...(typeof criteria == 'object' ? criteria : {}),
			}
		},
		clearPostsCriteria(state) {
			state.postsCriteria = initialPostsCriteria
		},
		increaseSkip(state, action) {
			let newValue = state.postsCriteria.skip + action.payload
			if (newValue < state.postsAmount) state.postsCriteria.skip = newValue
		},
		decreaseSkip(state, action) {
			let newValue = state.postsCriteria.skip - action.payload
			if (newValue >= 0) state.postsCriteria.skip = newValue
		},
		setType(state, action) {
			state.postsCriteria.type = action.payload
		},
		setPost(state, action) {
			state.post = {
				...(state.post ?? {}),
				...(typeof action.payload == 'object' ? action.payload : {}),
			}
		},
		setPostContent(state, action) {
			const {
				languageCode,
				content,
			} = ensureObject(action.payload)

			if (
				state.post === null ||
				!Array.isArray(state.post.contents) ||
				!languageCode ||
				!content
			) {
				return
			}

			const relevantContentsIdx = state.post.contents.findIndex(content => {
				return content.languageCode === languageCode
			}) ?? state.post.contents.length

			state.post = {
				...(state.post ?? {}),
				// The new contents
				contents: [
					...state.post.contents.slice(0, relevantContentsIdx),
					content,
					...state.post.contents.slice(relevantContentsIdx + 1),
				],
			}
		},
		clearPost(state) {
			state.post = initialPostState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchPost.pending, state => {
				state.post = null
			})
			.addCase(fetchPost.fulfilled, (state, action) => {
				state.post = action.payload
			})
			.addCase(fetchPosts.pending, state => {
				state.posts = null
				state.banners = null
				state.postsAmount = null
			})
			.addCase(fetchPosts.fulfilled, (state, action) => {
				const posts = action.payload

				if (state.postsCriteria.type === postType.banners) {
					state.banners = posts
				} else {
					state.posts = posts
				}

				state.postsAmount = posts?.length ?? null
			})
			.addCase(fetchCustomerGroups.pending, state => {
				state.customerGroups = null
			})
			.addCase(fetchCustomerGroups.fulfilled, (state, action) => {
				state.customerGroups = action.payload
			})
			.addCase(fetchRegions.pending, state => {
				state.regions = null
			})
			.addCase(fetchRegions.fulfilled, (state, action) => {
				state.regions = action.payload
			})
			.addCase(fetchCompanies.pending, state => {
				state.companies = null
			})
			.addCase(fetchCompanies.fulfilled, (state, action) => {
				state.companies = action.payload
			})
			.addMatcher(actions, statusHandler)
	}
})

export const selectPosts = state => state.cms.posts
export const selectPostsCriteria = state => state.cms.posts.postsCriteria
export const selectPostsData = state => state.cms.posts.posts
export const selectBannersData = state => state.cms.posts.banners
export const selectPostData = state => state.cms.posts.post
export const selectIsPostsLoading = state => state.cms.posts.isLoading
export const selectCustomerGroups = state => state.cms.posts.customerGroups
export const selectRegions = state => state.cms.posts.regions
export const selectCompanies = state => state.cms.posts.companies

export const {
	setPostsCriteria,
	clearPostsCriteria,
	increaseSkip,
	decreaseSkip,
	setType,
	setPost,
	setPostContent,
	clearPost,
} = postSlice.actions

export default postSlice.reducer

export const createInitialPostContentData = (
	languageCode,
) => {
	return {
		title: null,
		content: null,
		isDefault: false,
		languageCode,
	}
}
