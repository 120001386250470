import './InputLabel.scss'
import React from 'react'
import classNames from 'classnames'

interface InputLabelProps {
	text: string
	topMargin?: boolean
	noBottomMargin?: boolean
}

export const InputLabel: React.FC<InputLabelProps> = ({ 
	text, 
	topMargin = false,
	noBottomMargin = false
}: InputLabelProps) => {
	return (
		<label className={classNames('InputLabel', {
			'top-margin': topMargin,
			'no-bottom-margin': noBottomMargin
		})}>
			{text}
		</label>
	)
}

export default InputLabel
