import { Button, Modal, SwitchInput } from '@barnsten-it/gummidata-react-components'
import ScrollBox from 'components/ScrollBox/ScrollBox'
import WebText from 'components/WebText/WebText'
import { useSelector } from 'react-redux'
import { selectShowOnlyOnePolicy } from 'features/app/settings/settingsSlice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { setLocalStorageItem } from 'utils/utils'
import './PolicyModal.scss'
import { useLocation } from 'react-router-dom'
import { MainLabel } from 'components/labels/MainLabel/MainLabel'
import useWebTexts from 'utils/hooks/useWebTexts'

interface PolicyModalProps {
	accepted: () => void
}

const PolicyModal: React.FC<PolicyModalProps> = ({
	accepted
}) => {
	const { t } = useTranslation()
	const showOnlyOnePolicy = useSelector(selectShowOnlyOnePolicy)
	const [acceptCookies, setAcceptCookies] = React.useState(false)
	const [acceptGDPR, setAcceptGDPR] = React.useState(false)
	const location = useLocation()
	const { wt } = useWebTexts('policy')

	const acceptCookiesHandler = () => {
		setAcceptCookies(!acceptCookies)
	}

	const acceptGDPRHandler = () => {
		setAcceptGDPR(!acceptGDPR)
	}

	const acceptPolicys = () => {
		setLocalStorageItem('policyAccepted', true)
		accepted()
	}

	// Do not show policy modal for policy page
	if(location?.pathname === '/policy-gdpr')
		return null

	return (
		<div className="PolicyModal">
			<Modal label={wt('mainPopupTitle')} closeModalButton={false}>
				<ScrollBox>

					<div className="policy-content-wrapper">
						{!showOnlyOnePolicy && 
							<MainLabel text={wt('cookiePopupTitle')} />
						}
						<p><WebText namespace="policy" property="cookiePopupText" /></p>
					
						<div className="accept-row">
							<WebText namespace="policy" property="cookiePopupAcceptenceString" />
							<SwitchInput checked={acceptCookies} onChange={acceptCookiesHandler} />
						</div>

						{!showOnlyOnePolicy && 
							<div>
								<MainLabel text={wt('gdprPopupTitle')} />
								<p>
									<WebText namespace="policy" property="gdprPopupText" />
									<a href="https://gdpr-info.eu/">{t('common.readMore')}</a>
								</p>
							
								<div className="accept-row">
									<p><WebText namespace="policy" property="gdprPopupAcceptenceString" /></p>
									<SwitchInput checked={acceptGDPR} onChange={acceptGDPRHandler} />
								</div>
							</div>
						}

						<Button label={t('common.save')} disabled={!(((!showOnlyOnePolicy) ? acceptGDPR : true) && acceptCookies)} onClick={acceptPolicys}/>
					</div>
				</ScrollBox>
			</Modal>
		</div>
	)
}

export default PolicyModal
