import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { postListOnEmail } from 'utils/api/articleSearchAPI'
import { statusHandler, thunkActions } from 'utils/utils'


export const sendListOnEmail = createAsyncThunk('emailWheelPackage/emailList', async (criteria) => {
	return await postListOnEmail(criteria)
})

const searchActions = thunkActions(sendListOnEmail)

const initialState = {
	status: 'idle',
	emailAddress: '',
}

const emailWheelPackageListSlice = createSlice({
	name: 'emailWheelPackage',
	initialState,
	reducers: {	
		setEmail: (state, action) => {
			state.emailAddress = action.payload.emailAddress
		},
	},
	extraReducers: builder => {
		builder			
			.addCase(sendListOnEmail.fulfilled, (state, action) => {
				state.emailAddress = ''
			})
			.addMatcher(searchActions, statusHandler)
	}
})

export const selectStatus = state => state.search.emailWheelPackageList.status
export const selectSelectedEmail = state => state.search.emailWheelPackageList.emailAddress

export const {
	setEmail
} = emailWheelPackageListSlice.actions

export default emailWheelPackageListSlice.reducer