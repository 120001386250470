import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getTyresByProperties } from 'utils/api/articleSearchAPI'
import { createResultSelector } from 'utils/helper'
import { statusHandler, thunkActions } from 'utils/utils'
import { loggedOut } from 'utils/redux/commonActions'
import { isPreviousSearchCategory, isSearchCategory } from 'utils/helper'
import sliceStatus from 'utils/enums/sliceStatus'
import tyreType from 'utils/enums/tyreType'

export const searchTyres = createAsyncThunk('truckTyreSearch/searchTyres', async (criteria) => await getTyresByProperties(
	criteria.width,
	criteria.aspectRatio,
	criteria.diameter,
	criteria.secondaryWidth,
	criteria.secondaryAspectRatio,
	criteria.secondaryDiameter,
	criteria.articleText,
	null,
	null,
	null,
	null,
	criteria.tyreType,
	criteria.languageCode
))

const searchTyresActions = thunkActions(searchTyres)

const truckTyreSearchSlice = createSlice({
	name: 'truckTyreSearch',
	initialState: {
		status: 'idle',
		result: null,
		filterIds: null,
		filterState: null,
		sortBy: null,
		ascending: null,
		criteria: {
			tyreType: tyreType.truck,
			selectedSize: null,
			width: null,
			aspectRatio: null,
			diameter: null,
			secondaryWidth: null,
			secondaryAspectRatio: null,
			secondaryDiameter: null,
			articleText: '' // Why this not nullable in api?
		}
	},
	reducers: {
		setCriteria: (state, action) => {
			state.criteria[action.payload.name] = action.payload.value
		},
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState 
		},
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		}
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.result = null
				state.filterIds = null
				state.criteria = {
					tyreType: tyreType.truck,
					selectedSize: null,
					width: null,
					aspectRatio: null,
					diameter: null,
					secondaryWidth: null,
					secondaryAspectRatio: null,
					secondaryDiameter: null,
					articleText: ''
				}
			})
			.addCase(searchTyres.pending, state => {
				state.filterIds = null
				state.filterState = null
				state.result = null
				state.sortBy = null
				state.ascending = null
			})
			.addCase(searchTyres.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(isPreviousSearchCategory('truck', 'tyre'), (state) => {
				state.status = sliceStatus.idle
			})
			.addMatcher(isSearchCategory('truck', 'tyre'), (state, action) => {
				state.status = sliceStatus.success
				state.result = action.payload
			})
			.addMatcher(searchTyresActions, statusHandler)
	}
})

export const selectCritiera = state => state.search.tyre.truckTyre.criteria
export const selectStatus = state => state.search.tyre.truckTyre.status
export const selectResult = state => state.search.tyre.truckTyre.result
export const selectFilterIds = state => state.search.tyre.truckTyre.filterIds
export const selectFilterState = state => state.search.tyre.truckTyre.filterState
export const selectSortBy = state => state.search.tyre.truckTyre.sortBy
export const selectAscending = state => state.search.tyre.truckTyre.ascending

export const selectProcessedResult = createResultSelector(
	selectResult, 
	selectFilterIds, 
	selectSortBy, 
	selectAscending
)

export const { 
	setCriteria, 
	sortResult,
	setFilter 
} = truckTyreSearchSlice.actions

export default truckTyreSearchSlice.reducer
