import React from 'react'
import { 
	Facebook,
	Instagram,
	Linkedin,
	Twitter,
	Messenger,
	Reddit,
	Snapchat,
	WeChat,
	WhatsApp,
	Youtube
} from 'assets/svgs/svgr'

import './SocialLinks.scss'
import { SocialConfigModel } from 'types/SiteData'

interface SocialLinksProps {
	socials?: SocialConfigModel
}

const SocialLinks: React.FC<SocialLinksProps> = ({socials}) => {
	if (!socials || Object.keys(socials).length === 0) {
		return null
	}

	return (
		<div className="SocialLinks">
			{socials?.facebook && 
				<a href={socials.facebook} target="_blank" rel="noreferrer">
					<Facebook height={24} width={24} />
				</a>
			}

			{socials?.instagram && 
				<a href={socials.instagram} target="_blank" rel="noreferrer">
					<Instagram height={24} width={24} />
				</a>
			}

			{socials?.twitter && 
				<a href={socials.twitter} target="_blank" rel="noreferrer">
					<Twitter height={24} width={24}/>
				</a>
			}
					
			{socials?.linkedin && 
				<a href={socials.linkedin} target="_blank" rel="noreferrer">
					<Linkedin height={24} width={24}/>
				</a>
			}

			{socials?.messenger && 
				<a href={socials.messenger} target="_blank" rel="noreferrer">
					<Messenger height={24} width={24}/>
				</a>
			}
			
			{socials?.reddit && 
				<a href={socials.reddit} target="_blank" rel="noreferrer">
					<Reddit height={24} width={24}/>
				</a>
			}
						
			{socials?.snapchat && 
				<a href={socials.snapchat} target="_blank" rel="noreferrer">
					<Snapchat height={24} width={24}/>
				</a>
			}

			{socials?.wechat && 
				<a href={socials.wechat} target="_blank" rel="noreferrer">
					<WeChat height={24} width={24}/>
				</a>
			}

			{socials?.whatsapp && 
				<a href={socials.whatsapp} target="_blank" rel="noreferrer">
					<WhatsApp height={24} width={24}/>
				</a>
			}

			{socials?.youtube && 
				<a href={socials.youtube} target="_blank" rel="noreferrer">
					<Youtube height={24} width={24}/>
				</a>
			}

		</div>
	)
}

export default SocialLinks