import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { AcessoriesQuery, getAccessoriesByProperties } from 'utils/api/articleSearchAPI'
import sliceStatus, { SliceStatus } from 'utils/enums/sliceStatus'
import { createResultSelector } from 'utils/helper'
import { statusHandler, thunkActions } from 'utils/utils'

export const searchTubes = createAsyncThunk('carTubesSearch/search', async (payload: AcessoriesQuery) =>
	await getAccessoriesByProperties(
		payload.articleText,
		0,
		payload.mainGroupId,
		payload.diameter,
		payload.width,
		payload.aspectRatio,
	))

const tubesActions = thunkActions(searchTubes)

interface CarTubesState {
	status: SliceStatus
	result: object | null,
	filterIds: any | null,
	filterState: any | null,
	sortBy: string | null,
	ascending: boolean | null,
	criteria: {
		articleText: string | null
	}
}

const initialState: CarTubesState = {
	status: sliceStatus.idle,
	result: null,
	filterIds: null,
	filterState: null,
	sortBy: null,
	ascending: null,
	criteria: {
		articleText: ''
	}
}

const carTubesSearchSlice = createSlice({
	name: 'carTubesSearch',
	initialState,
	reducers: {
		setCriteria: (state, action: PayloadAction<any> ) => {
			state.criteria.articleText = action.payload.value
		},
		setFilter: (state, action: PayloadAction<any>) => {
			state.filterIds = action.payload.filterIds
			state.filterState = action.payload.filterState
		},
		sortResult: (state, action: PayloadAction<any>) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		}
	},
	extraReducers: builder => {
		builder
			.addCase(searchTubes.pending, state => {
				state.filterIds = null
				state.filterState = null
				state.result = null
			})
			.addCase(searchTubes.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(tubesActions, statusHandler)
	}
})

export const selectCriteria = (state: RootState) => state.search.tube.carTubesSearch.criteria
export const selectStatus = (state: RootState) => state.search.tube.carTubesSearch.status
export const selectResult = (state: RootState) => state.search.tube.carTubesSearch.result
export const selectFilterIds = (state: RootState) => state.search.tube.carTubesSearch.filterIds
export const selectFilterState = (state: RootState) => state.search.tube.carTubesSearch.filterState
export const selectSortBy = (state: RootState) => state.search.tube.carTubesSearch.sortBy
export const selectAscending = (state: RootState) => state.search.tube.carTubesSearch.ascending

export const selectProcessedResult = createResultSelector(selectResult, selectFilterIds, selectSortBy, selectAscending)

export const { 
	setCriteria, 
	setFilter, 
	sortResult 
} = carTubesSearchSlice.actions

export default carTubesSearchSlice.reducer