import { combineReducers } from '@reduxjs/toolkit'
import subCustomers from './subCustomers/subCustomersSlice'
import subCustomerOrders from './subCustomerOrders/subCustomerOrdersSlice'
import subCustomerDiscounts from './subCustomerDiscounts/subCustomerDiscountsSlice'
import subCustomerInvoices from './subCustomerInvoices/subCustomerInvoicesSlice'
import resellerSettings from './resellerSettings/resellerSettingsSlice'

export default combineReducers({
	subCustomers,
	subCustomerDiscounts,
	subCustomerOrders,
	subCustomerInvoices,
	resellerSettings,
})
