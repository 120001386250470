import { UserEditModel } from 'types/api'
import apiClient from './apiClient'

/**
 * Login
 * @param {string} username - CustomerId
 * @param {string} password - Password for customer or webuser
 * @param {boolean} rememberMe - Remember me
 * @returns 
 */
export const loginUser = async (payload: LoginPayload) => await apiClient.post('/api/login', payload)

export interface LoginPayload {
	username: string
	password: string
	rememberMe: boolean
}

/**
 * Logut
 */
export const logoutUser = async () => await apiClient.delete('/api/login')

/**
 * Fetch customer info model
 * @returns custmoer model
 */
export const fetchUser = async () => await apiClient.get('/api/user')

/**
 * Save user data
 * @param {object} data 
 * @returns 
 */
export const postUser = async (payload: UserEditModel) => await apiClient.post('/api/user', payload)

/**
 * Get due invocies
 * @returns array of due invoices
 */
export const getDueInvoices = async () => await apiClient.get('/api/userinvoices/')


/**
 * Upload PDF logo
 * @returns array of due invoices
 */
export const postLogo = async (payload) => await apiClient.post('/api/userLogo/', payload, { isFile: true })
