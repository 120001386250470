import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RimFilter from 'components/filters/RimFilter/RimFilter'
import { setFilter, selectResult, selectFilterState } from '../industryRimSearchSlice'

const IndustryRimSearchFilter = () => {
	const dispatch = useDispatch()
	const result = useSelector(selectResult)
	const filterState = useSelector(selectFilterState)

	const handleFilterChange = (articleIds, filterState) => {
		dispatch(setFilter({ articleIds, filterState }))
	}

	const emptySearchResult = result &&
		(result.inStock.length || result.incoming.length || result.toOrder.length) ? false : true

	return (
		<div className="IndustryRimSearchFilter">
			{
				!emptySearchResult &&
				<RimFilter 
					result={result} 
					filterState={filterState}
					onFilterChange={handleFilterChange} />
			}
		</div>
	)
}

export default IndustryRimSearchFilter
