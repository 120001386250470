import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Filter } from '@barnsten-it/gummidata-react-components'
import { getAllArticles } from 'utils/helper'
import FilterContainer from '@barnsten-it/gummidata-react-components/dist/Filter/FilterContainer/FilterContainer'
import { useCommonFilterSettings } from 'utils/hooks/useCommonFilterSettings'

const AccessoryFilter = ({
	result,
	onFilterChange,
	filterState, 
	showStockFilter=false
}) => {
	const [filterSettings, setFilterSettings] = useState(null)
	const [articles, setArticles] = useState(null)
	const { t } = useTranslation()
	const commonFilterSettings = useCommonFilterSettings()

	const createFilter = useCallback(() => {
		if (!result)
			return

		const allArticles = getAllArticles(result)
		const hasOutletArticles = allArticles.some(a => a.isOutlet)

		let settings = [
			{
				name: t('searchFilter.freeFilter'),
				property: 'freeText',
				items: [''],
				type: Filter.type.text,
				condition: (item, selected) => {
					const ids = item.id.toString().toUpperCase().includes(selected.toString().toUpperCase())						
					const manufacturerArticleId = item.manufacturerArticleId?.toString().toUpperCase().includes(selected.toString().toUpperCase())						
					const articleText = item.articleText?.toString().toUpperCase().includes(selected.toString().toUpperCase())
					return ids || articleText || manufacturerArticleId
				}
			},
			{
				name: t('searchFilter.brand'),
				property: 'manufacturer',
				enableLimit: true,
				sortEnabled: true,
				showMoreText: t('common.showMore'),
				type: Filter.type.multipleSelect
			},
			{
				name: t('searchFilter.diameter'),
				property: 'diameter',
				type: Filter.type.range
			},
			{
				name: t('searchFilter.hex'),
				property: 'hex',
				type: Filter.type.multipleSelect
			},
			{
				name: t('searchFilter.threadDiameter'),
				property: 'threadDiameter',
				type: Filter.type.multipleSelect
			},
			{
				name: t('searchFilter.thread'),
				property: 'thread',
				type: Filter.type.multipleSelect
			},
			{
				name: t('common.seatType'),
				property: 'seatType',
				type: Filter.type.multipleSelect
			},
			{
				name: t('searchFilter.innerDiameter'),
				property: 'innerDiameter',
				type: Filter.type.range,
				step: 0.1
			},
			{
				name: t('searchFilter.outerDiameter'),
				property: 'outerDiameter',
				type: Filter.type.range,
				step: 0.1
			},
			{
				name: t('searchFilter.thickness'),
				property: 'thickness',
				type: Filter.type.range,
				step: 0.1
			},
			{
				name: t('searchFilter.autoCenterBore'),
				property: 'autoCenterBore',
				type: Filter.type.multipleSelect
			},
			{
				name: t('searchFilter.spacerCenterBore'),
				property: 'spacerCenterBore',
				type: Filter.type.multipleSelect
			},
			{
				name: t('searchFilter.autoPCD'),
				property: 'autoPCD',
				type: Filter.type.multipleSelect
			},
			{
				name: t('searchFilter.spacerPCD'),
				property: 'spacerPCD',
				type: Filter.type.multipleSelect
			},
			{
				name: t('searchFilter.measurementA'),
				property: 'measurementA',
				type: Filter.type.multipleSelect
			},
			{
				name: t('searchFilter.measurementB'),
				property: 'measurementB',
				type: Filter.type.multipleSelect
			},
			{
				name: t('searchFilter.measurementC'),
				property: 'measurementC',
				type: Filter.type.multipleSelect
			},
			{
				name: t('searchFilter.measurementD'),
				property: 'measurementD',
				type: Filter.type.multipleSelect
			},
			{
				name: t('searchFilter.measurementE'),
				property: 'measurementE',
				type: Filter.type.multipleSelect
			},
			{
				name: t('searchFilter.vehicle'),
				property: 'vehicle',
				type: Filter.type.multipleSelect
			},
			{
				name: t('searchFilter.length'),
				property: 'length',
				type: Filter.type.range,
				step: 0.1
			}
		]

		if (hasOutletArticles) {
			settings = [
				...settings,
				...commonFilterSettings.outlet
			]
		}

		if (showStockFilter) {
			settings = [
				...settings,
				{
					name: t('common.stock'),
					type: Filter.type.multipleSelect,
					items: [
						{
							value: 1,
							text: t('common.inStock')
						},
						{
							value: 2,
							text: t('shopPage.incoming')
						},
						{
							value: 3,
							text: t('common.toOrder')
						},					
					],
					condition: (item, selected) => {
						const inStock = selected.includes(1) && item.quantityAvailable > 0
						const incoming = selected.includes(2) && (item.quantityAvailable === 0 && item.quantityExternal === 0 && item.quantityIncoming > 0 )
						const toOrder = selected.includes(3) && (item.quantityAvailable === 0 && item.quantityIncoming === 0)
						return inStock || incoming || toOrder
					}
				},
			]
		}

		setArticles(allArticles)
		setFilterSettings(settings)
	}, [t, result, showStockFilter, commonFilterSettings.outlet])

	useEffect(() => {
		if (result)
			createFilter()
	}, [result, createFilter])

	if (!filterSettings || !articles || !result)
		return null

	return (
		<div className="AccessoryFilter">
			<FilterContainer hasResult={!!result}>
				<Filter
					identifier="id"
					label={t('common.searchFilter')}
					settings={filterSettings}
					items={articles}
					selected={filterState?.selected}
					onFilterChange={onFilterChange} />
			</FilterContainer>
		</div>
	)
}

export default AccessoryFilter
