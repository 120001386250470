import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { createResultSelector } from 'utils/helper'
import { statusHandler, thunkActions } from 'utils/utils'
import { getWheelRimsByVehicleIdId, getWheelsTyresByProperties, getWheelsTpmsByVehicleId, getWheelRimsByCarModel } from 'utils/api/articleSearchAPI'
import { loggedOut } from 'utils/redux/commonActions'

export const searchRims = createAsyncThunk('wheelPackageSearch/searchRims', async (criteria) => {
	if (criteria.vehicleId) {
		return {
			result: await getWheelRimsByVehicleIdId(criteria.vehicleId, criteria.license, criteria.languageCode),
			criteria
		}
	}
	return {
		result: await getWheelRimsByCarModel(criteria.carModel, criteria.brandId, criteria.languageCode),
		criteria
	}
})

export const searchTyres = createAsyncThunk('wheelPackagesSearch/searchTyres', async (criteria) => {
	return await getWheelsTyresByProperties(
		criteria.vehicleId,
		criteria.frontArticleId,
		criteria.rearArticleId,
		criteria.frontThirdPartyIntegrationId,
		criteria.rearThirdPartyIntegrationId,
		criteria.tyreType,
		criteria.languageCode
	)
})

export const getTPMSInfo = createAsyncThunk('wheelPackagesSearch/getTPMSInfo', async (vehicleId) => await getWheelsTpmsByVehicleId(vehicleId))

const searchActions = thunkActions(searchRims, searchTyres, getTPMSInfo)

const initialState = {
	status: 'idle',
	rimResult: null,
	tyreResult: null,
	filterIds: null,
	filterState: null,
	rimFilterIds: null,
	rimFilterState: null,
	step: 'start',
	selectedRim: null,
	selectedTpms: null,
	selectedRimBack: null,
	selectedTyre: null,
	selectedTyreBack: null,
	showCombination: false,
	criteria: {
		vehicleId: null,
		license: null,
		brandId: null,
		carModel: null,
	},
	tpmsInfo: null,
	addingToCart: false,
	sortBy: null,
	ascending: null,
}

const carWheelPackageSearch = createSlice({
	name: 'carWheelPackage',
	initialState: initialState,
	reducers: {
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState

			if (state.step === 'rim') {
				state.rimFilterIds = action.payload.articleIds
				state.rimFilterState = action.payload.filterState
			} else if (state.step === 'tyre') {
				state.tyreFilterIds = action.payload.articleIds
				state.tyreFilterState = action.payload.filterState
			}
		},
		setShowCombination: (state, action) => {
			state.showCombination = action.payload
			state.selectedRim = null
			state.selectedRimBack = null
		},
		setSelectedRim: (state, action) => {
			state.selectedRim = action.payload
		},
		setSelectedRimBack: (state, action) => {
			state.selectedRimBack = action.payload
		},
		setSelectedTyre: (state, action) => {
			state.selectedTyre = action.payload
		},
		setSelectedTpms: (state, action) => {
			state.selectedTpms = action.payload
		},
		setSelectedTyreBack: (state, action) => {
			state.selectedTyreBack = action.payload
		},
		reset: () => initialState,
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		},
		resetRim: (state) => {
			state.selectedRim = null
			state.selectedRimBack = null
			state.selectedTyre = null
			state.selectedTyreBack = null
			state.step = 'rim'
		},
		resetTyre: (state) => {
			state.selectedTyre = null
			state.selectedTyreBack = null
			state.step = 'tyre'
		},
		setStep: (state, action) => {
			state.step = action.payload
		},
		setVehicle: (state, action) => {
			state.step = 'start'
			state.criteria.vehicleId = action.payload.vehicleId
			state.criteria.license = action.payload.license
			state.criteria.brandId = action.payload.brandId
			state.criteria.carModel = action.payload.carModel
		}
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, () => initialState)
			.addCase(searchRims.pending, () => initialState)
			.addCase(searchRims.fulfilled, (state, action) => {
				state.rimResult = action.payload.result
				state.criteria = action.payload.criteria
				state.step = 'rim'
			})
			.addCase(searchTyres.pending, state => {
				state.filterIds = null
				state.filterState = null
				state.tyreResult = null
				state.step = 'tyre'
			})
			.addCase(searchTyres.fulfilled, (state, action) => {
				state.tyreResult = action.payload
			})
			.addCase(getTPMSInfo.fulfilled, (state, action) => {
				state.tpmsInfo = action.payload
			})
			.addMatcher(searchActions, statusHandler)
	}
})

export const selectStatus = state => state.search.wheelPackage.carWheelPackage.status
export const selectWheelPacakge = state => state.search.wheelPackage.carWheelPackage
export const selectRimResult = state => state.search.wheelPackage.carWheelPackage.rimResult
export const selectTyreResult = state => state.search.wheelPackage.carWheelPackage.tyreResult
export const selectFilterIds = state => state.search.wheelPackage.carWheelPackage.filterIds
export const selectFilterState = state => state.search.wheelPackage.carWheelPackage.filterState
export const selectStep = state => state.search.wheelPackage.carWheelPackage.step
export const selectCriteria = state => state.search.wheelPackage.carWheelPackage.criteria
export const selectSelectedRim = state => state.search.wheelPackage.carWheelPackage.selectedRim
export const selectSelectedRimBack = state => state.search.wheelPackage.carWheelPackage.selectedRimBack
export const selectSelectedTyre = state => state.search.wheelPackage.carWheelPackage.selectedTyre
export const selectSelectedTyreBack = state => state.search.wheelPackage.carWheelPackage.selectedTyreBack
export const selectSelectedTpms = state => state.search.wheelPackage.carWheelPackage.selectedTpms
export const selectTpmsInfo = state => state.search.wheelPackage.carWheelPackage.tpmsInfo
export const selectSortBy = state => state.search.wheelPackage.carWheelPackage.sortBy
export const selectAscending = state => state.search.wheelPackage.carWheelPackage.ascending
export const selectShowCombination = state => state.search.wheelPackage.carWheelPackage.showCombination

// To keep track of filter
export const selectRimFilterIds = state => state.search.wheelPackage.rimFilterIds
export const selectRimFilterState = state => state.search.wheelPackage.rimFilterState
export const selectTyreFilterIds = state => state.search.wheelPackage.tyreFilterIds
export const selectTyreFilterState = state => state.search.wheelPackage.tyreFilterState

export const selectFilteredRimResult = createResultSelector(
	selectRimResult,
	selectFilterIds,
	selectSortBy,
	selectAscending,
	selectShowCombination
)

export const selectFilteredTyreResult = createResultSelector(
	selectTyreResult,
	selectFilterIds,
	selectSortBy,
	selectAscending,
	selectShowCombination
)

export const selectArticleCost = createSelector(
	[
		selectSelectedRim,
		selectSelectedRimBack,
		selectSelectedTyre,
		selectSelectedTyreBack,
		selectSelectedTpms,
	],
	(rim, rimBack, tyre, tyreBack, tpms) => {
		return {
			netPrice:
				(rim?.netPrice || 0) + 
				(tyre?.netPrice || 0) + 
				(tpms?.netPrice || 0),
			retailPrice:
				(rim?.retailPrice || 0) +
				(tyre?.retailPrice || 0) +
				(tpms?.retailPrice || 0),
			netPriceBack: rimBack
				? (rimBack?.netPrice || 0) +
				  (tyreBack?.netPrice || 0) +
				  (tpms?.netPrice || 0)
				: null,
			retailPriceBack: rimBack
				? (rimBack?.retailPrice || 0) +
				  (tyreBack?.retailPrice || 0) +
				  (tpms?.retailPrice || 0)
				: null,
		}
	}
)

export const {
	setFilter,
	setSelectedRim,
	setSelectedRimBack,
	setSelectedTyre,
	setSelectedTpms,
	setSelectedTyreBack,
	setShowCombination,
	setStep,
	reset,
	resetTyre,
	resetRim,
	sortResult,
	setVehicle,
} = carWheelPackageSearch.actions

export default carWheelPackageSearch.reducer
