import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getIndustryAgroTyreSizes } from 'utils/api/dimensionsAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchIndustryAgroTyreSizes = createAsyncThunk('tractorTyreSizes/fetchIndustryAgroTyreSizes', async (multi = false) => await getIndustryAgroTyreSizes(multi))
const fetchIndustryAgroTyreSizesAction = thunkActions(fetchIndustryAgroTyreSizes)

const industryAgroTyreSize = createSlice({
	name: 'industryAgroTyreSize',
	initialState: {
		status: 'idle',
		sizes: null
	},
	extraReducers: builder => {
		builder
			.addCase(fetchIndustryAgroTyreSizes.fulfilled, (state, action) => {
				state.sizes = action.payload
			})
			.addMatcher(fetchIndustryAgroTyreSizesAction, statusHandler)
	}
})

export const selectIndustryAgroTyreSizesStatus = state => state.dimensions.industryAgroTyreSize.status
export const selectIndustryAgroTyreSizes = state => state.dimensions.industryAgroTyreSize.sizes

export default industryAgroTyreSize.reducer
