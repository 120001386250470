import './QuantityInput.scss'
import React, { useEffect } from 'react'
import { ListViewQuantityInput, ListViewSelectPicker } from '@barnsten-it/gummidata-react-components/'
import { useTranslation } from 'react-i18next'
import MultiplySelectPicker from 'components/selects/MultiplySelectPicker/MultiplySelectPicker'
import { piecesPerPackageCount } from 'utils/settings'
import AnyArticle from 'types/AnyArticle'

interface QuantityInputProps {
	article: AnyArticle
	name?: string
	cart?: boolean
	value: number
	error?: string | null
	disabled?: boolean
	dropdown?: boolean
	dropdownStep?: number
	multiplyDropdown?: boolean
	multiplyBy?: number,
	isPackage?: boolean
	onQuantityChange: (article: AnyArticle, quantityChange: number) => void
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
	onEnterKey: () => void
}

const QuantityInput: React.FC<QuantityInputProps> = ({
	article,
	name = null,
	cart = false,
	value,
	error = null,
	disabled = false,
	dropdown = false,
	dropdownStep = 1,
	multiplyDropdown,
	multiplyBy,
	isPackage = false,
	onQuantityChange,
	onBlur = null,
	onEnterKey
}) => {
	const { t } = useTranslation()
	const [qtyArray, setQtyArray] = React.useState<number[] | null>(null)

	const handleQuantityChange = (quantityChange: any) => {
		onQuantityChange(article, quantityChange)
	}

	const getQtyArray = () => {
		var array = []
		for (let i = 0; i < 100 + dropdownStep; i++) {
			if (i % dropdownStep === 0) {
				array.push(i)
			}
		}
		return array
	}

	const onKeyUpHandler = (e: React.KeyboardEvent) => {	
		if (e.key === 'Enter'){
			onEnterKey()
		}
	}

	useEffect(() => {
		if (dropdown)
			setQtyArray(getQtyArray())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dropdown])

	return (
		<div className="QuantityInput">
			{dropdown && qtyArray ? (
				<ListViewSelectPicker
					name={name}
					value={value}
					error={error}
					onChange={handleQuantityChange}
					onBlur={onBlur}
				>
					{qtyArray.map((qty, index) => {
						return (
							<ListViewSelectPicker.Option
								key={index}
								name={qty + ' ' + (isPackage ? t('wheelPage.set') : t('common.pieces'))}
								value={qty}
							/>
						)
					})}
				</ListViewSelectPicker>
			) : multiplyDropdown && multiplyBy ? (
				<MultiplySelectPicker
					multiplyBy={multiplyBy}
					count={piecesPerPackageCount}
					optionLabel={isPackage ? t('wheelPage.set') : t('common.pieces')}
					name={name}
					value={value}
					isListViewPicker
					onChange={handleQuantityChange}
				/>
			) : cart ? (
				<ListViewQuantityInput
					name={name}
					value={value}
					error={error}
					disabled={disabled}
					onBlur={onBlur}
				/>
			) : (
				<ListViewQuantityInput
					name={name}
					value={value}
					error={error}
					disabled={disabled}
					onChange={handleQuantityChange}
					onKeyUp={onKeyUpHandler}
				/>
			)}
		</div>
	)
}

export default QuantityInput
