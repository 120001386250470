import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectPicker } from '@barnsten-it/gummidata-react-components'
import { fetchCountries, selectCountries } from '../countriesSlice'
import { useTranslation } from 'react-i18next'

const CountryPicker = ({ ...props }) => {
	const dispatch = useDispatch()
	const countries = useSelector(selectCountries)
	const { t } = useTranslation()

	useEffect(() => {
		if (!countries)
			dispatch(fetchCountries('SV'))
	}, [dispatch, countries])

	return (
		<SelectPicker {...props} placeholder={t('common.choose')}>
			{
				countries && countries.map(country =>
					<SelectPicker.Option
						key={country.id}
						name={country.name}
						value={country.countryCode}
					/>
				)
			}
		</SelectPicker>
	)
}

export default CountryPicker
