import { selectTheme, selectHasLogo, selectLogoText } from 'features/app/settings/settingsSlice'
import React from 'react'
import { useSelector } from 'react-redux'
import importAllImages from 'utils/importAllImages'

import './Logo.scss'

export enum LogoSize {
	Small = 'Small',
	Medium = 'Medium',
	Large = 'Large'
}

interface LogoProps {
	size: LogoSize
}

const Logo: React.FC<LogoProps> = ({ 
	size 
}) => {
	const theme = useSelector(selectTheme)
	const hasLogo = useSelector(selectHasLogo)
	const logoText = useSelector(selectLogoText)
	// @ts-ignore Cant be helped. This works fine.
	const images = importAllImages(require.context('assets/images/logos/', true, /\.png$/))
	const image = images[theme + '/Logo-' + size + '.png']

	return (
		<div className="Logo">
			{
				hasLogo ?
					<img src={image} alt="main logo" className="logo-img" />
					:
					<div className="logoText" >{logoText}</div>
			}
		</div>
	)
}
export default Logo