import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter, selectResult, selectFilterState } from '../carTpmsSearchSlice'
import TpmsFilter from 'components/filters/TpmsFilter/TpmsFilter'

const CarTpmsSearchFilter = () => {
	const dispatch = useDispatch()
	const result = useSelector(selectResult)
	const filterState = useSelector(selectFilterState)

	const handleFilterChange = (articleIds, filterState) => {
		dispatch(setFilter({ articleIds, filterState }))
	}

	const emptySearchResult = result &&
		(result?.inStock?.length || result?.incoming?.length || result?.toOrder?.length) ? false : true

	return (
		<div className="CarTpmsSearchFilter">
			{
				!emptySearchResult &&
				<TpmsFilter 
					result={result}
					filterState={filterState} 
					onFilterChange={handleFilterChange} />
			}
		</div>
	)
}

export default CarTpmsSearchFilter
