import { createSlice } from '@reduxjs/toolkit'
import { searchCategoryChange } from 'utils/redux/commonActions'

const initialState = {
	category: null,
	vehicleType: null
}

const checkCategorySlice = createSlice({
	name: 'checkCategory',
	initialState,
	reducers: {
		clearCategory: (state) => {
			state.category = null
			state.vehicleType = null
		}
	},
	extraReducers: builder => {
		builder.addCase(searchCategoryChange, (state, action) => {
			state.category = action.payload.category
			state.vehicleType = action.payload.vehicleType
		})
	}
})

export const { clearCategory } = checkCategorySlice.actions

export const selectCategory = state => state.search.checkCategory.category
export const selectVehicleType = state =>  state.search.checkCategory.vehicleType

export default checkCategorySlice.reducer
