import './CategoryMenu.scss'
import React from 'react'
import MenuItem from './MenuItem/MenuItem'
import { SelectPicker } from '@barnsten-it/gummidata-react-components'
import ArticleSearchType from 'utils/enums/articleSearchType'
import { useLocation, useNavigate } from 'react-router-dom'
import useUrlInterpreter from 'utils/hooks/useUrlInterpreter'

export interface CategoryMenuItem {
	label: string
	articleType: ArticleSearchType
	imageSrc: string
	disabled?: boolean
}

export interface CategoryMenuProps {
	menuItems: CategoryMenuItem[],
	width?: number
}


/**
 * CategoryMenu component
 * 
 * @description
 * The main Tab based category menu for the shop.
 * 
 * @interface CategoryMenuProps
 * 
 * @param {CategoryMenuItem[]} menuItems - Array of menu items
 * @param {number} width - Width of the menu in rem
 * 
 * @returns React.FC<CategoryMenuProps>
 */
const CategoryMenu: React.FC<CategoryMenuProps> = ({ 
	menuItems = [],
	width = 150
}) => {
	const navigate = useNavigate()
	const location = useLocation()
	const { searchType, articleType } = useUrlInterpreter()


	/**
	 * Width of the menu item in rem
	 */
	const itemWidth = width / 16


	/**
	 * Position of the active item for animations
	 */
	const [position, setPosition] = React.useState<number>(0)


	/**
	 * Mobile category select picker handler
	 * 
	 * @param articleType: articleSearchType 
	 */
	const selectPickerHandler = (articleType: ArticleSearchType) => {
		navigate({
			pathname: `/shop/${searchType}/${articleType}`,
			state: {
				from: location.pathname,
				fromVehicleType: searchType,
				fromCategoryType: articleType,
				searchType,
				articleType
			}
		})
	}


	/**
	 * Set the active item position.
	 */
	React.useEffect(() => {
		const nonDisabledItems = menuItems.filter(item => !item.disabled)
		const index = nonDisabledItems.findIndex(item => item.articleType === articleType)

		// Makes sure it wont trigger if the index is -1. (Its the default value if the item is not found)
		if (index >= 0) {
			setPosition(itemWidth * index)
		}
	}, [articleType, menuItems, itemWidth])


	// Return null if there are no items
	if (!menuItems) return null

	return (
		<div className="CategoryMenu">
			<div className="background-cover">
				<div className="item-active-cover" style={{ transform: 'translateX(' + position + 'rem)', width: itemWidth + 'rem' }}></div>
				{menuItems.map((item: CategoryMenuItem) => {
					if (!item.disabled) {
						return (
							<MenuItem
								imageSrc={item.imageSrc}
								active={item.articleType === articleType}
								label={item.label}
								width={itemWidth}
								url={`/shop/${searchType}/${item.articleType}`}
								key={item.articleType}
							/>
						)
					}
					return null
				})}
			</div>

			<div className="mobile-menu">
				<SelectPicker
					onChange={selectPickerHandler}
					value={articleType}
					placeholder=''
				>
					{menuItems.map((item: CategoryMenuItem) => 
						<SelectPicker.Option 
							key={item.articleType} 
							name={item.label} 
							value={item.articleType} 
							disabled={item.disabled} 
						/>
					)}
				</SelectPicker>
			</div>
		</div>
	)
}

export default CategoryMenu