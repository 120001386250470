import React from 'react'
import { useSelector } from 'react-redux'
import CarWheelPackageRimSearchFilter from './CarWheelPackageRimSearchFilter/CarWheelPackageRimSearchFilter'
import CarWheelPackageTyreSearchFilter from './CarWheelPackageTyreSearchFilter/CarWheelPackageTyreSearchFilter'
import { 
	selectStep,
	selectRimResult, 
	selectTyreResult
} from '../carWheelPackageSearchSlice'


const CarWheelPackageSearchFilter = () => {
	const currentStep = useSelector(selectStep)
	const rimResult = useSelector(selectRimResult)
	const tyreResult = useSelector(selectTyreResult)

	return (
		<div className="CarWheelPackageSearchFilter">
			{currentStep === 'rim' && rimResult && <CarWheelPackageRimSearchFilter />}
			{currentStep === 'tyre' && tyreResult && <CarWheelPackageTyreSearchFilter />}
		</div>
	)
}

export default CarWheelPackageSearchFilter