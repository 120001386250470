import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

const initialState = {
	show: false,
}
const contactInformationSlice = createSlice({
	name: 'resize',
	initialState: initialState,
	reducers: {
		setShowContactInformationModal: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.show = action.payload
		},

	}
})

export const selectShowContactInformationModal = (state: RootState) => state.contactInformation.show

export const { 
	setShowContactInformationModal, 
} = contactInformationSlice.actions

export default contactInformationSlice.reducer
