import { Filter, FilterContainer } from '@barnsten-it/gummidata-react-components'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getAllArticles } from 'utils/helper'

const OilFilter = ({
	result,
	onFilterChange,
	filterState
}) => {
	const { t } = useTranslation()

	const [
		articles,
		filterSettings,
	] = useMemo(() => {
		if (!result) {
			return [
				null,
				null,
			]
		}

		const allArticles = getAllArticles(result)?.filter(article => !!article)

		const filterSettings = [
			{
				name: t('common.components'),
				property: 'groupName',
				type: Filter.type.radioButtons,
			},
			{
				name: t('searchFilter.interval'),
				property: 'intervalName',
				type: Filter.type.radioButtons,
			},
			{
				name: t('common.usageType'),
				property: 'usageType',
				type: Filter.type.multipleSelect,
			},
			{
				name: t('searchFilter.pcsPerPackage'),
				property: 'piecesPerPackage',
				type: Filter.type.multipleSelect,
			},
			{
				name: t('searchFilter.litres'),
				property: 'recyclingQuantity',
				type: Filter.type.multipleSelect,
			}
		]

		return [
			allArticles,
			filterSettings,
		]
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [result])

	if (!filterSettings || !articles || !result) return null

	return (
		<div className="OilFilter">
			<FilterContainer hasResult={!!result}>
				<Filter
					identifier="id"
					settings={filterSettings}
					items={articles}
					label={t('common.searchFilter')}
					selected={filterState?.selected}
					onFilterChange={onFilterChange}
				/>
			</FilterContainer>
		</div>
	)
}

export default OilFilter
