const tyreType = {
	summer: 1,
	winter: 2,
	classic: 3,
	truck: 4,
	allTyres: 5,
	allSeason: 6,
	industryAgro: 7,
	allInchTyres: 8,
	summerInch: 48,
	winterInch: 49,
	tractor: 66,
	construction: 80,
	folkRace: 90,
	gateSlicks: 91,
	forklift: 95,
	smallTyre: 96,
} as const

export default tyreType

export type TyreType = keyof typeof tyreType
