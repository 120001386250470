import apiClient from './apiClient'
import _ from 'lodash'

/**
 * Get orders history
 * @param {object} payload Form data for application
 * @returns 
 */
export const fetchOrderHistory = async (payload) => {
	const newPayload = _.cloneDeep(payload)
	if (newPayload.status === 'all') {
		newPayload.status = ''
	}
	return await apiClient.get('/api/Orders', newPayload)
}

/**
 * Get order rows
 * @param {number} id order row id
 * @returns 
 */
export const fetchOrderRows = async (id) => await apiClient.get('/api/orderrows/' + id)

/**
 * Get order parcels
 * @param {number} id order row id
 * @returns 
 */
export const fetchOrderParcels = async (id) => await apiClient.get('/api/orderParcels/' + id)

/**
 * Set Order reservation
 * @param {number} id order row id
 * @returns 
 */
export const putOrderReservation = async (id) => await apiClient.put('/api/orderReservation/' + id)

/**
 * Remove Order reservation
 * @param {number} id order row id
 * @returns 
 */
export const deleteOrderReservation = async (id) => await apiClient.delete('/api/OrderReservation/' + id)

/**
 * Restore Order reservation
 * @param {number} id order row id
 * @returns 
 */
export const restoreOrderReservation = async (id) => await apiClient.put('/api/OrderReservationRestore/' + id)

/**
 * Get widget order info
 * @param {number} id - widgetOrderId
 * @returns 
 */
export const fetchWidgetOrderInfo = async id => await apiClient.get(`/api/widgetOrderInfo/${id}`)

/**
 * Set confirm order
 * @param {number} id order row id
 * @returns 
 */
export const putResellerOrderConfirmation = async (id) => await apiClient.put('/api/resellerOrders/' + id)

/**
 * Delete order
 * @param {number} id order row id
 * @returns 
 */
export const deleteResellerOrder = async (id) => await apiClient.delete('/api/resellerOrders/' + id)
