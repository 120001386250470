import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	deleteSubCustomer,
	getSubCustomer, 
	putSubCustomer, 
	getSubCustomers,
	postSubCustomer,
} from 'utils/api/subCustomerAPI'
import sliceStateBases, { SliceState } from 'utils/enums/sliceStateBases'
import { statusHandler, thunkActions } from 'utils/utils'
import { SubCustomer } from 'types/Responses'
import { loggedOut } from 'utils/redux/commonActions'
import ISubCustomersCiteria from 'types/ISubCustomersCriteria'
import { RootState } from 'app/store'

export const fetchSubCustomers = createAsyncThunk('subCustomers/getSubCustomers', async (criteria: ISubCustomersCiteria) => await getSubCustomers(criteria))
export const suspendSubCustomer = createAsyncThunk('subCustomers/deleteSubCustomer', async (id: number) => await deleteSubCustomer(id))
export const fetchSubCustomer = createAsyncThunk('subCustomers/getSubCustomer', async (id: string) => await getSubCustomer(id))
export const setSubCustomer = createAsyncThunk('subCustomers/putSubCustomer', async ([id, payload]: [string | number, SubCustomer]) => await putSubCustomer(String(id), payload))
export const addSubCustomer = createAsyncThunk('subCustomers/postSubCustomer', async (payload) => await postSubCustomer(payload))

const subCustomersActions = thunkActions(
	fetchSubCustomers,
	suspendSubCustomer,
	fetchSubCustomer,
	setSubCustomer,
	addSubCustomer,
)

export const initialCriteria: ISubCustomersCiteria = {
	customerId: '',
	customerName: '',
	city: '',
	companyId: '',
	skip: 0,
	take: 30,
	orderBy: 'id',
	orderDirection: 1,
} as const

export interface SubCustomersState {
	criteria: typeof initialCriteria
	newSubCustomerId: number | null
	subCustomer: SubCustomer | null
	subCustomers: SubCustomer[] | null
	subCustomerAmount: number | null
}

export const initialSubCustomersState: SliceState<'FEATURE', SubCustomersState> = {
	...sliceStateBases.FEATURE,
	criteria: initialCriteria,
	newSubCustomerId: null,
	subCustomer: null,
	subCustomers: null,
	subCustomerAmount: null,
} as const

export const subCustomersSlice = createSlice({
	name: 'subCustomers',
	initialState: initialSubCustomersState,
	reducers: {
		clearNewSubCustomerId(state) {
			state.newSubCustomerId = null
		},
		clearSubCustomer(state) {
			state.subCustomer = null
		},
		setSubCustomersCriteria(
			state,
			action: PayloadAction<ISubCustomersCiteria>,
		) {
			state.criteria = {
				...state.criteria,
				...action.payload,
			}
		},
		clearSubCustomersCriteria(state) {
			state.criteria = initialCriteria
		},
		increaseSubCustomersSkip(
			state,
			action: PayloadAction<number>,
		) {
			if (state.subCustomerAmount === null) return

			let newValue = state.criteria.skip + action.payload
			if (newValue < state.subCustomerAmount) state.criteria.skip = newValue
		},
		decreaseSubCustomersSkip(
			state,
			action: PayloadAction<number>,
		) {
			let newValue = state.criteria.skip - action.payload
			if (newValue >= 0) state.criteria.skip = newValue
		},
		orderSubCustomersBy(
			state,
			action: PayloadAction<string>,
		) {
			const columnName = action.payload
			if (state.criteria.orderBy === columnName) {
				state.criteria.orderDirection = (state.criteria.orderDirection === 1) ? 0 : 1
			} else {
				state.criteria.orderBy = columnName
				state.criteria.orderDirection = initialCriteria.orderDirection
			}
		},
	},
	extraReducers: builder => builder
		.addCase(loggedOut, state => {
			Object.assign(state, initialSubCustomersState)
		})
		.addCase(addSubCustomer.pending, state => {
			state.newSubCustomerId = null
		})
		.addCase(addSubCustomer.fulfilled, (state, action) => {
			state.newSubCustomerId = action.payload
		})
		.addCase(fetchSubCustomer.pending, state => {
			state.subCustomer = null
		})
		.addCase(fetchSubCustomer.fulfilled, (state, action) => {
			state.subCustomer = action.payload
		})
		.addCase(fetchSubCustomers.pending, state => {
			state.subCustomers = null
			state.subCustomerAmount = null
		})
		.addCase(fetchSubCustomers.fulfilled, (state, action) => {
			state.subCustomers = action.payload.customers
			state.subCustomerAmount = action.payload.count
		})
		.addMatcher(subCustomersActions, statusHandler)
})

export const selectSubCustomers = (state: RootState) => state.reseller.subCustomers
export const selectNewSubCustomerId = (state: RootState) => state.reseller.subCustomers.newSubCustomerId
export const selectSubCustomerData = (state: RootState) => state.reseller.subCustomers.subCustomer
export const selectSubCustomersData = (state: RootState) => state.reseller.subCustomers.subCustomers
export const selectIsSubCustomersLoading = (state: RootState) => state.reseller.subCustomers.isLoading

export const {
	clearNewSubCustomerId,
	clearSubCustomer,
	setSubCustomersCriteria,
	clearSubCustomersCriteria,
	increaseSubCustomersSkip,
	decreaseSubCustomersSkip,
	orderSubCustomersBy,
} = subCustomersSlice.actions

export default subCustomersSlice.reducer
