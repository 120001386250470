import { Notification } from '@barnsten-it/gummidata-react-components'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../app/useAppDispatch'
import {
	addNotification,
	removeNotification as _removeNotification,
} from '../../features/notifications/notificationSlice'
import { NotificationId, notifications } from '../enums/notifications'

export function useNotifications() {
	const dispatch = useAppDispatch()
	const { t } = useTranslation()
	
	const dispatchNotification = useCallback((
		messageId: NotificationId,
		options?: Partial<Notification>,
	) => {
		const notification = notifications[messageId](t)
		dispatch(addNotification({
			...notification,
			...options,
		}))
	}, [t, dispatch])

	const removeNotification = useCallback((
		messageId: NotificationId,
	) => {
		dispatch(_removeNotification(messageId))
	}, [dispatch])

	return {
		dispatchNotification,
		removeNotification,
	}
}
