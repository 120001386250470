import { throttle } from 'lodash'
import { idleCheck } from './commonActions'

const middleWare = ({dispatch}) => {
	let hasEventReg = false
	let isIdle = true
	let timer = null

	const dispatchHandler = () => {
		clearTimeout(timer)

		if (isIdle) {
			isIdle = false
			dispatch(idleCheck(false))
		}
		
		timer = setTimeout(() => {
			isIdle = true
			dispatch(idleCheck(true))
		}, 300000)
	}
	
	const throttleHandler = throttle(dispatchHandler, 1000)

	if(!hasEventReg) {
		hasEventReg = true

		const idleHandler = () => {
			throttleHandler()
		}

		window.addEventListener('scroll', idleHandler)
		window.addEventListener('click', idleHandler)
		window.addEventListener('mousemove', idleHandler)
		window.addEventListener('keydown', idleHandler)
		window.addEventListener('touchmove', idleHandler)
		window.addEventListener('touchstart', idleHandler)
		window.addEventListener('visibilitychange', idleHandler)
	}
	
	return next => action => {
		return next(action)
	}
}

export default middleWare
