import { useEffect, useRef } from 'react'

type CallbackFunction = (...args: any[]) => void

/**
 * Class representing a debounced callback.
 * It provides a mechanism to delay the execution of a function and includes an option to execute an immediate callback as well.
 *
 * @example
 * // Instantiate the DebouncedCallback class.
 * const debouncer = new DebouncedCallback(() => console.log('debounced'), 500);
 *
 * // Assign an immediate callback.
 * debouncer.now(() => console.log('immediate'));
 *
 * // Assign a new debounced callback.
 * debouncer.debounced(() => console.log('new debounced'));
 *
 * // Use the callback.
 * debouncer.callback();
 *
 * // Cancel the callback.
 * debouncer.cancel();
 */
class DebouncedCallback {
	private timeoutId?: number
	private immediateCallback?: CallbackFunction
	private debouncedCallback: CallbackFunction
	private delay: number

	constructor(callback: CallbackFunction, delay: number) {
		this.debouncedCallback = callback
		this.delay = delay
	}

	now(callback: CallbackFunction): this {
		this.immediateCallback = callback
		return this
	}

	debounced(callback: CallbackFunction): this {
		this.debouncedCallback = callback
		return this
	}

	private executeCallbacks(...args: any[]): void {
		if (this.immediateCallback) {
			this.immediateCallback.apply(null, args)
		}
		this.debouncedCallback.apply(null, args)
	}

	cancel(): void {
		clearTimeout(this.timeoutId)
		this.timeoutId = undefined
	}

	callback: CallbackFunction = (...args: any[]) => {
		// Cancel any existing timeout.
		this.cancel()

		// If there's an immediate callback, execute it now.
		if (this.immediateCallback) {
			this.immediateCallback.apply(null, args)
			this.immediateCallback = undefined
		}

		// Schedule the debounced callback for execution after delay.
		this.timeoutId = window.setTimeout(() => {
			this.debouncedCallback.apply(null, args)
		}, this.delay)
	}
}

/**
 * Creates a debouncer that can handle immediate and delayed execution of a callback.
 *
 * @param {number} delay - The delay in milliseconds for the debounced callback.
 * @returns {DebouncedCallback} - An instance of DebouncedCallback.
 *
 * @example
 * // Using method chaining.
 * const onChangeHandler = useDebounce(752)
 *   .now((event) => {
 *     console.log('imidiet', event.target.value);
 *     onSearch(event.target.value);
 *   })
 *   .debounced((event) => {
 *     console.log('debounced', event.target.value);
 *     onSearchHandler(event.target.value);
 *   });
 * <input onChange={onChangeHandler.callback} />
 *
 * @example
 * // Creating separate debouncers for immediate and delayed execution.
 * const immediateCallback = (event) => {
 *   console.log('imidiet', event.target.value);
 *   onSearch(event.target.value);
 * };
 * const debouncedCallback = (event) => {
 *   console.log('debounced', event.target.value);
 *   onSearchHandler(event.target.value);
 * };
 * const immediateDebouncer = useDebounce(0).now(immediateCallback);
 * const debouncedDebouncer = useDebounce(752).debounced(debouncedCallback);
 * const onChangeHandler = (event) => {
 *   immediateDebouncer.callback(event);
 *   debouncedDebouncer.callback(event);
 * };
 * <input onChange={onChangeHandler} />
 */
const useDebounce = (delay: number): DebouncedCallback => {
	const debouncedCallbackRef = useRef<DebouncedCallback>(
		new DebouncedCallback(() => {}, delay)
	)

	useEffect(() => {
		const currentCallback = debouncedCallbackRef.current

		return () => {
			currentCallback?.cancel()
		}
	}, [delay])

	return debouncedCallbackRef.current
}

export default useDebounce
