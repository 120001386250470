import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
	getCart,
	postCart,
	putCartData,
	patchCartRow,
	deleteRowItem,
	postWheelPackage,
	postCartRows,
	getOrderInfo,
	deleteCart,
} from 'utils/api/cartApi'
import { statusHandler, thunkActions } from 'utils/utils'
import { loggedOut } from 'utils/redux/commonActions'
import { postQuotation } from 'utils/api/quotationAPI'
import { postCartMatchArticle } from 'utils/api/matchRimAndTyresAPI'

export const fetchCart = createAsyncThunk('cart/fetchCart', async (languageCode) => await getCart(languageCode))

export const fetchMiniCart = createAsyncThunk('cart/fetchMiniCart', async (languageCode) => await getCart(languageCode))

export const updateRow = createAsyncThunk('cart/updateRow', async (payload, { getState }) => {
	var patchData = await patchCartRow(payload.rowId, payload.criteria, payload.languageCode, payload.lastChanged)
	const data = getState().cart.checkout.data
	const rows = data.rows.map(r => (patchData.rows && patchData.rows.find(p => p.id === r.id)) || r)

	if (patchData.removedId) {
		const index = rows.findIndex(r => r.id === patchData.removedId)
		rows.splice(index, 1)
	}

	return {
		cart: {
			lastChanged: patchData.lastChanged,
			errors: patchData.errors,
			requirePrepayment: patchData.requirePrepayment,
			enableWarehousePickup: patchData.enableWarehousePickup,
			amountExcludingVat: patchData.amountExcludingVat,
			amountIncludingVat: patchData.amountIncludingVat,
			amountRetailPrice: patchData.amountRetailPrice,
			shippingCost: patchData.shippingCost,
			vatAmount: patchData.vatAmount,
			disabled: patchData.disabled
		},
		rows: rows,
	}
})

export const createOrder = createAsyncThunk('cart/createOrder', async (payload, { getState }) => {
	const result = await postCart(payload)
	const data = getState().cart.checkout.data
	const rows = data.rows.map(row => ({
		...row,
		errors: (result.rowErrors && result.rowErrors.filter(p => p.rowId === row.id)) || null,
	}))

	return {
		result,
		rows,
	}
})

export const deleteRow = createAsyncThunk('cart/deleteRowItem', async (payload) => await deleteRowItem(payload))
export const deleteCartItem = createAsyncThunk('cart/deleteCartItem', async ({
	articleId,
	languageCode,
}, {
	getState,
}) => {
	const { lastChanged } = getState().cart.checkout.data ?? {}

	if (typeof articleId != 'number') return
	if (typeof languageCode != 'string') return
	
	await deleteRowItem(
		articleId,
		languageCode,
		lastChanged,
	)
})

export const saveQuotation = createAsyncThunk('cart/saveQuotation', async (payload) => await postQuotation(payload))
export const saveCart = createAsyncThunk('cart/saveCartData', async (payload) => await putCartData(payload))
export const clearCart = createAsyncThunk('cart/deleteCart', async () => await deleteCart())
export const addToCart = createAsyncThunk('cart/addToCart', async (payload) => await postCartRows(payload))
export const addMatchArticles = createAsyncThunk('cart/addMatchArticles', async (payload) => await postCartMatchArticle(payload))
export const addWheelPackage = createAsyncThunk('cart/addWheelPackage', async (payload) => await postWheelPackage(payload.packageInfo, payload.rows, payload.languageCode))
export const fetchOrderInfo = createAsyncThunk('cart/getOrderInfo', async (payload) => await getOrderInfo(payload))

export const cartActions = thunkActions(
	fetchCart,
	fetchMiniCart,
	saveCart,
	createOrder,
	deleteRow,
	addWheelPackage,
	addToCart,
	updateRow,
	fetchOrderInfo,
	deleteCartItem,
)

const cartSlice = createSlice({
	name: 'cart',
	initialState: {
		status: 'idle',
		data: null,
		orderResult: null
	},
	reducers: {
		changeCart: (state, action) => {
			state.data[action.payload.name] = action.payload.value
		},
		changeRow: (state, action) => {
			const updatedRows = state.data.rows.map((row) => {
				if (row.id === action.payload.rowId) {
					// Return a new object with the updated property
					return { ...row, [action.payload.name]: action.payload.value }
				}
				return row
			})
			// Return a new state object with the updated rows array
			return { ...state, data: { ...state.data, rows: updatedRows } }
		},
		resetOrderResult: (state) => {
			state.orderResult = null
		}
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.data = null
			})
			.addCase(fetchCart.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(fetchMiniCart.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(deleteRow.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(saveCart.fulfilled, (state, action) => {
				state.data = {
					...state.data,
					...action.payload
				}
				state.orderResult = null
			})
			.addCase(updateRow.fulfilled, (state, action) => {
				state.data = {
					...state.data,
					...action.payload.cart,
					rows: action.payload.rows
				}
			})
			.addCase(createOrder.fulfilled, (state, action) => {
				state.orderResult = action.payload.result
				state.data.rows = action.payload.rows
			})
			.addCase(fetchOrderInfo.fulfilled, (state, action) => {
				state.orderResult = action.payload
			})
			.addMatcher(cartActions, statusHandler)
	}
})

export const selectCart = state => state.cart.checkout.data
export const selectStatus = state => state.cart.checkout.status
export const selectError = state => state.cart.checkout.error
export const selectOrderResult = state => state.cart.checkout.orderResult
export const selectAllowSetDeliveryDate = state => state.cart.checkout.data.allowSetDeliveryDate

export const {
	changeCart,
	changeRow,
	resetOrderResult,
} = cartSlice.actions

export default cartSlice.reducer
