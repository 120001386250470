import { SliceStatus } from './sliceStatus'

const sliceStateBases: {
	FEATURE: {
		status: SliceStatus
		data: any | null
		isLoading: boolean
		hasSucceeded: boolean
		hasFailed: boolean
	}
} = {
	FEATURE: {
		status: 'idle',
		data: null,
		isLoading: false,
		hasSucceeded: false,
		hasFailed: false,
	},
}

export default sliceStateBases

export type SliceState<
	P extends keyof typeof sliceStateBases,
	T,
> = typeof sliceStateBases[P] & T
