import settingsTypes from 'utils/enums/settingsTypes'
import { useSettings } from './useSettings'
import { useSelector } from 'react-redux'
import { selectVatPercent } from 'features/user/userInfo/userInfoSlice'
import { usePriceFormatter } from './usePriceFormatter'
import { useCallback, useMemo } from 'react'
import { selectAlwaysDisplayRetailPrices } from 'features/user/userInfo/userInfoSlice'
import { useUserRoles } from './useUserRoles'

export interface priceSettings {
	retailPrice?: number
	netPrice?: number
	recommendedPrice?: number
}

const usePrice = () => {
	const { getSetting } = useSettings()
	const priceFormatter = usePriceFormatter()
	const {
		hasCustomerAdminRole,
		hasNetPricesRole,
	} = useUserRoles()
	const {
		retailPrice: retailPriceSetting,
	} = useSettings()

	/**
	 * Selectors
	 */

	const vatPercent = useSelector(selectVatPercent)
	const alwaysDisplayRetailPrice = useSelector(selectAlwaysDisplayRetailPrices)

	const showRetailPrice = retailPriceSetting || (!hasCustomerAdminRole && !hasNetPricesRole) || alwaysDisplayRetailPrice

	/**
	 * calculate price excluding vat
	 */

	const calcExclVat = useMemo(() => (price: number) => {
		return vatPercent ? price / (vatPercent + 1) : price
	}, [vatPercent])


	/**
	 * calculate price inclusive vat
	 */

	const calcIncVat = useMemo(() => (price: number) => {
		return vatPercent ? price * (vatPercent + 1) : price
	}, [vatPercent])


	/**
	 * Override article properties with settings properties
	 */

	const overRideSettings = useCallback((article: any, settings: any) => {
		if (article) {
			let obj: any = {}
			// Use article properties as default
			Object.keys(article).forEach(key => {  
				if (settings.hasOwnProperty(key)) {
					obj[key] = settings[key]
				} else {
					obj[key] = article[key]
				}     
			})
		
			return obj
		} else {
			
			// Else just use settings (not recommended)
			// Remember to use netPrice, recommendedPrice and retailPrice as properties
			return settings
		}
	}, [])

	/**
	 * The Retail price of the article depends on the settings activated by the user
	 *
	 * @param article
	 */

	interface retailPriceSettings {
		retailPrice?: number
	}

	const getRetailPrice = useMemo(() => (
		article: any,
		showSymbol: boolean = false,
		settings: retailPriceSettings
	) => {
		const { retailPrice } = overRideSettings(article, settings)

		if (getSetting(settingsTypes.retailExclVat)) {
			return priceFormatter(calcExclVat(retailPrice as number), {
				symbol: showSymbol,
			})
		} else {
			return priceFormatter(retailPrice, { symbol: showSymbol })
		}
	}, [
		getSetting, 
		priceFormatter, 
		calcExclVat, 
		overRideSettings
	])


	/**
	 * The price of the article depends on the settings activated by the user
	 *
	 * @param article
	 * @param showSymbol
	 * @returns Price in string or number format
	 */

	const getPrice = useMemo(() => (
		article: any,
		showSymbol: boolean = false,
		settings: priceSettings = {}
	): string | number => {
		const { 
			netPrice, 
			retailPrice, 
			recommendedPrice 
		} = overRideSettings(article, settings)

		if (showRetailPrice) {
			return getRetailPrice(article, showSymbol, { retailPrice })
		} else {
			if (getSetting(settingsTypes.hideNetPrice)) {
				if (getSetting(settingsTypes.displayPriceIncludingVat)) {
					return priceFormatter(calcIncVat(Number(recommendedPrice)), { symbol: showSymbol })
				} else {
					return priceFormatter(recommendedPrice, { symbol: showSymbol })
				}
			} else {
				if (getSetting(settingsTypes.displayPriceIncludingVat)) {
					return priceFormatter(calcIncVat(Number(netPrice)), { symbol: showSymbol })
				} else {
					return priceFormatter(netPrice, { symbol: showSymbol })
				}
			}
		}
	}, [
		overRideSettings,
		showRetailPrice,
		getRetailPrice,
		getSetting,
		priceFormatter,
		calcIncVat
	])


	/**
	 * The recycling fee of the article depends on the settings activated by the user
	 *
	 * @param article
	 * @param showSymbol
	 * @returns Price in string or number format
	 */

	const getRecyclingFee = (article: any, showSymbol: boolean = false) => {
		if (getSetting(settingsTypes.displayPriceIncludingVat)) {
			return priceFormatter(calcIncVat(Number(article.recyclingFee)), { symbol: showSymbol })
		} else {
			return priceFormatter(article.recyclingFee, { symbol: showSymbol })
		}
	}


	/**
	 * exports
	 */

	return {
		getPrice,
		getRetailPrice,
		getRecyclingFee,
		calcExclVat,
		calcIncVat,
		showRetailPrice
	}
}

export default usePrice
