import apiClient from './apiClient'

/**
 * Get quotation history
 * @param {object} payload Form data for application
 * @returns 
 */
export const getQuotationHistory = async (payload) => await apiClient.get('/api/Offers', payload)


/**
 * Get quotation history
 * @param {object} payload Form data for application
 * @returns 
 */
export const getQuotationRows = async (payload) => await apiClient.get('/api/OfferRows/' + payload.id + '?languageCode=' + payload.languageCode)


/**
 * Create Quotation
 * @param {object} payload Form data for application
 * @returns 
 */
export const postQuotation = async (payload) => await apiClient.post('/api/Offer', payload)


/**
 * Delete Quotation
 * @param {int} id 
 * @returns 
 */
export const deleteQuotation = async (id) => await apiClient.delete('/api/Offer/' + id)


/**
 * Adds offert to cart
 * @param {object} payload Form data for application
 * @returns 
 */
export const postQuotationsToCart = async (id) => await apiClient.post('/api/Offer/' + id)