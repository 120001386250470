import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { postArticleExport } from 'utils/api/articleExportAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const sendArticleExport = createAsyncThunk('articleExport/sendArticleExport', async (payload) => postArticleExport(payload))
export const articleExportAction = thunkActions(sendArticleExport)

const articleExportSlice = createSlice({
	name: 'articleExport',
	initialState: {
		status: 'idle'
	},
	extraReducers: (builder) => {
	 	builder
	 		.addCase(sendArticleExport.fulfilled, (state, action) => {})
	 		.addCase(sendArticleExport.rejected, (state) => {})
	 		.addMatcher(articleExportAction, statusHandler)
	 }
})

export const selectStatus = (state) => state.articleExport.status

export default articleExportSlice.reducer
