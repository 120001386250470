import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { statusHandler, thunkActions } from 'utils/utils'
import {
	getResellerSettings,
	postResellerSettings,
} from 'utils/api/resellerSettingsAPI'
import sliceStateBases, { SliceState } from 'utils/enums/sliceBaseStates'
import IResellerSettings from 'types/IResellerSettings'
import { RootState } from 'app/store'

export const fetchResellerSettings = createAsyncThunk('settings/getResellerSettings', getResellerSettings)
export const setResellerSettings = createAsyncThunk('settings/postResellerSettings', postResellerSettings)

export const actions = thunkActions(
	fetchResellerSettings,
	setResellerSettings,
)

export interface SettingsState {
	resellerSettings: IResellerSettings | null
}

export const initialSettingsState: SliceState<'FEATURE', SettingsState> = {
	...sliceStateBases.FEATURE,
	resellerSettings: null,
}

const resellerSettingsSlice = createSlice({
	name: 'resellerSettings',
	initialState: initialSettingsState,
	reducers: {},
	extraReducers: builder => builder
		.addCase(fetchResellerSettings.fulfilled, (state, action) => {
			state.resellerSettings = action.payload
		})
		.addCase(setResellerSettings.rejected, (state) => {
			state.resellerSettings = initialSettingsState.resellerSettings
		})
		.addMatcher(actions, statusHandler),
})

export const selectResellerSettings = (state: RootState) => state.app.settings.resellerSettings

export default resellerSettingsSlice.reducer
