import { combineReducers } from 'redux'
import brands from './brands/brandsSlice'
import search from './search/searchSlice'
import vehicleData from './vehicleData/vehicleDataSlice'
import tpms from './tpms/tpmsSlice'
import motoBrands from './brands/motoBrandsSlice'

export default combineReducers({
	brands,
	search,
	vehicleData,
	tpms,
	motoBrands
})