import { combineReducers } from 'redux'

import newTicket from './newTicket/newTicketSlice'
import tickets from './tickets/ticketsSlice'
import ticket from './ticket/ticketSlice'

export default combineReducers({
	newTicket,
	ticket,
	tickets
})
