import React, { useEffect, useState } from 'react'
import {
	TextInput,
	Form,
	PasswordScoreInput,
} from '@barnsten-it/gummidata-react-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { changePassword, selectShowPasswordChangeForm } from '../passwordSlice'
import { useDispatch } from 'react-redux'
import Button from '@barnsten-it/gummidata-react-components/dist/Inputs/Button/Button'
import Modal from '@barnsten-it/gummidata-react-components/dist/Common/Modal/Modal'
import LoadingOverlay from '@barnsten-it/gummidata-react-components/dist/Loading/LoadingOverlay/LoadingOverlay'
import Notifications from '@barnsten-it/gummidata-react-components/dist/Messages/Notifications/Notifications'
import { validateObject } from '@barnsten-it/object-validator'
import validationRules from './validationRules'
import { getUserInfo } from 'features/user/userInfo/userInfoSlice'
import { loggedOut } from 'utils/redux/commonActions'
import { useNotifications } from 'utils/hooks/useNotifications'
import './PasswordChangeForm.scss'

const PasswordChangeForm = ({
	force,
	label,
	description,
	doesCloseOutside = false,
	hasCloseModalButton = false,
	onDone,
	onClose,
	isOpen = false,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { dispatchNotification } = useNotifications()

	const [loading, setLoading] = useState()

	const cleanErrorState = {
		main: null,
		oldPassword: null,
		matchPassword: null,
	}

	const cleanCriteria = {
		currentPassword: null,
		newPassword: null,
		confirmPassword: null,
	}

	const [error, setError] = useState(cleanErrorState)
	const [criteria, setCriteria] = useState(cleanCriteria)

	const showPasswordChangeForm = useSelector(selectShowPasswordChangeForm)

	const submitPassword = async () => {
		// Check if error free
		setLoading(true)

		const result = await dispatch(changePassword(criteria))
		
		setLoading(false)

		if (result.payload?.done) {
			dispatchNotification('newPasswordSaved')
			setCriteria(cleanCriteria)
			setError(cleanErrorState)
			dispatch(getUserInfo())
			setLoading(false)

			if (typeof onDone === 'function') {
				onDone()
			}
		}

		if (result.error?.message === '403') {
			setError({
				...error,
				main: t('userPage.passwordInvalid'),
			})
		}

		if (result.error?.message === '406') {
			setError({
				...error,
				main: t('webUser.passwordNotAvailable'),
			})
		}
	}

	const onChangeHandler = (e, passwordScore) => {
		setCriteria({
			...criteria,
			passwordScore: passwordScore,
			[e.target.name]: e.target.value,
		})
	}

	const logoutHandler = () => {
		dispatch(loggedOut())
	}

	useEffect(() => {
		const result = validateObject(validationRules(t), criteria)
		setError(result.properties)
	}, [criteria, t])

	return showPasswordChangeForm || force || isOpen ? (
		<Modal
			onClose={onClose}
			label={label}
			description={description}
			closeOutside={doesCloseOutside}
			closeModalButton={hasCloseModalButton}
		>
			<div className="PasswordChangeForm">
				{loading && <LoadingOverlay />}

				<Notifications
					active={error.main}
					type="error"
					wideSize={true}
					absolute={false}
					message={error.main}
				>
					<Form>
						{!force && (
							<TextInput
								name="currentPassword"
								value={criteria.currentPassword}
								label={t('userPage.currentPassword')}
								type="password"
								onChange={onChangeHandler}
								error={error.oldPassword}
								autoComplete={false}
								labelOnTop={true}
							/>
						)}

						<PasswordScoreInput
							name="newPassword"
							value={criteria.newPassword}
							label={t('userPage.newPassword')}
							onChange={onChangeHandler}
							labelOnTop={true}
							error={error.newPassword}
						/>
						<TextInput
							name="confirmPassword"
							value={criteria.confirmPassword}
							label={t('userPage.confirmNewPassword')}
							type="password"
							labelOnTop={true}
							onChange={onChangeHandler}
							autoComplete={false}
							error={error.matchPassword}
						/>

						<Button
							type="button"
							onClick={submitPassword}
							label={t('userPage.changePassword')}
							disabled={
								!Boolean(
									criteria.confirmPassword &&
										criteria.newPassword &&
										criteria.newPassword === criteria.confirmPassword
								) || !(Boolean(criteria.currentPassword) || force)
							}
						/>
					</Form>

					{force && (
						<div className="or-logout" onClick={logoutHandler}>
							{t('common.orLogout')}
						</div>
					)}
				</Notifications>
			</div>
		</Modal>
	) : (
		<div />
	)
}

export default PasswordChangeForm
