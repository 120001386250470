import { Filter, FilterContainer } from '@barnsten-it/gummidata-react-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAllArticles } from 'utils/helper'

const BatteryFilter = ({ 
	result, 
	onFilterChange, 
	filterState
}) => {
	const [filterSettings, setFilterSettings] = useState(null)
	const [articles, setArticles] = useState(null)
	const { t } = useTranslation()

	const createFilter = useCallback(() => {
		if (!result)
			return

		const allArticles = getAllArticles(result)

		const settings = [
			{
				name: t('searchFilter.brand'),
				property: 'brandName',
				type: Filter.type.multipleSelect,
				enableLimit: true,
				showMoreText: t('common.showMore')
			},
			{
				name: t('articleInfo.ampereHours'),
				property: 'ampereHours',
				type: Filter.type.range
			},
			{
				name: t('articleInfo.coldCrankingAmps'),
				property: 'coldCrankingAmps',
				type: Filter.type.range
			},
			{
				name: t('common.length'),
				property: 'length',
				type: Filter.type.range
			},
			{
				name: t('common.width'),
				property: 'width',
				type: Filter.type.range
			},
			{
				name: t('common.height'),
				property: 'height',
				type: Filter.type.range
			},
			{
				name: t('common.brand'),
				property: 'subGroupName',
				type: Filter.type.multipleSelect,
			},
			{
				name: t('common.type'),
				property: 'batteryTypeName',
				type: Filter.type.multipleSelect
			},
			{
				name: t('articleInfo.batteryPolarity'),
				property: 'polarity',
				type: Filter.type.multipleSelect
			},
			{
				name: t('articleInfo.batteryBoxType'),
				property: 'batteryBoxType',
				type: Filter.type.multipleSelect,
				enableLimit: true,
				sortEnabled: true,
				showMoreText: t('common.showMore')
			},
			{
				name: t('articleInfo.batteryBottomFasteningType'),
				property: 'bottomFasteningType',
				type: Filter.type.multipleSelect,
				enableLimit: true,
				sortEnabled: true,
				showMoreText: t('common.showMore')
			}
		]

		setArticles(allArticles)
		setFilterSettings(settings)
	}, [t, result])

	useEffect(() => {
		if (result)
			createFilter()
	}, [result, createFilter])


	if (!filterSettings || !articles || !result)
		return null

	return (
		<div className="BatteryFilter">
			<FilterContainer hasResult={!!result}>
				<Filter
					identifier="id"
					settings={filterSettings}
					label={t('common.searchFilter')}
					items={articles}
					selected={filterState?.selected}
					onFilterChange={onFilterChange} />
			</FilterContainer>
		</div>
	)
}

export default BatteryFilter