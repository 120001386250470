import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchUser, postLogo, postUser } from 'utils/api/userAPI'
import { loggedOut } from 'utils/redux/commonActions'
import { statusHandler, thunkActions } from 'utils/utils'
import { RootState } from '../../../app/store'
import { UserInfo } from '../../../types/Responses'
import { SliceStatus } from '../../../utils/enums/sliceStatus'
import { CustomerModel, UserEditModel } from 'types/api'

export const getUserInfo = createAsyncThunk<UserInfo>('userInfo/fetchUser', async () => await fetchUser())
export const saveUserInfo = createAsyncThunk<unknown, UserEditModel>('userInfo/saveUser', async (payload) => await postUser(payload))
export const uploadLogo = createAsyncThunk<unknown, unknown>('userInfo/uploadLogo', async (payload) => await postLogo(payload))

export const cartActions = thunkActions(getUserInfo, saveUserInfo)

export interface UserInfoState {
	status: SliceStatus
	data: CustomerModel | null
	invalidPassword: boolean
	saved: boolean
}

export const initialUserInfoState: UserInfoState = {
	status: 'idle',
	data: null,
	invalidPassword: false,
	saved: false,
}

const userInfoSlice = createSlice({
	name: 'userInfo',
	initialState: initialUserInfoState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(loggedOut, state => {
				state.data = null
				state.status = 'idle'
				state.invalidPassword = false
				state.saved = false
			})
			.addCase(getUserInfo.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(getUserInfo.rejected, (state) => {
				state.data = null
			})
			.addCase(saveUserInfo.rejected, (state, action) => {
				state.invalidPassword = (action.error.message === '403')
			})
			.addMatcher(cartActions, statusHandler)
	}
})

export const selectStatus = (state: RootState) => state.user.userInfo.status
export const selectUserInfo = (state: RootState) => state.user.userInfo.data
export const selectUserId = (state: RootState) => state.user.userInfo.data?.id
export const selectEmailAddress = (state: RootState) => state.user.userInfo.data?.emailAddress
export const selectInvalidPassword = (state: RootState) => state.user.userInfo.invalidPassword
export const selectWarehousePickup = (state: RootState) => state.user.userInfo.data?.warehousePickup
export const selectWarehouseId = (state: RootState) => state.user.userInfo.data?.warehouseId
export const selectCustomerLanguageCode = (state: RootState) => state.user.userInfo.data?.customerLanguageCode
export const selectVatPercent = (state: RootState) => state.user.userInfo.data?.vatPercent
export const selectCurrency = (state: RootState) => state.user.userInfo.data?.currency
export const selectDisplayFullStockQuantity = (state: RootState) => state.user.userInfo.data?.displayFullStockQuantity
export const selectAlwaysDisplayRetailPrices = (state: RootState) => state.user.userInfo.data?.alwaysDisplayRetailPrices
export const selectHiddenCreditInfo = (state: RootState) => state.user.userInfo.data?.hiddenCreditInfo
export const selectHasDueInvoices = (state: RootState) => state.user.userInfo.data?.hasDueInvoices
export const selectNewInvoiceCount = (state: RootState) => state.user.userInfo.data?.newInvoiceCount
export const selectRoles = (state: RootState) => state.user.userInfo.data?.roles
export const selectHasCashNotes = (state: RootState) => state.user.userInfo.data?.hasCashNotes
export const selectHasWheelPackages = (state: RootState) => state.user.userInfo.data?.hasArticlePackages
export const selectForcePasswordChange = (state: RootState) => state.user.userInfo.data?.forcePasswordChange
export const selectFUPBrowserId = (state: RootState) => state.user.userInfo.data?.fupBrowserId

export default userInfoSlice.reducer
