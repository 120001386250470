enum TicketTypes {
	Warranty = 0, // Garantier
	MisShipment = 1, // Fel Skickat
	Return = 2, // Retur
	Claim = 3, // Feed-back
	Preseason = 4, // Preseason
	Realization = 5, // Realisering
}

export default TicketTypes
