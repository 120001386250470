import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from '../../../app/store'
import ViewTypes from 'utils/enums/viewTypes'
import { idleCheck } from 'utils/redux/commonActions'
import { loggedOut } from 'utils/redux/commonActions'
import widthVariables from 'utils/enums/widthVariables'

export interface MenuSettings {
	menuLocation: null
}

export interface DocumentInfoState {
	width: number
	height: number
	scrollY: number
	isIdle: boolean
	showMenu: boolean
	highResolution: boolean
	overlay: boolean
	menuSettings: MenuSettings
	viewType: ViewTypes,
	showQuickSearch: boolean,
	showAllProductsSearch: boolean,
	isMobile: boolean
}

export const initialDocumentInfoState: DocumentInfoState = {
	width: 0,
	height: 0,
	scrollY: 0,
	isIdle: true,
	showMenu: false,
	highResolution: false,
	overlay: false,
	showAllProductsSearch: false,
	menuSettings: {
		menuLocation: null
	},
	viewType: ViewTypes.list,
	showQuickSearch: false,
	isMobile: false,
} as const

const documentInfoSlice = createSlice({
	name: 'resize',
	initialState: initialDocumentInfoState,
	reducers: {
		setShowMenu: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.showMenu = action.payload
		},
		setOverlay: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.overlay = action.payload
		},
		setMenuSettings: (
			state,
			action: PayloadAction<MenuSettings>,
		) => {
			state.menuSettings = action.payload
		},
		setDocumentInfo: (
			state,
			action: PayloadAction<{ width: number, height: number }>,
		) => {
			const {
				width,
				height
			} = action.payload

			state.width = width
			state.height = height
			state.isMobile = width < widthVariables['md-max']
		},
		setHighResolution: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.highResolution = action.payload
		},
		setViewType: (
			state,
			action: PayloadAction<ViewTypes>,
		) => {
			state.viewType = action.payload
		},
		setShowQuickSearch: (
			state, 
			action: PayloadAction<boolean>,
		) => {
			state.showQuickSearch = action.payload
			state.overlay = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loggedOut, state => {
				state.isIdle = true
			})
			.addCase(idleCheck, (state, action) => {
				state.isIdle = action.payload
			})
	}
})

export const selectViewType = (state: RootState) => state.app.documentInfo.viewType
export const selectDocumentInfo = (state: RootState) => state.app.documentInfo
export const selectScreenWidth = (state: RootState) => state.app.documentInfo.width
export const selectScreenHeight = (state: RootState) => state.app.documentInfo.height
export const selectShowMenu = (state: RootState) => state.app.documentInfo.showMenu
export const selectHighResolution = (state: RootState) => state.app.documentInfo.highResolution
export const selectMenuSettings = (state: RootState) => state.app.documentInfo.menuSettings
export const selectIdleCheck = (state: RootState) => state.app.documentInfo.isIdle
export const selectOverlay = (state: RootState) => state.app.documentInfo.overlay
export const selectIsMobile = (state: RootState) => state.app.documentInfo.isMobile
export const selectShowQuickSearch = (state: RootState) => state.app.documentInfo.showQuickSearch

export const { 
	setDocumentInfo, 
	setShowMenu, 
	setMenuSettings,  
	setHighResolution,
	setOverlay,
	setViewType,
	setShowQuickSearch
} = documentInfoSlice.actions

export default documentInfoSlice.reducer
