import apiClient from './apiClient'

/**
 * @description Get list of SubCustomers
 * @param {*} criteria 
 * @returns 
 */
export const getSubCustomers = async (criteria) => apiClient.get('/api/SubCustomers', criteria)

/**
 * @description Get SubCustomer
 * @param {*} id 
 * @returns 
 */
export const getSubCustomer = async (id) => await apiClient.get(`/api/SubCustomer/${id}`)

/**
 * @description Post SubCustomer
 * @param {*} payload 
 * @returns 
*/
export const postSubCustomer = async (payload) => await apiClient.post('/api/SubCustomer', payload)

/**
 * @description Put SubCustomers
 * @returns
*/
export const putSubCustomer = async (id, payload) => await apiClient.put(`/api/SubCustomer/${id}`, payload)

/**
 * @description Delete SubCustomer
 * @param {*} id 
 * @returns 
 */
export const deleteSubCustomer = async (id) => await apiClient.delete(`/api/SubCustomer/${id}`)