enum LanguageName {
	en = 'English',
	sv = 'Svenska',
	fi = 'Suomi',
	lv = 'Latviski',
	lt = 'Lietuvių',
	et = 'Eesti',
	ru = 'Русский',
	no = 'Norsk',
}

export default LanguageName