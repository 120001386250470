import React from 'react'

import './HorizontalSpacer.scss'

interface HorizontalSpacerProps {
	label: string
}

const HorizontalSpacer: React.FC<HorizontalSpacerProps> = ({
	label
}) => {
	return (
		<div className="HorizontalSpacer">
			{label}
		</div>
	)
}

export default HorizontalSpacer
