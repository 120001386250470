import { combineReducers } from 'redux'
import atvTyre from './AtvTyreSearch/atvTyreSearchSlice'
import carTyre from './CarTyreSearch/carTyreSearchSlice'
import industryTyre from './IndustryTyreSearch/industryTyreSearchSlice'
import mcTyre from './McTyreSearch/mcTyreSearchSlice'
import truckTyre from './TruckTyreSearch/truckTyreSearchSlice'
import tinyTyre from './TinyTyreSearch/tinyTyreSearchSlice'

export default combineReducers({
	atvTyre,
	carTyre,
	industryTyre,
	mcTyre,
	truckTyre,
	tinyTyre
})