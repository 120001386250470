import * as React from 'react'

function ViewGridIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="1.25rem"
			height="1.25rem"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M0 8.889h8.889V0H0v8.889zM0 20h8.889v-8.889H0V20zm11.111 0H20v-8.889h-8.889V20zm0-20v8.889H20V0"
				fill="#444"
			/>
		</svg>
	)
}

export default ViewGridIcon