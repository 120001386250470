import apiClient from './apiClient'

/**
 * Get all car brands
 * @returns Array of Id/Name
 */
export const getBrands = async () => await apiClient.get('/api/carbrands')

/**
 * Get all LTK Car brands
 * @returns 
 */
export const getBrandsLtk = async () => await apiClient.get('/api/carbrandsltk')

/**
 * Get all car brands for wheel packages
 * @param {boolean} removeNonSpecified - Remove unspecified brands from list, e.g "Mopedbil"
 * @returns Array of brands
 */
export const getPackageBrands = async (removeNonSpecified) => await apiClient.get('/api/carbrandspackages', { removeNonSpecified })

/**
 * Get all models for one brand
 * @param {number} brandId 
 * @returns Array of models
 */
export const getModels = async (brandId: number) => await apiClient.get('/api/carmodels', { brandId })

/**
 * Get all LTK car models
 * @param {*} brandId 
 * @returns 
 */
export const getModelsLtk = async (brandId) => await apiClient.get('/api/carmodelsltk', { brandId })

/**
 * Get all models for one brand
 * @param {number} brandId 
 * @returns 
 */
export const getPackageModels = async (brandId: number) => await apiClient.get('/api/carmodelspackage', { brandId })

/**
 * Get all body types for one model
 * @param {*} brandId 
 * @param {*} modelId 
 * @returns 
 */
export const getCarBodyTypes = async (modelId) => await apiClient.get('/api/CarBodyTypes', { modelId })

/**
 * Get vehicles by modelid
 * @param {number} modelId 
 * @returns Array of vehicles
 */
export const getVehicles = async (bodyTypeId) => await apiClient.get('/api/CarEngines', { bodyTypeId })

/**
 * Get vehicles by licenseplate
 * @param {string} license 
 * @returns Array of vehicles
 */
export const getVehiclesByLicensePlate = async (license) => await apiClient.get('/api/CarVehiclesByLicense', { license })

/**
 * Get vehicle by TecDoc
 * @param {string} license 
 * @returns Array of vehicles
 */
export const getVehicleByTecDoc = async (TecDoc) => await apiClient.get('/api/carvehicles', { TecDoc })

/**
 * Get extended vehicle data by TecDoc
 * @param {string} license 
 * @returns Array of vehicles
 */
export const fetchExtendedVehicleVehicleId = async (payload) => await apiClient.get('/api/CarVehicleData', { vehicleId: payload.vehicleId, languageCode: payload.languageCode })

/**
 * Get all moto brands
 * @returns Array of Id/Name
 */
export const getMotoBrands = async () => await apiClient.get('/api/motoBrands')

/**
 * Get all models for one brand
 * @param {string} brandName 
 * @returns Array of models
 */
export const getMotoEngineCapacities = async (brandName) => await apiClient.get('/api/motoEngineCapacities', { brandName })

/**
 * Get all models for one brand
 * @param {string} brandName 
 * @param {decimal} engineCapacity
 * @returns Array of models
 */
export const getMotoModels = async (brandName, engineCapacity) => await apiClient.get('/api/motoModels', { brandName, engineCapacity })