import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { statusHandler, thunkActions } from 'utils/utils'
import { getWayOfDeliveries } from 'utils/api/cartApi'
import { loggedOut } from 'utils/redux/commonActions'

export const fetchWayOfDeliveries = createAsyncThunk('cartWayOfDeliveries/fetchWayOfDeliveries', async () => await getWayOfDeliveries())
export const actions = thunkActions(fetchWayOfDeliveries)

const initialState = {
	status: 'idle',
	data: null
}

const wayOfDeliveriesSlice = createSlice({
	name: 'wayOfDeliveries',
	initialState,
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.data = null
			})
			.addCase(fetchWayOfDeliveries.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addMatcher(actions, statusHandler)
	}
})

export const selectWayOfDeliveriesStatus = state => state.cart.wayOfDeliveries.status
export const selectWayOfDeliveries = state => state.cart.wayOfDeliveries.data
export const selectPresetWayOfDeliveryId = state => {
	if (!state.cart.wayOfDeliveries.data)
		return null

	const preset = state.cart.wayOfDeliveries.data.length === 1
	return preset ? state.cart.wayOfDeliveries.data.find(w => w.isSelected)?.id || null : null
}

export default wayOfDeliveriesSlice.reducer
