import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectRoles } from '../../features/user/userInfo/userInfoSlice'

export function useUserRoles() {
	const roles = useSelector(selectRoles)
	
	const presentRoles = useMemo(() => {
		const presentRoles = {
			hasAdminRole: false,
			hasUsedAuthRole: false,
			hasCustomerAdminRole: false,
			hasCustomerGroupAdminRole: false,
			hasMyPageRole: false,
			hasOrderHistoryRole: false,
			hasInvoicesRole: false,
			hasNetPricesRole: false,
			hasCreditLimitRole: false,
			hasCreateOrderRole: false,
			hasCreateReservationRole: false,
			hasDeActivateOrderRole: false,
			hasArticleListExportRole: false,
			hasAutoSelectOERole: false,
			hasDhlCustomAgreementRole: false,
			hasDropshipping: false,
			hasMultiUser: false,
			hasTickets: false,
			hasCMSEdit: false,
			hasPreSeasonReturn: false,
			hasRealizationReturn: false,
			hasCustomerStock: false,
		}
		
		if (!roles) return presentRoles

		for (const role of roles) {
			switch (role) {
				case userRoles.Admin:
					presentRoles.hasAdminRole = true
					break
				case userRoles.UsedAuth:
					presentRoles.hasUsedAuthRole = true
					break
				case userRoles.CustomerAdmin:
					presentRoles.hasCustomerAdminRole = true
					break
				case userRoles.CustomerGroupAdmin:
					presentRoles.hasCustomerGroupAdminRole = true
					break
				case userRoles.MyPage:
					presentRoles.hasMyPageRole = true
					break
				case userRoles.OrderHistory:
					presentRoles.hasOrderHistoryRole = true
					break
				case userRoles.Invoices:
					presentRoles.hasInvoicesRole = true
					break
				case userRoles.NetPrices:
					presentRoles.hasNetPricesRole = true
					break
				case userRoles.CreditLimit:
					presentRoles.hasCreditLimitRole = true
					break
				case userRoles.CreateOrder:
					presentRoles.hasCreateOrderRole = true
					break
				case userRoles.CreateReservation:
					presentRoles.hasCreateReservationRole = true
					break
				case userRoles.DeActivateOrder:
					presentRoles.hasDeActivateOrderRole = true
					break
				case userRoles.ArticleListExport:
					presentRoles.hasArticleListExportRole = true
					break
				case userRoles.AutoSelectOE:
					presentRoles.hasAutoSelectOERole = true
					break
				case userRoles.DhlCustomAgreement:
					presentRoles.hasDhlCustomAgreementRole = true
					break
				case userRoles.Dropshipping:
					presentRoles.hasDropshipping = true
					break
				case userRoles.MultiUser:
					presentRoles.hasMultiUser = true
					break
				case userRoles.Tickets:
					presentRoles.hasTickets = true
					break
				case userRoles.CMSEdit:
					presentRoles.hasCMSEdit = true
					break
				case userRoles.PreSeasonReturn:
					presentRoles.hasPreSeasonReturn = true
					break
				case userRoles.RealizationReturn:
					presentRoles.hasRealizationReturn = true
					break
				case userRoles.CustomerStock:
					presentRoles.hasCustomerStock = true
					break
			}
		}

		return presentRoles
	}, [roles])

	return presentRoles
}

export const userRoles = {
	'Admin': 'Admin',
	'UsedAuth': 'UsedAuth',
	'CustomerAdmin': 'CustomerAdmin',
	'CustomerGroupAdmin': 'CustomerGroupAdmin',
	'MyPage': 'MyPage',
	'OrderHistory': 'OrderHistory',
	'Invoices': 'Invoices',
	'NetPrices': 'NetPrices',
	'CreditLimit': 'CreditLimit',
	'CreateOrder': 'CreateOrder',
	'CreateReservation': 'CreateReservation',
	'DeActivateOrder': 'DeActivateOrder',
	'ArticleListExport': 'ArticleListExport',
	'AutoSelectOE': 'AutoSelectOE',
	'DhlCustomAgreement': 'DhlCustomAgreement',
	'Dropshipping': 'Dropshipping',
	'MultiUser': 'MultiUser',
	'Tickets': 'Tickets',
	'CMSEdit': 'CMSEdit',
	'PreSeasonReturn': 'PreSeasonReturn',
	'RealizationReturn': 'RealizationReturn',
	'CustomerStock': 'CustomerStock',
} as const

export type UserRole = keyof typeof userRoles
