import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/store'
import apiClient from '../../../../utils/api/apiClient'
import sliceStateBases, { SliceState } from '../../../../utils/enums/sliceBaseStates'

export const getOrderReservations = createAsyncThunk<number, void>(
	'orderReservations/getOrderReservations',
	() => apiClient.get('/api/OrderReservations'),
)

interface OrderReservationsState {
	
}

export const initalOrderReservationsState: SliceState<'FEATURE', OrderReservationsState> = {
	...sliceStateBases.FEATURE,
} as const

export const orderReservationsSlice = createSlice({
	name: 'orderReservations',
	initialState: initalOrderReservationsState,
	reducers: {},
	extraReducers: builder => builder
		.addCase(getOrderReservations.pending, (state) => {
			state.data = null
		})
		.addCase(getOrderReservations.fulfilled, (state, action) => {
			state.data = action.payload
		})
})

export default orderReservationsSlice.reducer

export const selectIsOrderReservations = (state: RootState) => state.user.orderReservations
export const selectOrderReservationsAmount = (state: RootState) => state.user.orderReservations.data
export const selectIsOrderReservationsLoading = (state: RootState) => state.user.orderReservations.isLoading
