import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getTpmsByArticleText, getTpmsByCar, getTpmsByVehicleId } from 'utils/api/articleSearchAPI'
import { createResultSelector } from 'utils/helper'
import { loggedOut } from 'utils/redux/commonActions'
import { statusHandler, thunkActions } from 'utils/utils'

export const searchTpms = createAsyncThunk('carTpmsSearch/searchTpms', async (vehicleId) => await getTpmsByVehicleId(
	vehicleId
))

export const searchTpmsByArticleText = createAsyncThunk('carTpmsSearch/searchTpms', async (articleText) => await getTpmsByArticleText(
	articleText
))

export const searchTpmsByCar = createAsyncThunk('carTpmsSearch/searchTpmsByCar', async ({ brand, model, year }) => await getTpmsByCar(
	brand, 
	model, 
	year
))

const searchActions = thunkActions(searchTpms, searchTpmsByCar)

const initialState = {
	status: 'idle',
	result: null,
	filterIds: null,
	filterState: null,
	sortBy: null,
	ascending: null,
	criteria: {
		vehicleId: null,
		license: null,
		articleText: ''
	}
}

const carTpmsSearch = createSlice({
	name: 'carTpmsSearch',
	initialState,
	reducers: {
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		},
		setVehicle: (state, action) => {
			state.criteria.vehicleId = action.payload.vehicleId
			state.criteria.license = action.payload.license
		},
		setArticleText: (state, action) => {
			state.criteria.articleText = action.payload.value
		},
		resetCriteria: (state) => {
			state.criteria = {
				...initialState.criteria
			}
		}
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, () => initialState)
			.addCase(searchTpms.pending, (state) => {
				state.result = null
				state.filterIds = null
				state.filterState = null
			})
			.addCase(searchTpms.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addCase(searchTpmsByCar.pending, (state) => {
				state.result = null
				state.filterIds = null
				state.filterState = null
			})
			.addCase(searchTpmsByCar.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addCase(searchTpms.rejected, (state) => {
				state.result = []
			})
			.addMatcher(searchActions, statusHandler)
	}
})

export const { setFilter, sortResult, resetCriteria, setVehicle, setArticleText } = carTpmsSearch.actions

export const selectStatus = state => state.search.tpms.carTpms.status
export const selectResult = state => state.search.tpms.carTpms.result
export const selectFilterIds = state => state.search.tpms.carTpms.filterIds
export const selectFilterState = state => state.search.tpms.carTpms.filterState
export const selectSortBy = state => state.search.tpms.carTpms.sortBy
export const selectAscending = state => state.search.tpms.carTpms.ascending
export const selectCritiera = state => state.search.tpms.carTpms.criteria

export const selectProcessedResult = createResultSelector(selectResult, selectFilterIds, selectSortBy, selectAscending)

export default carTpmsSearch.reducer
