import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAccessoriesByProperties } from 'utils/api/articleSearchAPI'
import { createResultSelector } from 'utils/helper'
import { statusHandler, thunkActions } from 'utils/utils'
import { loggedOut } from 'utils/redux/commonActions'
import mainGroups from 'utils/enums/MainGroups'

export const searchAccessories = createAsyncThunk('otherAccessorySearch/search', async (criteria) =>
	await getAccessoriesByProperties(
		criteria.articleText,
		criteria.accessoryType,
		mainGroups.accessoriesOil
	))

const searchAccessoriesActions = thunkActions(searchAccessories)

const otherAccessorySearch = createSlice({
	name: 'otherAccessorySearch',
	initialState: {
		status: 'idle',
		result: null,
		filterIds: null,
		filterState: null,
		sortBy: null,
		ascending: null,
		criteria: {
			articleText: '',
			accessoryType: null
		}
	},
	reducers: {
		setCriteria: (state, action) => {
			state.criteria[action.payload.name] = action.payload.value
		},
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		}
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.result = null
				state.filterIds = null
				state.criteria = {
					articleText: '',
					accessoryType: ''
				}
			})
			.addCase(searchAccessories.pending, state => {
				state.filterIds = null
				state.filterState = null
				state.result = null
			})
			.addCase(searchAccessories.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(searchAccessoriesActions, statusHandler)
	}
})

export const selectCriteria = state => state.search.accessory.otherAccessory.criteria
export const selectStatus = state => state.search.accessory.otherAccessory.status
export const selectResult = state => state.search.accessory.otherAccessory.result
export const selectFilterIds = state => state.search.accessory.otherAccessory.filterIds
export const selectFilterState = state => state.search.accessory.otherAccessory.filterState
export const selectSortBy = state => state.search.accessory.otherAccessory.sortBy
export const selectAscending = state => state.search.accessory.otherAccessory.ascending

export const selectProcessedResult = createResultSelector(selectResult, selectFilterIds, selectSortBy, selectAscending)

export const {
	setCriteria,
	setFilter,
	sortResult,
} = otherAccessorySearch.actions

export default otherAccessorySearch.reducer
