import * as React from 'react'

function ViewListIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="1.5625rem"
			height="1.25rem"
			viewBox="0 0 25 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M0 0v2.857h25V0H0zm0 8.571h25V5.714H0v2.857zM0 20h25v-2.857H0V20zm0-5.714h25v-2.857H0v2.857z"
				fill="#444"
			/>
		</svg>
	)
}

export default ViewListIcon