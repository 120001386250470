import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchOrderHistory, fetchOrderRows } from 'utils/api/orderHistoryAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const getOrderHistory = createAsyncThunk('orderHistory/getOrderHistory', async (payload: OrderHistoryCriteria) => fetchOrderHistory(payload))
export const getRowData = createAsyncThunk('orderHistory/getOrderRows', async (id) => fetchOrderRows(id))
export const orderAction = thunkActions(getOrderHistory, getRowData)

const orderHistorySlice = createSlice({
	name: 'orderHistory',
	initialState: {
		status: 'idle',
		data: null,
		rowData: []
	},
	extraReducers: (builder) => {
	 	builder
	 		.addCase(getOrderHistory.fulfilled, (state, action) => {
	 			state.data = action.payload
	 		})
	 		.addCase(getOrderHistory.rejected, (state) => {
	 			state.data = null
	 		})
			.addCase(getRowData.fulfilled, (state, action) => {
				state.data.result.find(order => order.id === action.meta.arg).rowData = action.payload
			})
			.addCase(getRowData.rejected, (state) => {
				state.rowData = null
			})
	 		.addMatcher(orderAction, statusHandler)
	 }
})
export const selectOrderHistoryStatus = state => state.user.orderHistory.status
export const selectOrderHistory = state => state.user.orderHistory.data
export const selectOrderRows = state => state.user.orderHistory.rowData

export default orderHistorySlice.reducer