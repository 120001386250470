import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchExtendedVehicleVehicleId } from 'utils/api/vehicleAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const getExtendedVehicle = createAsyncThunk('vehicleData/getVehicleData', async (payload) => await fetchExtendedVehicleVehicleId(payload))
export const vehicleDataActions = thunkActions(getExtendedVehicle)

const vehicleDataSlice = createSlice({
	name: 'vehicleData',
	initialState: {
		status: 'idle',
		data: null,
		notFound: false,
	},
	reducers: {
		resetVehicleData: (state) => {
			state.data = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getExtendedVehicle.pending, (state, action) => {
				state.notFound = false
			})
			.addCase(getExtendedVehicle.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(getExtendedVehicle.rejected, (state) => {
				state.data = null
				state.notFound = true
			})
			.addMatcher(vehicleDataActions, statusHandler)
	}
})

export const selectVehicleData = state => state.vehicle.vehicleData.data
export const selectStatus = state => state.vehicle.vehicleData.status
export const selectNotFound = state => state.vehicle.vehicleData.notFound
export const { resetVehicleData } = vehicleDataSlice.actions

export default vehicleDataSlice.reducer