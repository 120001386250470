import React from 'react'
import Footer from 'components/Footer/Footer'
import SubMenu from 'components/SubMenu/SubMenu'

const CartLayout = ({ 
	children, 
	...rest 
}) => {
	return (
		<div className="CartLayout">
			<SubMenu />
			<div className="content-wrapper">
				<section className="content-full">
					{children}
					<Footer detailed />
				</section>
			</div>
		</div>
	)
}

export default CartLayout
