enum WidthVariables {
	'xs-max' = 768,
	'sm-min' = 769,
	'sm-max' = 970,
	'md-min' = 971, // transform into touch device mode
	'md-max' = 1170,
	'lg-min' = 1171,
	'lg-max' = 1600,
	'xl-min' = 1601,
}

export default WidthVariables

