import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import {
	deleteTicket,
	getTicket,
	getTicketEvents,
	postTicketEvent,
} from 'utils/api/ticketAPI'
import { 
	statusHandler, 
	thunkActions 
} from 'utils/utils'
import { RootState } from 'app/store'
import sliceStateBases, { SliceState } from 'utils/enums/sliceBaseStates'
import { 
	Ticket, 
	TicketEventPayload, 
	TicketEventsQuery, 
	TicketQuery 
} from 'types/ITicket'

/**
 * Ticket slice state
 * 
 * @interface TicketsState
 */
export interface TicketsState {
 data: Ticket | null
 events: any | null	
 scrollDown: boolean
}

/**
 * Ticket services
*/
export const fetchTicket = createAsyncThunk('tickets/getSingleTicket', async (query: TicketQuery) => await getTicket(query))
export const fetchTicketEvents = createAsyncThunk('tickets/getTicketEvents', async (query: TicketEventsQuery) => await getTicketEvents(query))
export const sendTicketEvent = createAsyncThunk('tickets/sendTicketEvent', async (payload: TicketEventPayload) => await postTicketEvent(payload))
export const closeTicket = createAsyncThunk('tickets/deleteTicket', async (ticketId: number) => await deleteTicket(ticketId))

export const ticketAction = thunkActions(
	fetchTicketEvents,
	fetchTicket,
	sendTicketEvent,
	closeTicket,
)

export const initialTicketsState: SliceState<'FEATURE', TicketsState> = {
	...sliceStateBases.FEATURE,
	events: null,
	scrollDown: false
}

const ticketsSlice = createSlice({
	name: 'tickets',
	initialState: initialTicketsState,
	reducers: {
		resetTicket: (state) => {
			state.data = null
			state.events = null
			state.scrollDown = false
		},
		resetScrollDown: (state) => {
			state.scrollDown = false
		}
	},
	extraReducers: (builder) => {
	 	builder
			.addCase(fetchTicket.fulfilled, (state, action: PayloadAction<Ticket>) => {
	 			state.data = action.payload
				state.events = action.payload.chat
	 		})
	 		.addCase(fetchTicket.rejected, (state) => {
	 			state.data = null
				state.events = null
	 		})
			.addCase(fetchTicketEvents.fulfilled, (state, action) => {
	 			state.events = action.payload
	 		})
	 		.addCase(fetchTicketEvents.rejected, (state) => {
	 			state.events = state.data
	 		})
			.addCase(sendTicketEvent.fulfilled, (state, action) => {
				state.scrollDown = true
				state.events = action.payload
			})
	 		.addMatcher(ticketAction, statusHandler)
	 }
})

export const selectTicketData = (state: RootState) => state.tickets.ticket.data
export const selectTicketEvents = (state: RootState) => state.tickets.ticket.events
export const selectTicketStatus = (state: RootState) => state.tickets.ticket.status

export default ticketsSlice.reducer
