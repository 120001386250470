import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AccessoryFilter from 'components/filters/AccessoryFilter/AccessoryFilter'
import { setFilter, selectResult, selectFilterState } from '../otherAccessorySearchSlice'

const OtherAccessoriesSearchFilter = () => {
	const result = useSelector(selectResult)
	const dispatch = useDispatch()
	const filterState = useSelector(selectFilterState)

	const handleFilterChange = (articleIds, filterState) => {
		dispatch(setFilter({ articleIds, filterState }))
	}

	return (
		<div className="OtherAccessoriesSearchFilter">
			{
				result &&
				<AccessoryFilter 
					result={result} 
					filterState={filterState}
					onFilterChange={handleFilterChange} />
			}
		</div>
	)
}

export default OtherAccessoriesSearchFilter
