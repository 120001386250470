import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RimFilter from 'components/filters/RimFilter/RimFilter'
import { 
	selectRimResult, 
	setFilter, 
	selectRimFilterState,
	selectCriteria
} from '../../carWheelPackageSearchSlice'

const CarWheelPackageRimSearchFilter = () => {
	const result = useSelector(selectRimResult)
	const dispatch = useDispatch()
	const filterState = useSelector(selectRimFilterState)
	const usedCriteria = useSelector(selectCriteria)
	
	const handleFilterChange = (articleIds, filterState) => {
		dispatch(setFilter({articleIds, filterState}))
	}

	return (
		<div className="CarWheelPackageRimSearchFilter">			
			<RimFilter 
				result={result}
				filterState={filterState}
				onFilterChange={handleFilterChange}
				criteria={usedCriteria} />
		</div>
	)
}

export default CarWheelPackageRimSearchFilter
