enum LanguageCode {
	en = 'en',
	sv = 'sv',
	fi = 'fi',
	lv = 'lv',
	lt = 'lt',
	et = 'et',
	ru = 'ru',
	no = 'no',
}

export default LanguageCode