import './WarehouseInfoBubble.scss'
import React, {
	useState,
	useMemo,
} from 'react'
import { Modal } from '@barnsten-it/gummidata-react-components'
import { useSelector } from 'react-redux'
import { selectWarehousesResult } from '../../features/warehouses/warehousesSlice'
import { StockQuantity } from '../../types/Responses'
import { createWarehousesListViewData } from '../../utils/helpers'
import { WarehouseQuantitiesListView } from '../ListViews/WarehouseQuantitiesListView/WarehouseQuantitiesListView'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Availability from 'utils/enums/Availability'
import AlertCircle from 'assets/svgs/svgr/AlertCircle'

interface WarehouseInfoBubbleProps {
	stockQuantities?: StockQuantity[]
	disabled?: boolean
	className?: string
	availability?: Availability
}

export const WarehouseInfoBubble: React.FC<WarehouseInfoBubbleProps> = ({
	stockQuantities,
	className,
	availability,
	disabled=false,
}) => {
	const { t } = useTranslation()
	const [isModalOpen, setIsModalOpen] = useState(false)
	const warehouses = useSelector(selectWarehousesResult)

	const warehousesListViewData = useMemo(() => {
		if (stockQuantities == null) return []

		return createWarehousesListViewData(
			stockQuantities,
			warehouses,
		)
	}, [stockQuantities, warehouses])

	const hasStockQuantities = stockQuantities != null && stockQuantities.length > 0

	const openModal = () => {
		if (!hasStockQuantities) return
		setIsModalOpen(true)
	} 

	const closeModal = () => setIsModalOpen(false)
	
	let colorClassName = className
	switch (availability) {
		case Availability.Immediate:
			colorClassName = 'inStock'
			break
		case Availability.Delayed:
			colorClassName = 'delayed'
			break
		case Availability.Unavailable:
			colorClassName = 'external'
			break
		case Availability.Error:
			colorClassName = 'expired'
			break
		default:
			break
	}

	return (
		<div className={classNames('WarehouseInfoBubble', colorClassName)}>
			{isModalOpen && 
				<Modal
					label={t('articleInfo.stockQuantities')}
					onClose={closeModal}
				>
					<WarehouseQuantitiesListView data={warehousesListViewData} />
				</Modal>
			}

			{!disabled &&
				<button 
					className={classNames('row middle-xs', { 'no-cursor': !hasStockQuantities })} 
					onClick={openModal}
					title={(!hasStockQuantities) ? t('common.externalStock') : t('articleInfo.stockQuantities')}
				>
					<AlertCircle
						width={'1.5625rem'}
						height={'1.5625rem'}
					/>
				</button>
			}
		</div>
	)
}
