import './SearchButton.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '@mdi/react'
import { mdiMagnify } from '@mdi/js'

interface SearchButtonProps {
	onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const SearchButton = ({
	onClick,
}: SearchButtonProps) => {
	const { t } = useTranslation()

	return (
		<button className="SearchButton" title={t('common.search')} onClick={onClick}>
			<Icon path={mdiMagnify} size="1.875rem" />
		</button>
	)
}

export default SearchButton
