import React from 'react'
import SubMenu from 'components/SubMenu/SubMenu'
import { LayoutProps } from 'utils/wrappers/PageWrapper'


const DefaultLayout: React.FC<LayoutProps> = ({ 
	children, 
	...rest 
}) => {
	return (
		<div>
			<SubMenu />
			<div className="content-wrapper">
				<section className="content">
					{children}
				</section>
			</div>
		</div>
	)
}

export default DefaultLayout
