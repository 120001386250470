import './QuickSearch.scss'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '@barnsten-it/gummidata-react-components/dist/Inputs/Button/Button'
import { 
	fetchQuickSearch,
	selectQuickSearchCriteria,  
	selectQuickSearchIsLoading,  
	selectQuickSearchData,
	setQuickSearchCriteria,
} from 'features/search/allProducts/QuickSearch/quickSearchSlice'
import SearchInput from 'components/inputs/SearchInput/SearchInput'
import { setShowQuickSearch } from 'features/app/documentInfo/documentInfoSlice'
import { useTranslation } from 'react-i18next'
import { Loading } from '@barnsten-it/gummidata-react-components'
import QuickSearchList from './QuickSearchList/QuickSearchList'


export interface SearchResultsPanelProps {
	onClose: (isClosed: boolean) => void
}

/**
 * Quick search panel
 * @param onClose
 * @returns React.FunctionalComponent
 */
const QuickSearch = ({
	onClose,
}: SearchResultsPanelProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	
	// Refs
	const searchRef = useRef<HTMLDivElement>(null)

	// Selectors
	const criteria = useSelector(selectQuickSearchCriteria)
	const searchData = useSelector(selectQuickSearchData)
	const isLoading = useSelector(selectQuickSearchIsLoading)

	const onSearchHandler = (searchQuery: string) => {
		if (searchQuery !== '') {
			dispatch(fetchQuickSearch({
				...criteria,
				searchQuery: searchQuery,
				limit: 5,
			}))
		}
	}


	/**
	 * Go to All search button handler
	 * @returns void
	 */
	const SearchButtonHandler = () => {
		// Close search panel and overlay
		dispatch(setShowQuickSearch(false))

		// Move to search page
		navigate(`/search?searchQuery=${criteria.searchQuery}`)
	}


	/**
	 * Close search panel when clicking outside
	 * @param event
	 * @returns void
	 */
	useEffect(() => {
		const checkIfClickedOutside = (event: { target: any }) => {
			if (!searchRef?.current?.contains(event.target)) {
				dispatch(setShowQuickSearch(false))
				onClose(true)
			}
		}

		document.addEventListener('mousedown', checkIfClickedOutside)
		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside)
		}
	}, [dispatch, onClose])


	/**
	 * Result logic
	 * @returns React.FunctionalComponent
	 */
	const ResultLogic = () => {
		if (isLoading) {
			return (
				<Loading />
			)
		} else if(!searchData && criteria.searchQuery === '') {
			return null
		} else if ((!searchData || searchData.length === 0) && criteria.searchQuery !== '') {
			return (
				<div className="no-results">
					{t('shopPage.filterNoItems')}
				</div>
			)
		} else {
			return (
				<>
					<QuickSearchList searchData={searchData} />
					<Button
						label={t('searchMenu.showAllResults')}
						wideSize
						onClick={SearchButtonHandler}
					/>
				</>
			)
		}
	}

	return (
		<div className="QuickSearch" ref={searchRef}>
			<div className="search-wrapper">
				<SearchInput 
					onSearch={onSearchHandler}
					criteria={criteria}
					criteriaSetter={setQuickSearchCriteria}
				/>

				<div className="search-results"> 
					<ResultLogic />
				</div>
			</div>
		</div>
	)
}

export default QuickSearch
