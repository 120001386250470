import * as React from 'react'
import { SVGProps } from 'react'
const SvgAssemblyIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		{...props}
	>
		<path d="M34.3 23.872c.019-.28.075-.562.075-.843v.937l-.075-.094Zm-18.675-.843c0 1.33.281 2.606.788 3.75H6.25v1.875a1.88 1.88 0 0 1-1.875 1.875H2.5a1.88 1.88 0 0 1-1.875-1.875v-15l3.9-11.25A2.81 2.81 0 0 1 7.187.529h20.625a2.81 2.81 0 0 1 2.663 1.875l3.9 11.25v9.375c0-5.175-4.2-9.375-9.375-9.375s-9.375 4.2-9.375 9.375ZM10 18.34a2.809 2.809 0 0 0-2.813-2.812 2.809 2.809 0 0 0-2.812 2.812 2.809 2.809 0 0 0 2.813 2.813A2.809 2.809 0 0 0 10 18.34Zm20.625-6.562L27.812 3.34H7.188L4.376 11.78h26.25Zm7.256 20.98-7.706-7.705c.769-1.95.337-4.238-1.275-5.832-1.688-1.706-4.219-2.043-6.262-1.106l3.637 3.638-2.531 2.55-3.732-3.657C19 22.691 19.47 25.222 21.12 26.93c1.612 1.612 3.881 2.025 5.85 1.275l7.706 7.687a.787.787 0 0 0 1.181 0l1.95-1.931c.413-.338.413-.938.075-1.2Z" />
	</svg>
)
export default SvgAssemblyIcon
