import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAccessoryTypes } from 'utils/api/articleSearchAPI'
import { statusHandler, thunkActions } from 'utils/utils'
import { loggedOut } from 'utils/redux/commonActions'
import mainGroups from 'utils/enums/MainGroups'

export const getTypes = createAsyncThunk('batteryAccessoryTypes/getTypes', async () => await getAccessoryTypes(mainGroups.accessoriesBattery))

const searchAccessoryTypesAction = thunkActions(getTypes)

const batteryAccessoryTypes = createSlice({
	name: 'batteryAccessoryTypes',
	initialState: {
		status: 'idle',
		result: [],
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.result = []
			})
			.addCase(getTypes.pending, state => {
				state.result = []
			})
			.addCase(getTypes.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(searchAccessoryTypesAction, statusHandler)
	}
})

export const selectStatus = state => state.search.accessory.batteryAccessoryType.status
export const selectResult = state => state.search.accessory.batteryAccessoryType.result

export default batteryAccessoryTypes.reducer