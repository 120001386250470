/**
 * Second helpers file. TypeScript.
 *//** */

import { StockQuantity, Warehouse } from '../types/Responses'

import { WarehouseListViewItem } from '../components/ListViews/WarehouseQuantitiesListView/WarehouseQuantitiesListView'
import dayjs from 'dayjs'

export function createWarehousesListViewData(
	stockQuantities: StockQuantity[],
	warehouses: Warehouse[],
): WarehouseListViewItem[] {
	const product: WarehouseListViewItem[] = []

	for (const warehouse of warehouses) {
		const stockQuantity = stockQuantities
			.find(({ warehouseId }) => warehouseId === warehouse.id)
		
		if (stockQuantity == null) continue

		product.push({
			quantity: stockQuantity.quantity,
			sortOrder: stockQuantity.sortOrder,
			warehouseId: warehouse.id,
			warehouseName: warehouse.name,
		})
	}

	return product
}

/**
 * Determines whether value is nullish.
 */
export function isNullish(
	value: any,
): boolean {
	return (
		value === null ||
		value === undefined
	)
}

/**
 * Extracts number values from a property from each article object,
 * for example, for `centerBore`: `[57.1, 70.1, 75, 66.6]`.
 */
export function getNumberValuesOfArticleProperty<T>(
	articles: T[],
	property: keyof T,
): number[] {
	const numbers: {
		[num: string]: number
	} = {}

	for (const article of articles) {
		const num = article[property]
		if (typeof num != 'number' || isNaN(num)) continue

		numbers[num] = num
	}

	return Object.values(numbers)
}

/**
 * Get the index of closest number in the array of numbers
 * Thank you chatGPT.
 */

export function closestNumber(arr: number[], target: number): number {
	if(arr.length === 0) {
		return -1
	}
	let closestIndex: number = 0
	// @ts-ignore:next-line
	let closestDistance: number = Math.abs(arr[0] - target)
	arr.reduce((prev: number, curr: number, index: number) => {
		let currDistance: number = Math.abs(curr - target)
		if (currDistance < closestDistance) {
			closestIndex = index
			closestDistance = currDistance
		}
		return prev
	}, 0)
	return closestIndex
}

/**
 * Checks if iso string is today or before today
 * @param isoString 
 * @returns bool
 */
export const isTodayOrBefore = (isoString: string) => { 
	const currentDate = dayjs().startOf('day')
	const givenDate = dayjs(isoString).startOf('day')
	return givenDate.isSame(currentDate, 'day') || givenDate.isBefore(currentDate, 'day')
}

/**
 * Check if its a number of a numeric string
 * 
 * @param value 
 * @returns {boolean} 
 */
export const isNumber = (value: any): boolean => {
	return (typeof value === 'number') || 
				 (typeof value === 'string' && value.trim() !== '' && !isNaN(Number(value)))
}

/**
 * Make sure the first letter is lowercase
 * 
 * @param value 
 * @returns {string}
 */
export const firstLetterLowerCase = (value: string): string => {
	if (!value) return ''
	return value.charAt(0).toLowerCase() + value.slice(1)
}


/**
 * Extract the second segment of a url
 * 
 * @param url 
 * @returns 
 */
export const extractSecondSegment = (url: string): string | null => {
	const segments = url.split('/')
	return segments.length > 2 && segments[2] !== undefined ? segments[2] : null
}

/**
 * Sets empty string for null and undefined values in criteria
 * 
 * @param criteria 
 * @returns cleaned criteria
 */
export const cleanCriteria = (criteria: Record<string, any>): Record<string, any> => {
	const cleanedCriteria: Record<string, any> = {}

	Object.entries(criteria).forEach(([key, value]) => {
		if (value === null || value === undefined || value === 'null' || value === 'undefined') {
			cleanedCriteria[key] = ''
		} else {
			cleanedCriteria[key] = value
		}
	})

	return cleanedCriteria
}