import { combineReducers } from 'redux'

import documentInfo from './documentInfo/documentInfoSlice'
import settings from './settings/settingsSlice'
import version from './version/versionSlice'

export default combineReducers({
	documentInfo,
	settings,
	version,
})
