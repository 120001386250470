import React from 'react'
import SubMenu from 'components/SubMenu/SubMenu'
import { useSelector } from 'react-redux'
import { selectLoggedIn } from 'features/user/login/loginSlice'

const StartpageLayout: React.FC<LayoutProps> = ({
	children,
	...props
}) => {
	const isLoggedIn = useSelector(selectLoggedIn)

	return (
		<div>
			{isLoggedIn && <SubMenu />}
			<div className="Startpage-wrapper">
				<section className="content">
					{children}
				</section>
			</div>
		</div>
	)
}

export default StartpageLayout
