import apiClient from './apiClient'

/**
 * Get retailMarkUps
 * @returns 
 */
export const fetchRetailMarkUps = async () => await apiClient.get('/api/CustomerRetailMarkUps')

/**
 * update markUp item 
 * @returns 
 */
export const putRetailMarkUp = async (payload) => await apiClient.put('/api/CustomerRetailMarkUps', payload)

/**
 * update markUp item 
 * @returns 
 */
export const postAllRetailMarkUp = async (payload) => await apiClient.post('/api/CustomerRetailMarkUps', payload)