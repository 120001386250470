import React, { FC }from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectResult, setFilter, selectFilterState } from '../carTubesSearchSlice'
import TubeFilter from 'components/filters/TubeFilter/TubeFilter'

const CarTubesSearchFilter: FC = () => {
	const dispatch = useDispatch()
	const result = useSelector(selectResult)
	const filterState = useSelector(selectFilterState)

	const handleFilterChange = (articleIds: Array<number>, filterState: Object) => {
		dispatch(setFilter({ articleIds, filterState }))
	}

	return (
		<div className="CarTubeSearchFilter">
			{
				result &&
				<TubeFilter 
					result={result} 
					filterState={filterState}
					onFilterChange={handleFilterChange} />
			}
		</div>
	)
}

export default CarTubesSearchFilter