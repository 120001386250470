import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { getCountries } from 'utils/api/countriesAPI'

export const fetchCountries = createAsyncThunk('countries/fetchCountries', async (languageCode) => await getCountries(languageCode))

const initialState = {
	status: 'idle',
	items: null
}

const countriesSlice = createSlice({
	name: 'countries',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCountries.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(fetchCountries.fulfilled, (state, action) => {
				state.status = 'success'
				state.items = action.payload
			})
			.addCase(fetchCountries.rejected, (state) => {
				state.status = 'failed'
			})
	},
})

export const selectCountries = state => state.countries.items
export const selectByCountryCode = (countryCode) => createSelector(selectCountries, countries => {
	if(!countryCode || !countries)
		return null

	return countries.find(t => t.countryCode === countryCode)
})

export default countriesSlice.reducer
