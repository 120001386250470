import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRimsByVehicleId, getRimsBySize, getRimsByCarModel } from 'utils/api/articleSearchAPI'
import searchRimType from 'utils/enums/searchRimType'
import { createResultSelector, isPreviousSearchCategory, isSearchCategory } from 'utils/helper'
import { statusHandler, thunkActions } from 'utils/utils'
import { loggedOut } from 'utils/redux/commonActions'
import sliceStatus from 'utils/enums/sliceStatus'

export const searchRims = createAsyncThunk('steelRimSearch/search', async (criteria) => {
	if (criteria.vehicleId) {
		return await getRimsByVehicleId(criteria.vehicleId, criteria.license, searchRimType.steel, criteria.languageCode)
	} else if (criteria.carModel) {
		return await getRimsByCarModel(criteria.carModel, criteria.brandId, searchRimType.steel, criteria.languageCode)
	}
	return await getRimsBySize(criteria.boltPattern, criteria.boltCircle, criteria.diameter, null, criteria.articleText, searchRimType.steel, criteria.languageCode)
})

const searchRimActions = thunkActions(searchRims)

const initialState = {
	status: 'idle',
	result: null,
	filterIds: null,
	filterState: null,
	sortBy: null,
	ascending: null,
	criteria: {
		bolts: '',
		circle: '',
		diameter: '',
		license: '',
		brandId: '',
		modelId: '',
		vehicleId: null,
		articleText: '',
		carModel: null
	}
}

const carSteelRimSearch = createSlice({
	name: 'carSteelRim',
	initialState: initialState,
	reducers: {
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		reset: () => initialState,
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		},
		setCriteria: (state, action) => {
			state.criteria = {
				...state.criteria,
				...action.payload,
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loggedOut, (state) => {
				state.status = 'idle'
				state.result = null
				state.filteredResult = null
			})
			.addCase(searchRims.pending, (state) => {
				state.result = null
				state.filterIds = null
				state.filterState = null
				state.sortBy = null
				state.ascending = null
			})
			.addCase(searchRims.fulfilled, (state, action) => {
				state.filterIds = null
				state.result = action.payload
			})
			.addMatcher(isPreviousSearchCategory('car', 'steelRim'), (state) => {
				state.status = sliceStatus.idle
			})
			.addMatcher(isSearchCategory('car', 'steelRim'), (state, action) => {
				state.status = sliceStatus.success
				state.result = action.payload
			})
			.addMatcher(searchRimActions, statusHandler)
	},
})

export const selectStatus = state => state.search.steelRim.carSteelRim.status
export const selectResult = state => state.search.steelRim.carSteelRim.result
export const selectFilterIds = state => state.search.steelRim.carSteelRim.filterIds
export const selectFilterState = state => state.search.steelRim.carSteelRim.filterState
export const selectSortBy = state => state.search.steelRim.carSteelRim.sortBy
export const selectAscending = state => state.search.steelRim.carSteelRim.ascending
export const selectCriteria = state => state.search.steelRim.carSteelRim.criteria

export const selectProcessedResult = createResultSelector(
	selectResult,
	selectFilterIds,
	selectSortBy,
	selectAscending
)

export const { setFilter, sortResult, reset, setCriteria } =
	carSteelRimSearch.actions

export default carSteelRimSearch.reducer
