import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SubCustomerDiscount, SubCustomerMarkUp } from '../../../types/Responses'
import sliceStateBases, { SliceState } from '../../../utils/enums/sliceStateBases'
import { isNullish, statusHandler, thunkActions } from '../../../utils/utils'
import {
	deleteSubCustomerDiscounts as _deleteSubCustomerDiscounts,
	getSubCustomerDiscounts as _getSubCustomerDiscounts,
	postSubCustomerDiscounts as _postSubCustomerDiscounts,
	putSubCustomerDiscounts as _putSubCustomerDiscounts,
} from 'utils/api/subCustomerDiscountsAPI'
import {
	deleteSubCustomerMarkUps as _deleteSubCustomerMarkUps,
	getSubCustomerMarkUps as _getSubCustomerMarkUps,
	postSubCustomerMarkUps as _postSubCustomerMarkUps,
	putSubCustomerMarkUps as _putSubCustomerMarkUps,
} from 'utils/api/subCustomerMarkUpsAPI'
import { getMarkUpId } from './helpers/getMarkUpId'
import { RootState } from '../../../app/store'

export const getSubCustomerDiscounts = createAsyncThunk(
	'subCustomers/getSubCustomerDiscounts',
	async (id: string) => await _getSubCustomerDiscounts(id),
)
export const putSubCustomerDiscounts = createAsyncThunk(
	'subCustomers/putSubCustomerDiscounts',
	async ([id, payload]: [string, SubCustomerDiscount]) => await _putSubCustomerDiscounts(id, payload),
)
export const postSubCustomerDiscounts = createAsyncThunk(
	'subCustomers/postSubCustomerDiscounts',
	async (payload) => await _postSubCustomerDiscounts(payload),
)
export const deleteSubCustomerDiscounts = createAsyncThunk(
	'subCustomers/deleteSubCustomerDiscounts',
	async () => await _deleteSubCustomerDiscounts(),
)
export const getSubCustomerMarkUps = createAsyncThunk(
	'subCustomers/getSubCustomerMarkUps',
	async (subCustomerId: string) => await _getSubCustomerMarkUps(subCustomerId),
)
export const postSubCustomerMarkUps = createAsyncThunk(
	'subCustomers/postSubCustomerMarkUps',
	async (payload) => await _postSubCustomerMarkUps(payload),
)
export const putSubCustomerMarkUps = createAsyncThunk(
	'subCustomers/putSubCustomerMarkUps',
	async (payload: SubCustomerMarkUp) => await _putSubCustomerMarkUps(payload),
)
export const deleteSubCustomerMarkUps = createAsyncThunk(
	'subCustomers/deleteSubCustomerMarkUps',
	async () => await _deleteSubCustomerMarkUps(),
)

const subCustomerDiscountsActions = thunkActions(
	getSubCustomerDiscounts,
	putSubCustomerDiscounts,
	postSubCustomerDiscounts,
	deleteSubCustomerDiscounts,
	getSubCustomerMarkUps,
	postSubCustomerMarkUps,
	putSubCustomerMarkUps,
	deleteSubCustomerMarkUps,
)

export interface SubCustomerDiscountsDataBlanket {
	[subGroupId: string]: SubCustomerDiscount
}

export interface SubCustomerMarkUpsDataBlanket {
	[markUpId: string]: SubCustomerMarkUp
}

export interface SubCustomerDiscountsState {
	subCustomerDiscounts: SubCustomerDiscount[] | null
	subCustomerDiscountsDataBlanket: SubCustomerDiscountsDataBlanket
	subCustomerDiscountsOrderedBy: string | null
	subCustomerDiscountsOrderedByDirection: 'ascending' | 'descending'
	subCustomerMarkUps: SubCustomerMarkUp[] | null
	subCustomerMarkUpsDataBlanket: SubCustomerMarkUpsDataBlanket
}

export const initialSubCustomerDiscountsState: SliceState<'FEATURE', SubCustomerDiscountsState> = {
	...sliceStateBases.FEATURE,
	subCustomerDiscounts: null,
	subCustomerDiscountsDataBlanket: {},
	subCustomerDiscountsOrderedBy: null,
	subCustomerDiscountsOrderedByDirection: 'ascending',
	subCustomerMarkUps: null,
	subCustomerMarkUpsDataBlanket: {},
}

export const subCustomerDiscountsSlice = createSlice({
	name: 'subCustomerDiscounts',
	initialState: initialSubCustomerDiscountsState,
	reducers: {
		setSubCustomerDiscountsDataBlanket(
			state,
			action: PayloadAction<SubCustomerDiscount>,
		) {
			const subGroup = action.payload
			const { subGroupId } = subGroup
			if (isNullish(subGroupId)) return

			state.subCustomerDiscountsDataBlanket[subGroupId] = subGroup
		},
		setSubCustomerMarkUpsDataBlanket(
			state,
			action: PayloadAction<SubCustomerMarkUp>,
		) {
			const markUp = action.payload
			const {
				articleType,
				diameter,
			} = markUp

			const markUpId = getMarkUpId(articleType, diameter)

			state.subCustomerMarkUpsDataBlanket[markUpId] = markUp
		},
		clearSubCustomerDiscounts(state) {
			state.subCustomerDiscounts = null
			state.subCustomerDiscountsDataBlanket = {}
		},
		clearSubCustomerMarkUps(state) {
			state.subCustomerMarkUps = null
		},
		orderDiscountsBy(
			state,
			action: PayloadAction<keyof SubCustomerDiscount>,
		) {
			const propertyName = action.payload

			if (state.subCustomerDiscountsOrderedBy === propertyName) {
				// Flip direction
				state.subCustomerDiscountsOrderedByDirection =
					state.subCustomerDiscountsOrderedByDirection === 'ascending'
						? 'descending'
						: 'ascending'
			} else {
				state.subCustomerDiscountsOrderedBy = propertyName
				state.subCustomerDiscountsOrderedByDirection = (
					initialSubCustomerDiscountsState.subCustomerDiscountsOrderedByDirection
				)
			}

			if (state.subCustomerDiscountsOrderedByDirection === 'ascending') {
				// Order ascending
				state.subCustomerDiscounts?.sort(
					(
						{ [propertyName]: a },
						{ [propertyName]: b },
					) => {
						if (a === null) return 1 // Place at the end
						if (b === null) return 1 // Place at the end
						if (
							typeof a != 'number' ||
							typeof b != 'number'
						) return (a < b ? -1 : a > b ? 1 : 0)
						return a - b
					}
				)
			} else {
				// Order descending
				state.subCustomerDiscounts?.sort(
					(
						{ [propertyName]: a },
						{ [propertyName]: b },
					) => {
						if (a === null) return 1 // Place at the end
						if (b === null) return 1 // Place at the end
						if (
							typeof a != 'number' ||
							typeof b != 'number'
						) return (a < b ? 1 : a > b ? -1 : 0)
						return b - a
					}
				)
			}
		},
	},
	extraReducers: builder => builder
		.addCase(getSubCustomerDiscounts.pending, state => {
			state.subCustomerDiscounts = null
		})
		.addCase(getSubCustomerDiscounts.fulfilled, (state, action) => {
			state.subCustomerDiscounts = action.payload
		})
		.addCase(getSubCustomerMarkUps.pending, state => {
			state.subCustomerMarkUps = null
		})
		.addCase(getSubCustomerMarkUps.fulfilled, (state, action) => {
			state.subCustomerMarkUps = action.payload
		})
		.addMatcher(subCustomerDiscountsActions, statusHandler)
})

export default subCustomerDiscountsSlice.reducer

export const {
	setSubCustomerDiscountsDataBlanket,
	setSubCustomerMarkUpsDataBlanket,
	clearSubCustomerDiscounts,
	clearSubCustomerMarkUps,
	orderDiscountsBy,
} = subCustomerDiscountsSlice.actions

export const selectSubCustomerDiscounts = (state: RootState) => state.reseller.subCustomerDiscounts
export const selectSubCustomerDiscountsData = (state: RootState) => state.reseller.subCustomerDiscounts.subCustomerDiscounts
export const selectSubCustomerDiscountsDataBlanket = (state: RootState) => state.reseller.subCustomerDiscounts.subCustomerDiscountsDataBlanket
export const selectSubCustomerMarkUpsData = (state: RootState) => state.reseller.subCustomerDiscounts.subCustomerMarkUps
export const selectSubCustomerMarkUpsDataBlanket = (state: RootState) => state.reseller.subCustomerDiscounts.subCustomerMarkUpsDataBlanket
export const selectIsSubCustomerDiscountsLoading = (state: RootState) => state.reseller.subCustomerDiscounts.isLoading
