import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TyreFilter from 'components/filters/TyreFilter/TyreFilter'
import { setFilter, selectResult,  selectFilterState } from '../mcTyreSearchSlice'

const McTyreSearchFilter = () => {
	const result = useSelector(selectResult)
	const dispatch = useDispatch()
	const filterState = useSelector(selectFilterState)
	
	const handleFilterChange = (articleIds, filterState) => {
		dispatch(setFilter({ articleIds, filterState }))
	}

	const emptySearchResult = result &&
		(result.inStock.length || result.incoming.length || result.toOrder.length) ? false : true

	return (
		<div className="McTyreSearchFilter">
			{
				!emptySearchResult &&
				<TyreFilter 
					result={result} 
					filterState={filterState}
					onFilterChange={handleFilterChange} />
			}
		</div>
	)
}

export default McTyreSearchFilter
