import { DeliveryAddress } from 'types/Responses'
import apiClient from './apiClient'
import LanguageCode from 'utils/enums/languageCode'
import { 
	NewTicketCreatePayload, 
	NewTicketInitiateResponse, 
	NewTicketOrderCheckPayload, 
	NewTicketOrderCheckResponse 
} from 'types/INewTicket'
import { 
	Ticket, 
	TicketEvent, 
	TicketEventPayload, 
	TicketEventsQuery, 
	TicketQuery, 
	TicketsQuery 
} from 'types/ITicket'
import TicketTypes from 'utils/enums/ticketTypes'

/**
 * Get ticket list.
 */
export const getTickets = async (
	payload: TicketsQuery,
): Promise<Ticket[]> => await apiClient.get('/api/tickets', payload)

/**
 * Get ticket.
 */
export const getTicket = async (
	payload: TicketQuery,
): Promise<Ticket> => await apiClient.get('/api/ticket', payload)

/**
 * Post ticket.
 */
export const postTicket = async (
	payload: NewTicketCreatePayload,
): Promise<number> => await apiClient.post('/api/ticket', payload)

/**
 * Delete ticket.
 */
export const deleteTicket = async (
	id: string | number,
): Promise<undefined> => await apiClient.delete('/api/ticket/' + id)

/**
 * Post ticket event message.
 */
export const postTicketEvent = async (
	payload: TicketEventPayload,
): Promise<undefined> => await apiClient.post('/api/TicketEvents', payload)

/**
 * Get ticket.
 */
export const getTicketEvents = async (
	query: TicketEventsQuery,
): Promise<TicketEvent[]> => await apiClient.get('/api/TicketEvents', query)

export interface TicketInitiatePayload {
	id: number
	languageCode: LanguageCode
	ticketType: TicketTypes
}

export const getTicketOrderCheck = async (
	payload: NewTicketOrderCheckPayload,
): Promise<NewTicketOrderCheckResponse> => await apiClient.get('/api/TicketOrderCheck', payload)


/**
 * Get ticket when initiating.
 */
export const getTicketInitiate = async (
	payload: TicketInitiatePayload,
): Promise<NewTicketInitiateResponse> => await apiClient.get('/api/TicketInitiate/' + payload.id, payload)

/**
 * Get order/customer delivery addresses.
 */
export const getTicketDeliveryAddresses = async (
	deliveryAddressId: number,
): Promise<DeliveryAddress[]> => await apiClient.get('/api/TicketDeliveryAddresses', { deliveryAddressId })