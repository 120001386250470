import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getTyreSizes } from 'utils/api/dimensionsAPI'
import mainGroups from 'utils/enums/MainGroups'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchSmallTyreSize = createAsyncThunk('tractorTyreSizes/fetchSmallTyreSize', async (multi = false) => await getTyreSizes(multi, mainGroups.smallTyre))
const fetchSmallTyreSizeAction = thunkActions(fetchSmallTyreSize)

const SmallTyreSize = createSlice({
	name: 'SmallTyreSize',
	initialState: {
		status: 'idle',
		sizes: null
	},
	extraReducers: builder => {
		builder
			.addCase(fetchSmallTyreSize.fulfilled, (state, action) => {
				state.sizes = action.payload
			})
			.addMatcher(fetchSmallTyreSizeAction, statusHandler)
	}
})

export const selectSmallTyreSizesStatus = state => state.dimensions.smallTyreSize.status
export const selectSmallTyreSizes = state => state.dimensions.smallTyreSize.sizes

export default SmallTyreSize.reducer
