import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getArticleLink } from 'utils/api/articleLinkAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchArticleLink = createAsyncThunk('articleLink/fetchArticleLink', async (articleIds) => await getArticleLink(articleIds))

const articleLinkActions = thunkActions(fetchArticleLink)

const initialState = {
	showWindow: false,
	status: 'idle',
	result: null
}

const articleLinkSlice = createSlice({
	name: 'articleLink',
	initialState,
	reducers: {
		closeWindow: (state) => {
			state.showWindow = !state.showWindow
			state.result = null
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchArticleLink.fulfilled, (state, action) => {
				state.result = action.payload
				state.showWindow = action.payload && action.payload.length > 0
			})
			.addMatcher(articleLinkActions, statusHandler)
	}
})

export const { closeWindow } = articleLinkSlice.actions

export const selectShowWindow = state => state.articles.articleLink.showWindow
export const selectResult = state => state.articles.articleLink.result

export default articleLinkSlice.reducer
