import { combineReducers } from 'redux'
import miniCart from './MiniCart/miniCartSlice'
import checkout from './checkout/checkoutSlice'
import deliveryAddresses from './deliveryAddresses/deliveryAddressesSlice'
import wayOfDeliveries from './wayOfDeliveries/wayOfDeliveriesSlice'

export default combineReducers({
	miniCart,
	checkout,
	deliveryAddresses,
	wayOfDeliveries
})