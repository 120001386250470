import { combineReducers } from 'redux'
import truckRimSizes from './truckRimSize/truckRimSizeSlice'
import truckTyreSizes from './truckTyreSize/truckTyreSizeSlice'
import tractorTyreSizes from './tractorTyreSize/tractorTyreSizeSlice'
import loaderTyreSizes from './loaderTyreSize/loaderTyreSizeSlice'
import inchTyreSizes from './inchTyreSize/inchTyreSizeSlice'
import industryAgroRimSize from './industryAgroRimSize/industryAgroRimSizeSlice'
import industryAgroTyreSize from './industryAgroTyreSize/industryAgroTyreSizeSlice'
import forkliftTyreSize from './forkLiftTyreSize/forkLiftTyreSizeSlice'
import smallTyreSize from './smallTyreSize/smallTyreSizeSlice'
import industryTubeSize from './industryTubeSize/industryTubeSizeSlice'

export default combineReducers({
	truckRimSizes,
	truckTyreSizes,
	tractorTyreSizes,
	loaderTyreSizes,
	inchTyreSizes,
	industryAgroRimSize,
	industryAgroTyreSize,
	forkliftTyreSize,
	smallTyreSize,
	industryTubeSize
})
