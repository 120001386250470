import './CreditInfo.scss'
import React from 'react'
import { SpeachBubble } from '@barnsten-it/gummidata-react-components'
import { selectIdleCheck, selectScreenWidth } from 'features/app/documentInfo/documentInfoSlice'
import { selectSiteConfig } from 'features/app/settings/settingsSlice'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getCredits, selectCreditInfo } from '../CreditInfoSlice'
import { CreditIcon } from 'assets/svgs/svgr'
import widthVariables from 'utils/enums/widthVariables'
import { usePriceFormatter } from 'utils/hooks/usePriceFormatter'
import { selectHiddenCreditInfo } from 'features/user/userInfo/userInfoSlice'
import { useUserRoles } from 'utils/hooks/useUserRoles'
import { useSettings } from 'utils/hooks/useSettings'
import SettingsTypes from 'utils/enums/settingsTypes'

type TimerRef = NodeJS.Timeout | null;

interface CreditInfoProps {
	mobileView?: boolean
}

const CreditInfo = ({ 
	mobileView 
}: CreditInfoProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const ref = React.useRef<HTMLDivElement>()
	const timer = React.useRef<TimerRef>(null)
	const priceFormatter = usePriceFormatter()

	const [show, setShow] = React.useState(false)

	const hiddenCreditInfo = useSelector(selectHiddenCreditInfo)
	const siteSettings = useSelector(selectSiteConfig)
	const creditData = useSelector(selectCreditInfo)
	const screenWidth = useSelector(selectScreenWidth)	
	const idleCheck = useSelector(selectIdleCheck)

	const {
		[SettingsTypes.hideCreditInfo]: hideCreditInfo,
	} = useSettings()

	const {
		hasCustomerAdminRole,
		hasCreditLimitRole,
	} = useUserRoles()

	const showCreditLimits = siteSettings?.creditControl && (hasCustomerAdminRole || hasCreditLimitRole) && !(hiddenCreditInfo || hideCreditInfo)

	const onClickHandler = () => {
		setShow(!show)
	}

	React.useEffect(() => {
		if (!idleCheck && showCreditLimits) {
			timer.current = setInterval(() => {
				dispatch(getCredits())
			}, 60000)
		} else {
			clearTimeout(timer.current as NodeJS.Timeout)
		}

		// clear on component unmount
		return () => {
			clearInterval(timer.current as NodeJS.Timeout)
		}

	}, [idleCheck, dispatch, showCreditLimits])

	React.useEffect(() => {
		if (showCreditLimits)
			dispatch(getCredits())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Checks if pressing outside the MiniCart wrapper
	React.useEffect(() => {
		const checkIfClickedOutside = (event: any) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (show && ref.current && !ref.current.contains(event.target)) {
				setShow(false)
			}
		}

		document.addEventListener('mousedown', checkIfClickedOutside)
		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside)
		}
	}, [show])

	// If we don't have any credit limit to show based on configuration, return nothing
	if(!showCreditLimits) {
		return null
	}

	if (mobileView) {
		return (
			<div className="creditInfo-mobile-view">
				<div className="text-row">
					<p>{t('common.creditLimit')}:</p>
					<p>{priceFormatter(creditData.creditLimit)}</p>
				</div>
				<div className="text-row">
					<p>{t('common.creditAvailable')}:</p>
					<p>{priceFormatter(creditData.availableCredit)}</p>
				</div>
				<div className="text-row">
					<p>{t('common.requestBilled')}:</p>
					<p>{priceFormatter(creditData.amountDue)}</p>
				</div>
				<div className="text-row">
					<p>{t('common.uninvoicedRequest')}:</p>
					<p>{priceFormatter(creditData.reservedAmount)}</p>
				</div>
				<div className="text-row">
					<p>{t('dueInvoice.dueInvoices') + ' ' + t('dueInvoice.amount').toLowerCase()}:</p>
					<p>{priceFormatter(creditData.overdueInvoiceAmount)}</p>
				</div>
			</div>
		)
	} else {
		return (
			<div className="CreditInfo" onClick={onClickHandler} ref={ref as React.RefObject<HTMLDivElement>}>
				{!hiddenCreditInfo ? (
					(screenWidth < widthVariables['lg-max']) ? (
						<div className="credit-svg">
							<CreditIcon />
						</div>
					) : (
						<div className="credit-wrapper">
							<div>
								{t('common.creditAvailable')}
							</div>
							<div>
								{priceFormatter(creditData?.availableCredit)}
							</div>
						</div>
					)
				) : (
					<div className="credit-wrapper">
						<span>{t('header.showCredit')}</span>
					</div>
				)}

				{show &&
					<SpeachBubble>
						<div className="text-row">
							<p>{t('common.creditLimit')}:</p>
							<p>{priceFormatter(creditData?.creditLimit)}</p>
						</div>
						<div className="text-row">
							<p>{t('common.creditAvailable')}:</p>
							<p>{priceFormatter(creditData?.availableCredit)}</p>
						</div>
						<div className="text-row">
							<p>{t('common.requestBilled')}:</p>
							<p>{priceFormatter(creditData?.amountDue)}</p>
						</div>
						<div className="text-row">
							<p>{t('common.uninvoicedRequest')}:</p>
							<p>{priceFormatter(creditData?.reservedAmount)}</p>
						</div>
						<div className="text-row">
							<p>{t('dueInvoice.dueInvoices') + ' ' + t('dueInvoice.amount').toLowerCase()}:</p>
							<p>{priceFormatter(creditData?.overdueInvoiceAmount)}</p>
						</div>
					</SpeachBubble>
				}
			</div>
		)
	}
}

export default CreditInfo
