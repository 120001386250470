import * as React from 'react'
import { SVGProps } from 'react'
const SvgUserIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		{...props}
	>
		<g clipPath="url(#a)">
			<path
				fill="#247DBA"
				d="M10 17.2c-2.5 0-4.71-1.28-6-3.2.03-2 4-3.1 6-3.1s5.97 1.1 6 3.1a7.232 7.232 0 0 1-6 3.2ZM10 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6m0-3a10 10 0 1 0 10 10c0-5.53-4.5-10-10-10Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h20v20H0z" />
			</clipPath>
		</defs>
	</svg>
)
export default SvgUserIcon
