import { useTranslation } from 'react-i18next'


const useWebTexts = (namespace: string) => {
	const { t } = useTranslation(`webtext.${namespace}`)

	const wt = (property: string) => {
		return t(`webtext.${namespace}.${property}`)
	}

	return {
		wt
	}
}

export default useWebTexts