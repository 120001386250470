import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SwitchInput } from '@barnsten-it/gummidata-react-components'
import { setLocalStorageItem } from 'utils/utils'
import { RootState } from 'app/store'

interface SettingSwitchProps {
  settingName: string;
  label: string;
  description?: string | null;
  selector: (state: RootState) => boolean;
  action: (value: boolean) => void;
	condition?: boolean;
	local?: boolean;
	callback?: (value: boolean) => void;
	disabled?: boolean;
}

const SettingSwitch = ({
	settingName,
	label,
	description = null,
	selector,
	action,
	disabled = false,
	condition = true,
	local = true,
	callback,
}: SettingSwitchProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const currentValue = useSelector(selector)
	
	const handleOnChange = () => {
		const newValue = !currentValue
		
		// Callback
		if (callback) {
			callback(newValue)
		}

		// Set redux state
		dispatch(action(newValue))

		// Set localStorage - default value is true
		if (local) {
			setLocalStorageItem(settingName, newValue)
		} 
	}

	if (!condition) {
		return null
	}

	return (
		<div className={`SettingsSwitch ${settingName}Switch`} title={description ?? ''}>
			<SwitchInput
				name={settingName}
				checked={currentValue}
				onChange={handleOnChange}
				description={description}
				disabled={disabled}
				label={t(label)}
			/>
		</div>
	)
}

export default SettingSwitch