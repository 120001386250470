import { combineReducers } from 'redux'

// Article category reducers
import steelRim from './steelRim/steelRimReducer'
import aluminiumRim from './aluminiumRim/aluminiumRimReducer'
import wheelPackage from './wheelPackage/wheelPackageReducer'
import oil from './oil/oilReducer'
import battery from './battery/batteryReducer'
import tyre from './tyre/tyreReducer'
import rim from './rim/rimReducer'
import tpms from './tpms/tpmsReducer'
import tube from './tube/tubeReducer'
import accessory from './accessory/accessoryReducer'
import allProducts from './allProducts/allProductsReducer'

// Random things that should be moved?
import checkCategory from './checkCategory/checkCategorySlice'
import searchSettings from './searchSettings/searchSettingsSlice'

import emailWheelPackageList from './emailWheelPackageList/emailWheelPackageListSlice'
import alternativeDimensions from './alternativeDimensions/alternativeDimensionsSlice'

export default combineReducers({
	// Article category reducers
	accessory,
	allProducts,
	aluminiumRim,
	battery,
	oil,
	rim,
	steelRim,
	tpms,
	tube,
	tyre,
	wheelPackage,
	
	// Other reducers
	checkCategory,
	searchSettings,
	emailWheelPackageList,
	alternativeDimensions,
})