import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TyreFilter from 'components/filters/TyreFilter/TyreFilter'
import { 
	selectTyreResult, 
	setFilter, 
	selectTyreFilterState 
} from '../../carWheelPackageSearchSlice'


const CarWheelPackageTyreSearchFilter = () => {
	const result = useSelector(selectTyreResult)
	const dispatch = useDispatch()
	const filterState = useSelector(selectTyreFilterState)

	const handleFilterChange = (articleIds, filterState) => {	
		dispatch(setFilter({articleIds, filterState})) 
	}

	return (
		<div className="CarWheelPackageTyreSearchFilter">			
			<TyreFilter 
				result={result}
				filterState={filterState} 
				onFilterChange={handleFilterChange} />
		</div>
	)
}

export default CarWheelPackageTyreSearchFilter
