/// <reference types="./globals.d.ts" /> 

import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

// import the app
import App from './app/App'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import { BrowserTracing } from '@sentry/tracing'
import {
	BrowserRouter,
	useLocation,
} from 'react-router-dom'
import 'utils/polyfills'
import './app/App.scss'
import store from './app/store'
import reportWebVitals from './reportWebVitals'

/**
 * @sentry/react init
 */
if (process.env['NODE_ENV'] !== 'development') {
	Sentry.init({
		dsn: 'https://55e50ee30f8d4904a30b6a721ddce0ff@o558011.ingest.sentry.io/6100322',
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV5Instrumentation(
					useEffect,
					// @ts-ignore
					useLocation,
				),
			}),
		],
		normalizeDepth: 10,
		release: `gummidata-webapp@${process.env['REACT_APP_VERSION']}`,
		tracesSampleRate: 0.1,
		//debug: true,
	})
}

// const SentryRoutes = Sentry.withSentryRouting(Routes)

/**
 * App
 */
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
