import {
	ActionReducerMapBuilder,
	PayloadAction,
	createAsyncThunk,
	createSlice
} from '@reduxjs/toolkit'
import {
	LoginPayload,
	fetchUser,
	loginUser,
	logoutUser
} from 'utils/api/userAPI'
import {
	statusHandler,
	thunkActions
} from 'utils/utils'

import { RootState } from 'app/store'
import { loggedOut } from 'utils/redux/commonActions'

export const sendLogin = createAsyncThunk('login/sendLogin', async (payload: LoginPayload) => await loginUser(payload))
export const checkLogin = createAsyncThunk('login/checkLogin', async () => await fetchUser())
export const sendLogout = createAsyncThunk('login/sendLogout', async () => await logoutUser())

const actions = thunkActions(
	sendLogin,
	checkLogin,
	sendLogout
)

interface State {
	status: 'idle' | 'loading' | 'succeeded' | 'failed'
	loading: boolean
	loggedIn: boolean | null
	authUsed: boolean
	loginChecked: boolean
	showLoginModal: boolean
	loginFailed: boolean
	error?: string
	resetPassword: boolean
}

const initialState: State = {
	status: 'idle',
	loading: false,
	loggedIn: null,
	authUsed: false,
	loginChecked: false,
	showLoginModal: false,
	loginFailed: false,
	resetPassword: false
}

const loginSlice = createSlice({
	name: 'login',
	initialState: initialState,
	reducers: {
		openLoginModal: (state: State) => {
			state.showLoginModal = true
		},
		closeLoginModal: (state: State) => {
			state.showLoginModal = false
			state.loginFailed = false
			state.resetPassword = false
		},
		setResetPassword: (state: State, action: PayloadAction<boolean>) => {
			state.resetPassword = action.payload
		}
	},
	extraReducers: (builder: ActionReducerMapBuilder<State>) => builder
		.addCase(sendLogin.fulfilled, (state: State) => {
			state.loggedIn = true
			state.showLoginModal = false
			state.loginFailed = false
		})
		.addCase(sendLogin.rejected, (state: State) => {
			state.loginFailed = true
		})
		.addCase(sendLogin.pending, (state: State) => {
			state.loginFailed = false
		})
		.addCase(sendLogout.fulfilled, (state: State) => {
			state.loggedIn = false
			window.location.replace('/')
		})
		.addCase(checkLogin.fulfilled, (state: State) => {
			state.loggedIn = true
			state.loginChecked = true
		})
		.addCase(checkLogin.rejected, (state: State) => {
			state.loginChecked = true
			state.loggedIn = false
		})
		.addCase(loggedOut, (state: State) => {
			state.loginChecked = true
			state.loggedIn = false
		})
		.addMatcher(actions, statusHandler)
})

export const selectLoggedIn = (state: RootState) => state.user.login.loggedIn
export const selectLoginChecked = (state: RootState) => state.user.login.loginChecked
export const selectShowLoginModal = (state: RootState) => state.user.login.showLoginModal
export const selectResetPassword = (state: RootState) => state.user.login.resetPassword
export const selectLoginStatus = (state: RootState) => state.user.login.status
export const selectLoginFailed = (state: RootState) => state.user.login.loginFailed
export const selectLoginError = (state: RootState) => state.user.login.error

export const { 
	closeLoginModal, 
	openLoginModal,
	setResetPassword
} = loginSlice.actions

export default loginSlice.reducer