import * as React from 'react'
import { SVGProps } from 'react'
const SvgCarIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		{...props}
	>
		<path
			fill="#BDBDBD"
			d="m2.925 6.243 1.5-4.5h11l1.5 4.5h-14Zm12.5 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm-11 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm12.42-10c-.2-.58-.76-1-1.42-1h-11c-.66 0-1.22.42-1.42 1l-2.08 6v8a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1h12v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-8l-2.08-6Z"
		/>
	</svg>
)
export default SvgCarIcon
