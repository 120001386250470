import './StockQuantity.scss'
import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { selectShowWarehousesOnWeb } from 'features/app/settings/settingsSlice'
import { WarehouseInfoBubble } from '../WarehouseInfoBubble/WarehouseInfoBubble'
import { useStockQuantity } from 'utils/hooks/useStockQuantity'
import AnyArticle from 'types/AnyArticle'

interface StockQuantityProps {
	article: AnyArticle
	hasWarehouseInfoBubble?: boolean
}

const StockQuantity: React.FC<StockQuantityProps> = ({
	article,
	hasWarehouseInfoBubble = false,
}) => {
	const { stockType, quantityLabel } = useStockQuantity(article)
	const showWarehousesOnWeb = useSelector(selectShowWarehousesOnWeb)

	return (
		<div className='StockQuantity'>
			<span
				className={classNames({
					[stockType]: true,
				})}
			>
				{quantityLabel}
			</span>
			{hasWarehouseInfoBubble && showWarehousesOnWeb && (
				<div className='row'>
					<div style={{ width: '0.1875rem' }} />
					<WarehouseInfoBubble
						stockQuantities={article?.stockQuantities}
						disabled={article?.stockQuantities?.length === 0}
						className={stockType}
					/>
				</div>
			)}
		</div>
	)
}

export default StockQuantity
