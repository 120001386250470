import './ResetPassword.scss'

import { IconLink } from '@barnsten-it/gummidata-react-components'
import PasswordResetForm from 'features/user/password/PasswordResetForm/PasswordResetForm'
import React from 'react'
import { mdiArrowLeft } from '@mdi/js'
import { setResetPassword } from '../loginSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

/**
 * ResetPassword
 * @param props
 */
const ResetPassword: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	return (
		<div className="ResetPassword">
			<IconLink 
				icon={mdiArrowLeft} 
				onClick={() => dispatch(setResetPassword(false))} 
				label={t('resetPassword.goBackToLogin')} 
			/>
			<div className="reset-password-container">
				<PasswordResetForm />
			</div>
		</div>
	)
}

export default ResetPassword