import { Loading } from '@barnsten-it/gummidata-react-components'
import React, { Suspense } from 'react'

export interface PageProps {
  [key: string]: any
}

export interface LayoutProps<T = any> {
  children: React.ReactNode;
  data?: PageProps | T
}

interface PageWrapperProps {
	layout: React.ComponentType<LayoutProps>
	page: React.ComponentType<PageProps>
	data?: PageProps
	disable?: boolean
}

// PageWrapper component definition
const PageWrapper: React.FC<PageWrapperProps> = ({
	layout: Layout,
	page: Page,
	data = {},
	...rest
}) => {
	return (
		<Layout data={data} {...rest}>
			<Suspense fallback={<Loading />}>
				<Page data={data} {...rest} />
			</Suspense>
		</Layout>
	)
}

export default PageWrapper
