import './PasswordResetForm.scss'

import {
	Button,
	LoadingOverlay,
	TextInput,
} from '@barnsten-it/gummidata-react-components'
import React, { useState } from 'react'
import { closeLoginModal } from 'features/user/login/loginSlice'

import InputLabel from 'components/labels/InputLabel/InputLabel'
import MainLabel from 'components/labels/MainLabel/MainLabel'
import WebText from 'components/WebText/WebText'
import customerLoginType from 'utils/enums/customerLoginTypes'
import { resetPassword } from '../passwordSlice'
import { selectCustomerLoginType } from 'features/app/settings/settingsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useWebTexts from 'utils/hooks/useWebTexts'
import { validateObject } from '@barnsten-it/object-validator'
import validationRules from './validationRules'

interface Criteria {
	customerId: number
	email: string
	languageCode: string
}

interface ErrorState {
	email?: string
	name?: string
}

const PasswordResetForm = () => {
	const { t, i18n } = useTranslation()
	const { wt } = useWebTexts('resetPassword')
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const loginType = useSelector(selectCustomerLoginType)

	const cleanCriteria: Criteria = {
		customerId: NaN,
		email: '',
		languageCode: i18n.language
	}
	
	const [criteria, setCriteria] = useState<Criteria>(cleanCriteria)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<ErrorState | null>(null)


	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name } = e.target

		var obj = {}
		if (name === 'customerId') {
			obj = {
				...criteria,
				[e.target.name]: e.target.value.replace(/[^\d]/g, '').toString()
			}
		} else {
			obj = {
				...criteria,
				[e.target.name]: e.target.value,
			}
		}

		setCriteria(obj as Criteria)
		const result = validateObject(validationRules(), obj)
		setError(result.properties)
	}

	const resetPasswordHandler = async (e: React.FormEvent) => {
		e.preventDefault()
				
		const payload = {
			...criteria,
			languageCode: i18n.language
		}
		
		setLoading(true)
		await dispatch(resetPassword(payload))
		dispatch(closeLoginModal())
		setLoading(false)
		setCriteria(cleanCriteria)
		navigate('/reset-password-success')
	}

	const haveTwoValuesSet = (obj: Criteria) => {
		var count = 0
		for (const key in obj) {
			if ((key === 'customerId' || key === 'email') && obj[key]) {
				count++
			}
		}
		return (count) >= 1 ? true : false
	}

	return (
		<div className="PasswordResetForm">
			<form onSubmit={resetPasswordHandler}>
				<MainLabel text={t('resetPassword.resetPassword')} />
				
				{wt('resetPasswordInfo').length > 0 &&
					<div className="info-text">
						<WebText namespace="resetPassword" property="resetPasswordInfo" />
					</div>
				}

				{loading && <LoadingOverlay />}
				<div>
					{(loginType === customerLoginType.email ||
						loginType === customerLoginType.customerId) && (
						<div>
							<InputLabel text={t('resetPassword.specifyEmail')} />
							<TextInput
								autocomplete={false}
								name="email"
								type="email"
								onChange={onChangeHandler}
								labelOnTop={true}
								value={criteria.email}
								error={error?.email}
								placeholder={t('common.email')}
								disabled={Boolean(criteria.customerId)}
							/>
						</div>
					)}

					{loginType === customerLoginType.customerId && 
						<div>
							<InputLabel text={t('resetPassword.specifyCustomerId')} topMargin={true} />
							<TextInput
								autocomplete={false}
								type={'number'}
								name="customerId"
								onChange={onChangeHandler}
								value={criteria.customerId.toString()}
								labelOnTop={true}
								placeholder={t('common.customerNo')}
								disabled={Boolean(criteria.email)}
							/>
						</div>
					}

					<Button
						className="pw-reset-btn"
						label={t('resetPassword.reset')} 
						disabled={!(
							(haveTwoValuesSet(criteria) && !(error?.email)) || 
							((loginType === customerLoginType.email) && !(error?.email) && criteria.email)
						)} 
					/>
				</div>
			</form>
		</div>
	)
}

export default PasswordResetForm
