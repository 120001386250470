import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { statusHandler, thunkActions } from 'utils/utils'
import { getBanners } from 'utils/api/bannersAPI'
import { RootState } from 'app/store'
import BannerTypes from 'utils/enums/BannerTypes'
import LanguageCode from 'utils/enums/languageCode'

export const fetchBanners = createAsyncThunk('banners/getBanners', async (languageCode: LanguageCode) => await getBanners(languageCode))

const actions = thunkActions(fetchBanners)

export interface BannerModal {
	id: number
	campaignId: number
	type: BannerTypes
	image: string
	link: string
	alt: string
}
	
export interface BannersState {
	data: BannerModal[] | null
}

const initialState: BannersState = {
	data: null,
}

const bannersSlice = createSlice({
	name: 'banners',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchBanners.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(fetchBanners.rejected, (state) => {
				state.data = null
			})
			.addMatcher(actions, statusHandler)
	} 		
})

export const selectBanners = (state: RootState) => state.banners.data

export default bannersSlice.reducer


