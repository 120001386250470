import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { statusHandler, thunkActions } from 'utils/utils'
import { getCartDeliveryAddresses } from 'utils/api/cartApi'
import { loggedOut } from 'utils/redux/commonActions'

export const getCartAddresses = createAsyncThunk('cartDeliveryAddresses/fetchDeliveryAddresses', async () => await getCartDeliveryAddresses())

export const actions = thunkActions(getCartAddresses)

const initialState = {
	status: 'idle',
	data: null
}

const deliveryAddressesSlice = createSlice({
	name: 'cartDeliveryAddresses',
	initialState,
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.data = null
			})
			.addCase(getCartAddresses.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addMatcher(actions, statusHandler)
	}
})

export const selectDeliveryAddressesStatus = state => state.cart.deliveryAddresses.status
export const selectDeliveryAddresses = state => state.cart.deliveryAddresses.data

export default deliveryAddressesSlice.reducer
