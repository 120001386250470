enum SettingsTypes {
	displayPriceIncludingVat = 'displayPriceIncludingVat',
	autoScrollToResult = 'autoScrollToResult',
	compactMode = 'compactMode',
	fullScreen = 'fullScreen',
	hideNetPrice = 'hideNetPrice',
	retailPrice = 'retailPrice',
	retailExclVat = 'retailExclVat',
	displayRetailMargins = 'displayRetailMargins',
	displayCalculator = 'displayCalculator',
	hideCreditInfo = 'hideCreditInfo',
	bulkAdd = 'bulkAdd',
	goDirectlyToCart = 'goDirectlyToCart',
}

export default SettingsTypes