import React from 'react'
import { useSelector } from 'react-redux'
import ContactInformationModal from 'components/ContactInformationModal/ContactInformationModal'
import { 
	selectShowContactInformationModal, 
	setShowContactInformationModal 
} from '../contactInformationSlice'


/**
 * ContactInformation
 * @param props
 */
const ContactInformation: React.FC = () => {
	const showContactFormModal = useSelector(selectShowContactInformationModal)
	
	if (showContactFormModal) {
		return (
			<div className="ContactInformation">
				<ContactInformationModal show={showContactFormModal} onClose={() => setShowContactInformationModal(false)} />
			</div>
		)
	}
	
	return null
}

export default ContactInformation