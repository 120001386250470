import './LoginModal.scss'

import React, { FC } from 'react'
import {
	closeLoginModal,
	selectResetPassword,
	selectShowLoginModal
} from '../loginSlice'
import {
	useDispatch,
	useSelector
} from 'react-redux'

import LoginSection from '../LoginSection/LoginSection'
import { Modal } from '@barnsten-it/gummidata-react-components'
import ResetPassword from '../ResetPassword/ResetPassword'
import { useTranslation } from 'react-i18next'

const LoginModal: FC = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const showLoginModal = useSelector(selectShowLoginModal)
	const resetPassword = useSelector(selectResetPassword)

	if (showLoginModal) {
		return (
			<div className="LoginModal">
				<Modal onClose={() => dispatch(closeLoginModal())} label={(!resetPassword) ? t('common.login') : ''}>	
					{!resetPassword &&
						<LoginSection />
					}			

					{resetPassword &&
						<ResetPassword />
					}	
				</Modal>
			</div>
		)
	} 
	return null
}

export default LoginModal
