import * as React from 'react'
import { SVGProps } from 'react'
const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
		<path
			fill="currentColor"
			d="M6.62 10.79c1.44 2.83 3.76 5.15 6.59 6.59l2.2-2.2c.28-.28.67-.36 1.02-.25 1.12.37 2.32.57 3.57.57a1 1 0 0 1 1 1V20a1 1 0 0 1-1 1A17 17 0 0 1 3 4a1 1 0 0 1 1-1h3.5a1 1 0 0 1 1 1c0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2Z"
		/>
	</svg>
)
export default SvgPhone
