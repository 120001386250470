import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRimsByVehicleId, getRimsBySize, getRimsByCarModel } from 'utils/api/articleSearchAPI'
import searchRimType from 'utils/enums/searchRimType'
import sliceStatus from 'utils/enums/sliceStatus'
import ViewTypes from 'utils/enums/viewTypes'
import { createResultSelector, isPreviousSearchCategory, isSearchCategory } from 'utils/helper'
import { loggedOut } from 'utils/redux/commonActions'
import { statusHandler, thunkActions } from 'utils/utils'

export const searchRims = createAsyncThunk('rimSearch/search', async (criteria) => {
	if (criteria.vehicleId) {
		return await getRimsByVehicleId(criteria.vehicleId, criteria.license, searchRimType.aluminumAndSteel, criteria.languageCode)
	} else if (criteria.carModel) {
		return await getRimsByCarModel(criteria.carModel, criteria.brandId, searchRimType.aluminumAndSteel, criteria.languageCode)
	} else {
		return await getRimsBySize(criteria.boltPattern, criteria.boltCircle, criteria.diameter, null, criteria.articleText, searchRimType.aluminumAndSteel, criteria.languageCode)
	}
})
const searchRimActions = thunkActions(searchRims)

const initialState = {
	status: 'idle',
	result: null,
	filterIds: null,
	filterState: null,
	showCombination: false,
	viewType: ViewTypes.grid,
	sortBy: null,
	ascending: null,
	criteria: {
		bolts: '',
		circle: '',
		diameter: '',
		license: '',
		brandId: '',
		modelId: '',
		vehicleId: null,
		articleText: '',
		carModel: null
	}
}

const carRimSearch = createSlice({
	name: 'carRimSearch',
	initialState: initialState,
	reducers: {
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		setShowCombination: (state, action) => {
			state.showCombination = action.payload
		},
		reset: () => initialState,
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		},
		setCriteria: (state, action) => {
			state.criteria = {
				...state.criteria,
				...action.payload
			}
		},
		setRimViewType: (state, action) => {
			state.viewType = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.result = null
				state.filterIds = null
			})
			.addCase(searchRims.pending, (state, action) => {
				state.result = null
				state.filterIds = null
				state.filterState = null
				state.sortBy = null
				state.ascending = null
			})
			.addCase(searchRims.fulfilled, (state, action) => {
				state.filterIds = null
				state.result = action.payload
			})
			.addMatcher(isPreviousSearchCategory('car', 'rim'), (state) => {
				state.status = sliceStatus.idle
			})
			.addMatcher(isSearchCategory('car', 'rim'), (state, action) => {
				state.status = sliceStatus.success
				state.result = action.payload
			})
			.addMatcher(searchRimActions, statusHandler)
	}
})

export const selectStatus = state => state.search.rim.carRim.status
export const selectResult = state => state.search.rim.carRim.result
export const selectFilterIds = state => state.search.rim.carRim.filterIds
export const selectFilterState = state => state.search.rim.carRim.filterState
export const selectShowCombination = state => state.search.rim.carRim.showCombination
export const selectSortBy = state => state.search.rim.carRim.sortBy
export const selectAscending = state => state.search.rim.carRim.ascending
export const selectCriteria = state => state.search.rim.carRim.criteria
export const selectRimViewType = state => state.search.rim.carRim.viewType
export const selectBrandId = state => state.search.rim.carRim.result?.carInfo?.brandId
export const selectCarModel = state => state.search.rim.carRim.result?.carInfo?.carModel

export const selectProcessedResult = createResultSelector(selectResult, selectFilterIds, selectSortBy, selectAscending, selectShowCombination)

export const { setFilter, setShowCombination, sortResult, reset, setCriteria, setRimViewType } = carRimSearch.actions

export default carRimSearch.reducer
