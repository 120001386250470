enum articleSearchType {
	all = 'all',
	tyre = 'tyre',
	mcTyre = 'mcTyre',
	atvTyre = 'atvTyre',
	rim = 'rim',
	aluminiumRim = 'aluminiumRim',
	steelRim = 'steelRim',
	wheelPackage = 'wheelPackage',
	accessories = 'accessories',
	tpms = 'tpms',
	battery = 'battery',
	oil	= 'oil',
	tubes = 'tubes',
	tools = 'tools',
	mounting = 'mounting',
	other = 'other',
} 

export default articleSearchType
