import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { statusHandler, thunkActions } from 'utils/utils'
import { getWebDownloads } from 'utils/api/downloadAPI'

export const fetchDownloads = createAsyncThunk('downloads/getDownloads', async () => await getWebDownloads())
const actions = thunkActions(fetchDownloads)

const downloadsSlice = createSlice({
	name: 'downloads',
	initialState: {
		status: 'idle',
		data: null,
	},
	extraReducers: builder => {
		builder
			.addCase(fetchDownloads.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(fetchDownloads.rejected, (state) => {
				state.data = null
			})
			.addMatcher(actions, statusHandler)
	} 		
})

export const selectDownloads = state => state.downloads.data

export default downloadsSlice.reducer


