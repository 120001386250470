import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getDueInvoices } from 'utils/api/userAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchDueInvoices = createAsyncThunk('dueinvoices/fetchDueInvoices', async () => {
	const dueInvoices = await getDueInvoices()
	return {
		dueInvoices,
		showWindow: dueInvoices && dueInvoices.length > 0
	}
})
export const cartActions = thunkActions(fetchDueInvoices)

const userInfoSlice = createSlice({
	name: 'dueinvoices',
	initialState: {
		status: 'idle',
		showWindow: false,
		data: {}
	},
	reducers: {
		toggleWindow: (state) => {
			state.showWindow = !state.showWindow
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchDueInvoices.fulfilled, (state, action) => {
				state.data = action.payload.dueInvoices
				state.showWindow = action.payload.showWindow
			})
			.addMatcher(cartActions, statusHandler)
	}
})

export const selectDueInvoices = state => state.user.dueInvoices.data
export const selectShowDueInvoices = state => state.user.dueInvoices.showWindow

export const { toggleWindow } = userInfoSlice.actions


export default userInfoSlice.reducer