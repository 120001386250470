import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import sliceStateBases, { SliceState } from 'utils/enums/sliceBaseStates'
import { RootState } from 'app/store'
import apiClient from 'utils/api/apiClient'

export interface CustomerStockModel {
	id: number
	articleId: number
	articleText: string
	targetQuantity: number
	currentQuantity: number
}

export interface CustomerStockEditListModel {
	list: CustomerStockEditModel[]
}

export interface CustomerStockEditModel {
	id: number
	currentQuantity: number
}

export interface CustomerStockCriteria {
	search: string
}

export const fetchCustomerStocks = createAsyncThunk('customerStock/getCustomerStock', async (criteria: CustomerStockCriteria) => await apiClient.get('/api/CustomerStock', criteria))
export const updateCustomerStocks = createAsyncThunk('customerStock/updateCustomerStock', async (editListModel: CustomerStockEditListModel) => await apiClient.put('/api/customerStock', editListModel))

export interface CustomerStockState {
	customerStockData: CustomerStockModel[] | null
	criteria: CustomerStockCriteria
	editList: CustomerStockEditModel[]
}

const initialCriteria = {
	search: '',
}

const initialCustomerStockState: SliceState<'FEATURE', CustomerStockState> = {
	...sliceStateBases.FEATURE,
	customerStockData: null,
	criteria: initialCriteria,
	editList: [],
}

const customerStockSlice = createSlice({
	name: 'customerStock',
	initialState: initialCustomerStockState,
	reducers: {
		setCustomerStockCriteria: (state: CustomerStockState, action: PayloadAction<{name: string, value: any}>) => {
			state.criteria = {
				...state.criteria,
				[action.payload.name]: action.payload.value,
			}
		},
		resetCustomerStockCriteria: (state: CustomerStockState) => {
			state.criteria = initialCriteria
		},
		addToEditList: (state: CustomerStockState, action: PayloadAction<CustomerStockEditModel>) => {
			const item = action.payload
			const index = state.editList.findIndex(i => i.id === item.id)
			if (index !== -1) {
				state.editList[index] = item
			} else {
				state.editList.push(item)
			}
		},
		removeFromEditList: (state: CustomerStockState, action: PayloadAction<{ id: number }>) => {
			state.editList = state.editList.filter(item => item.id !== action.payload.id)
		},
		resetEditList: (state: CustomerStockState) => {
			state.editList = []
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCustomerStocks.pending, (state) => {
				state.customerStockData = null
				state.editList = []
			})
			.addCase(fetchCustomerStocks.fulfilled, (state, action) => {
				state.customerStockData = action.payload
			})
			.addCase(fetchCustomerStocks.rejected, (state) => {
				state.customerStockData = null
			})
			.addCase(updateCustomerStocks.fulfilled, (state, action) => {
				state.editList = []
			})
	},
})

export const { 
	setCustomerStockCriteria, 
	resetCustomerStockCriteria, 
	addToEditList, 
	removeFromEditList,
	resetEditList
} = customerStockSlice.actions

// Selectors
export const selectCustomerStockData = (state: RootState) => state.customerStock.customerStockData
export const selectCustomerStockCriteria = (state: RootState) => state.customerStock.criteria
export const selectEditList = (state: RootState) => state.customerStock.editList

export default customerStockSlice.reducer
