import apiClient from './apiClient'

/**
 * Fetch webUsers
 * @returns custmoer model
 */
export const getUsers = async () => await apiClient.get('/api/WebUsers')


/**
 * update webUser
 * @returns custmoer model
 */
export const putUser = async (payload) => await apiClient.put('/api/WebUser/' + payload.id, payload.criteria)


/**
 * delete webUser
 * @returns custmoer model
 */
export const deleteUser = async (payload) => await apiClient.delete('/api/WebUser/' + payload)

/**
 * update webUser
 * @returns custmoer model
 */
export const postUser = async (payload) => await apiClient.post('/api/WebUser/', payload)

/**
 * Returns if is multi user account or is email is available
 * @returns MultiUserAccountModel
 */
export const getMultiUserAccount = async (email) => await apiClient.get('/api/MultiUserAccount/?email=' + email)