import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { statusHandler, thunkActions } from 'utils/utils'
import { getNewsPosts } from 'utils/api/newsAPI'

export const fetchNews = createAsyncThunk('news/getNews', async (payload) => await getNewsPosts(payload))
const actions = thunkActions(fetchNews)

const newsSlice = createSlice({
	name: 'news',
	initialState: {
		status: 'idle',
		data: null,
	},
	extraReducers: builder => {
		builder
			.addCase(fetchNews.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(fetchNews.rejected, (state) => {
				state.data = null
			})
			.addMatcher(actions, statusHandler)
	} 		
})

export const selectNews = state => state.news.data

export default newsSlice.reducer


