import { combineReducers } from 'redux'

import motoTubesSearch from './MotoTubesSearch/motoTubesSearchSlice'
import industryTubesSearch from './IndustryTubesSearch/industryTubesSearchSlice'
import carTubesSearch from './CarTubesSearch/carTubesSearchSlice'

export default combineReducers({
	motoTubesSearch,
	industryTubesSearch,
	carTubesSearch
})