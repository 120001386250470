import React from 'react'
import MobileMenuItem from './MobileMenuItem/MobileMenuItem'
import { MenuItem } from 'utils/hooks/useMenu'
import classNames from 'classnames'

interface MobileMenuItemsProps { 
	menu: MenuItem[]
	onStepChange: (step: number) => void
	location: any
	step: number
}

const MobileMenuItems = ({ 
	menu, 
	onStepChange, 
	location, 
	step,  
}: MobileMenuItemsProps) => {
	const [clickedItem, setCLickedItem] = React.useState(null)


	return (
		<div className="MobileMenuItems">
			{menu.map((item: MenuItem) => {
				if (item.active && !item.hideMobileMenu) {

					if(item.externalLink) {
						return (
							<a key={item.name + item.to} href={item.to} className={classNames('menu-item')} target={'_blank'} rel="noreferrer">{item.name}</a>
						)
					}

					return (
						<div className="item-wrapper" key={item.id}>
							<MobileMenuItem
								to={item.to}
								name={item.name as string}
								active={(location.pathname === item.to) || (item.toBase && location.pathname.startsWith(item.toBase))}
								onClick={onStepChange}
								subItems={item.subItems}
								step={step + 1}
								setClickedItem={setCLickedItem}
							/>

							{item.subItems && clickedItem === item.to && (
								<div className="sub-wrapper">
									{item.subItems.filter(s => s.active).map((subItem, index) =>{
										if (!subItem.hideMobileMenu) {
											return (
												<MobileMenuItem
													key={index}
													to={subItem.to}
													name={subItem.name as string}
													active={location.pathname === subItem.to}
													leadsToExternalLink={Boolean(subItem.externalLink)}
												/>
											)
										} else {
											return null
										}
									}
									)}
								</div>
							)}
						</div>
					)
				}
				return null
			})}
		</div>
	)
}

export default MobileMenuItems