import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAccessoryTypes } from 'utils/api/articleSearchAPI'
import { statusHandler, thunkActions } from 'utils/utils'
import { loggedOut } from 'utils/redux/commonActions'
import mainGroups from 'utils/enums/MainGroups'

export const getTypes = createAsyncThunk('industryAccessoryTypes/getTypes', async () => await getAccessoryTypes(mainGroups.accessoriesIndustry))

const searchAccessoryTypesAction = thunkActions(getTypes)

const industryAccessoryTypes = createSlice({
	name: 'industryAccessoryTypes',
	initialState: {
		status: 'idle',
		result: [],
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.result = []
			})
			.addCase(getTypes.pending, state => {
				state.result = []
			})
			.addCase(getTypes.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(searchAccessoryTypesAction, statusHandler)
	}
})

export const selectStatus = state => state.search.accessory.industryAccessoryTypes.status
export const selectResult = state => state.search.accessory.industryAccessoryTypes.result

export default industryAccessoryTypes.reducer