import { combineReducers } from 'redux'

import login from './login/loginSlice'
import password from './password/passwordSlice'
import userInfo from './userInfo/userInfoSlice'
import orderHistory from './myPages/orderHistory/orderHistorySlice'
import invoiceHistory from './myPages/invoiceHistory/invoiceHistorySlice'
import newInvoices from './myPages/newInvoices/newInvoicesSlice'
import quotationHistory from './myPages/quotationHistory/quotationHistorySlice'
import retailMarkUps from './myPages/retailMarkUps/retailMarkUpsSlice'
import users from './myPages/users/usersSlice'
import creditInfo from './CreditInfo/CreditInfoSlice'
import deliveryAddresses from './myPages/deliveryAddresses/deliveryAddressesSlice'
import dueInvoices from './dueInvoices/dueInvoicesSlices'
import orderReservations from './myPages/orderReservations/orderReservationsSlice'
import notifications from './notifications/notificationsSlice'
import multiUserLinks from './multiUserLinks/multiUserLinksSlice'

export default combineReducers({
	login,
	password,
	userInfo,
	orderHistory,
	orderReservations,
	invoiceHistory,
	quotationHistory,
	deliveryAddresses,
	users,
	retailMarkUps,
	creditInfo,
	dueInvoices,
	newInvoices,
	notifications,
	multiUserLinks
})