const rules = () => ({
	name: {
		required: true
	},
	companyId: {
		required: true
	},
	contactPerson: {
		required: true
	},
	address: {
		required: true
	},
	zipCode: {
		required: true
	},
	city: {
		required: true
	},
	country: {
		required: true
	},
	telephone: {
		required: true
	},
	email: {
		required: true,
		email: true
	},
	emailInvoice: {
		email: true
	},
	hasTaxNote: {
		required: true,
		checkbox: true
	}
})

export default rules