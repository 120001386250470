import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { fetchCredits } from 'utils/api/creditInfoAPI'
import sliceStateBases, { SliceState } from 'utils/enums/sliceBaseStates'
import { statusHandler, thunkActions } from 'utils/utils'

export const getCredits = createAsyncThunk('userInfo/fetchCredits', async () => await fetchCredits())

export const cartActions = thunkActions(getCredits)

interface CreditInfoData {
	creditLimit: number
  availableCredit: number
	amountDue: number
	reservedAmount: number
	overdueInvoiceAmount: number
}

export interface CreditInfoState {
  data: CreditInfoData | null
  error: string | null
}

const initialState: SliceState<'FEATURE', CreditInfoState> = {
	...sliceStateBases.FEATURE,
	data: null,
	error: null
}

const userInfoSlice = createSlice({
	name: 'creditInfoSlice',
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getCredits.pending, (state) => {
				state.data = null
				state.error = null
			})
			.addCase(getCredits.fulfilled, (state, action: PayloadAction<CreditInfoData>) => {
				state.data = action.payload
				state.error = null
			})
			.addCase(getCredits.rejected, (state, action) => {
				state.data = null
				state.error = action.error.message ?? 'Unknown error'
			})
			.addMatcher(cartActions, statusHandler)
	}
})

export const selectCreditInfo = (state: RootState) => state.user.creditInfo.data
export const selectAvailableCredit = (state: RootState) => state.user.creditInfo.data?.availableCredit

export default userInfoSlice.reducer