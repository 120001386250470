import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearCategory, selectCategory, selectVehicleType } from '../checkCategorySlice'
import { selectLoggedIn } from 'features/user/login/loginSlice'

const SearchCategoryHandler = () => {
	const navigate = useNavigate()
	const category = useSelector(selectCategory)
	const vehicleType = useSelector(selectVehicleType)
	const loggedIn = useSelector(selectLoggedIn)
	const dispatch = useDispatch()

	useEffect(() => {
		if (category && loggedIn) {
			dispatch(clearCategory())
			navigate(`/shop/${vehicleType}/${category}`)
		}
	}, [category, vehicleType, loggedIn, dispatch, navigate])

	return null
}

export default SearchCategoryHandler
