enum HttpStatusCodes {
	Ok = 200,
	NotModified = 304,
	BadRequest = 400,
	Unauthorized = 401,
	Forbidden = 403,
	NotFound = 404,
	NotAcceptable = 406,
	Conflict = 409,
	Gone = 410,
	UnsupportedMediaType = 415,
	InternalServerError = 500,
	NotImplemented = 501,
	BadGateway = 502,
}

export default HttpStatusCodes