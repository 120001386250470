import './QuickSettings.scss'
import React from 'react'
import RetailPriceSwitch from 'features/user/retailPrice/RetailPriceSwitch/RetailPriceSwitch'
import { useTranslation } from 'react-i18next'
import { selectFeatureConfig, selectResellerConfig, selectSiteConfig } from 'features/app/settings/settingsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CMSPreviewSwitch from 'features/cms/cmsInfo/CMSPreviewSwitch/CMSPreviewSwitch'
import { Button, Modal } from '@barnsten-it/gummidata-react-components'
import { useUserRoles } from 'utils/hooks/useUserRoles'
import Icon from '@mdi/react'
import { mdiCog } from '@mdi/js'
import { MainLabel } from 'components/labels/MainLabel/MainLabel'
import SettingSwitch from 'components/SettingSwitch/SettingSwitch'
import LayoutControls from 'components/ListViewControls/LayoutControls/LayoutControls'

import { 
	setDisplayCalculator, 
	selectDisplayCalculator, 
	selectCompactMode, 
	selectDisplayPriceIncludingVat, 
	setDisplayPriceIncludingVat,
	setHideCreditInfo,
	selectHideCreditInfo,
	setCompactMode,
	selectHideNetPrice,
	setHideNetPrice,
	setRetailPrice,
	selectAutoScrollToResult,
	setAutoScrollToResult,
	selectDisplayRetailMargins,
	setDisplayRetailMargins,
	setBulkAdd,
	selectBulkAdd,
	selectGoDirectlyToCart,
	setGoDirectlyToCart,
} from 'features/search/searchSettings/searchSettingsSlice'

import SettingsTypes from 'utils/enums/settingsTypes'
import ScrollBox from 'components/ScrollBox/ScrollBox'
import { useSettings } from 'utils/hooks/useSettings'
import { selectHighResolution } from 'features/app/documentInfo/documentInfoSlice'


const QuickSettings = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	
	const [openSettings, setOpenSettings] = React.useState(false)

	const { 
		[SettingsTypes.displayCalculator]: displayCalculator,
		[SettingsTypes.displayPriceIncludingVat]: displayPriceIncludingVat,
		[SettingsTypes.autoScrollToResult]: autoScrollToResult,
		[SettingsTypes.compactMode]: compactMode,
		[SettingsTypes.hideNetPrice]: hideNetPrice,
		[SettingsTypes.retailPrice]: retailPrice,
		[SettingsTypes.retailExclVat]: retailExclVat,
		[SettingsTypes.goDirectlyToCart]: goDirectlyToCart,
	} = useSettings()

	const siteConfig = useSelector(selectSiteConfig)
	const resellerConfig = useSelector(selectResellerConfig)
	const featureConfig = useSelector(selectFeatureConfig)
	const highResolution = useSelector(selectHighResolution)

	const {
		hasCMSEdit,
		hasCustomerAdminRole,
		hasNetPricesRole,
		hasUsedAuthRole
	} = useUserRoles()

	const openSettingsHandler = () => {
		setOpenSettings(true)
	}

	const closeSettingsHandler = () => {
		setOpenSettings(false)

		// Send settings to Google Analytics
		window.gtag('event', 'page_settings', {
			calculator: displayCalculator,
			price_including_vat: displayPriceIncludingVat,
			auto_scroll_to_result: autoScrollToResult,
			compact_mode: compactMode,
			hide_net_price: hideNetPrice,
			retail_price: retailPrice,
			retail_excl_vat: retailExclVat,
			go_directly_to_cart: goDirectlyToCart,
		})
	}

	const goToCmsHandler = () => {
		navigate('/cms')
		closeSettingsHandler()
	}

	if (!(hasCustomerAdminRole || hasNetPricesRole)) {
		return null
	}

	// Callbacks
	const displayCalculatorCallback = (value: boolean) => {
		if (value && retailPrice) {
			dispatch(setRetailPrice(false))
		}
	}

	return (
		<div className="QuickSettings">
			<button className="icon-wrapper"
				title={t('settings.quickSettings')}
				onClick={openSettingsHandler}
			>
				<Icon path={mdiCog} size="1.5rem" />
			</button>

			{openSettings && 
				<Modal 
					onClose={closeSettingsHandler} 
					label={t('settings.quickSettings')}
					description={t('settings.quickSettingsDescription')}
				>
					<ScrollBox>

						<MainLabel text={t('settings.generalSettings')}/>
						<SettingSwitch
							label={t('settings.hideCreditInfo')}
							description={t('settings.hideCreditInfoDescription')}
							settingName={SettingsTypes.hideCreditInfo}
							selector={selectHideCreditInfo}
							condition={siteConfig?.calculator}
							action={setHideCreditInfo}
						/>
						<SettingSwitch
							label={t('settings.bulkAdd')}
							description={t('settings.bulkAddDescription')}
							settingName={SettingsTypes.bulkAdd}
							selector={selectBulkAdd}
							action={setBulkAdd}
						/>
						<SettingSwitch
							label={t('settings.goDirectToCart')}
							description={t('settings.goDirectToCartDescription')}
							settingName={SettingsTypes.goDirectlyToCart}
							selector={selectGoDirectlyToCart}
							action={setGoDirectlyToCart}
						/>

						{highResolution && (
							<>
								<MainLabel text={t('common.layout')} />
								<div className="layout-wrapper">
									<LayoutControls standalone />
								</div>
							</>
						)}

						<MainLabel text={t('common.shop')} />
						<SettingSwitch
							label={t('settings.displayCalculator')}
							description={t('settings.displayCalculatorDescription')}
							settingName={SettingsTypes.displayCalculator}
							condition={siteConfig?.calculator}
							selector={selectDisplayCalculator}
							callback={displayCalculatorCallback}
							action={setDisplayCalculator}
						/>
						<SettingSwitch
							label={t('settings.autoScrollToResult')}
							description={t('settings.autoScrollToResultDescription')}
							settingName={SettingsTypes.autoScrollToResult}
							selector={selectAutoScrollToResult}
							action={setAutoScrollToResult}
						/>
						<SettingSwitch
							label={t('settings.compactMode')}
							description={t('settings.compactModeDescription')}
							settingName={SettingsTypes.compactMode}
							selector={selectCompactMode}
							action={setCompactMode}
						/>
						
						<MainLabel text={t('common.price')} />
						<SettingSwitch
							label={t('settings.hideNetPriceColumn')}
							description={t('settings.hideNetPriceColumnDescription')}
							settingName={SettingsTypes.hideNetPrice}
							condition={featureConfig?.showRecommendedPrice}
							selector={selectHideNetPrice}
							action={setHideNetPrice}
							local={false} // Not sure if it should be localStorage or not. It was not before.
						/>
						<SettingSwitch
							label={t('settings.displayPriceIncludingVat')}
							description={t('settings.displayPriceIncludingVatDescription')}
							settingName={SettingsTypes.displayPriceIncludingVat}
							condition={(featureConfig?.showPriceIncludingVatDefault || siteConfig?.priceIncludingVat)}
							disabled={retailPrice}
							selector={selectDisplayPriceIncludingVat}
							action={setDisplayPriceIncludingVat}
						/>

						<RetailPriceSwitch showMessage={false} description />

						<SettingSwitch
							label={t('settings.displayRetailMargins')}
							description={t('settings.displayRetailMarginsDescription')}
							settingName={SettingsTypes.displayRetailMargins}
							condition={hasUsedAuthRole && retailPrice && siteConfig?.authFeatures}
							selector={selectDisplayRetailMargins}
							action={setDisplayRetailMargins}
						/>

						{((hasCMSEdit) || (resellerConfig && hasCustomerAdminRole)) && (
							<div className="CMS-settings">
								<MainLabel text={t('settings.contentManagementSystem')} />
								<div className='settings-wrapper cms'>
									<Button
										label={t('settings.manageContent')}
										onClick={goToCmsHandler}
									/>
									<CMSPreviewSwitch />
								</div>
							</div>
						)}
					</ScrollBox>
				</Modal>
			}
		</div>
	)
}

export default QuickSettings
