import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getTyresByProperties } from 'utils/api/articleSearchAPI'
import { createResultSelector } from 'utils/helper'
import { statusHandler, thunkActions } from 'utils/utils'

export const searchTyres = createAsyncThunk('tinyTyreSearch/search', async (criteria) => await getTyresByProperties(
	criteria.width,
	criteria.aspectRatio,
	criteria.diameter,
	criteria.secondaryWidth,
	criteria.secondaryAspectRatio,
	criteria.secondaryDiameter,
	criteria.articleText,
	null,
	null,
	null,
	null,
	criteria.tyreType,
	criteria.languageCode
))

const searchAtvTyresActions = thunkActions(searchTyres)

const defaultCriteria = {
	tyreType: '96',
	width: '',
	aspectRatio: '',
	diameter: '',
	secondaryWidth: '',
	secondaryAspectRatio: '',
	secondaryDiameter: '',
	articleText: ''
}

const tinyTyreSearch = createSlice({
	name: 'tinyTyre',
	initialState: {
		status: 'idle',
		result: null,
		sortBy: null,
		ascending: null,
		criteria: defaultCriteria,
		filterIds: null,
	},
	reducers: {
		setCriteria: (state, action) => {
			state.criteria[action.payload.name] = action.payload.value
		},
		clearCriteria: (state) => {
			state.criteria = defaultCriteria
		},
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		},
	},
	extraReducers: builder => {
		builder
			.addCase(searchTyres.pending, state => {
				state.filterIds = null
				state.filterState = null
				state.result = null
				state.sortBy = null
				state.ascending = null
			})
			.addCase(searchTyres.fulfilled, (state, action) => {
				state.result = action.payload
			})			
			.addMatcher(searchAtvTyresActions, statusHandler)
	}
})

export const selectCritiera = state => state.search.tyre.tinyTyre.criteria
export const selectStatus = state => state.search.tyre.tinyTyre.status
export const selectResult = state => state.search.tyre.tinyTyre.result
export const selectFilterIds = state => state.search.tyre.tinyTyre.filterIds
export const selectFilterState = state => state.search.tyre.tinyTyre.filterState
export const selectSortBy = state => state.search.tyre.tinyTyre.sortBy
export const selectAscending = state => state.search.tyre.tinyTyre.ascending

export const selectProcessedResult = createResultSelector(
	selectResult, 
	selectFilterIds, 
	selectSortBy, 
	selectAscending
)

export const { 
	setCriteria, 
	clearCriteria, 
	sortResult, 
	setFilter 
} = tinyTyreSearch.actions

export default tinyTyreSearch.reducer