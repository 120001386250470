import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAccessoryTypes } from 'utils/api/articleSearchAPI'
import { statusHandler, thunkActions } from 'utils/utils'
import { loggedOut } from 'utils/redux/commonActions'
import sliceStatus, { SliceStatus } from 'utils/enums/sliceStatus'
import { RootState } from 'app/store'

export const getTypes = createAsyncThunk('truckAccessoryTypes/getTypes', async (mainGroupId: number) =>
	await getAccessoryTypes(mainGroupId))

const searchAccessoryTypesAction = thunkActions(getTypes)

interface TruckAccessoryTypesState {
	status: SliceStatus
	result: object | null,
}

const initialState: TruckAccessoryTypesState = {
	status: sliceStatus.idle,
	result: [],
}

const truckAccessoryTypes = createSlice({
	name: 'truckAccessoryTypes',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.result = []
			})
			.addCase(getTypes.pending, state => {
				state.result = []
			})
			.addCase(getTypes.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(searchAccessoryTypesAction, statusHandler)
	}
})

export const selectStatus = (state: RootState) => state.search.accessory.truckAccessoryTypes.status
export const selectResult = (state: RootState) => state.search.accessory.truckAccessoryTypes.result

export default truckAccessoryTypes.reducer