import { TicketGroupedArticlesInitiateResponse } from 'types/INewTicketGroupedArticles'
import apiClient from './apiClient'
import TicketTypes from 'utils/enums/ticketTypes'
import LanguageCode from 'utils/enums/languageCode'

export interface getGroupedArticlesInitialProps {
	criteria: GroupedArticlesRowsCriteria, 
	ticketType: TicketTypes.Realization | TicketTypes.Preseason
}

/**
 * Get ticket list.
 */
export const getGroupedArticlesInitial = async (
	payload: GroupedArticlesRowsCriteria,
	ticketType: TicketTypes.Realization | TicketTypes.Preseason
): Promise<TicketGroupedArticlesInitiateResponse> => await apiClient.get('/api/TicketGroupedArticles/', {...payload, ticketType})


export const getGroupedArticlesCheck = async (
	payload: GroupedArticlesCheckPayload,
): Promise<TicketGroupedArticlesInitiateResponse> => await apiClient.post('/api/TicketGroupedArticlesCheck/', payload)

export interface GroupedArticlesCheckPayload {
	languageCode: LanguageCode
	ticketType: TicketTypes.Realization | TicketTypes.Preseason
	rows: {
		orderRowId: number
		quantity: number
	}[]
}

export interface GroupedArticlesRowsCriteria {
	search: string | ''
	width: number | null
	aspectRatio: number | null
	diameter: number | null
	fromDate: string | null
	toDate: string | null
	deliveryAddressId: number | ''
}
