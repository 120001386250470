import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { fetchMainGroups } from 'utils/api/mainGroupAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const getMainGroups = createAsyncThunk('mainGroups/getMainGroups', async (languageCode = 'SV') => await fetchMainGroups(languageCode))

const getMainGroupsActions = thunkActions(getMainGroups)

const mainGroupsSlice = createSlice({
	name: 'mainGroups',
	initialState: {
		status: 'idle',
		items: null
	},
	extraReducers: builder => {
		builder
			.addCase(getMainGroups.fulfilled, (state, action) => {
				state.items = action.payload
			})
			.addMatcher(getMainGroupsActions, statusHandler)
	}
})

export const selectMainGroupIdsStatus = state => state.mainGroups.status
export const selectMainGroupIds = state => state.mainGroups.items
export const selectDefaultQuantity = (mainGroupId) => createSelector(selectMainGroupIds, mainGroups => {
	if(!mainGroups) return null

	return mainGroups.find(m => m.id === mainGroupId)?.defaultQuantity
})

export default mainGroupsSlice.reducer
