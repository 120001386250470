import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SubCustomerOrder, SubCustomerOrdersResponse } from 'types/Responses'
import { OrderSearchModel } from 'types/api'
import { deleteResellerOrder, getResellerOrders, putResellerOrder } from 'utils/api/resellerOrdersAPI'
import sliceStateBases, { SliceState } from 'utils/enums/sliceStateBases'
import { statusHandler, thunkActions } from 'utils/utils'
export interface OrderHistoryCriteria extends OrderSearchModel {
	isDirty: boolean
	doSearch: boolean
}

export const getSubCustomerOrders = createAsyncThunk(
	'subCustomers/getSubCustomerOrders',
	async (criteria: OrderHistoryCriteria) => await getResellerOrders(criteria),
)
export const deleteSubCustomerOrder = createAsyncThunk(
	'subCustomers/deleteSubCustomerOrder',
	async (id) => await deleteResellerOrder(id),
)
export const putSubCustomerOrder = createAsyncThunk(
	'subCustomers/putSubCustomerOrder',
	async (id) => await putResellerOrder(id),
)

const subCustomerOrdersActions = thunkActions(
	getSubCustomerOrders,
	deleteSubCustomerOrder,
	putSubCustomerOrder,
)

export const initialOrdersCriteria: OrderHistoryCriteria = {
	take: 30,
	articleText: '',
	referenceText: '',
	fromDate: '',
	toDate: '',
	skip: 0,
	status: '',
	type: 'all',
	orderBy: 'deliveryDate',
	orderDirection: 1,
	isDirty: false,
	doSearch: true,
} as const

export interface SubCustomerOrdersState {
	criteria: OrderHistoryCriteria
	subCustomerOrders: SubCustomerOrder[] | null
	subCustomerOrderAmount: number | null
}

export const initialSubCustomerOrdersState: SliceState<'FEATURE', SubCustomerOrdersState> = {
	...sliceStateBases.FEATURE,
	criteria: initialOrdersCriteria,
	subCustomerOrders: null,
	subCustomerOrderAmount: null,
}

const subCustomerOrdersSlice = createSlice({
	name: 'subCustomerOrders',
	initialState: initialSubCustomerOrdersState,
	reducers: {
		setOrdersCriteria(
			state,
			action: PayloadAction<Partial<OrderHistoryCriteria>>,
		) {
			state.criteria = {
				...state.criteria,
				...action.payload,
			}
		},
		clearOrdersCriteria(state) {
			state.criteria = initialOrdersCriteria
		},
		increaseOrdersSkip(
			state,
			action: PayloadAction<number>,
		) {
			if (state.subCustomerOrders === null) return
			if (state.subCustomerOrderAmount === null) return
			let newValue = state.criteria.skip + action.payload
			if (newValue < state.subCustomerOrderAmount) state.criteria.skip = newValue
		},
		decreaseOrdersSkip(
			state,
			action: PayloadAction<number>,
		) {
			let newValue = state.criteria.skip - action.payload
			if (newValue >= 0) state.criteria.skip = newValue
		},
		orderOrdersBy(state, action) {
			const propertyName = action.payload

			if (state.criteria.orderBy === propertyName) {
				// Flip direction
				state.criteria.orderDirection = state.criteria.orderDirection === 1
					? 0
					: 1
			} else {
				state.criteria.orderBy = propertyName
				state.criteria.orderDirection = initialOrdersCriteria.orderDirection
			}
		},
	},
	extraReducers: builder => builder
		.addCase(getSubCustomerOrders.pending, state => {
			state.subCustomerOrders = null
			state.subCustomerOrderAmount = null
		})
		.addCase(getSubCustomerOrders.fulfilled, (
			state,
			action: PayloadAction<SubCustomerOrdersResponse>,
		) => {
			state.subCustomerOrders = action.payload.result
			state.subCustomerOrderAmount = action.payload.count
		})
		.addMatcher(subCustomerOrdersActions, statusHandler)
})

export default subCustomerOrdersSlice.reducer

export const {
	setOrdersCriteria,
	clearOrdersCriteria,
	increaseOrdersSkip,
	decreaseOrdersSkip,
	orderOrdersBy,
} = subCustomerOrdersSlice.actions

export const selectSubCustomerOrders = (state: any) => state.reseller.subCustomerOrders
export const selectSubCustomerOrdersData = (state: any) => state.reseller.subCustomerOrders.subCustomerOrders
