import Filter from '@barnsten-it/gummidata-react-components/dist/Filter/Filter'

export const filterSettings = {
	brandName: {
		property: 'brandName',
		type: Filter.type.multipleSelect,
		enableLimit: true,
		// These are configured through `t` from `useTranslation` inside of `FilterBase`
		name: '',
		showMoreText: '',
	},
	modelName: {
		property: 'modelName',
		type: Filter.type.multipleSelect,
		enableLimit: true,
		hideDisabled: true,
		name: '',
		showMoreText: '',
	},
}

export const filterablePropertyNames = {
	'brandName': 'brandName',
	'modelName': 'modelName',
}
