import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchKitsets } from 'utils/api/kitsetAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const getKitsets = createAsyncThunk('kitset/getKitsets', async (payload) => await fetchKitsets(payload))
const getKitsetsActions = thunkActions(getKitsets)

const kitset = createSlice({
	name: 'kitset',
	initialState: {
		status: 'idle',
		kitsets: null,
	},
	reducers: {
		resetKitsets: (state) => {
			state.status = 'idle'
			state.kitsets = null
		} 
	},
	extraReducers: builder => {
		builder
			.addCase(getKitsets.fulfilled, (state, action) => {
				state.kitsets = action.payload
			})
			.addCase(getKitsets.rejected, (state) => {
				state.status = 'idle'
				state.kitsets = null
			})
			.addMatcher(getKitsetsActions, statusHandler)
	} 		
})

export const selectKitsets = state => state.kitset.kitsets

export default kitset.reducer


