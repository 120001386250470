import { addNotification } from 'features/notifications/notificationSlice'
import { internalServerError, serviceUnavailableError, loggedOut } from './commonActions'

const middleWare = ({
	dispatch
}) => next => action => {
	const {
		error,
		meta
	} = action

	if (meta?.requestStatus === 'rejected') {
		
		//Unauthorized error
		if (error?.message === '401') {
			dispatch(loggedOut())
		}

		// Internal server error
		if (error?.message === '500') {
			dispatch(addNotification({ type: 'error', text: 'Internal server error 500'}))
			dispatch(internalServerError())
		}

		// Service unavailable
		if (error?.message === '503') {
			dispatch(addNotification({ type: 'error', text: 'Serviceu navailable 503'}))
			dispatch(serviceUnavailableError())
		}		
	}

	return next(action)
}

export default middleWare
