import * as React from 'react'

function ArrowExpandIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="1.5625rem"
			height="1.25rem"
			viewBox="0 0 25 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M8.75 8.75h7.5V5l5 5-5 5v-3.75h-7.5V15l-5-5 5-5v3.75zM0 20V0h2.5v20H0zm22.5 0V0H25v20h-2.5z"
				fill="#444"
			/>
		</svg>
	)
}

export default ArrowExpandIcon

