import React from 'react'

const LoginPageLayout: React.FC<LayoutProps> = ({
	children,
	...props
}) => {
	return (
		<div className='LoginPageLayout'>
			{children}
		</div>
	)
}

export default LoginPageLayout
