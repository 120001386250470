import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { FilterIds } from 'types/GlobalTypes'
import { 
	getQuickSearch, 
	QuickSearchPayload,
} from 'utils/api/articleSearchAPI'
import sliceStateBases, { SliceState } from 'utils/enums/sliceStateBases'
import { loggedOut } from 'utils/redux/commonActions'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchQuickSearch = createAsyncThunk('quickSearch/quickSearch', async (payload: QuickSearchPayload) => await getQuickSearch(payload))

const searchQuickActions = thunkActions(
	fetchQuickSearch,
)

export interface QuickSearchState {
	criteria: QuickSearchPayload
	filterIds?: FilterIds
}

export const initialQuickSearchState: SliceState<'FEATURE', QuickSearchState> = {
	...sliceStateBases.FEATURE,
	data: null,
	criteria: {
		searchQuery: '',
		languageCode: '',
	},
}

const quickSearchSlice = createSlice({
	name: 'quickSearchSlice',
	initialState: initialQuickSearchState,
	reducers: {
		setQuickSearchCriteria: (state, action) => {
			state.criteria = {
				...state.criteria,
				...action.payload,
			}

			if (action.payload.searchQuery === '') {
				state.data = null
				state.noResults = false
			}
		},
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
		},
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				Object.assign(state, initialQuickSearchState)
			})
			.addCase(fetchQuickSearch.pending, state => {
				state.data = null
			})
			.addCase(fetchQuickSearch.fulfilled, (state, action) => {
				state.data = action.payload
				state.noResults = !state.data.length
			})
			.addCase(fetchQuickSearch.rejected, state => {
				state.data = null
				state.noResults = false
			})
			.addMatcher(
				searchQuickActions,
				statusHandler,
			)
	},
})
export const selectQuickSearchCriteria = (state: RootState) => state.search.allProducts.quickSearch.criteria
export const selectQuickSearch = (state: RootState) => state.search.allProducts.quickSearch
export const selectQuickSearchData = (state: RootState) => state.search.allProducts.quickSearch.data
export const selectQuickSearchIsLoading = (state: RootState) => state.search.allProducts.quickSearch.isLoading
export const selectQuickSearchNoResults = (state: RootState) => state.search.allProducts.quickSearch.noResults

export const {
	setQuickSearchCriteria,
	setFilter,
} = quickSearchSlice.actions

export default quickSearchSlice.reducer
