export const webStartPageType = {
	/**
	 * Start page with content.
	 */
	startPage: 1,
	/**
	 * Login portal with login on right side.
	 */
	loginPortalRightSide: 2,
	/**
	 * Login portal with login at the center and no background.
	 */
	loginPortalCenter: 3,
} as const

export type WebStartPageType = typeof webStartPageType[keyof typeof webStartPageType]
export type WebStartPageTypeLabel = keyof typeof webStartPageType

export function isLoginPortalType(
	type: number,
): boolean {
	if (
		type === webStartPageType.loginPortalCenter ||
		type === webStartPageType.loginPortalRightSide
	) return true
	return false
}
