import Filter from '@barnsten-it/gummidata-react-components/dist/Filter/Filter'
import FilterContainer from '@barnsten-it/gummidata-react-components/dist/Filter/FilterContainer/FilterContainer'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { filterSettings, filterablePropertyNames } from './filterSettings'

const FilterBase = ({
	identifier = 'id',
	items,
	propertyNames,
	onFilterChange,
	filterState
}) => {
	const { t } = useTranslation()
	const hasResults = Array.isArray(items) && !!items.length

	const settings = useMemo(() => {
		const settings = []

		for (let propertyName of propertyNames) {
			let name = ''
			let showMoreText = ''
			// Choose appropriate texts based on property name
			switch (propertyName) {
				case filterablePropertyNames.brandName:
					name = t('searchFilter.brand')
					showMoreText = t('common.showMore')
					break
				case filterablePropertyNames.modelName:
					name = t('common.carModel')
					showMoreText = t('common.showMore')
					break
				default:
					break
			}

			settings.push({
				...filterSettings[propertyName],
				name,
				showMoreText,
			})
		}

		return settings
	}, [t, propertyNames])

	return (
		<FilterContainer
			hasResult={hasResults}
		>
			<Filter
				identifier={identifier}
				settings={settings}
				label={t('common.searchFilter')}
				selected={filterState?.selected}
				items={items ?? []}
				onFilterChange={onFilterChange}
			/>
		</FilterContainer>
	)
}

export default FilterBase
