import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getTubeSizes } from 'utils/api/dimensionsAPI'
import { statusHandler, thunkActions } from 'utils/utils'
import sliceStatus, { SliceStatus } from 'utils/enums/sliceStatus'
import { RootState } from 'app/store'

export const fetchIndustryTubeSizes = createAsyncThunk('industryTubeSizes/fetchIndustryTubeSizes', async (payload: IndustryTubePayload) => await getTubeSizes(payload.multi, payload.mainGroupId))
const fetchIndustryTubesSizesAction = thunkActions(fetchIndustryTubeSizes)

interface IndustryTubePayload {
	multi: boolean,
	mainGroupId: number
}

interface IndustryTubeState {
	sizes: [] | null,
	status: SliceStatus
}

const initialState: IndustryTubeState = {
	sizes: null,
	status: sliceStatus.idle,
}

const industryTubeSizeSlice = createSlice({
	name: 'industryTubeSizes',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchIndustryTubeSizes.fulfilled, (state, action) => {
				state.sizes = action.payload
			})
			.addMatcher(fetchIndustryTubesSizesAction, statusHandler)
	}
})

export const selectIndustryTubeSizesStatus = (state: RootState) => state.dimensions.industryTubeSize.status
export const selectIndustryTubeSizes = (state: RootState) => state.dimensions.industryTubeSize.sizes

export default industryTubeSizeSlice.reducer