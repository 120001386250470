import * as React from 'react'
import { SVGProps } from 'react'
const SvgExpandArrow = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
		<path
			fill="currentColor"
			d="M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10A10 10 0 0 1 2 12 10 10 0 0 1 12 2m-5 8 5 5 5-5H7Z"
		/>
	</svg>
)
export default SvgExpandArrow
