import * as React from 'react'
import { SVGProps } from 'react'
const SvgRimIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		{...props}
	>
		<path
			fill="#BDBDBD"
			d="M17.907 3.429A9.934 9.934 0 0 0 10.836.5 9.935 9.935 0 0 0 3.765 3.43 9.935 9.935 0 0 0 .835 10.5a9.935 9.935 0 0 0 2.93 7.071 9.935 9.935 0 0 0 7.071 2.929 9.934 9.934 0 0 0 7.07-2.929 9.934 9.934 0 0 0 2.93-7.071 9.934 9.934 0 0 0-2.93-7.071Zm-.409 4.186a.406.406 0 0 1 .226.23c.114.295.21.6.285.908a.406.406 0 0 1-.313.493l-3.93.805a.406.406 0 0 1-.25-.767l3.661-1.663a.406.406 0 0 1 .321-.006Zm-4.306-.88.288-2.554a.406.406 0 0 1 .581-.32c.81.395 1.534.929 2.152 1.587a.406.406 0 0 1-.128.647L13.764 7.15a.406.406 0 0 1-.572-.415ZM10.07 3.278a.406.406 0 0 1 .285-.144c.32-.021.644-.021.964 0a.406.406 0 0 1 .377.45l-.456 4.045a.406.406 0 0 1-.806 0l-.456-4.045a.406.406 0 0 1 .092-.306Zm.767 6a1.222 1.222 0 1 1 0 2.444 1.222 1.222 0 0 1 0-2.444Zm-5.378-3.83A7.384 7.384 0 0 1 7.61 3.86a.405.405 0 0 1 .582.32l.287 2.554a.406.406 0 0 1-.57.415L5.585 6.095a.406.406 0 0 1-.128-.647ZM3.663 8.753c.075-.308.171-.613.285-.909a.406.406 0 0 1 .547-.223l3.66 1.663a.406.406 0 0 1-.25.767l-3.929-.805a.406.406 0 0 1-.313-.493Zm1.051 5.495a.406.406 0 0 1-.356-.21 7.333 7.333 0 0 1-.823-2.446.406.406 0 0 1 .483-.458l2.434.498a.406.406 0 0 1 .218.672l-1.656 1.812a.406.406 0 0 1-.3.132Zm2.525 2.56a.41.41 0 0 1-.315-.05 7.458 7.458 0 0 1-.755-.543.405.405 0 0 1-.043-.588l2.65-2.898a.406.406 0 0 1 .652.474l-1.935 3.41a.406.406 0 0 1-.254.194Zm5.155.74a.406.406 0 0 1-.298.226 7.396 7.396 0 0 1-2.52 0 .406.406 0 0 1-.285-.6l1.192-2.1a.406.406 0 0 1 .706 0l1.191 2.1c.065.114.07.254.014.373Zm3.257-1.618a.407.407 0 0 1-.148.285c-.24.196-.494.378-.755.542a.406.406 0 0 1-.569-.143l-1.935-3.41a.406.406 0 0 1 .652-.475l2.65 2.898a.406.406 0 0 1 .105.303Zm1.663-1.893a.406.406 0 0 1-.656.079l-1.656-1.812a.406.406 0 0 1 .218-.672l2.434-.498a.406.406 0 0 1 .483.458 7.336 7.336 0 0 1-.823 2.445Z"
		/>
	</svg>
)
export default SvgRimIcon
