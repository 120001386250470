import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRimsBySize } from 'utils/api/articleSearchAPI'
import { createResultSelector } from 'utils/helper'
import { statusHandler, thunkActions } from 'utils/utils'
import { loggedOut } from 'utils/redux/commonActions'
import searchRimType from 'utils/enums/searchRimType'


export const searchRims = createAsyncThunk('truckRimSearch/search', async (criteria) => await getRimsBySize(
	criteria.boltPattern,
	criteria.boltCircle,
	criteria.diameter,
	criteria.width,
	criteria.articleText,
	criteria.rimType,
	criteria.languageCode
))

const searchRimsActions = thunkActions(searchRims)

const truckRimSearchSlice = createSlice({
	name: 'truckRimSearch',
	initialState: {
		status: 'idle',
		result: null,
		filterIds: null,
		filterState: null,
		sortBy: null,
		ascending: null,
		criteria: {
			width: '',
			boltPattern: '',
			boltCircle: '',
			diameter: '',
			rimType: searchRimType.steelLV,
			selectedSize: '',
			articleText: ''
		}
	},
	reducers: {
		setCriteria: (state, action) => {
			state.criteria[action.payload.name] = action.payload.value
		},
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		}
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.result = null
				state.filterIds = null
				state.criteria = {
					width: '',
					boltPattern: '',
					boltCircle: '',
					diameter: '',
					rimType: searchRimType.steelLV,
					selectedSize: '',
					articleText: ''
				}
			})
			.addCase(searchRims.pending, state => {
				state.filterIds = null
				state.filterState = null
				state.result = null
				state.sortBy = null
				state.ascending = null
			})
			.addCase(searchRims.fulfilled, (state, action) => {
				state.result = action.payload
			})
			.addMatcher(searchRimsActions, statusHandler)
	}
})

export const selectCritiera = state => state.search.rim.truckRim.criteria
export const selectStatus = state => state.search.rim.truckRim.status
export const selectResult = state => state.search.rim.truckRim.result
export const selectFilterIds = state => state.search.rim.truckRim.filterIds
export const selectFilterState = state => state.search.rim.truckRim.filterState
export const selectSortBy = state => state.search.rim.truckRim.sortBy
export const selectAscending = state => state.search.rim.truckRim.ascending

export const selectProcessedResult = createResultSelector(selectResult, selectFilterIds, selectSortBy, selectAscending)

export const { setCriteria, sortResult, setFilter } = truckRimSearchSlice.actions

export default truckRimSearchSlice.reducer
