import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getLoaderTyreSizes } from 'utils/api/dimensionsAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchLoaderTyreSizes = createAsyncThunk('loaderTyreSizes/getLoaderTyreSizes', async (multi = false) => await getLoaderTyreSizes(multi))
const fetchLoaderTyreSizesAction = thunkActions(fetchLoaderTyreSizes)

const loaderTyreSizeSlice = createSlice({
	name: 'loaderTyreSizes',
	initialState: {
		status: 'idle',
		sizes: null
	},
	extraReducers: builder => {
		builder
			.addCase(fetchLoaderTyreSizes.fulfilled, (state, action) => {
				state.sizes = action.payload
			})
			.addMatcher(fetchLoaderTyreSizesAction, statusHandler)
	}
})

export const selectLoaderTyreSizesStatus = state => state.dimensions.loaderTyreSizes.status
export const selectLoaderTyreSizes = state => state.dimensions.loaderTyreSizes.sizes

export default loaderTyreSizeSlice.reducer
