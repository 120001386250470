import * as React from 'react'
import { SVGProps } from 'react'
const SvgReturnBoxIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		fillRule="evenodd"
		strokeLinejoin="round"
		strokeMiterlimit={2}
		clipRule="evenodd"
		viewBox="0 0 50 50"
		width="1em"
		height="1em"
		{...props}
	>
		<path fill="none" d="M0 0h50v50H0z" />
		<path
			fillRule="nonzero"
			d="m30.21 37.089-5.92 3.327c-.195.146-.439.22-.696.22-.256 0-.5-.074-.696-.22l-9.649-5.423a1.211 1.211 0 0 1-.647-1.075v-6.766c-.366.208-.831.22-1.221 0a1.202 1.202 0 0 1-.452-1.673l1.832-3.164c.134-.244.342-.415.574-.513l9.563-5.374c.196-.146.44-.22.696-.22.257 0 .501.074.696.22l9.649 5.423c.232.122.428.318.538.562l1.771 3.078a1.213 1.213 0 0 1-.44 1.661l-1.221.708v2.409a6.895 6.895 0 0 1-2.443.563v-1.567l-6.107 3.542c-.403.22-.855.207-1.221.012v4.507l2.769-1.56a3.122 3.122 0 0 0 2.552 1.323h.073zm-15.165-3.891 7.328 4.128v-8.195l-7.328-4.116v8.183zM8.723 13.873v.008c0 .357.172.674.437.873l4.415 4.415a1.09 1.09 0 0 0 1.54-1.541l-2.658-2.658h19.381a8.948 8.948 0 0 1 8.951 8.951v.003a8.95 8.95 0 0 1-8.951 8.951h-1.701a1.09 1.09 0 0 0 0 2.178h1.701a11.13 11.13 0 0 0 11.129-11.129v-.003a11.129 11.129 0 0 0-11.129-11.129H12.425l2.658-2.658a1.09 1.09 0 0 0-1.541-1.54l-4.5 4.501a1.082 1.082 0 0 0-.319.778zm17.131 16.21 7.67-4.433-.708-1.234-7.671 4.434.709 1.233zm-2.26-11.249v8.184l7.28-4.092-7.28-4.092z"
		/>
	</svg>
)
export default SvgReturnBoxIcon
