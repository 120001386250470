import React from 'react'
import SvgExternalLink from 'assets/svgs/svgr/ExternalLink'
import SvgMenuRight from 'assets/svgs/svgr/MenuRight'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { MenuSubItem } from 'utils/hooks/useMenu'

interface MobileMenuItemProps {
	to: string
	name: string
	active: boolean
	onClick?: (step: number) => void
	step?: number
	subItems?: MenuSubItem[]
	setClickedItem?: (item: any) => void
	leadsToExternalLink?: boolean
}

const MobileMenuItem = ({ 
	to, 
	name, 
	active, 
	onClick, 
	step, 
	subItems, 
	setClickedItem, 
	leadsToExternalLink
}: MobileMenuItemProps) => {
	const handleClick = () => {
		
		if (subItems && step && typeof onClick === 'function') {
			onClick(step)
		}

		if (typeof setClickedItem === 'function') {
			setClickedItem(to)
		}
	}

	if (leadsToExternalLink) {
		return (
			<a href={to} className='menu-item' target={'_blank'} rel='noreferrer'>
				<div className='nav-name'>
					{name}
				</div>
				<div className='nav-icon'>
					<SvgExternalLink />
				</div>
			</a>
		)
	}

	if (step && step > 0 && subItems) {
		return (
			<div className={classNames('menu-item', { 'active': active })} onClick={handleClick}>
				<div className="nav-name">{name}</div>
				<div className="nav-icon">
					<SvgMenuRight />
				</div>
			</div>
		)
	}

	return (
		<Link className={classNames('menu-item', { 'active': active })} onClick={handleClick} to={to}>
			<div className="nav-name">{name}</div>
			<div className="nav-icon">
				<SvgMenuRight />
			</div>
		</Link>
	)
}

export default MobileMenuItem