import React from 'react'
import { useSelector } from 'react-redux'
import { selectSingleProduct } from 'features/articles/singleProduct/singleProductSlice'
import Footer from 'components/Footer/Footer'
import SubMenu from 'components/SubMenu/SubMenu'

const ProductLayout: React.FC<LayoutProps> = ({ children, ...rest }) => {
	const productData = useSelector(selectSingleProduct)

	return (
		<div className="ProductLayout">
			<SubMenu crumbData={productData} />
			<section className="content-full">
				{children}
			</section>
			<Footer detailed />
		</div>
	)
}

export default ProductLayout
