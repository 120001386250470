import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { 
	getUsers, 
	putUser, 
	postUser, 
	deleteUser, 
	getMultiUserAccount 
} from 'utils/api/usersAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => getUsers())
export const removeUser = createAsyncThunk('users/removeUser', async (payload) => deleteUser(payload))
export const updateUser = createAsyncThunk('users/updateUser', async (payload) => putUser(payload))
export const addUser = createAsyncThunk('users/updateUser', async (payload) => postUser(payload))
export const checkEmail = createAsyncThunk('users/checkEmail', async (email) => getMultiUserAccount(email))
export const quotationAction = thunkActions(fetchUsers)

const usersSlice = createSlice({
	name: 'quotationHistory',
	initialState: {
		status: 'idle',
		data: null
	},
	extraReducers: (builder) => {
	 	builder
	 		.addCase(fetchUsers.fulfilled, (state, action) => {
	 			state.data = action.payload
	 		})
	 		.addCase(fetchUsers.rejected, (state) => {
	 			state.data = null
	 		})
	 		.addMatcher(quotationAction, statusHandler)
	 }
})
export const selectUsers = state => state.user.users.data

export default usersSlice.reducer