import apiClient from './apiClient'

export const getSearchConfig = (): Promise<SearchConfig> => apiClient.get('/api/SearchConfig')

export const getSearchConfigDefaults = (): Promise<SearchConfig> => apiClient.get('/api/SearchConfig?defaults=true')

export interface SearchConfig {
	enableSelector: boolean
	tubesMainGroupId: number | null
	car: CarSearchConfig | null
	moto: MotoSearchConfig | null
	truck: TruckSearchConfig | null
	industry: IndustrySearchConfig | null
	other: OtherSearchConfig | null
	oil: OilSearchConfig | null
	battery: BatterySearchConfig | null
	tinyTyre: TinyTyreSearchConfig | null
}

export interface CarSearchConfig {
	alloyRims: boolean
	steelRims: boolean
	wheels: boolean
	onlyCompletedPackages: boolean
	tyreGroups: number[]
	tyresCarModel: boolean
	tyres: boolean
	rims: boolean
	tubes: boolean
	tpms: boolean
	accessories: boolean
}

export interface MotoSearchConfig {
	tyres: boolean
	rims: boolean
	tubes: boolean
	tpms: boolean
	accessories: boolean
}

export interface TruckSearchConfig {
	tyres: boolean
	rims: boolean
	tubes: boolean
	tpms: boolean
	accessories: boolean
}

export interface IndustrySearchConfig {
	tyreGroups: number[]
	tyres: boolean
	rims: boolean
	tubes: boolean
	tpms: boolean
	accessories: boolean
}

export interface OtherSearchConfig {
	accessories: boolean
}

export interface OilSearchConfig {
	accessories: boolean
}

export interface BatterySearchConfig {
	accessories: boolean
}
export interface TinyTyreSearchConfig {
	tyres: boolean
	rims: boolean
	tubes: boolean
	tpms: boolean
	accessories: boolean
}