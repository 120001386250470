import * as React from 'react'
import { SVGProps } from 'react'
const SvgVolume = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		viewBox="0 0 512 512"
		{...props}
	>
		<path d="M439 256v219h73V37h-73v219zM329 292.5V475h73V110h-73v182.5zM219 329v146h74V183h-74v146zM110 384v91h73V293h-73v91zM0 420.5V475h73V366H0v54.5z" />
	</svg>
)
export default SvgVolume
