import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getIndustryAgroRimSizes } from 'utils/api/dimensionsAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchSizes = createAsyncThunk('industryAgroRimSize/fetchSizes', async () => await getIndustryAgroRimSizes())
const fetchAction = thunkActions(fetchSizes)

const industryAgroRimSizeSlice = createSlice({
	name: 'industryAgroRimSize',
	initialState: {
		status: 'idle',
		sizes: null
	},
	extraReducers: builder => {
		builder
			.addCase(fetchSizes.fulfilled, (state, action) => {
				state.sizes = action.payload
			})
			.addMatcher(fetchAction, statusHandler)
	}
})

export const selectIndustryRimSizesStatus = state => state.dimensions.industryAgroRimSize.status
export const selectIndustryRimSizes = state => state.dimensions.industryAgroRimSize.sizes

export default industryAgroRimSizeSlice.reducer
