import {
	ListView,
	ListViewColumn,
} from '@barnsten-it/gummidata-react-components'
import ScrollBox from 'components/ScrollBox/ScrollBox'
import StockQuantity from 'components/StockQuantity/StockQuantity'
import { selectAlwaysDisplayRetailPrices } from 'features/user/userInfo/userInfoSlice'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import QuantityInput from '../ListGroupResult/QuantityInput/QuantityInput'
import { selectRetailPrice } from 'features/search/searchSettings/searchSettingsSlice'
import { ArticleLinkModel } from 'types/api'
import { useUserRoles } from 'utils/hooks/useUserRoles'
import { usePriceFormatter } from 'utils/hooks/usePriceFormatter'

interface ArticleLinkViewProps {
	result: ArticleLinkModel[]
	onQuantityChange: any
	quantities: any[]
}

const ArticleLinkView: React.FC<ArticleLinkViewProps> = ({
	result,
	onQuantityChange,
	quantities,
}) => {
	const { t } = useTranslation()
	const priceFormatter = usePriceFormatter()
	const { 
		hasCustomerAdminRole, 
		hasNetPricesRole 
	} = useUserRoles()
	const showRetailPrice = useSelector(selectRetailPrice)
	const alwaysDisplayRetailPrice = useSelector(selectAlwaysDisplayRetailPrices)
	const retailPrice =
		showRetailPrice ||
		!(hasCustomerAdminRole || hasNetPricesRole) ||
		alwaysDisplayRetailPrice

	const columns: ListViewColumn[] = useMemo(
		() => [
			{
				name: 'articleText',
				text: t('common.description'),
				align: 'left',
				className: 'col-xs-6',
			},
			{
				name: 'stock',
				text: t('common.stock'),
				align: 'right',
				className: 'col-xs',
			},
			{
				name: 'netPrice',
				text: t('common.netPrice'),
				align: 'right',
				className: 'col-xs',
				hide: retailPrice,
			},
			{
				name: 'retailPrice',
				text: t('common.retailPrice'),
				align: 'right',
				className: 'col-xs',
				hide: !retailPrice,
			},
			{
				name: 'buy',
				text: '',
				align: 'right',
				className: 'col-xs',
				minWidth: '100px',
			},
		],
		[t, retailPrice],
	)

	return (
		<div className="ArticleLinkView">
			<ScrollBox>
				<ListView columns={columns} addItemCount={100}>
					{({ columns }: { columns: { [key: string]: ListViewColumn[] } }) =>
						result.map((row, index) => (
							<ListView.Item key={index}>
								<ListView.Columns>
									<ListView.Column
										column={columns['articleText']}
									>{`${row.articleText} ${row.minimumQuantity > 0 ? '(' + t('shopPage.bracketQuantity').replace('{{quantity}}', row.minimumQuantity.toString()) + ')' : ''}`}</ListView.Column>
									<ListView.Column column={columns['stock']}>
										<StockQuantity article={row} />
									</ListView.Column>
									<ListView.Column column={columns['netPrice']}>
										{row.netPrice > 0
											? priceFormatter(row.netPrice, { symbol: false })
											: t('shopPage.call')}
									</ListView.Column>
									<ListView.Column column={columns['retailPrice']}>
										{priceFormatter(row.retailPrice, { symbol: false })}
									</ListView.Column>
									<ListView.Column column={columns['buy']}>
										<QuantityInput
											article={row}
											onQuantityChange={onQuantityChange}
											value={
												quantities.find(
													(q) =>
														q.id === row.id &&
														q.minimumQuantity === row.minimumQuantity,
												)?.quantity
											}
											multiplyDropdown={undefined}
											multiplyBy={undefined}
											onEnterKey={undefined}
										/>
									</ListView.Column>
								</ListView.Columns>
							</ListView.Item>
						))
					}
				</ListView>
			</ScrollBox>
		</div>
	)
}

export default ArticleLinkView
