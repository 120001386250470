import React, { useMemo } from 'react'
import { setFilter, selectAllProductsSearch } from 'features/search/allProducts/AllProductsSearch/allProductsSearchSlice'
import { useDispatch, useSelector } from 'react-redux'
import { filterablePropertyNames } from 'components/filters/FilterBase/filterSettings'
import FilterBase from '../FilterBase/FilterBase'


const AllProductsSearchFilter = () => {
	const dispatch = useDispatch()
	const { data } = useSelector(selectAllProductsSearch)

	const onFilterChange = (articleIds) => {
		dispatch(setFilter({ articleIds }))
	}

	const propertyNames = useMemo(() => [
		filterablePropertyNames.brandName,
		filterablePropertyNames.modelName,
	], [])

	return (
		<div className='AllProductsSearchFilter'>
			{data && (
				<FilterBase
					items={data}
					propertyNames={propertyNames}
					onFilterChange={onFilterChange}
				/>
			)}
		</div>
	)
}


export default AllProductsSearchFilter