import './SearchLayout.scss'
import React from 'react'
import { useSelector } from 'react-redux'
import SearchFilter from 'features/search/SearchFilter/SearchFilter'
import Footer from 'components/Footer/Footer'
import { selectSearchConfig } from 'features/app/settings/settingsSlice'
import classNames from 'classnames'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import SearchCategoryHandler from 'features/search/checkCategory/SearchCategoryHandler/SearchCategoryHandler'
import SubMenu from 'components/SubMenu/SubMenu'

import ArticleSearchType from 'utils/enums/articleSearchType'
import shopSearchType from 'utils/enums/shopSearchType'
import { LayoutProps } from 'utils/wrappers/PageWrapper'

const SearchLayout: React.FC<LayoutProps> = ({
	children,
	...rest
}) => {
	const searchConfig = useSelector(selectSearchConfig)

	return (
		<div className={classNames('SearchLayout content-wrapper no-flex detail-background', { 'no-categories': !searchConfig?.enableSelector })}>
			<div className="detail-background">
				<SubMenu />
			</div>
			<div className="no-background">
				<div className="search-content">
					<ErrorBoundary>
						<SearchFilter 
							searchType={shopSearchType.search}
							articleType={ArticleSearchType.all} />
					</ErrorBoundary>

					<section>
						<SearchCategoryHandler />
						{children}
						<Footer alignLeft detailed />
					</section>
				</div>
			</div>
		</div>
	)
}

export default SearchLayout