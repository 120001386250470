import './DetailedFooter.scss'
import React from 'react'
import { selectSiteDataCompany, selectSiteDataFooter } from 'features/siteData/siteDataSlice'
import { useSelector } from 'react-redux'
import MainLabel from 'components/labels/MainLabel/MainLabel'
import { LinkInfoModel } from 'types/SiteData'
import SocialLinks from 'components/SocialLinks/SocialLinks'
import { useTranslation } from 'react-i18next'
import Card from 'components/Card/Card'

interface DetailedFooterProps {
	
}

/**
 * DetailedFooter
 * @param props
 */
const DetailedFooter: React.FC<DetailedFooterProps> = () => {
	const { t } = useTranslation()
	const footerData = useSelector(selectSiteDataFooter)
	
	// Dummy data
	// const footerData: FooterModel = {
	// 	links: [
	// 		{
	// 			label: 'Contact',
	// 			url: 'https://google.com'
	// 		},
	// 		{
	// 			label: 'Media',
	// 			url: 'https://google.com'
	// 		},
	// 		{
	// 			label: 'Cookies',
	// 			url: 'https://google.com'
	// 		},
	// 		{
	// 			label: 'Privacy Policy',
	// 			url: 'https://google.com'
	// 		},
	// 		{
	// 			label: 'Terms and conditions',
	// 			url: 'https://google.com'
	// 		}
	// 	],
	// 	socialConfig: {
	// 		facebook: 'https://facebook.com',
	// 		instagram: 'https://instagram.com',
	// 		twitter: 'https://twitter.com',
	// 		linkedin: 'https://linkedin.com',
	// 		messenger: 'https://messenger.com',
	// 	}
	// }
	
	const company = useSelector(selectSiteDataCompany)
	const address = `${company?.address1} ${company?.zipCode} ${company?.city} ${company?.countryName}`

	return (
		<div className="DetailedFooter">
			<Card grayCard className="footer-content">
				<div className="row">
					{footerData?.links?.length > 0 &&
						<div className="link-list">
							<MainLabel text={t('common.links')} noTopMargin />
							<nav>
								{footerData?.links?.map((link: LinkInfoModel) => {
									return (
										<a href={link.url} key={link.url}>
											{link.label}
										</a>
									)
								})}
							</nav>
							<SocialLinks socials={footerData?.socialConfig} />
						</div>
					}

					<div className="company-details">
						<MainLabel text={company?.name} noTopMargin />
						{address &&
							<div className="address">
								{address}
							</div>
						}
						{company?.vatNumber &&
							<div className="vat-number">
								{company?.vatNumber}
							</div>
						}
						{company?.organisationNumber &&
							<div className="organisation-number">
								{company?.organisationNumber}
							</div>
						}
						{company?.emailaddress &&
							<div className="email">
								{company?.emailaddress}
							</div>
						}
						{company?.telephone &&
							<div className="phone">
								{company?.telephone}
							</div>
						}

					</div>
				</div>

				
			</Card>
		</div>
	)
}

export default DetailedFooter