import i18next from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageCode from 'utils/enums/languageCode'
import { getLocalStorageItem } from 'utils/utils'

const i18nStartFunction = (startLanguage: LanguageCode) => {
	i18next
		.use(ChainedBackend)
		.use(initReactI18next)
		.init({
			fallbackLng: getLocalStorageItem('languageCode') || startLanguage,
			debug: false,
			react: {
				useSuspense: false,
			},
			parseMissingKeyHandler: (property) => {
				// if (process.env.NODE_ENV !== 'development') return ''
				return property
			},
			backend: {
				backends: [
					HttpBackend,
				],
				backendOptions: [{
					requestOptions: {
						credentials: 'include',
					},
					loadPath: (_: string, namespaces: string[]) => {
						const namespace = namespaces[0]

						/**
						 * Select the webTexts namespace
						 */
						if (namespace?.startsWith('webtext')) {
							const ns = namespace.replace('webtext.', '')
							return process.env['REACT_APP_API_URL'] + `/api/WebTexts/?languageCode={{lng}}&ns=${ns}`
						}
						
						/**
						 * Select the staticTexts namespace
						 */
						if (namespace?.startsWith('staticTexts')) {
							const ns = namespace.replace('staticTexts.', '')

							// public folder of this project
							return `/staticTexts/{{lng}}/${ns}.json`
						}
						
						/**
						 * Default to basic translations
						 */
						return process.env['REACT_APP_API_URL'] + '/api/Language/?languageCode={{lng}}&ns={{ns}}'
					}
				}]
			}
		})
}

export default i18nStartFunction
