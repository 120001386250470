import React from 'react'
import WebText from 'components/WebText/WebText'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectLoggedIn } from 'features/user/login/loginSlice'
import { 
	selectFeatureConfig, 
	selectPageConfig, 
	selectResellerConfig, 
	selectSearchConfig, 
	selectSettings 
} from 'features/app/settings/settingsSlice'
import { ranomizerNumber } from 'utils/utils'
import { useUserRoles } from './useUserRoles'

export interface MenuSubItem {
	id?: number
	name: string | JSX.Element // JSX.Element needs to be removed when we remove webTexts from the menu
	to: string
	active: boolean
	externalLink?: boolean
	hideMobileMenu?: boolean
}

export interface MenuItem {
	id: number
	toBase: string
	name: string | JSX.Element // JSX.Element needs to be removed when we remove webTexts from the menu
	to: string
	mainMenu: boolean
	active: boolean
	externalLink?: boolean
	hideMobileMenu?: boolean
	subItems?: MenuSubItem[]
}


const useMenu = () => {
	const { t } = useTranslation()
	const loggedIn = useSelector(selectLoggedIn)
	const settings = useSelector(selectSettings)
	const searchConfig = useSelector(selectSearchConfig)
	const pageConfig = useSelector(selectPageConfig)
	const resellerConfig = useSelector(selectResellerConfig)
	const featureConfig = useSelector(selectFeatureConfig)
	const resellerSettings = useSelector(selectResellerConfig)

	const {
		hasAdminRole,
		hasCustomerAdminRole,
		hasMyPageRole,
		hasOrderHistoryRole,
		hasInvoicesRole,
		hasArticleListExportRole,
		hasTickets,
		hasCustomerStock
	} = useUserRoles()

	const extraPages: MenuSubItem[] = []

	if (settings?.extraPages) {
		settings.extraPages.forEach(page => {
			let addPage: MenuSubItem = {
				id: ranomizerNumber(),
				name: t('extraPage.' + page.translation),
				to: page.link,
				externalLink: page.externalLink,
				active: !page.requireLogin || loggedIn
			}

			if (page.webText?.length > 0){
				addPage.to = `/info/page/${page.link}`
			}

			extraPages.push(addPage)
		})
	}

	/**
	 * My pages: /my-pages
	 */
	const myPagesGeneral: MenuSubItem[] = [
		{
			id: 16,
			to: '/my-pages/customer-info',
			name: t('common.customerSettings'),
			active: Boolean(settings?.pageConfig?.myPage && (hasCustomerAdminRole || hasMyPageRole))
		},
		{
			id: 17,
			to: '/my-pages/order-history',
			name: t('common.shippingOrderHistory'),
			active: Boolean(hasCustomerAdminRole || hasOrderHistoryRole)
		},
		{
			id: 18,
			to: '/my-pages/invoices',
			name: t('common.invoices'),
			active: Boolean(settings?.pageConfig?.invoicePage && (hasCustomerAdminRole || hasInvoicesRole) && (!settings?.resellerConfig || (settings?.resellerConfig && settings?.resellerConfig?.visma)))
		},
		{
			id: 19,
			to: '/my-pages/delivery-addresses',
			name: t('common.deliveryAddresses'),
			active: Boolean(!resellerConfig?.disableDeliveryAddress) && (hasCustomerAdminRole || hasMyPageRole)
		},
		{
			id: 20,
			to: '/my-pages/quotations',
			name: t('common.offers'),
			active: Boolean(settings?.siteConfig?.offers) && (hasCustomerAdminRole || hasMyPageRole),
		},
		{
			id: 211,
			to: '/my-pages/tickets',
			name: t('common.tickets'),
			active: Boolean(settings?.featureConfig?.useTickets) && (hasTickets || hasCustomerAdminRole),
		},
		{
			id: 21,
			to: '/my-pages/retail-markups',
			name: t('common.customerPrices'),
			active: !Boolean(settings?.featureConfig?.hideRetailPrice) && (hasCustomerAdminRole || hasMyPageRole),
		},
		{
			id: 22,
			to: '/my-pages/users',
			name: t('common.users'),
			active: Boolean(settings?.siteConfig?.webUsers && hasCustomerAdminRole)
		},
		{
			id: 23,
			to: '/my-pages/article-export',
			name: t('common.articleExport'),
			active: Boolean(!resellerSettings && hasArticleListExportRole),
			hideMobileMenu: true,
		},
		{
			id: 223,
			to: '/my-pages/customer-stock',
			name: t('customerStock.ownStock'),
			active: hasCustomerStock,
			hideMobileMenu: true,
		},
	]

	const myPagesAdmin: MenuSubItem[] = [
		{
			id: 24,
			to: '/my-pages/new-customer',
			name: t('common.newCustomer'),
			active: hasAdminRole,
		},
		{
			id: 25,
			to: '/my-pages/customers',
			name: t('common.viewCustomers'),
			active: hasAdminRole,
		},
		{
			id: 26,
			to: '/my-pages/orders',
			name: t('common.receivedOrders'),
			active: hasAdminRole,
		},
		{
			id: 27,
			to: '/my-pages/article-export',
			name: t('common.articleExport'),
			active: hasAdminRole,
		},
		{
			id: 28,
			to: '/my-pages/price-template',
			name: t('common.priceModel'),
			active: hasAdminRole,
		},
		{
			id: 29,
			to: '/my-pages/invoice',
			name: t('common.invoice'),
			active: hasAdminRole,
		}
	]

	const myPages: MenuSubItem[] = [
		...myPagesGeneral,
		...myPagesAdmin
	]

	/**
	 * Information pages: /info
	 */
	const info: MenuSubItem[] = [
		{
			id: 31,
			to: '/info',
			name: t('common.tyre'),
			active: (
				Boolean(settings?.pageConfig?.informationPage) &&
				Boolean(settings?.pageConfig?.tyreInfo)
			)
		},
		{
			id: 32,
			to: '/info/rimInfo',
			name: t('common.rim'),
			active: (
				Boolean(settings?.pageConfig?.informationPage) &&
				Boolean(settings?.pageConfig?.rimInfo)
			)
		},
		{
			id: 33,
			to: '/info/tyreCalculator',
			name: t('common.tyreCalculator'),
			active: (
				Boolean(settings?.pageConfig?.informationPage) &&
				Boolean(settings?.pageConfig?.tyreCalculation)
			),
		},
		{
			id: 34,
			to: '/info/rimCalculator',
			name: t('common.rimCalculator'),
			active: (
				Boolean(settings?.pageConfig?.informationPage) &&
				Boolean(settings?.pageConfig?.rimCalculation)
			)
		},
		{
			id: 35,
			to: '/info/downloads',
			name: t('common.downloads'),
			active: (
				Boolean(settings?.pageConfig?.informationPage) &&
				Boolean(settings?.pageConfig?.webDownloads)
			)
		},
		{
			id: 36,
			to: '/info/terms-of-purchase',
			name: t('common.terms'),
			active: Boolean(settings?.pageConfig?.termsOfPurchase)
		},
		{
			id: 37,
			to: '/info/policy',
			name: t('common.privacyPolicy'),
			active: Boolean(settings?.pageConfig?.privacyPolicy)
		},
		...extraPages,
	]
	

	/**
	 * Shop pages: /info
	 */
	const shop: MenuSubItem[] = [
		{
			id: 4,
			to: '/shop/car/tyre',
			name: <WebText namespace="shop" property="carMenuLabel" translation={t('common.car')} />,
			active: Boolean(searchConfig?.car),
		},
		{
			id: 5,
			to: '/shop/truck/tyre',
			name: <WebText namespace="shop" property="truckMenuLabel" translation={t('common.truck')} />,
			active: Boolean(searchConfig?.truck),
		},
		{
			id: 6,
			to: '/shop/industry/tyre',
			name: <WebText namespace="shop" property="industryMenuLabel" translation={t('common.industry')}/>,
			active: Boolean(searchConfig?.industry)
		},
		{
			id: 7,
			to: '/shop/moto/mcTyre',
			name: <WebText namespace="shop" property="motoMenuLabel" translation={`${t('common.mc')} / ${t('common.atv')}`}/>,
			active: Boolean(searchConfig?.moto)
		},
		{
			id: 8,
			to: '/shop/oil/oil',
			name: <WebText namespace="shop" property="oilMenuLabel" translation={t('common.oil')} />,
			active: Boolean(searchConfig?.oil)
		},
		{
			id: 9,
			to: '/shop/battery/battery',
			name: <WebText namespace="shop" property="batteryMenuLabel" translation={t('common.battery')} />,
			active: Boolean(searchConfig?.battery)
		},
		{
			id: 1083,
			to: '/shop/tinyTyre/tyre',
			name: t('common.tinyTyres'),
			active: Boolean(searchConfig?.tinyTyre)
		},
		{
			id: 10,
			to: '/shop/other/other',
			name: <WebText namespace="shop" property="otherMenuLabel" translation={t('common.other')} />,
			active: Boolean(searchConfig?.other)
		},
	]


	/** 
	 * CMS pages: /cms
	 */
	const cms: MenuSubItem[] = [
		{
			id: 70,
			to: '/cms',
			name: t('cms.dashboard'),
			active: true
		},
		{
			id: 71,
			to: '/cms/news',
			name: t('common.news'),
			active: Boolean(settings?.siteConfig?.newsPage)
		},
		{
			id: 72,
			to: '/cms/banners',
			name: t('common.banners'),
			active: Boolean(settings?.siteConfig?.newsPage)
		},
		{
			id: 73,
			to: '/cms/texts',
			name: t('cms.texts'),
			active: true
		}
	]


	/**
	 * Main menu items
	 */
	const menu: MenuItem[] = [
		{
			id: 1,
			toBase: '/faq/',
			to: '/faq',
			name: 'FAQ',
			mainMenu: true,
			active: false,
		},
		{
			id: 2,
			toBase: '/about/',
			to: '/about',
			name: t('common.aboutUs'),
			mainMenu: true,
			active: !loggedIn && Boolean(pageConfig?.aboutPage),
		},
		{
			id: 3,
			toBase: '/news/',
			to: '/news',
			name: t('common.news'),
			mainMenu: true,
			active: Boolean(settings?.siteConfig?.newsPage && loggedIn)
		},
		{
			id: 4,
			toBase: '/shop/',
			to: '/shop/car/tyre',
			name: <WebText namespace="shop" property="shopMenuLabel" translation={t('common.shop')} />,
			active: loggedIn,
			mainMenu: true,
			subItems: shop
		},
		// Special cases for latokka
		{
			id: 11,
			toBase: '/shop/battery/',
			to: '/shop/battery/battery',
			name: t('common.battery'),
			active: Boolean(searchConfig?.battery) && loggedIn,
			mainMenu: true,
		},
		// Special cases for starco
		{
			id: 12,
			toBase: '/shop/oil/',
			to: '/shop/oil/oil',
			name: t('common.oil'),
			active: Boolean(searchConfig?.oil) && loggedIn,
			mainMenu: true,
		},
		{
			id: 15,
			toBase: '/my-pages/',
			to: '/my-pages/customer-info',
			name: t('common.myPage'),
			active: (
				loggedIn &&
				Boolean(settings?.pageConfig?.myPage) && (hasCustomerAdminRole || hasMyPageRole)
			),
			mainMenu: true,
			subItems: myPages
		},
		{
			id: 30,
			toBase: '/info/',
			to: '/info',
			name: t('common.info'),
			active: (
				loggedIn &&
				Boolean(settings?.pageConfig?.informationPage)
			),
			mainMenu: true,
			subItems: info
		},
		{
			id: 38,
			toBase: '/contact/',
			to: '/contact',
			name: t('common.contact'),
			mainMenu: true,
			active: Boolean(pageConfig?.contactPage),
		},
		{
			id: 39,
			toBase: '/rimCatalog',
			to: '/rimCatalog',
			name: t('common.rim'),
			mainMenu: true,
			active: Boolean(featureConfig?.showLoggedOutRimSearch && !loggedIn)
		},
	]

	
	// Return the menus
	return {
		menu,
		shop,
		myPages,
		myPagesGeneral,
		myPagesAdmin,
		info,
		cms
	}
}

export default useMenu
