import './QuickSearchList.scss'
import React from 'react'
import { QuickSearchArticle } from 'utils/api/articleSearchAPI'
import SlimProductCard from 'components/SlimProductCard/SlimProductCard'
import ScrollBox from 'components/ScrollBox/ScrollBox'
interface QuickSearchPanelArticleProps {
	searchData: QuickSearchArticle[]
}

const QuickSearchList = ({
	searchData,
}: QuickSearchPanelArticleProps) => {
	return (
		<div className='QuickSearchList'>
			<ScrollBox>
				{searchData.map((article: any) => 
					<SlimProductCard key={article.id} article={article} />
				)}
			</ScrollBox>
		</div>
	)
}

export default QuickSearchList