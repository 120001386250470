import { ListView, ListViewColumn } from '@barnsten-it/gummidata-react-components'
import { useAppSelector } from 'app/useAppSelector'
import { selectMaxQuantityShown } from 'features/app/settings/settingsSlice'
import React, {
	FunctionComponent,
	useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { StockQuantity } from '../../../types/Responses'

interface Props {
	data: WarehouseListViewItem[]
}

export const WarehouseQuantitiesListView: FunctionComponent<Props> = ({
	data,
}) => {
	const { t } = useTranslation()
	const maxQuantityShown = useAppSelector(selectMaxQuantityShown)

	const columns = useMemo(() => {
		const columns: ListViewColumn[] = [
			{
				name: 'warehouseName',
				text: t('articleInfo.warehouseName'),
				width: 40,
			},
			{
				name: 'quantity',
				text: t('common.amount'),
				width: 40,
			},
		]

		return columns
	}, [t])

	return (
		<ListView
			columns={columns}
		>
			{data.map(item => (
				<ListView.Item
					key={item.warehouseId}
				>
					<ListView.Columns>
						<ListView.Column name='warehouseName'>
							{item.warehouseName}
						</ListView.Column>
						<ListView.Column name='quantity'>
							{item.quantity >= (maxQuantityShown || 0) ? `${item.quantity}+` : item.quantity}
						</ListView.Column>
					</ListView.Columns>
				</ListView.Item>
			))}
		</ListView>
	)
}

export interface WarehouseListViewItem extends StockQuantity {
	warehouseName: string
}
