import apiClient from './apiClient'
import { } from 'types/Responses'
import { TpmsCarYearPayload } from 'types/Requests'

/**
 * Get TPMS Car brands
 * @returns 
 */
export const getTpmsCarBrands = async (): Promise<string[]> =>
	await apiClient.get('/api/TpmsCarBrands')

/**
 * Get TPMS Brand models
 * @param brand 
 * @returns 
 */
export const getTpmsCarModels = async (brand: string): Promise<string[]> =>
	await apiClient.get('/api/TpmsCarModels', { brand })

/**
 * Get years for model
 * @param brand 
 * @param model 
 * @returns 
 */
export const getTpmsCarYears = async (payload: TpmsCarYearPayload): Promise<string[]> =>
	await apiClient.get('/api/TpmsCarYears', payload)
