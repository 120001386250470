import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { deleteAddress, fetchAddresses, getDeliveryAddressStatus, postAddress, putAddress } from 'utils/api/deliveryAddressAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const getAddresses = createAsyncThunk('deliveryAddresses/getAddresses', async (payload) => fetchAddresses(payload.oderBy, payload.orderDirection, payload.skip, payload.take))
export const updateAddress = createAsyncThunk('deliveryAddresses/updateAddress', async (payload) => putAddress(payload))
export const removeAddress = createAsyncThunk('deliveryAddresses/removeAddress', async (id) => deleteAddress(id))
/** @type {Function} */
export const addAddress = createAsyncThunk('deliveryAddresses/addAddress', async (payload) => postAddress(payload))
export const getAddressStatus = createAsyncThunk('deliveryAddresses/getAddressStatus', async (id) => await getDeliveryAddressStatus(id))
export const addressAction = thunkActions(getAddresses, removeAddress, updateAddress, addAddress)

const deliveryAddressesSlice = createSlice({
	name: 'deliveryAddresses',
	initialState: {
		status: 'idle',
		data: null,
	},
	extraReducers: (builder) => {
	 	builder
	 		.addCase(getAddresses.fulfilled, (state, action) => {
	 			state.data = action.payload
	 		})
	 		.addCase(getAddresses.rejected, (state) => {
	 			state.data = null
	 		})
	 		.addMatcher(addressAction, statusHandler)
	 }
})

export const selectDeliveryAddressesStatus = state => state.user.deliveryAddresses.status
export const selectDeliveryAddresses = state => state.user.deliveryAddresses.data
export const selectIsDeliveryAddressesLoading = state => state.user.deliveryAddresses.isLoading

export default deliveryAddressesSlice.reducer
