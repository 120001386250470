import * as React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { useLocation } from 'react-router'
import './MainMenu.scss'

interface MainMenuProps {
	menu: any[]
}

const MainMenu = ({ 
	menu 
}: MainMenuProps) => {
	const location = useLocation()

	return (
		<nav className="MainMenu">
			{menu.map((item) => {
				if (item.active && item.mainMenu) {
					const basePath = item.toBase.endsWith('/') ? item.toBase.slice(0, -1) : item.toBase
					const isIgnoredPath = location.pathname.startsWith('/shop/battery') || location.pathname.startsWith('/shop/oil')
					const isMatchingPath = location.pathname.startsWith(basePath)
					const active = !isIgnoredPath && isMatchingPath

					return (
						<div key={item.id} className={classNames('menu-item', { active })}>
							{item.externalLink ? (
								<a href={item.to} className="menu-link" target={'_blank'} rel="noreferrer">
									<div className="nav-name">{item.name}</div>
								</a>
							) : (
								<Link key={item.id} className="menu-link" to={item.to}>
									<div className="nav-name">{item.name}</div>
								</Link>
							)}
							<div className="background"></div>
						</div>
					)
				}
				return null
			})}
		</nav>
	)
}

export default MainMenu