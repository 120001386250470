import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AccessoryFilter from 'components/filters/AccessoryFilter/AccessoryFilter'
import { setFilter, selectResult, selectFilterState } from '../batteryAccessorySearchSlice'

const BatteryAccessoriesSearchFilter = () => {
	const result = useSelector(selectResult)
	const dispatch = useDispatch()
	const filterState = useSelector(selectFilterState)
	const handleFilterChange = (articleIds, filterState) => {
		dispatch(setFilter({ articleIds, filterState }))
	}

	return (
		<div className="BatteryAccessoriesSearchFilter">
			{
				result &&
				<AccessoryFilter
					result={result}
					onFilterChange={handleFilterChange}
					filterState={filterState}
					showStockFilter={true}
				/>
			}
		</div>
	)
}

export default BatteryAccessoriesSearchFilter
