import {
	ChangePasswordPayload,
	ResetPasswordLinkPayload,
	ResetPasswordPayload,
	getChangePasswordLink,
	postChangePassword,
	postChangePasswordLink,
	postResetPassword
} from 'utils/api/passwordAPI'
import {
	PayloadAction,
	createAsyncThunk,
	createSlice
} from '@reduxjs/toolkit'

import { RootState } from 'app/store'

interface PasswordState {
	data: null
	showPasswordChangeForm: boolean
}

const initialState = {
	data: null,
	showPasswordChangeForm: false,
}

export const changePassword = createAsyncThunk('password/changePassword', async (payload: ChangePasswordPayload) => await postChangePassword(payload))
export const resetPassword = createAsyncThunk('password/resetPassword', async (payload: ResetPasswordPayload) => await postResetPassword(payload))
export const checkPasswordLink = createAsyncThunk('password/checkPasswordLink', async (customerId: number, token: string) => await getChangePasswordLink(customerId, token))
export const changePasswordByLink = createAsyncThunk('password/changePasswordByLink', async (payload: ResetPasswordLinkPayload) => await postChangePasswordLink(payload))

const passwordSlice = createSlice({
	name: 'passwordSlice',
	initialState,
	reducers: {
		setShowPasswordChangeForm: (state: PasswordState, action: PayloadAction<Boolean>) => {
			state.showPasswordChangeForm = action.payload
		},
	},
})

export const selectShowPasswordChangeForm = (state: RootState) => state.user.password.showPasswordChangeForm

export const { 
	setShowPasswordChangeForm,
} = passwordSlice.actions

export default passwordSlice.reducer
