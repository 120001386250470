import './RetailPriceSwitch.scss'
import React, { useState } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, SwitchInput, Modal } from '@barnsten-it/gummidata-react-components'
import { setLocalStorageItem } from 'utils/utils'
import { selectAlwaysDisplayRetailPrices } from 'features/user/userInfo/userInfoSlice'
import { selectFeatureConfig } from 'features/app/settings/settingsSlice'
import { useUserRoles } from 'utils/hooks/useUserRoles'
import { 
	selectDisplayCalculator, 
	selectRetailExclVat, 
	selectRetailPrice, 
	setDisplayCalculator, 
	setRetailExclVat,
	setRetailPrice
} from 'features/search/searchSettings/searchSettingsSlice'

interface RetailPriceSwitchProps {
	showMessage?: boolean
	description?: boolean
}

const RetailPriceSwitch = ({
	showMessage,
	description = false
}: RetailPriceSwitchProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {
		hasCustomerAdminRole,
		hasNetPricesRole,
	} = useUserRoles()
	
	const featureConfig = useSelector(selectFeatureConfig)
	const retailExclVat = useSelector(selectRetailExclVat)
	const displayCalculator = useSelector(selectDisplayCalculator)
	const showRetailPrice = useSelector(selectRetailPrice)
	const alwaysDisplayRetailPrice = useSelector(selectAlwaysDisplayRetailPrices)
	
	const [showModal, setShowModal] = useState(false)
	
	const onChangeShowRetailPrice = () => {
		if (displayCalculator && !showRetailPrice) {
			dispatch(setDisplayCalculator(false))
		}
		dispatch(setRetailPrice(!showRetailPrice))
		setLocalStorageItem('showRetailPrice', !showRetailPrice)
	}

	const onChangeShowRetailExclVat = () => {
		dispatch(setRetailExclVat(!retailExclVat))

		if (showMessage)
			setShowModal(true)
	}

	const closeHandler = () => {
		setShowModal(false)
	}

	if (featureConfig?.hideRetailPrice) {
		return null
	}
	
	const webUserRetailPrice = !(!hasCustomerAdminRole && !hasNetPricesRole)

	return (
		<div className="RetailPriceSwitch">
			{webUserRetailPrice && !alwaysDisplayRetailPrice &&
				<div className='retail-price'>
					<SwitchInput
						name={'showRetail'}
						checked={showRetailPrice}
						onChange={onChangeShowRetailPrice}
						label={t('settings.displayRetailPrice')} 
						description={description ? t('settings.displayRetailPriceDescription') : null}
					/>
				</div>
			}

			{(showRetailPrice || alwaysDisplayRetailPrice) &&
				<div className={classNames('excl-vat', {alone: !webUserRetailPrice})}>
					<SwitchInput
						name={'showRetailExclVat'}
						checked={retailExclVat}
						onChange={onChangeShowRetailExclVat}
						label={t('settings.displayRetailPriceExcludingVat')} 
						description={description ? t('settings.displayRetailPriceExcludingVatDescription') : null}
					/>
				</div>
			}

			{showModal &&
				<Modal label={t('settings.information')}>
					<div className="information">
						<p className="info-text">
							{!retailExclVat ?
								t('settings.DisplayPriceIncludingVatAlert') : t('settings.DisplayPriceExcludingVatAlert')}
						</p>
						<Button label={t('common.close')} onClick={closeHandler}></Button>
					</div>
				</Modal>
			}
		</div>
	)
}

export default RetailPriceSwitch
