import { combineReducers } from 'redux'

import battery from './BatterySearch/batterySearchSlice'
import batteryVoltage from './batteryVoltage/batteryVoltageSlice'
import batteryVehicle from './batteryVehicle/batteryVehicleSlice.js'

export default combineReducers({
	battery,
	batteryVoltage,
	batteryVehicle
})