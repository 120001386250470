import './MobileHeader.scss'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { openLoginModal, selectLoggedIn, sendLogout } from 'features/user/login/loginSlice'
import { openApplicationWindow } from 'features/resellerApplication/resellerApplicationSlice'
import { useTranslation } from 'react-i18next'
import { selectShowMenu, setShowMenu } from 'features/app/documentInfo/documentInfoSlice'
import { loggedOut } from 'utils/redux/commonActions'
import { selectUserInfo } from 'features/user/userInfo/userInfoSlice'
import { useUserRoles } from 'utils/hooks/useUserRoles'
import useMenu from 'utils/hooks/useMenu'
import LanguageSelector from 'components/LanguageSelector/LanguageSelector'
import MobileMainMenu from './MobileMainMenu/MobileMainMenu'
import Icon from '@mdi/react'
import { mdiAccountCircle, mdiMenu } from '@mdi/js'
import Logo from 'components/Logo/Logo'
import MiniCart from 'features/cart/MiniCart/MiniCart'
import QuickSettings from '../QucikSettings/QuickSettings'

const MobileHeader = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const loggedIn = useSelector(selectLoggedIn)
	const showMenu = useSelector(selectShowMenu)
	const userInfo = useSelector(selectUserInfo)
	const { menu } = useMenu()

	const {
		hasCustomerAdminRole,
		hasMyPageRole,
	} = useUserRoles()

	const onClickLogin = () => {
		dispatch(setShowMenu(false))
		dispatch(openLoginModal())
	}

	const onClickNewCustomer = () => {
		dispatch(setShowMenu(false))
		dispatch(openApplicationWindow())
	}

	const toggelMenuHandler = () => {
		dispatch(setShowMenu(!showMenu))
	}

	const onClickLogout = () => {
		dispatch(sendLogout())
		dispatch(loggedOut())
	}

	return (
		<header className="MobileHeader Header mobile">
			<div className="header-wrapper">
				<div className="menu-button" onClick={toggelMenuHandler}>
					<Icon path={mdiMenu} size={'1.875rem'} />
				</div>

				<Link to="/" className="center-logo">
					<Logo size="medium" />
				</Link>
				
				{loggedIn && 
					<MiniCart />
				}

			</div>
			<div className="header-sub-wrapper">
				{loggedIn && (
					<>
						<div className="my-pages-wrapper">
							{userInfo ? (
								<>
									{(hasCustomerAdminRole || hasMyPageRole) ? (
										<Link to={'/my-pages/'}>
											<div className="my-pages-icon">
												<Icon path={mdiAccountCircle} size="1.875rem" />
											</div>
											<span className="my-pages-label">
												{t('common.myPage')}
											</span>
										</Link>
									) : (
										<span className="no-my-page-info" >
											{userInfo?.username ? userInfo?.username : userInfo?.name}, ({userInfo?.id})
										</span>
									)}
								</>
							) : (
								<>
									<div className="my-pages-icon">
										<Icon path={mdiAccountCircle} size="1.875rem" />
									</div>
									<span className="my-pages-label">
										{t('common.login')}
									</span>
								</>	
							)}
						</div>
						<QuickSettings />
					</>
				)}

				{!loggedIn && (
					<div className="login-wrapper">
						<button className="login-button" onClick={onClickLogin}>
							{t('common.login')}
						</button>
					</div>
				)}

				<LanguageSelector />

				<MobileMainMenu
					menu={menu}
					isOpen={showMenu}
					onClose={toggelMenuHandler}
					onClickNewCustomer={onClickNewCustomer}
					onClickLogin={onClickLogin}
					onClickLogout={onClickLogout}
				/>
			</div>
		</header>
	)
}

export default MobileHeader