import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { statusHandler, thunkActions } from 'utils/utils'
import { getBrands, getEngines, getFuelTypes, getModels, getYears } from 'utils/api/batteryVehicleSelectorAPI'

export const fetchBrands = createAsyncThunk('batteryVehicle/getBrands', async () => await getBrands())
export const fetchFuelTypes = createAsyncThunk('batteryVehicle/getFuels', async (brand) => await getFuelTypes(brand))
export const fetchModels = createAsyncThunk('batteryVehicle/getModels', async (payload) => await getModels(payload.brand, payload.fuelType))
export const fetchEngines = createAsyncThunk('batteryVehicle/fetchEngines', async (payload) => await getEngines(payload.brand, payload.fuelType, payload.model, payload.engineType))
export const fetchYears = createAsyncThunk('batteryVehicle/fetchYears', async (payload) => await getYears(payload.brand, payload.fuelType, payload.model, payload.engineType, payload.engine))

const action = thunkActions(fetchBrands, fetchModels)

const initialState = {
	status: 'idle',
	brands: null,
	fuelTypes: null,
	models: null,
	engines: null,
	engineTypes: null,
	years: null
}

const batteryVehicle = createSlice({
	name: 'batteryVehicle',
	initialState,
	reducers: {
		resetEngine: (state) => {
			state.engines = null
			state.years = null
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchBrands.pending, state => {
				state.brands = null
			})
			.addCase(fetchBrands.fulfilled, (state, action) => {
				state.brands = action.payload
			})
			.addCase(fetchFuelTypes.pending, state => {
				state.fuelTypes = null
			})
			.addCase(fetchFuelTypes.fulfilled, (state, action) => {
				state.fuelTypes = action.payload
			})
			.addCase(fetchModels.pending, state => {
				state.models = null
			})
			.addCase(fetchModels.fulfilled, (state, action) => {
				state.models = action.payload.batteryCarModels
				state.engineTypes = action.payload.batteryCarEngineTypeModels
			})
			.addCase(fetchEngines.pending, state => {
				state.engines = null
			})
			.addCase(fetchEngines.fulfilled, (state, action) => {
				state.engines = action.payload
			})
			.addCase(fetchYears.pending, state => {
				state.years = null
			})
			.addCase(fetchYears.fulfilled, (state, action) => {
				state.years = action.payload
			})
			.addMatcher(action, statusHandler)
	}
})

export const selectStatus = state => state.search.battery.batteryVehicle.status
export const selectBrands = state => state.search.battery.batteryVehicle.brands
export const selectFuelTypes = state => state.search.battery.batteryVehicle.fuelTypes
export const selectModels = state => state.search.battery.batteryVehicle.models
export const selectEngines = state => state.search.battery.batteryVehicle.engines
export const selectEngineTypes = state => state.search.battery.batteryVehicle.engineTypes
export const selectYears = state => state.search.battery.batteryVehicle.years

export const { resetEngine } = batteryVehicle.actions

export default batteryVehicle.reducer