import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getVersion } from 'utils/api/versionAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchVersion = createAsyncThunk('version/getVersion', async () => await getVersion())
export const fetchCurrentVersion = createAsyncThunk('version/getCurrentVersion', async () => await getVersion())

const actions = thunkActions(fetchVersion, fetchCurrentVersion)

const versionSlice = createSlice({
	name: 'version',
	initialState: {
		version: null,
		currentVersion: null
	},
	extraReducers: builder => {
		builder
			.addCase(fetchVersion.fulfilled, (state, action) => {
				state.version = action.payload.version
			})
			.addCase(fetchCurrentVersion.fulfilled, (state, action) => {
				state.currentVersion = action.payload.version
			})
			.addMatcher(actions, statusHandler)
	}
})

export const selectVersion = state => state.app.version.version
export const selectCurrentVersion = state => state.app.version.currentVersion

export default versionSlice.reducer
