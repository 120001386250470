import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Filter } from '@barnsten-it/gummidata-react-components'
import { getAllArticles } from 'utils/helper'
import FilterContainer from '@barnsten-it/gummidata-react-components/dist/Filter/FilterContainer/FilterContainer'
import { useCommonFilterSettings } from 'utils/hooks/useCommonFilterSettings'

const TpmsFilter = ({
	result,
	onFilterChange,
	filterState
}) => {
	const [filterSettings, setFilterSettings] = useState(null)
	const [articles, setArticles] = useState(null)
	const { t } = useTranslation()
	const commonFilterSettings = useCommonFilterSettings()

	const createFilter = useCallback(() => {
		if (!result)
			return

		const allArticles = getAllArticles(result)
		const hasOutletArticles = allArticles.some(a => a.isOutlet)

		var settings = [
			{
				name: t('searchFilter.freeFilter'),
				property: 'freeText',
				items: [''],
				type: Filter.type.text,
				condition: (item, selected) => {
					const ids = item.id.toString().toUpperCase().includes(selected.toString().toUpperCase())
					const manufacturerArticleId = item.manufacturerArticleId?.toString().toUpperCase().includes(selected.toString().toUpperCase())
					const articleText = item.articleText?.toString().toUpperCase().includes(selected.toString().toUpperCase())
					return ids || articleText || manufacturerArticleId
				}
			},
			{
				name: t('common.brand'),
				property: 'brandName',
				type: Filter.type.multipleSelect
			}
		]

		if (hasOutletArticles) {
			settings = [
				...settings,
				...commonFilterSettings.outlet
			]
		}

		if (allArticles.some(a => a.preProgrammed)) {
			settings.push({
				name: t('searchFilter.properties'),
				group: [
					{
						name: t('searchFilter.preProgrammed'),
						property: 'preProgrammed',
						type: Filter.type.checkbox,
						condition: (item, selected) => {
							return selected && item.preProgrammed
						}
					},
					{
						name: t('searchFilter.notPreProgrammed'),
						property: 'notPreProgrammed',
						type: Filter.type.checkbox,
						condition: (item, selected) => {
							return selected && !item.preProgrammed
						}
					}
				]
			})
		}

		setArticles(allArticles)
		setFilterSettings(settings)
	}, [t, result, commonFilterSettings.outlet])

	useEffect(() => {
		if (result)
			createFilter()
	}, [result, createFilter])

	if (!filterSettings || !articles || !result)
		return null

	return (
		<div className="TpmsFilter">
			<FilterContainer hasResult={!!result}>
				<Filter
					identifier="id"
					settings={filterSettings}
					items={articles}
					label={t('common.searchFilter')}
					selected={filterState?.selected}
					onFilterChange={onFilterChange} />
			</FilterContainer>
		</div>
	)
}

export default TpmsFilter
