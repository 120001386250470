import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	webTextPreview: false,
	showHeader: true
}

const cmsInfoSlice = createSlice({
	name: 'info',
	initialState,
	reducers: {
		setShowHeader: (state, action) => {
			state.showHeader = action.payload
		},
		toggleWebTextPreview: (state, action) => {
			state.webTextPreview = !state.webTextPreview
		}
	}
})

export const selectWebTextPreview = state => state.cms.info.webTextPreview
export const selectShowHeader = state => state.cms.info.showHeader

export const {
	setShowHeader,
	toggleWebTextPreview
} = cmsInfoSlice.actions

export default cmsInfoSlice.reducer