import './SearchInput.scss'
import React from 'react'
import { useDispatch } from 'react-redux'
import { mdiMagnify, mdiClose } from '@mdi/js'
import Icon from '@mdi/react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import useDebounce from 'utils/hooks/useDebounce'


interface SearchInputProps {
	onSearch: (searchQuery: string) => void
	criteria: any
	criteriaSetter: (criteria: any) => void
} 

const SearchInput = ({
	onSearch,
	criteria,
	criteriaSetter,
}: SearchInputProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	// Run search on server on enter key
	const onEnterKeyHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onSearch(criteria.searchQuery)
		}
	}

	// Add text but don't run search on server
	const onSearchHandler = (searchQuery: string) => {
		dispatch(criteriaSetter({
			searchQuery: searchQuery,
		}))
	}

	// Clear search and run search
	const onClearSearch = () => {
		onSearch('')
		dispatch(criteriaSetter({
			searchQuery: '',
		}))
	}

	// Debounce search
	const onChangeHandler = useDebounce(752)
		.now((event: React.ChangeEvent<HTMLInputElement>) => {
			onSearchHandler(event.target.value)
		})
		.debounced((event: React.ChangeEvent<HTMLInputElement>) => {
			onSearch(event.target.value)
		})

	const haveSearchQuery = criteria.searchQuery !== ''

	return (
		<div className={classNames('SearchInput', { 'have-search-query': haveSearchQuery })}>
			<div className="search-button">
				<Icon path={mdiMagnify} size="1.875rem"/>
			</div>
			<button className="close-button" onClick={onClearSearch}>
				<Icon path={mdiClose} size="1.072rem" />
			</button>
			<input 
				className="text-input"
				type="text" 
				name="searchQuery" 
				placeholder={t('common.search') + '...'}
				onKeyDown={onEnterKeyHandler}
				value={criteria.searchQuery}
				onChange={onChangeHandler.callback} 
			/>
		</div>
	)
}

export default SearchInput