import './SidebarLayout.scss'
import React from 'react'
import loadable,{ LoadableComponent } from '@loadable/component'
import { 
	Sidebar, 
	Loading 
} from '@barnsten-it/gummidata-react-components'
import SubMenu from 'components/SubMenu/SubMenu'
import Footer from 'components/Footer/Footer'
import SidebarMenus from 'utils/enums/SidebarMenus'
import { LayoutProps } from 'utils/wrappers/PageWrapper'

const SidebarLayout: React.FC<LayoutProps<{menu: any}>> = ({
	children,
	data,
	...rest 
}) => {
	const menu = data?.menu

	// Code splitting to get the right sidebar menu
	const SidebarMenu: LoadableComponent<any> = loadable(
		(props: {menu: SidebarMenus}) => import(`./menus/${props.menu}`).catch((error) => {
			throw new Error(`Error: ${error}, Unable to load sidebar menu: ${props.menu}`)
		})
	)

	return (
		<div className="SidebarLayout">
			<SubMenu />
			<div className="content-wrapper overlapping-content">

				<div className="background-wrapper">
					<div className="detail-background"></div>
					<div className="no-background"></div>
				</div>

				<div className="layout-content">
					<div className="content-row content-full">

						<Sidebar onClickCollapse={() => {return null}} show={false}>
							{menu &&
								<SidebarMenu fallback={<Loading darkBackground={true} />} menu={menu}/>
							}
						</Sidebar>
						
						<section className="content-full myPages">
							{children}
							<Footer alignLeft />
						</section>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SidebarLayout
