import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRimsByVehicleId, getRimsBySize, getRimsByCarModel } from 'utils/api/articleSearchAPI'
import searchRimType from 'utils/enums/searchRimType'
import sliceStatus from 'utils/enums/sliceStatus'
import { createResultSelector, isPreviousSearchCategory, isSearchCategory } from 'utils/helper'
import { loggedOut } from 'utils/redux/commonActions'
import { statusHandler, thunkActions } from 'utils/utils'

export const searchRims = createAsyncThunk('aluminiumRimSearch/search', async (criteria) => {
	if (criteria.vehicleId) {
		return await getRimsByVehicleId(criteria.vehicleId, criteria.license, searchRimType.aluminum, criteria.languageCode)
	} else if (criteria.carModel) {
		return await getRimsByCarModel(criteria.carModel, criteria.brandId, searchRimType.aluminum, criteria.languageCode)
	} else {
		return await getRimsBySize(criteria.boltPattern, criteria.boltCircle, criteria.diameter, null, criteria.articleText, searchRimType.aluminum, criteria.languageCode)
	}
})
const searchRimActions = thunkActions(searchRims)

const initialState = {
	status: 'idle',
	result: null,
	filterIds: null,
	filterState: null,
	showCombination: false,
	sortBy: null,
	ascending: null,
	criteria: {
		bolts: '',
		circle: '',
		diameter: '',
		license: '',
		brandId: '',
		modelId: '',
		vehicleId: null,
		articleText: '',
		carModel: null
	}
}

const carAluminiumRimSearch = createSlice({
	name: 'carAluminiumRim',
	initialState: initialState,
	reducers: {
		setFilter: (state, action) => {
			state.filterIds = action.payload.articleIds
			state.filterState = action.payload.filterState
		},
		setShowCombination: (state, action) => {
			state.showCombination = action.payload
		},
		reset: () => initialState,
		sortResult: (state, action) => {
			state.sortBy = action.payload.sortBy
			state.ascending = action.payload.ascending
		},
		setCriteria: (state, action) => {
			state.criteria = {
				...state.criteria,
				...action.payload
			}
		}
	},
	extraReducers: builder => {
		builder
			.addCase(loggedOut, state => {
				state.status = 'idle'
				state.result = null
				state.filterIds = null
			})
			.addCase(searchRims.pending, (state, action) => {
				state.result = null
				state.filterIds = null
				state.filterState = null
				state.sortBy = null
				state.ascending = null
			})
			.addCase(searchRims.fulfilled, (state, action) => {
				state.filterIds = null
				state.result = action.payload
			})
			.addMatcher(isPreviousSearchCategory('car', 'rim'), (state) => {
				state.status = sliceStatus.idle
			})
			.addMatcher(isSearchCategory('car', 'rim'), (state, action) => {
				state.status = sliceStatus.success
				state.result = action.payload
			})
			.addMatcher(searchRimActions, statusHandler)
	}
})

export const selectStatus = state => state.search.aluminiumRim.carAluminiumRim.status
export const selectResult = state => state.search.aluminiumRim.carAluminiumRim.result
export const selectFilterIds = state => state.search.aluminiumRim.carAluminiumRim.filterIds
export const selectFilterState = state => state.search.aluminiumRim.carAluminiumRim.filterState
export const selectShowCombination = state => state.search.aluminiumRim.carAluminiumRim.showCombination
export const selectSortBy = state => state.search.aluminiumRim.carAluminiumRim.sortBy
export const selectAscending = state => state.search.aluminiumRim.carAluminiumRim.ascending
export const selectCriteria = state => state.search.aluminiumRim.carAluminiumRim.criteria

export const selectProcessedResult = createResultSelector(
	selectResult, 
	selectFilterIds, 
	selectSortBy, 
	selectAscending, 
	selectShowCombination
)

export const { 
	setFilter, 
	setShowCombination, 
	sortResult, 
	reset, 
	setCriteria 
} = carAluminiumRimSearch.actions

export default carAluminiumRimSearch.reducer
