import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { INotifications } from 'types/INotifications'
import { getNotifications } from 'utils/api/notificationAPI'
import sliceStateBases, { SliceState } from 'utils/enums/sliceBaseStates'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchNotifications = createAsyncThunk('notifications/fetchNotifications', async () => await getNotifications())
export const cartActions = thunkActions(fetchNotifications)

export interface SubCustomerOrdersState {
	data: INotifications
}

const initialNotificationsState: SliceState<'FEATURE', SubCustomerOrdersState> = {
	...sliceStateBases.FEATURE,
	data: {
		unhandledTickets: 0,
		unhandledInvoices: 0,
		unhandledOrderReservarions: 0
	}
}

const notificationsSlice = createSlice({
	name: 'notificationsSlice',
	initialState: initialNotificationsState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchNotifications.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addCase(fetchNotifications.rejected, (state) => {
				state.data = {}
			})
			.addMatcher(cartActions, statusHandler)
	}
})

export const selectNotifications = (state: RootState) => state.user.notifications.data
export const selectUnhandledTickets = (state: RootState) => state.user.notifications.data.unhandledTickets
export const selectUnhandledInvoices = (state: RootState) => state.user.notifications.data.unhandledInvoices
export const selectUnhandledOrderReservarions = (state: RootState) => state.user.notifications.data.unhandledOrderReservarions

export default notificationsSlice.reducer