import {
	getLocalStorageItem,
	setLocalStorageItem
} from 'utils/utils'
// Importing selectors
import {
	selectAutoScrollToResult,
	selectCompactMode,
	selectDisplayCalculator,
	selectDisplayPriceIncludingVat,
	selectFullScreen,
	selectHideNetPrice,
	selectRetailExclVat,
	selectRetailPrice,
	selectBulkAdd,
	setBulkAdd,
} from 'features/search/searchSettings/searchSettingsSlice'
// Importing setters
import {
	selectDisplayRetailMargins,
	selectGoDirectlyToCart,
	selectHideCreditInfo,
	setAutoScrollToResult,
	setCompactMode,
	setDisplayCalculator,
	setDisplayPriceIncludingVat,
	setDisplayRetailMargins,
	setGoDirectlyToCart,
	setFullScreen,
	setHideCreditInfo,
	setHideNetPrice,
	setRetailExclVat,
	setRetailPrice,
} from 'features/search/searchSettings/searchSettingsSlice'
import {
	useDispatch,
	useSelector
} from 'react-redux'

import SettingsTypes from 'utils/enums/settingsTypes'
import { selectFeatureConfig } from 'features/app/settings/settingsSlice'

/**
 * Load settings from local storage and set them in the store
 * If no local storage is found, set the default value
 * 
 * @returns void
 */
export const useSettingsLoader = () => {
	const dispatch = useDispatch()
	const featureConfig = useSelector(selectFeatureConfig)
	
	const settingsLoader = () => {
		// Set settings on mount
		const displayPriceIncludingVatLocal = getLocalStorageItem(SettingsTypes.displayPriceIncludingVat)
		const retailPriceLocal = getLocalStorageItem(SettingsTypes.retailPrice)
		const autoScrollToResultLocal = getLocalStorageItem(SettingsTypes.autoScrollToResult)
		const fullScreenLocal = getLocalStorageItem(SettingsTypes.fullScreen)
		const compactModeLocal = getLocalStorageItem(SettingsTypes.compactMode)
		const bulkAddLocal = getLocalStorageItem(SettingsTypes.bulkAdd)
		const goDirectlyToCartLocal = getLocalStorageItem(SettingsTypes.goDirectlyToCart)

		// Check selectedWidth
		if (fullScreenLocal) {
			dispatch(setFullScreen(fullScreenLocal === 'true'))
		}
		
		try {
			// Show price including vat
			if (!displayPriceIncludingVatLocal) {
				if (featureConfig?.showPriceIncludingVatDefault) {
					dispatch(setDisplayPriceIncludingVat(true))
					setLocalStorageItem(SettingsTypes.retailExclVat, true)
				}
			} else {
				dispatch(setDisplayPriceIncludingVat(displayPriceIncludingVatLocal === 'true'))
			}

			// Show retail price
			if (!retailPriceLocal) {
				if (featureConfig?.hideRetailPrice) {
					dispatch(setRetailPrice(false))
					setLocalStorageItem(SettingsTypes.retailPrice, false)
				}
			} else {
				dispatch(setRetailPrice(retailPriceLocal === 'true'))
			}

			// Auto scroll to result
			if (!autoScrollToResultLocal) {
				dispatch(setAutoScrollToResult(true))
				setLocalStorageItem(SettingsTypes.autoScrollToResult, true)
			} else {
				dispatch(setAutoScrollToResult(autoScrollToResultLocal === 'true'))
			}

			// Compact mode
			if (!compactModeLocal) {
				dispatch(setCompactMode(true))
				setLocalStorageItem(SettingsTypes.compactMode, true)
			} else {
				dispatch(setCompactMode(compactModeLocal === 'true'))
			}

			// Compact mode
			if (!compactModeLocal) {
				dispatch(setCompactMode(true))
				setLocalStorageItem(SettingsTypes.compactMode, true)
			} else {
				dispatch(setCompactMode(compactModeLocal === 'true'))
			}

			if (!bulkAddLocal) {
				if (featureConfig?.buyButtonPerRow) {
					dispatch(setBulkAdd(false))
					setLocalStorageItem(SettingsTypes.bulkAdd, false)
				}
			} else {
				dispatch(setBulkAdd(Boolean(bulkAddLocal === 'true')))
			}

	
			dispatch(setGoDirectlyToCart(goDirectlyToCartLocal === 'true'))

		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error, 'Error loading settings in settingsLoader')
		}
	}

	return {
		settingsLoader
	}
}


/**
 * Get the setting from the store
 *
 * @param settingName
 * @returns boolean
 */
export const useSettings = () => {
	const dispatch = useDispatch()
	const displayCalculator = useSelector(selectDisplayCalculator)
	const displayPriceIncludingVat = useSelector(selectDisplayPriceIncludingVat)
	const autoScrollToResult = useSelector(selectAutoScrollToResult)
	const compactMode = useSelector(selectCompactMode)
	const hideNetPrice = useSelector(selectHideNetPrice)
	const retailPrice = useSelector(selectRetailPrice)
	const retailExclVat = useSelector(selectRetailExclVat)
	const displayRetailMargins = useSelector(selectDisplayRetailMargins)
	const hideCreditInfo = useSelector(selectHideCreditInfo)
	const bulkAdd = useSelector(selectBulkAdd)
	const goDirectlyToCart = useSelector(selectGoDirectlyToCart)
	const fullScreen = useSelector(selectFullScreen)

	// Get setting from store
	const getSetting = (settingName: SettingsTypes) => {
		try {
			switch (settingName) {
				case SettingsTypes.displayCalculator:
					return displayCalculator
				case SettingsTypes.displayPriceIncludingVat:
					return displayPriceIncludingVat
				case SettingsTypes.autoScrollToResult:
					return autoScrollToResult
				case SettingsTypes.compactMode:
					return compactMode
				case SettingsTypes.hideNetPrice:
					return hideNetPrice
				case SettingsTypes.retailPrice:
					return retailPrice
				case SettingsTypes.retailExclVat:
					return retailExclVat
				case SettingsTypes.displayRetailMargins:
					return displayRetailMargins
				case SettingsTypes.hideCreditInfo:
					return hideCreditInfo
				case SettingsTypes.bulkAdd:
					return bulkAdd
				case SettingsTypes.goDirectlyToCart:
					return goDirectlyToCart
				case SettingsTypes.fullScreen:
					return fullScreen

				default:
					throw new Error('Setting not found')
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('could not find setting ' + settingName + ' in useSetting hook', error)
			return false // default value to return
		}
	}

	// Set setting in store
	const setSetting = (settingName: SettingsTypes, value: boolean, local = false) => {
		try {
			if (local) {
				setLocalStorageItem(settingName, value)
			}

			switch (settingName) {
				case SettingsTypes.displayCalculator:
					return dispatch(setDisplayCalculator(value))
				case SettingsTypes.displayPriceIncludingVat:
					return dispatch(setDisplayPriceIncludingVat(value))
				case SettingsTypes.autoScrollToResult:
					return dispatch(setAutoScrollToResult(value))
				case SettingsTypes.compactMode:
					return dispatch(setCompactMode(value))
				case SettingsTypes.hideNetPrice:
					return dispatch(setHideNetPrice(value))
				case SettingsTypes.retailPrice:
					return dispatch(setRetailPrice(value))
				case SettingsTypes.retailExclVat:
					return dispatch(setRetailExclVat(value))
				case SettingsTypes.displayRetailMargins:
					return dispatch(setDisplayRetailMargins(value))
				case SettingsTypes.hideCreditInfo:
					return dispatch(setHideCreditInfo(value))
				case SettingsTypes.bulkAdd:
					return dispatch(setBulkAdd(value))
				case SettingsTypes.goDirectlyToCart:
					return dispatch(setGoDirectlyToCart(value))

				case SettingsTypes.fullScreen:
					return dispatch(setFullScreen(value))
					
				default:
					throw new Error('Setting not found')
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('could not find setting ' + settingName + ' in useSetting hook', error)
			return false // default value to return
		}
	}

	return {
		getSetting,
		setSetting,
		displayCalculator,
		displayPriceIncludingVat,
		displayRetailMargins,
		autoScrollToResult,
		compactMode,
		retailPrice,
		retailExclVat,
		hideNetPrice,
		hideCreditInfo,
		bulkAdd,
		goDirectlyToCart,
		fullScreen
	}
}