import './Card.scss'
import classNames from 'classnames'
import MainLabel from 'components/labels/MainLabel/MainLabel'
import React from 'react'

interface CardProps {
	children: any
	noPadding?: boolean
	noMargin?: boolean
	margin?: boolean
	title?: string
	flex?: boolean
	grayCard?: boolean
	className?: string
}

/**
 * Card
 * 
 * @description Used for box wrapping around content
 * 
 * @param noPadding
 * @param noMargin
 * @param margin
 * @param flex
 * @param title
 * @param grayCard
 * @param className
 */
const Card: React.FC<CardProps> = ({
	children,
	noPadding = false,
	noMargin = false,
	margin = false,
	flex = false,
	title,
	grayCard = false,
	className,
}) => {
	return (
		<div 
			className={classNames('Card', className, {
				'gray-card': grayCard,
				'no-padding': noPadding,
				'no-margin': noMargin,
				'flex-card': flex,
				'margin': margin,
			})}>

			{title && (
				<MainLabel text={title}>
					{title}
				</MainLabel>
			)}

			{children}
		</div>
	)
}

export default Card