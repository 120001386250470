import './CMSPreviewSwitch.scss'
import React from 'react'
import SwitchInput from '@barnsten-it/gummidata-react-components/dist/Inputs/SwitchInput/SwitchInput'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectWebTextPreview, toggleWebTextPreview } from '../cmsInfoSlice'

const CMSPreviewSwitch = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const webTextPreview = useSelector(selectWebTextPreview)

	return (
		<div className='CMSPreviewSwitch'>
			<SwitchInput
				name=''
				label={t('cms.enablePreview')}
				checked={webTextPreview}
				onChange={() => dispatch(toggleWebTextPreview())}
			/>
		</div>
	)
}

export default CMSPreviewSwitch
