/**
 * importAllImages.ts 
 * 
 * This file is used to import all images from a folder. Using the require.context function in webpack,
 * we can import all images from a folder and its subfolders. This function will return an object with
 * the image file names as keys and the image file paths as values.
 * 
 * Usage:
 * 		importAllImages(require.context('../assets/images', true, /\.(png|jpe?g|svg)$/))
 * 		// OR
 * 		importAllImages(require.context('../assets/images', true, /\.(png|jpe?g|svg)$/), ['png', 'jpg', 'jpeg', 'svg'])
 *
 * @param context - The require context (based on the require.context function in webpack)
 * @param allowedExtensions - The allowed file extensions in an array of strings
 * @returns - An object with the image file names as keys and the image file paths as values
 * 
 */

// Define the require context
interface ImageFiles {
  [key: string]: string;
}

// Define the require context
export type CustomRequireContext = {
  (directory: string, useSubdirectories?: boolean, regExp?: RegExp): {
    keys(): string[];
    <T>(id: string): T;
    id: string;
  };
  keys(): string[];
  id: string;
};

// Define a type for the allowed file extensions
type AllowedExtensions = 'svg' | 'jpg' | 'jpeg' | 'png' | string;

// Define the function
const importAllImages = (
	context: CustomRequireContext,
	allowedExtensions: AllowedExtensions[] = ['png', 'jpg', 'jpeg', 'svg']
): ImageFiles => {
	const imageFiles: ImageFiles = {}

	// Loop through all the files in the context
	context.keys().forEach((key: string) => {
		// Get the file extension
		const extension = key.split('.').pop()?.toLowerCase()

		// If the extension is in the allowed list, add it to the imageFiles object
		if (extension && allowedExtensions.includes(extension as AllowedExtensions)) {
			imageFiles[key.replace('./', '')] = String(context(key))
		}
	})

	return imageFiles
}

export default importAllImages