import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addNotification } from '../../../notifications/notificationSlice'
import { ListViewButton } from '@barnsten-it/gummidata-react-components'
import { fetchCurrentVersion, selectCurrentVersion, selectVersion } from '../versionSlice'

const NewVersionChecker = ({ time }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const reloadPage = () => window.location.reload(true)
	const timer = React.useRef(null)

	const version = useSelector(selectVersion)
	const currentVersion = useSelector(selectCurrentVersion)

	useEffect(() => {
		timer.current = setInterval(() => {
			if (process.env.NODE_ENV !== 'development') {
				dispatch(fetchCurrentVersion())
			}
		}, 60000 * time)

		// clear on component unmount
		return () => {
			clearInterval(timer.current)
		}

	}, [dispatch, time])

	useEffect(() => {
		if (currentVersion && currentVersion !== version) {
			dispatch(addNotification({
				id: 'newVersionChecker',
				text: (
					<div className='row middle-xs'>
						<span style={{ marginRight: '6px' }}>{t('base.newVersionText')}</span>
						<ListViewButton label={t('common.reload')} onClick={reloadPage} />
					</div>
				),
				type: 'warning',
				presistent: true
			}))
		}
	}, [currentVersion, dispatch, t, version])

	return null
}

export default NewVersionChecker
