import React, { useState, useRef } from 'react'
import SvgUserIcon from 'assets/svgs/svgr/UserIcon'
import {
	ChangeAccountIcon,
	LogoutIconSquare
} from 'assets/svgs/svgr'
import { useTranslation } from 'react-i18next'
import { useUserRoles } from 'utils/hooks/useUserRoles'
import { useNavigate } from 'react-router-dom'
import { sendLogout } from 'features/user/login/loginSlice'
import { loggedOut } from 'utils/redux/commonActions'
import { useDispatch, useSelector } from 'react-redux'
import { selectFeatureConfig, selectSettings } from 'features/app/settings/settingsSlice'
import { selectUserInfo } from 'features/user/userInfo/userInfoSlice'
import {
	selectUnhandledInvoices,
	selectUnhandledOrderReservarions,
	selectUnhandledTickets,
} from 'features/user/notifications/notificationsSlice'

import './UserMenu.scss'

export const UserMenu = () => {
	const ref = useRef<HTMLDivElement | null>(null)
	const dispatch = useDispatch()

	const { t } = useTranslation()
	const navigate = useNavigate()

	const settings = useSelector(selectSettings)
	const unhandledInvoices = useSelector(selectUnhandledInvoices)
	const unhandledTickets = useSelector(selectUnhandledTickets)
	const unhandledOrderReservarions = useSelector(selectUnhandledOrderReservarions)
	const userInfo = useSelector(selectUserInfo)
	const featureConfig = useSelector(selectFeatureConfig)

	const [isOpen, setIsOpen] = useState<boolean>(false)

	const {
		hasCustomerAdminRole,
		hasOrderHistoryRole,
		hasInvoicesRole,
		hasMultiUser,
		hasTickets
	} = useUserRoles()

	const openMenuHandler = () => {
		setIsOpen(!isOpen)
	}

	const goToMyTickets = () => {
		navigate('/my-pages/tickets')
	}

	const goToMyOrders = () => {
		navigate('/my-pages/order-history')
	}

	const goToMyInvoices = () => {
		navigate('/my-pages/invoices')
	}

	const goToSwitchAccount = () => {
		navigate('/my-pages/switch-account')
	}

	const logoutHandler = () => {
		dispatch(sendLogout())
		dispatch(loggedOut())
	}

	// Checks if pressing outside the MiniCart wrapper
	React.useEffect(() => {
		const checkIfClickedOutside = (event: { target: any }) => {
			// If the menu is open and the clicked target is not within the menu,

			// @ts-ignore ignoring the contains as its a bitch
			if (isOpen && ref.current && !ref.current.contains(event.target)) {
				setIsOpen(false)
			}
		}
		document.addEventListener('mousedown', checkIfClickedOutside)
		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside)
		}
	}, [isOpen])


	if (userInfo && settings) {

		return (
			<div className="UserMenu" ref={ref}>
				<button
					className="subMenu account-item"
					title={String(userInfo.id)}
					onClick={openMenuHandler}
				>
					<span>
						{userInfo.name ? userInfo.name : userInfo.username}, ({userInfo.id})
					</span>

					<span className="user-icon">
						<SvgUserIcon height="1.25rem" width="1.25rem" />
					</span>

				</button>

				{isOpen && (
					<div className="account-menu">

						{/* Tickets item */}
						{featureConfig?.useTickets && (hasTickets || hasCustomerAdminRole) &&
							<div
								className="menu-items tickets-item"
								title={t('common.tickets').replace('{count}', unhandledTickets)}
								onClick={goToMyTickets}
							>
								<span>
									{t('common.tickets')}
								</span>

								{unhandledOrderReservarions !== 0 && (
									<span className="notification-badge">
										{unhandledTickets}
									</span>
								)}
							</div>
						}
				

						{/* OrderHistory item */}
						{(hasCustomerAdminRole || hasOrderHistoryRole) && (
							<div
								className="menu-items orderhistory-item"
								title={t('header.newOrderReservations').replace('{count}', unhandledOrderReservarions)}
								onClick={goToMyOrders}
							>
								<span>
									{t('webUser.orderHistory')}
								</span>

								{unhandledOrderReservarions !== 0 && (
									<span className="notification-badge">
										{unhandledOrderReservarions}
									</span>
								)}
							</div>
						)}

						{/* Incoice item */}
						{settings?.pageConfig?.invoicePage &&
							(hasCustomerAdminRole || hasInvoicesRole) &&
							(!settings.resellerConfig || (settings.resellerConfig && settings.resellerConfig.visma)) &&
							(
								<div
									className="menu-items invoice-item"
									title={t('header.newInvoices').replace('{count}', unhandledInvoices)}
									onClick={goToMyInvoices}
								>
									<span>
										{t('common.invoices')}
									</span>

									{unhandledInvoices !== 0 && (
										<span className="notification-badge">
											{unhandledInvoices}
										</span>
									)}
								</div>
							)}

						{/* Switch account item */}
						{hasMultiUser &&
							(hasCustomerAdminRole || hasInvoicesRole) &&
							(!settings.resellerConfig || (settings.resellerConfig && settings.resellerConfig.visma)) &&
							<div
								className="menu-items switch-account-item"
								onClick={goToSwitchAccount}
							>
								<span>
									{t('common.switchAccount')}
								</span>

								<ChangeAccountIcon height="1.25rem" width="1.25rem" />
							</div>
						}

						{/* Logout item */}
						<div className="menu-items logout-item" onClick={logoutHandler}>
							<span>{t('common.logout')}</span>
							<LogoutIconSquare height="1.25rem" width="1.25rem" />
						</div>


						{/* logedin as */}
						{hasMultiUser &&
							<>
								<hr />
								<div className="logedin-as-item">
									<div className="logedin-as">
										logedin as:
									</div>
									<div>
										{(userInfo.username) ? userInfo.username : userInfo.name}
									</div>
								</div>
							</>
						}

					</div>
				)}
			</div>
		)
	} else {
		return null
	}
}