import { Filter } from '@barnsten-it/gummidata-react-components'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

interface CommonFilterSetting {
	name: string
	group?: CommonFilterSetting[]
	property?: string
	type?: any
}

interface CommonFilterSettings {
	outlet: CommonFilterSetting[]
}

export const useCommonFilterSettings = (): CommonFilterSettings => {
	const { t } = useTranslation()

	return useMemo(() => {
		const outletFilter: CommonFilterSetting[] = [
			{
				name: t('searchFilter.outlet'),
				group: [
					{
						name: t('searchFilter.outlet'),
						property: 'isOutlet',
						type: Filter.type.checkbox,
					},
				],
			},
		]

		return {
			outlet: outletFilter,
		}
	}, [t])
}