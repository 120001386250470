import './SubMenu.scss'

import ContactInformationButton from './ContactInformationButton/ContactInformationButton'
import { MenuSubItem } from 'utils/hooks/useMenu'
import React from 'react'
import SubMenuItem from './SubMenuItem/SubMenuItem'
import { UserMenu } from './UserMenu/UserMenu'
import classNames from 'classnames'
import { selectLoggedIn } from 'features/user/login/loginSlice'
import { selectScreenWidth } from 'features/app/documentInfo/documentInfoSlice'
import { startsWith } from 'utils/utils'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { useSettings } from 'utils/hooks/useSettings'
import widthVariables from 'utils/enums/widthVariables'

interface SubMenuProps {
	menu?: MenuSubItem[] | null
}

const SubMenu = ({
	menu,
}: SubMenuProps) => {
	const location = useLocation()
	const screenWidth = useSelector(selectScreenWidth)
	const isLoggedIn = useSelector(selectLoggedIn)

	const {
		fullScreen,
	} = useSettings()

	if (isLoggedIn && screenWidth > widthVariables['md-max']) {
		return (
			<div className={classNames('SubMenu', {'full-screen': fullScreen})}>

				<div className="SubMenu-wrapper">
					<div className="menu">
						{menu && menu.map((item: MenuSubItem) => {
							if (item.active) {
								const parts = item.to.split('/')
								const active = startsWith(location.pathname, parts.slice(0, 3).join('/'))
						
								return (
									<SubMenuItem
										key={item.id}
										to={item.to}
										name={item.name}
										active={active}
									/>
								)
							}
							return null
						})}

					  <div className="filler" />

					</div>	
				</div>
				<div className="right-items">
					<ContactInformationButton />
					<UserMenu />					
				</div>
			</div>
		)
	} else if (screenWidth > widthVariables['md-max']) {
		// Makes sure to keep a subMenu when not logged in on desktop
		return <div className="SubMenu notLoggedIn"></div>
	} else {
		return null
	}
}

export default SubMenu
