import './ShopLayout.scss'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import ArticleSearchType from 'utils/enums/articleSearchType'
import SearchFilter from 'features/search/SearchFilter/SearchFilter'
import { useTranslation } from 'react-i18next'
import Footer from 'components/Footer/Footer'
import {
	selectCarSearchConfig,
	selectIndustrySearchConfig,
	selectMotoSearchConfig,
	selectOilSearchConfig,
	selectSearchConfig,
	selectTruckSearchConfig,
} from 'features/app/settings/settingsSlice'
import classNames from 'classnames'
import SearchCategoryHandler from 'features/search/checkCategory/SearchCategoryHandler/SearchCategoryHandler'
import SubMenu from 'components/SubMenu/SubMenu'
import CategoryMenu, { CategoryMenuItem } from 'components/CategoryMenu/CategoryMenu'
import { LayoutProps } from 'utils/wrappers/PageWrapper'

// Menu
import useMenu from 'utils/hooks/useMenu'
import useUrlInterpreter from 'utils/hooks/useUrlInterpreter'

// Car
import tires from 'assets/images/menuIcons/tires.png'
import rims from 'assets/images/menuIcons/rims.png'
import steelRims from 'assets/images/menuIcons/steel-rims.png'
import wheelPackage from 'assets/images/menuIcons/wheel-package.png'
import accessories from 'assets/images/menuIcons/accessories.png'
import tpms from 'assets/images/menuIcons/tpms.png'
import tubes from 'assets/images/menuIcons/tube.png'

// MC & ATV
import mcTires from 'assets/images/menuIcons/MC-tires.png'
import atvTires from 'assets/images/menuIcons/ATV-tires.png'

// industrial
import emTires from 'assets/images/menuIcons/EM-tires.png'
import lvRims from 'assets/images/menuIcons/LV-rims.png'
import shopSearchType from 'utils/enums/shopSearchType'

// Others
import tools from 'assets/images/menuIcons/tools.png'
import oil from 'assets/images/menuIcons/oil.png'
import battery from 'assets/images/menuIcons/battery.png'

const ShopLayout: React.FC<LayoutProps> = ({
	children
}) => {
	const { searchType, articleType } = useUrlInterpreter()
	const { t } = useTranslation()
	
	const searchConfig = useSelector(selectSearchConfig)
	const carSearchConfig = useSelector(selectCarSearchConfig)
	const truckSearchConfig = useSelector(selectTruckSearchConfig)
	const motoSearchConfig = useSelector(selectMotoSearchConfig)
	const industrySearchConfig = useSelector(selectIndustrySearchConfig)
	const oilSearchConfig = useSelector(selectOilSearchConfig)

	const { shop: shopMenu } = useMenu()

	/**
	 * Car article search types
	 */
	const carMenu: CategoryMenuItem[] = useMemo(() => ([
		{
			label: t('common.tyre'),
			articleType: ArticleSearchType.tyre,
			imageSrc: tires,
			disabled: !carSearchConfig?.tyres,
		},
		{
			label: t('common.rim'),
			articleType: ArticleSearchType.aluminiumRim,
			imageSrc: rims,
			disabled: !carSearchConfig?.alloyRims,
		},
		{
			label: t('common.rim'),
			articleType: ArticleSearchType.rim,
			imageSrc: rims,
			disabled: !carSearchConfig?.rims,
		},
		{
			label: t('common.steelRim'),
			articleType: ArticleSearchType.steelRim,
			imageSrc: steelRims,
			disabled: !carSearchConfig?.steelRims,
		},
		{
			label: t('common.wheelPackage'),
			articleType: ArticleSearchType.wheelPackage,
			imageSrc: wheelPackage,
			disabled: !carSearchConfig?.wheels,
		},
		{
			label: t('GROUPNAMES.mainGroup_520'),
			articleType: ArticleSearchType.tubes,
			imageSrc: tubes,
			disabled: !carSearchConfig?.tubes
		},
		{
			label: t('common.tpms'),
			articleType: ArticleSearchType.tpms,
			imageSrc: tpms,
			disabled: !carSearchConfig?.tpms,
		},
		{
			label: t('common.accessories'),
			articleType: ArticleSearchType.accessories,
			imageSrc: accessories,
			disabled: !carSearchConfig?.accessories,
		},
	]), [t, carSearchConfig])


	/**
	 * Truck article search types
	 */
	const truckMenu: CategoryMenuItem[] = useMemo(() => ([
		{
			label: t('common.tyre'),
			articleType: ArticleSearchType.tyre,
			imageSrc: emTires,
			disabled: !truckSearchConfig?.tyres,
		},
		{
			label: t('common.rim'),
			articleType: ArticleSearchType.rim,
			imageSrc: lvRims,
			disabled: !truckSearchConfig?.rims,
		},
		{
			label: t('common.tpms'),
			articleType: ArticleSearchType.tpms,
			imageSrc: tpms,
			disabled: !truckSearchConfig?.tpms,
		},
		{
			label: t('common.accessories'),
			articleType: ArticleSearchType.accessories,
			imageSrc: accessories,
			disabled: !truckSearchConfig?.accessories,
		},
	]), [t, truckSearchConfig])


	/**
	 * Moto article search types
	 */
	const motoMenu: CategoryMenuItem[] = useMemo(() => ([
		{
			label: t('GROUPNAMES.mainGroup_85'),
			articleType: ArticleSearchType.mcTyre,
			imageSrc: mcTires
		},
		{
			label: t('GROUPNAMES.mainGroup_88'),
			articleType: ArticleSearchType.atvTyre,
			imageSrc: atvTires
		},
		{
			label: t('GROUPNAMES.mainGroup_520'),
			articleType: ArticleSearchType.tubes,
			imageSrc: tubes,
			disabled: !motoSearchConfig?.tubes,
		},
	]), [t, motoSearchConfig])


	/**
	 * Industry article search types
	 */
	const industryMenu: CategoryMenuItem[] = useMemo(() => ([
		{
			label: t('common.tyre'),
			articleType: ArticleSearchType.tyre,
			imageSrc: emTires
		},
		{
			label: t('common.rim'),
			articleType: ArticleSearchType.rim,
			imageSrc: lvRims,
			disabled: !industrySearchConfig?.rims,
		},
		{
			label: t('GROUPNAMES.mainGroup_520'),
			articleType: ArticleSearchType.tubes,
			imageSrc: tubes,
			disabled: !industrySearchConfig?.tubes,
		},
		{
			label: t('common.accessories'),
			articleType: ArticleSearchType.accessories,
			imageSrc: accessories,
			disabled: !industrySearchConfig?.accessories,
		}
	]), [t, industrySearchConfig])


	/**
	 * Oil article search types
	 */
	const oilMenu: CategoryMenuItem[] = useMemo(() => ([
		{
			label: t('common.oil'),
			articleType: ArticleSearchType.oil,
			imageSrc: oil,
		},
		{
			label: t('common.accessories'),
			articleType: ArticleSearchType.accessories,
			imageSrc: accessories,
			disabled: !oilSearchConfig?.accessories,
		},
	]), [t, oilSearchConfig])


	/**
	 * Battery article search types
	 */
	const batteryMenu: CategoryMenuItem[] = useMemo(() => ([
		{
			label: t('common.battery'),
			articleType: ArticleSearchType.battery,
			imageSrc: battery
		},
		{
			label: t('common.accessories'),
			articleType: ArticleSearchType.accessories,
			imageSrc: accessories
		}
	]), [t])


	/**
	 * Other article search types
	 */
	const otherMenu: CategoryMenuItem[] = useMemo(() => ([
		{
			label: t('common.other'),
			articleType: ArticleSearchType.other,
			imageSrc: tools,
		},
	]), [t])


	/**
	 * tiny Tyre article search types
	 */
	const tinyTyreMenu: CategoryMenuItem[] = useMemo(() => ([
		{
			label: t('common.tyre'),
			articleType: ArticleSearchType.tyre,
			imageSrc: emTires
		},
	]), [t])

	
	/**
	 * Handles the search type change
	 * 		- Grabs the menu items based on the search type
	 * 
	 * @type {Object} menuType
	*/
	const menuType = useMemo(() => {
		switch (searchType) {
			case shopSearchType.car:
				return carMenu
			case shopSearchType.truck:
				return truckMenu
			case shopSearchType.industry:
				return industryMenu
			case shopSearchType.moto:
				return motoMenu
			case shopSearchType.oil:
				return oilMenu
			case shopSearchType.battery:
				return batteryMenu
			case shopSearchType.other:
				return otherMenu
			case shopSearchType.tinyTyre:
				return tinyTyreMenu
			default:
				return carMenu
		}
	}, [
		searchType, 
		carMenu, 
		truckMenu, 
		industryMenu, 
		motoMenu, 
		oilMenu, 
		batteryMenu, 
		otherMenu,
		tinyTyreMenu
	])

	return (
		<div className={classNames('ShopLayout content-wrapper no-flex detail-background', { 'no-categories': !searchConfig?.enableSelector })}>
			<div className="detail-background">
				<SubMenu menu={shopMenu} />

				<div className="category-wrapper">
					<div className="sidebar-margin">
						{searchConfig?.enableSelector &&
							<CategoryMenu 
								menuItems={menuType} 
							/>
						}
					</div>
				</div>
			</div>
			<div className="no-background">
				<div className="shop-content">
					<SearchFilter 
						searchType={searchType} 
						articleType={articleType} 
					/>
					<section>
						<SearchCategoryHandler />
						{children}
						<Footer alignLeft detailed />
					</section>
				</div>
			</div>
		</div>
	)
}

export default ShopLayout
