import apiClient from './apiClient'

/**
 * Get list of delvieryaddresses for customer
 * @param {string} orderBy - Property to order by
 * @param {number} orderDirection - Ascending: 0, Descending: 1
 * @param {number} skip - Skip count
 * @param {number} take - Take count
 * @returns 
 */
export const fetchAddresses = async (orderBy = 'Id', orderDirection = 0, skip = 0, take = 20) => await apiClient.get('/api/deliveryaddresses', {
	orderBy,
	orderDirection,
	skip,
	take
})


/**
 * Get one delivery address
 * @param {number} id - DeliveryAddressId
 * @returns 
 */
export const fetchAddressById = async id => await apiClient.get(`/api/deliveryaddresses/${id}`)


/**
 * Set the update delivery address
 * @param {Object} payload 
 * @returns 
 */
export const putAddress = async payload => await apiClient.put(`/api/deliveryaddresses/${payload.id}`, payload)

/**
 * add a delivery address
 * @param {Object} payload 
 * @returns 
 */
export const postAddress = async payload => await apiClient.post('/api/deliveryaddresses', payload)

/**
 * Set the default delivery address
 * @param {number} id 
 * @returns 
 */
export const deleteAddress = async id => await apiClient.delete(`/api/DeliveryAddresses/${id}`)

export const getDeliveryAddressStatus = async id => apiClient.get(`/api/DeliveryAddresses/CheckStatus/${id}`)
