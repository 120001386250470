import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Filter } from '@barnsten-it/gummidata-react-components'
import { getAllArticles } from 'utils/helper'
import { selectSettings, selectFeatureConfig } from 'features/app/settings/settingsSlice'
import mainGroup from 'utils/enums/MainGroups'
import FilterContainer from '@barnsten-it/gummidata-react-components/dist/Filter/FilterContainer/FilterContainer'
import { useUserRoles } from '../../../utils/hooks/useUserRoles'
import { getNumberValuesOfArticleProperty } from 'utils/helpers'
import { useCommonFilterSettings } from 'utils/hooks/useCommonFilterSettings'

const RimFilter = ({ 
	result, 
	onFilterChange,
	filterState, 
	showRimType, 
	criteria, 
	isTruckRim 
}) => {
	const [filterSettings, setFilterSettings] = useState(null)
	const [articles, setArticles] = useState(null)
	const { t } = useTranslation()
	const settings = useSelector(selectSettings)
	const filterConfig = settings.filterConfig
	const featureConfig = useSelector(selectFeatureConfig)
	const { hasAutoSelectOERole } = useUserRoles()
	const commonFilterSettings = useCommonFilterSettings()

	const config = useMemo(() => ({
		isECEPlus: filterConfig.rimECE,
		aluminumRim: showRimType,
		steelRim: showRimType,
		isOE: featureConfig.showOeOnRim && !isTruckRim,
		isWinterApproved: !isTruckRim,
		upstepNoteIds: !isTruckRim,
		hasRimCombination: !isTruckRim,
		brandName: filterConfig.rimBrand,
		modelName: filterConfig.rimModel
	}), [filterConfig, showRimType, isTruckRim, featureConfig])

	const settingsFilter = useCallback((f) => {
		const property = f.property
		return config[property] === undefined || config[property]
	}, [config])

	const createFilter = useCallback(() => {
		if (!result)
			return

		const allArticles = getAllArticles(result)
		const searchContainsUpstepNotes = allArticles.some(i => i.upstepNoteIds?.length === 0)
		const hasAnyOEArticles = allArticles.some(i => i.isOE)
		const hasOutletArticles = allArticles.some(a => a.isOutlet)

		let settings = [
			{
				name: t('searchFilter.diameter'),
				property: 'diameter',
				type: Filter.type.multipleSelect
			}
		]

		if (!featureConfig.showAxlePositionOnRim) {
			settings = [
				...settings,
				{
					name: t('searchFilter.rimCombination'),
					property: 'hasRimCombination',
					type: Filter.type.checkbox,
					resetProperty: 'upstepNoteIds',
					condition: (item, selected) => selected && (item.rimCombination?.frontIds?.length > 0 || item.rimCombination?.rearIds?.length > 0)
				}
			]
		}

		if (hasOutletArticles) {
			settings = [
				...settings,
				...commonFilterSettings.outlet
			]
		}

		settings = [
			...settings,
			//...commonFilterSettings.outlet,
			{
				name: t('searchFilter.properties'),
				group: [
					{
						name: t('common.aluminumRim'),
						property: 'aluminumRim',
						type: Filter.type.checkbox,
						condition: (item, selected) => {
							return selected && item.mainGroupId === mainGroup.aluminumRim
						}
					},
					{
						name: t('common.steelRim'),
						property: 'steelRim',
						type: Filter.type.checkbox,
						condition: (item, selected) => {
							return selected && item.mainGroupId === mainGroup.steelRim
						}
					},
					{
						name: t('shopPage.originalFit'),
						property: 'isOE',
						type: Filter.type.checkbox,
						preSelected: (hasAnyOEArticles && (filterConfig.selectIsOE || hasAutoSelectOERole) && !!criteria?.tecDocId),
						condition: (item, selected) => selected && (filterConfig.ignoreTopSortingOE ? (item.isOE || item.topSorting) : item.isOE)
					},
					{
						name: t('searchFilter.ece'),
						property: 'isECEPlus',
						type: Filter.type.checkbox,
						condition: (item, selected) => selected && item.isOE && item.isECE
					},
					{
						name: t('searchFilter.winterApproved'),
						property: 'isWinterApproved',
						type: Filter.type.checkbox
					},
					{
						name: t('searchFilter.hasNoNotes'),
						property: 'upstepNoteIds',
						preSelected: searchContainsUpstepNotes && !allArticles.some(a => a.hasRimCombination),
						type: Filter.type.checkbox,
						condition: (item, selected) => selected && item.upstepNoteIds && item.upstepNoteIds.length === 0
					},
				].filter(settingsFilter)
			},
			{
				name: t('common.width'),
				property: 'width',
				type: Filter.type.range,
				step: 0.5
			},
			{
				name: t('common.centerBore'),
				property: 'centerBore',
				type: Filter.type.range,
				step: 100,
				customValues: getNumberValuesOfArticleProperty(allArticles, 'centerBore'),
			},
			{
				name: t('searchFilter.rimOffset'),
				property: 'offset',
				type: Filter.type.range
			},
			{
				name: t('articleInfo.maxLoad'),
				property: 'maximumLoad',
				type: Filter.type.range
			},
			{
				name: t('common.brand'),
				property: 'brandName',
				type: Filter.type.multipleSelect,
				enableLimit: true,
				sortEnabled: true,
				showMoreText: t('common.showMore')
			},
			{
				name: t('common.carModel'),
				property: 'modelName',
				type: Filter.type.multipleSelect,
				enableLimit: true,
				sortEnabled: true,
				showMoreText: t('common.showMore'),
				hideDisabled: true
			}
		]

		if (filterConfig.rimParentColor) {
			settings = [
				...settings,
				{
					name: t('common.parentColor'),
					property: 'parentColor',
					type: Filter.type.multipleSelect,
					enableLimit: true,
					showMoreText: t('common.showMore')
				}
			]
		}

		if (filterConfig.rimColor) {
			settings = [
				...settings,
				{
					name: t('common.color'),
					property: 'color',
					type: Filter.type.multipleSelect,
					enableLimit: true,
					sortEnabled: true,
					showMoreText: t('common.showMore')
				}
			]
		}

		setArticles(allArticles)
		setFilterSettings(settings.filter(settingsFilter))
	}, [
		t,
		result,
		filterConfig,
		settingsFilter,
		commonFilterSettings.outlet,
		hasAutoSelectOERole,
		featureConfig,
		criteria
	])

	useEffect(() => {
		if (result)
			createFilter()
	}, [result, createFilter])

	if (!filterSettings || !articles || !result)
		return null

	return (
		<div className="RimFilter">
			<FilterContainer hasResult={!!result}>
				<Filter
					identifier="id"
					settings={filterSettings}
					items={articles}
					label={t('common.searchFilter')}
					selected={filterState?.selected}
					onFilterChange={onFilterChange} />
			</FilterContainer>
		</div>
	)
}

export default RimFilter
