const searchRimType = {
	aluminum: 1,
	steel: 2,
	steelLV: 3,
	aluminumAndSteel: 4,
	industry: 5,
} as const

export default searchRimType

export type RimSearchType = keyof typeof searchRimType
