import './LoginSection.scss'

import {
	Button,
	Checkbox,
	Notifications,
	TextInput,
} from '@barnsten-it/gummidata-react-components'
import React, {
	FC,
	useState
} from 'react'
import {
	closeLoginModal,
	selectLoginError,
	selectLoginFailed,
	selectLoginStatus,
	sendLogin,
	setResetPassword,
} from 'features/user/login/loginSlice'
import {
	useDispatch,
	useSelector
} from 'react-redux'
import {
	validate,
	validateObject
} from '@barnsten-it/object-validator'
import validationRules, { LoginValidationRules } from './validationRules'

import HorizontalSpacer from 'components/HorizontalSpacer/HorizontalSpacer'
import HttpStatusCodes from 'utils/enums/HttpStatusCodes'
import InputLabel from 'components/labels/InputLabel/InputLabel'
import customerLoginType from 'utils/enums/customerLoginTypes'
import { openApplicationWindow } from 'features/resellerApplication/resellerApplicationSlice'
import { reset } from 'features/search/aluminiumRim/CarAluminiumRimSearch/carAluminiumRimSearchSlice'
import { resetVehicleSearch } from 'features/vehicle/search/searchSlice'
import { selectCustomerLoginType, selectSiteConfig } from 'features/app/settings/settingsSlice'
import { useTranslation } from 'react-i18next'

interface LoginModel {
	username: string
	password: string
}

const LoginSection: FC = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const siteConfig = useSelector(selectSiteConfig)
	const loginType = useSelector(selectCustomerLoginType)
	const error = useSelector(selectLoginError)
	const loginFailed = useSelector(selectLoginFailed)
	const status = useSelector(selectLoginStatus)

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [rememberMe, setRememberMe] = useState(false)
	const [errors, setErrors] = useState<LoginModel>({ username: '', password: '' })


	/**
	 * 
	 * @param {*} e 
	 * @returns 
	 */
	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		const payload = { username, password, rememberMe }
		const validation = validateObject(validationRules(), payload)

		if (!validation.valid) {
			setErrors(validation.properties)
			return
		}

		dispatch(reset())
		dispatch(resetVehicleSearch())
		dispatch(sendLogin(payload))
	}


	/**
	 * Validate input on blur
	 * @param {*} e
	 */
	const validateInput = (e: React.FocusEvent<HTMLInputElement>) => {
		const { 
			name, 
			value 
		} = e.target

		if (errors.hasOwnProperty(name)) {
			const validation = validate(validationRules()[name as keyof LoginValidationRules], value)

			setErrors({ 
				...errors, 
				[name]: validation.error 
			})
		}
	}

	const onClickNewCustomer = () => {
		dispatch(closeLoginModal())
		dispatch(openApplicationWindow())
	}

	const type = loginType === customerLoginType.email ? 'email' : 'text'

	const getErrorMessage = (error: HttpStatusCodes | string | undefined) => {
		if (!error) {
			return ''
		}

		// Ensure error is treated as a number whether it comes as a string or a number
		const errorCode = typeof error === 'number' ? error : parseInt(error, 10)

		switch (errorCode) {
			case HttpStatusCodes.Conflict:
				return `${t('resetPassword.errorWithAccount')} ${t('common.errorCode')}: 11`
			case HttpStatusCodes.BadRequest:
				return t('common.loginFailed')
			case HttpStatusCodes.Unauthorized:
				return t('common.loginLocked')
			default:
				return '' // Or any default message
		}
	}

	return (
		<div className="LoginSection">
			<form onSubmit={onSubmit} className="login-form">
				<InputLabel
					text={
						loginType === customerLoginType.email
							? t('common.email')
							: `${t('common.email')} / ${t('common.customerNo')}`
					}
				/>
				<TextInput
					name="username"
					value={username}
					type={type}
					error={errors.username}
					onBlur={validateInput}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
					disabled={status === 'loading'}
					placeholder={t('common.user')}
				/>
				<InputLabel topMargin={true} text={t('common.password')} />
				<TextInput
					name="password"
					value={password}
					type="password"
					autocomplete={false}
					error={errors.password}
					onBlur={validateInput}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
					disabled={status === 'loading'}
					placeholder={t('common.password')}
				/>
				<div className="password-panel">
					<Checkbox
						name="rememberMe"
						checked={rememberMe}
						label={t('common.rememberMe') as string}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRememberMe(e.target.checked)}
					/>
					<button
						className="reset-button" 
						onClick={() => dispatch(setResetPassword(true))}
						type='button'
					>
						{t('resetPassword.forgotPassword')}
					</button>
				</div>

				{loginFailed && (
					<div className="message-box">
						<Notifications
							active={true}
							type={'error'}
							wideSize={true}
							absolute={false}
							message={getErrorMessage(error)}
						/>
					</div>
				)}
				
				<Button label={t('common.login')} color="secondary" />
			</form>

			{siteConfig?.customerApplication && (
				<div>
					<HorizontalSpacer label={t('common.or').toLowerCase()} />
					<Button
						label={t('common.becomeCustomer')}
						onClick={onClickNewCustomer}
					/>
				</div>
			)}
		</div>
	)
}

export default LoginSection
