import './SearchFilter.scss'
import React from 'react'
import { useLocation } from 'react-router-dom'
import articleSearchType from 'utils/enums/articleSearchType'
import shopSearchType from 'utils/enums/shopSearchType'

// Filters
import AluminiumRimSearchFilter from '../aluminiumRim/CarAluminiumRimSearch/CarAluminiumRimSearchFilter/CarAluminiumRimSearchFilter'
import SteelRimSearchFilter from '../steelRim/CarSteelRimSearch/CarSteelRimSearchFilter/CarSteelRimSearchFilter'
import TyreSearchFilter from '../tyre/CarTyreSearch/CarTyreSearchFilter/CarTyreSearchFilter'
import McTyreSearchFilter from '../tyre/McTyreSearch/McTyreSearchFilter/McTyreSearchFilter'
import AtvTyreSearchFilter from '../tyre/AtvTyreSearch/AtvTyreSearchFilter/AtvTyreSearchFilter'
import IndustryRimSearchFilter from '../rim/IndustryRimSearch/IndustryRimSearchFilter/IndustryRimSearchFilter'
import IndustryTyreSearchFilter from '../tyre/IndustryTyreSearch/IndustryTyreSearchFilter/IndustryTyreSearchFilter'
import RimSearchFilter from '../rim/CarRimSearch/CarRimSearchFilter/RimSearchFilter'
import BatterySearchFilter from '../battery/BatterySearch/BatterySearchFilter/BatterySearchFilter'
import TruckTyreSearchFilter from '../tyre/TruckTyreSearch/TruckTyreSearchFilter/TruckTyreSearchFilter'
import TruckRimSearchFilter from '../rim/TruckRimSearch/TruckRimSearchFilter/TruckRimSearchFilter'
import CarTpmsSearchFilter from '../tpms/CarTpmsSearch/CarTpmsSearchFilter/CarTpmsSearchFilter'
import TruckTpmsSearchFilter from '../tpms/TruckTpmsSearch/TruckTpmsSearchFilter/TruckTpmsSearchFilter'
import OtherAccessoriesSearchFilter from '../accessory/OtherAccessorySearch/OtherAccessoriesSearchFilter/OtherAccessoriesSearchFilter'
import BatteryAccessoriesSearchFilter from '../accessory/BatteryAccessorySearch/BatteryAccessoriesSearchFilter/BatteryAccessoriesSearchFilter'
import IndustryAccessoriesSearchFilter from '../accessory/IndustryAccessorySearch/IndustryAccessoriesSearchFilter/IndustryAccessoriesSearchFilter'
import OilSearchFilter from '../oil/OilSearch/OilSearchFilter/OilSearchFilter'
import MotoTubeSearchFilter from '../tube/MotoTubesSearch/MotoTubeSearchFilter/MotoTubeSearchFilter'
import IndustryTubeSearchFilter from '../tube/IndustryTubesSearch/IndustryTubeSearchFilter/IndustryTubeSearchFilter'
import CarTubesSearchFilter from '../tube/CarTubesSearch/CarTubesSearchFilter/CarTubesSearchFilter'
import OilAccessoriesSearchFilter from '../accessory/OilAccessorySearch/OilAccessoriesSearchFilter/OilAccessoriesSearchFilter'
import AllProductsSearchFilter from 'components/filters/AllProductsSearchFilter/AllProductsSearchFilter'
import CarAccessoriesSearchFilter from '../accessory/CarAccessorySearch/CarAccessoriesSearchFilter/CarAccessoriesSearchFilter'

import WheelPackageSearchFilter from '../wheelPackage/CarWheelPackageSearch/CarWheelPackageSearchFilter/CarWheelPackageSearchFilter'
import PremadeWheelPackageFilter from '../wheelPackage/PremadeWheelPackageSearch/PremadeWheelPackageFilter/PremadeWheelPackageFilter'
import TinyTyreSearchFilter from '../tyre/TinyTyreSearch/TinyTyreSearchFilter/TinyTyreSearchFilter'

interface SearchFilterProps {
	searchType: shopSearchType | null
	articleType: articleSearchType | null
}

const SearchFilter = ({
	searchType,
	articleType,
}: SearchFilterProps) => {
	const location = useLocation()
	const search = articleType === articleSearchType.all && searchType === shopSearchType.search
	
	const preMadePackage = location?.pathname === '/shop/car/wheelPackage/premade'
	const wheelPackage = location?.pathname?.includes('/shop/car/wheelPackage/custom')

	const atvTyre = articleType === articleSearchType.atvTyre && searchType === shopSearchType.moto
	const tinyTyre = articleType === articleSearchType.tyre && searchType === shopSearchType.tinyTyre
	const lvRim = articleType === articleSearchType.rim && searchType === shopSearchType.truck
	const industryTyre = articleType === articleSearchType.tyre && searchType === shopSearchType.industry
	const industryRim = articleType === articleSearchType.rim && searchType === shopSearchType.industry
	const mcTyre = articleType === articleSearchType.mcTyre && searchType === shopSearchType.moto
	const carTyre = articleType === articleSearchType.tyre && searchType === shopSearchType.car
	const carRim = articleType === articleSearchType.rim && searchType === shopSearchType.car
	const carAluRim = articleType === articleSearchType.aluminiumRim && searchType === shopSearchType.car
	const carSteelRim = articleType === articleSearchType.steelRim && searchType === shopSearchType.car
	const accessories = articleType === articleSearchType.accessories && searchType === shopSearchType.car
	const truckTyre = articleType === articleSearchType.tyre && searchType === shopSearchType.truck
	const carTpms = articleType === articleSearchType.tpms && searchType === shopSearchType.car
	const truckTpms = articleType === articleSearchType.tpms && searchType === shopSearchType.truck
	const motoTubes = articleType === articleSearchType.tubes && searchType === shopSearchType.moto
	const carTubes = articleType === articleSearchType.tubes && searchType === shopSearchType.car
	const industryTubes = articleType === articleSearchType.tubes && searchType === shopSearchType.industry
	const otherAccessories = articleType === articleSearchType.tyre && searchType === shopSearchType.other
	const industryAccessories = articleType === articleSearchType.accessories && searchType === shopSearchType.industry

	const battery = articleType === articleSearchType.battery && searchType === shopSearchType.battery
	const batteryAccessory = articleType === articleSearchType.accessories && searchType === shopSearchType.battery
	const oil = articleType === articleSearchType.oil && searchType === shopSearchType.oil
	const oilAccessory = articleType === articleSearchType.accessories && searchType === shopSearchType.oil
	
	const rimCatalog = location?.pathname.includes('rimCatalog')

	return (
		<div className='SearchFilter'>
			{oil && <OilSearchFilter />}
			{preMadePackage && <PremadeWheelPackageFilter />}
			{wheelPackage && <WheelPackageSearchFilter />}
			{atvTyre && <AtvTyreSearchFilter />}
			{tinyTyre && <TinyTyreSearchFilter />}
			{lvRim && <TruckRimSearchFilter />}
			{industryRim && <IndustryRimSearchFilter />}
			{industryTyre && <IndustryTyreSearchFilter />}
			{truckTyre && <TruckTyreSearchFilter />}
			{mcTyre && <McTyreSearchFilter />}
			{carTyre && <TyreSearchFilter />}
			{(carRim || rimCatalog) && <RimSearchFilter />} 
			{carAluRim && <AluminiumRimSearchFilter />}
			{carSteelRim && <SteelRimSearchFilter />}
			{accessories && <CarAccessoriesSearchFilter />}
			{carTpms && <CarTpmsSearchFilter />}
			{truckTpms && <TruckTpmsSearchFilter />}
			{battery && <BatterySearchFilter />}
			{batteryAccessory && <BatteryAccessoriesSearchFilter />}
			{motoTubes && <MotoTubeSearchFilter />}
			{industryTubes && <IndustryTubeSearchFilter />}
			{carTubes && <CarTubesSearchFilter/>}
			{otherAccessories && <OtherAccessoriesSearchFilter />}
			{industryAccessories && <IndustryAccessoriesSearchFilter />}
			{oilAccessory && <OilAccessoriesSearchFilter />}
			{search && <AllProductsSearchFilter />}
		</div>
	)
}

export default SearchFilter
