import { FeatureModel } from 'types/api'
import { WebStartPageType } from '../enums/webStartPageType'
import apiClient from './apiClient'

export const getShopSettings = async (): Promise<ShopSettings> => await apiClient.get('/api/ShopSettings')

export interface ShopSettings {
	defaultTyreType: number
	businessId: number
	marketId: number
	shortDateFormat: string
	longDateShortTimeFormat: string
	trackingUrls: TrackingUrlDetails[]
	studdingPrice: number
	programmingPrice: number
	assemblyPrice: number
	kitsetPrice: number
	vatPercent: number
	defaultOfferText: string
	maintenance: boolean
	maxQuantityShown: number
	theme: string
	hasLogo: boolean
	logo: string
	logoText: string
	domain: string
	symbol: string
	title: string
	countryCode: string
	languageConfig: LanguageConfig
	siteConfig: SiteConfig
	socialConfig: SocialConfig
	resellerConfig: ResellerConfig
	filterConfig: FilterConfig
	pageConfig: PageConfig
	featureConfig: FeatureModel
	extraPages: ExtraPageDetails[]
	eprelUrl: string
	startPageType: WebStartPageType
	handlingAddedDays: string
	showWarehousesAsOneLocation: boolean
}

export interface TrackingUrlDetails {
	wayOfDeliveryId: number
	trackingUrlId: number
	url: string
}

export interface LanguageConfig {
	defaultLanguage: string
	displaySwedish: boolean
	displayEnglish: boolean
	displayFinnish: boolean
	displayRussian: boolean
	displayNorwegian: boolean
	displayLithuanian: boolean
	displayLatvian: boolean
	displayEstonian: boolean
	showLanguageSelect: boolean
}

export interface SiteConfig {
	calculator: boolean
	listPrice: boolean
	stopTime: boolean
	showWeight: boolean
	customerBalance: boolean
	paymentExternal: boolean
	paymentSwish: boolean
	promotions: boolean
	offers: boolean
	resetPassword: boolean
	awaitOrder: boolean
	showAllOrderRows: boolean
	carRegistrySearch: boolean
	cookies: boolean
	showAvailableCount: boolean
	assemblyDefault: boolean
	webUsers: boolean
	matchArticles: boolean
	customerStock: boolean
	priceIncludingVat: boolean
	authFeatures: boolean
	onlyResetPassword: boolean
	newsPage: boolean
	customerApplication: boolean
	creditControl: boolean
}

export interface SocialConfig {
	linkedin: string
	facebook: string
	instagram: string
	youtube: string
	twitter: string
	reddit: string
	snapchat: string
	whatsApp: string
	messenger: string
	weChat: string
}

export interface ResellerConfig {
	affiliateRetailPrice: boolean
	visma: boolean
	creditControl: boolean
	customTyreGroups: boolean
	customSearchConfig: boolean
	consumerOption: boolean
	usePriceTemplate: boolean
	priceCalculationMethod: number
	articleListExport: boolean
	disableDeliveryAddress: boolean
	deleteOrder: boolean
}

export interface FilterConfig {
	selectIsOE: boolean
	ignoreTopSortingOE: boolean
	rimModel: boolean
	rimBrand: boolean
	rimParentColor: boolean
	rimColor: boolean
	tyreCombination: boolean
	selectCombination: boolean
	rimECE: boolean
}

export interface PageConfig {
	invoicePage: boolean
	helpPage: boolean
	myPage: boolean
	wheelPackages: boolean
	informationPage: boolean
	tyreInfo: boolean
	extraTyreInfo: boolean
	termsOfPurchase: boolean
	rimInfo: boolean
	tyreCalculation: boolean
	rimCalculation: boolean
	webDownloads: boolean
	contactPage: boolean
	privacyPolicy: boolean
	aboutPage: boolean
}

export interface FeatureConfig {
	selectTPMSArticle: boolean
	showTPMSCarModel: boolean
	showFrictionAsStudFree: boolean
	wheelPackagePreSelectedTyreTypeWinter: boolean
	disableToOrderAddToCart: boolean
	disableIncomingAddToCart: boolean
	showOutOfStockInfo: boolean
	hideRetailPrice: boolean
	showRecommendedPrice: boolean
	hideListPrice: boolean
	hideLicenseSearch: boolean
	hideSeatLocation: boolean
	showPriceIncludingVatDefault: boolean
	customerLoginType: 0 | 1
	hideEngineSelect: boolean
	vehicleSearchType: 0 | 1
	newAddressesAsTemporary: boolean
	showAxlePositionOnRim: boolean
	showRimRemarks: boolean
	showPaymentInTimeDiscountPercentage: boolean
	showTyreLogo: boolean
	hideRecyclingFee: boolean
	showCookieAndGDPR: boolean
	showOnlyOnePolicy: boolean
	showOeOnRim: boolean
	setTpmsProgramming: boolean
	setStudding: boolean
	useBusinessUnmanned: boolean
	useTickets: boolean
	ticketSettings: TicketSettings
	usePreselectedSeason: boolean
	buyButtonPerRow: boolean
	disableOrderReservation: boolean
	dropdownQtyForWheelpackages: boolean
	showCutOffInstedOfWarehouse: boolean
	confirmOnSendOrder: boolean
	enableNotification: boolean
	enableQuickSearch: boolean
	wayBillPdf: boolean
	enableWayOfDelivery: boolean
	warnOnEarlyTpms: boolean
	hideEmptyWarehouseLocations: boolean
	enableNotificationCost: boolean
	enableWayOfDeliveryCost: boolean
	enableTpmsManual: boolean
	showArticleId: boolean
	showWarehousesOnWeb: boolean
	lockDeliveryAddressCountryPicker: boolean
	showLoggedOutRimSearch: boolean
	showNetPriceSwitchInListViewControls: boolean
	showSalesPerson: boolean
	enableCostBelowThreshhold: boolean
}

export interface ExtraPageDetails {
	title: string
	translation: string
	link: string
	externalLink: boolean
	requireLogin: boolean
	webText: string
}

export interface TicketSettings {
	showWarranty: boolean
	showMisShipment: boolean
	showReturn: boolean
	showClaim: boolean
	preSeasonReturnsEnabled: boolean
	realizationReturnsEnabled: boolean
	maxAgeOfPreSeasonOrderReturn: number
	useReturnsEnabledSettings: boolean
	returnMaxDays: number
}
