import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ConfirmInvoicesModel, NewInvoiceModel } from 'types/api'
import apiClient from 'utils/api/apiClient'
import { statusHandler, thunkActions } from 'utils/utils'

// Define types for the payloads and state
interface NewInvoicesState {
	status: string
	data: NewInvoiceModel[] | null
	showNewInvoices: boolean
}

// Async thunks for fetching and validating new invoices
export const getNewInvoices = createAsyncThunk('newInvoices/getNewInvoices', async () => await apiClient.get('/api/InvoicesNew'))
export const postNewInvoicesValidated = createAsyncThunk('newInvoices/postNewInvoices',async (payload: ConfirmInvoicesModel) => await apiClient.post('/api/InvoicesNew', payload))

// Combine actions for matcher
export const invoiceAction = thunkActions(getNewInvoices, postNewInvoicesValidated)

// Initial state
const initialState: NewInvoicesState = {
	status: 'idle',
	data: null,
	showNewInvoices: false
}

// Create the slice
const newInvoicesSlice = createSlice({
	name: 'newInvoices',
	initialState,
	reducers: {
		setShowNewInvoices: (state, action: PayloadAction<boolean>) => {
			state.showNewInvoices = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getNewInvoices.fulfilled, (state, action: PayloadAction<any>) => {
				state.data = action.payload
				state.status = 'succeeded'
			})
			.addCase(getNewInvoices.rejected, (state) => {
				state.data = null
				state.status = 'failed'
			})
			.addCase(postNewInvoicesValidated.fulfilled, (state, action: PayloadAction<any>) => {
				state.data = action.payload
				state.status = 'succeeded'
			})
			.addCase(postNewInvoicesValidated.rejected, (state) => {
				state.data = null
				state.status = 'failed'
			})
			.addMatcher(invoiceAction, statusHandler)
	}
})

// Selectors
export const selectNewInvoiceStatus = (state: RootState) => state.user.newInvoices.status
export const selectNewInvoices = (state: RootState) => state.user.newInvoices.data
export const selectShowNewInvoices = (state: RootState) => state.user.newInvoices.showNewInvoices

// Export actions
export const { setShowNewInvoices } = newInvoicesSlice.actions

// Export reducer
export default newInvoicesSlice.reducer
