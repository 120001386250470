import'./LayoutControls.scss'

import {
	selectHighResolution,
	selectScreenWidth,
} from 'features/app/documentInfo/documentInfoSlice'

import ArrowCollapseIcon from './assets/ArrowCollapsIcon'
// Icons
import ArrowExpandIcon from './assets/ArrowExpandIcon'
import React from 'react'
import SettingsTypes from 'utils/enums/settingsTypes'
import ViewGridIcon from './assets/ViewGridIcon'
import ViewListIcon from './assets/ViewListIcon'
import ViewTypes from 'utils/enums/viewTypes'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useSettings } from 'utils/hooks/useSettings'
import { useTranslation } from 'react-i18next'
import widthVariables from 'utils/enums/widthVariables'

interface	LayoutControlsProps {
	onViewTypeChange?: (viewType: ViewTypes) => void
	selectedViewType?: ViewTypes
	standalone?: boolean
}

const LayoutControls = ({
	onViewTypeChange,
	selectedViewType,
	standalone = false
}: LayoutControlsProps) => {
	const { t } = useTranslation()
	const highResolution = useSelector(selectHighResolution)
	const screenWidth = useSelector(selectScreenWidth)

	const {
		fullScreen,
		setSetting
	} = useSettings()

	const listViewSelectHandler = () => {
		if (typeof onViewTypeChange !== 'undefined') {
			onViewTypeChange(ViewTypes.list)
		}
	}

	const gridViewSelectHandler = () => {
		if (typeof onViewTypeChange !== 'undefined') {
			onViewTypeChange(ViewTypes.grid)
		}
	}

	return (
		<div className={classNames('LayoutControls', {standalone})}>
			{highResolution &&
			<span className="screen-mode-type">
				<button
					title={t('common.wideView')}
					className={classNames('view-button', { active: fullScreen })}
					onClick={() => setSetting(SettingsTypes.fullScreen, true, true)}>
					<ArrowExpandIcon />
				</button>
				<button
					title={t('common.narrowView')}
					className={classNames('view-button', { active: !fullScreen })}
					onClick={() => setSetting(SettingsTypes.fullScreen, false, true)}>
					<ArrowCollapseIcon />
				</button>
			</span>
			}

			{!(screenWidth < widthVariables['md-min'] || (typeof onViewTypeChange === 'undefined' || standalone)) &&
			<span className="view-type">
				<button
					title={t('common.listView')}
					className={classNames('view-button', { active: selectedViewType === ViewTypes.list })}
					onClick={listViewSelectHandler}>
					<ViewListIcon />
				</button>
				<button
					title={t('common.productCardView')}
					className={classNames('view-button', { active: selectedViewType === ViewTypes.grid })}
					onClick={gridViewSelectHandler}>
					<ViewGridIcon />
				</button>
			</span>
			}
		</div>
	)
}

export default LayoutControls