import './ScrollBox.scss'
import React, {
	FunctionComponent,
	useEffect,
	useRef,
	useState,
	DOMAttributes,
} from 'react'
import classNames from 'classnames'
import { useResizeDetector } from 'react-resize-detector'

interface Props {
	defaultBottom?: boolean
	disabled?: boolean
	children: React.ReactNode
}

export const ScrollBox: FunctionComponent<Props> = ({
	defaultBottom,
	disabled,
	children,
}) => {
	const [scrollPosition, setScrollPosition] = useState('none')
	const scrollRef = useRef<HTMLDivElement>(null)
	const { height } = useResizeDetector({
		targetRef: scrollRef,
		refreshMode: 'debounce',
		refreshRate: 0,
	})

	// The scroll listener
	const scrollHandler: DOMAttributes<HTMLDivElement>['onScroll'] = (e) => {
		const target = e.currentTarget
		
		if (target.scrollTop < 5) {
			setScrollPosition('top')
		}
		if (target.scrollTop > 5) {
			setScrollPosition('center')
		}
		if (target.scrollTop > target.scrollHeight - target.offsetHeight - 5) {
			setScrollPosition('bottom')
		}
	}

	useEffect(() => {
		if (!scrollRef.current) return
		if (disabled) return
		
		const elem = scrollRef.current

		if (elem.scrollHeight > elem.clientHeight) {
			setScrollPosition('none')
		}
	}, [height, disabled])


	useEffect(()=> {
		if (!scrollRef.current) return

		if (defaultBottom && !disabled) {
			scrollRef.current.scrollTo({
				top: scrollRef.current.scrollHeight,
				behavior: 'smooth',
			})
		}
	}, [defaultBottom, disabled])
	
	if (disabled) {
		return (
			<div>
				{children}
			</div>
		)
	}

	return (
		<div
			className={classNames({
				'ScrollBox': true,
				'fancyScroll': true,
				[scrollPosition]: true,
			})}
			ref={scrollRef}
			onScroll={scrollHandler}
		>
			{children}
		</div>
	)
}

export default ScrollBox
