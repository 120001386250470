import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchRetailMarkUps, postAllRetailMarkUp, putRetailMarkUp } from 'utils/api/retailPricesAPI'
import { statusHandler, thunkActions } from 'utils/utils'

export const getRetailMarkUps = createAsyncThunk('orderHistory/getRetailMarkUps', async () => fetchRetailMarkUps())
export const updateRetailMarkUp = createAsyncThunk('orderHistory/updateRetailMarkUp', async (payload) => putRetailMarkUp(payload))
export const setAllRetailMarkUp = createAsyncThunk('orderHistory/setAllRetailMarkUp', async (payload) => postAllRetailMarkUp(payload))
export const orderAction = thunkActions(getRetailMarkUps, updateRetailMarkUp)

const retailMarkUpsSlice = createSlice({
	name: 'retailMarkUps',
	initialState: {
		status: 'idle',
		data: null,
		rowData: []
	},
	extraReducers: (builder) => {
	 	builder
	 		.addCase(getRetailMarkUps.fulfilled, (state, action) => {
	 			state.data = action.payload
	 		})
	 		.addCase(getRetailMarkUps.rejected, (state) => {
	 			state.data = null
	 		})
	 		.addMatcher(orderAction, statusHandler)
	 }
})

export const selectRetailMarkUpsStatus = state => state.user.retailMarkUps.status
export const selectRetailMarkUps = state => state.user.retailMarkUps.data

export default retailMarkUpsSlice.reducer