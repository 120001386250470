import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import SiteData from 'types/SiteData'
import { getSiteData } from 'utils/api/siteDataAPI'
import { SliceState } from 'utils/enums/sliceBaseStates'
import sliceStateBases from 'utils/enums/sliceStateBases'
import { statusHandler, thunkActions } from 'utils/utils'

export const fetchSiteData = createAsyncThunk('siteData/fetchSiteData', async () => await getSiteData())

const actions = thunkActions(fetchSiteData)

interface SiteDataState {
	data: SiteData | null
}

const initialState: SliceState<'FEATURE', SiteDataState> = {
	...sliceStateBases.FEATURE,
}

const siteDataSlice = createSlice({
	name: 'siteData',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSiteData.fulfilled, (state, action) => {
				state.data = action.payload
			})
			.addMatcher(actions, statusHandler)
	}
})

export const selectSiteData = (state: RootState) => state.siteData.data
export const selectSiteDataCompany = (state: RootState) => state.siteData.data?.company
export const selectSiteDataFooter = (state: RootState) => state.siteData.data?.footer

export default siteDataSlice.reducer