import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectResult, setFilter, selectFilterState } from '../batterySearchSlice'
import BatteryFilter from 'components/filters/BatteryFilter/BatteryFilter'

const BatterySearchFilter = () => {
	const result = useSelector(selectResult)
	const dispatch = useDispatch()
	const filterState = useSelector(selectFilterState)

	const handleFilterChange = (articleIds, filterState) => {
		dispatch(setFilter({ articleIds, filterState }))
	}

	return (
		<div className="BatterySearchFilter">
			{
				result &&
				<BatteryFilter 
					result={result} 
					filterState={filterState}
					onFilterChange={handleFilterChange} />
			}
		</div>
	)
}

export default BatterySearchFilter