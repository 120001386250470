import Price from 'components/Price/Price'
import './SlimProductCard.scss'
import React from 'react'
import StockQuantity from 'components/StockQuantity/StockQuantity'
import { useNavigate } from 'react-router-dom'
import { setShowQuickSearch } from 'features/app/documentInfo/documentInfoSlice'
import { useDispatch } from 'react-redux'
import smallPlaceholder from 'assets/images/placeholders/small.png'
import ArticleText from 'components/ArticleText/ArticleText'
interface SlimProductCardProps {
	article: any
}

/**
 * SlimProductCard
 * @param props
 */
const SlimProductCard: React.FC<SlimProductCardProps> = ({
	article
}) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const toToArticle = () => {
		// Close search panel and overlay
		dispatch(setShowQuickSearch(false))

		// Go to article
		navigate(`/product/${article.id}`)
	}

	return (
		<div className="SlimProductCard" onClick={toToArticle}>
			<div className="product-image">
				{article.imageId ? (
					<img src={`${process.env['REACT_APP_API_URL']}/api/ArticleImageThumbnail/${article.imageId}`} alt={article.name} />
				) : (
					<img src={smallPlaceholder} alt={`placeholder ${article.name}`} />
				)}
			</div>
			<div className="product-info">
				<label className="product-name">
					<ArticleText value={article.articleText} />
				</label>
				<div className="product-details">
					<Price article={article} showSymbol />
					<StockQuantity article={article} />
				</div>
			</div>
		</div>
	)
}

export default SlimProductCard