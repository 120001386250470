import React from 'react'
import { useTranslation } from 'react-i18next'
import { ListViewLicensePlateInput, ListViewButton } from '@barnsten-it/gummidata-react-components'
import './MiniCartCard.scss'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCart } from '../../checkout/checkoutSlice'
import MiniCartSummary from '../MiniCartSummary/MiniCartSummary'
import {
	selectCountryCode,
	selectFeatureConfig
} from 'features/app/settings/settingsSlice'
import { LoadingOverlay } from '@barnsten-it/gummidata-react-components'
import { selectStatus } from '../../checkout/checkoutSlice'
import ArticleText, { formatArticleText } from 'components/ArticleText/ArticleText'

const MiniCartCard = ({ onRemove, onClose, articleCount }) => {
	const { t } = useTranslation()
	const featureConfig = useSelector(selectFeatureConfig)
	const cartData = useSelector(selectCart)
	const status = useSelector(selectStatus)
	const countryCode = useSelector(selectCountryCode)
	const maxInList = 10

	const PackageRow = ({ articles, licensePlate }) => {
		return (
			<div className="PackageRow">
				<div className="side-label">
					<div>
						{t('common.package')}
					</div>
				</div>
				<div className="cart-item tyre">
					<Link to={'/product/' + articles[0].articleId} className="product-item-content">
						{featureConfig.showArticleId && (
							<div className="item-id">{articles[0].articleId}</div>
						)}
						<div className="item-name" title={formatArticleText(articles[0].articleText)}><ArticleText value={articles[0].articleText} /></div>
					</Link>

					<ListViewLicensePlateInput
						value={licensePlate}
						countryCode={countryCode}
						readOnly
					/>
				</div>
				{articles[1] && (
					<div className="cart-item rim">
						<Link to={'/product/' + articles[1].articleId} className="product-item-content">
							{featureConfig.showArticleId && (
								<div className="item-id">{articles[1].articleId}</div>
							)}
							<div className="item-name" title={formatArticleText(articles[0].articleText)}><ArticleText value={articles[0].articleText} /></div>
						</Link>

						<ListViewButton onClick={() => onRemove(articles[0].id)} label={t('common.remove')} />
					</div>
				)}
			</div>
		)
	}

	return (
		<div className="MiniCartCard">
			<div className="chosen-text">
				{maxInList} {t('cartPage.latestSelectedProduct')}
			</div>
			{
				status === 'loading' &&
				<LoadingOverlay small={true} />
			}
			<div className="product-list">
				{cartData.rows.map((item, index) => {
					if (item.type === 'simple') {
						const articleId = item.articles[0].articleId
						const articleText = item.articles[0].articleText

						return (
							<div className="cart-item" key={'cart-item-' + index}>
								<Link to={'/product/' + articleId} className="product-item-content">
									{featureConfig.showArticleId && (
										<div className="item-id">{articleId}</div>
									)}
									<div className="item-name" title={formatArticleText(articleText)}>{<ArticleText value={articleText} />}</div>
								</Link>

								<ListViewButton onClick={() => onRemove(item.id)} label={t('common.remove')} />
							</div>
						)
					} else if (item.type === 'package') {
						return (
							<PackageRow key={'cart-item-' + index} licensePlate={item.licensePlate} articles={item.articles} />
						)
					} else {
						return ''
					}
				}).reverse().slice(0, maxInList)}
			</div>

			<MiniCartSummary cartData={cartData} onClose={onClose} articleCount={articleCount} />
		</div>
	)
}

export default MiniCartCard