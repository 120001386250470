import apiClient from './apiClient'

/**
 * Do oil search
 * @param {number} id order row id
 * @returns 
 */
export const getOils = async (payload) => await apiClient.get('/api/oilVehicleData/', payload)

/**
 * Get Oils vehicle types
 * @returns 
 */
export const getOilVehicleTypes = async () => await apiClient.get('/api/oilVehicleCategory/')

/**
 * Get Oils vehicle brands
 * @param {number} int value of vehicle type
 * @returns 
 */
export const getOilBrands = async (payload) => await apiClient.get('/api/oilVehicleManufacturers/', { categoryId: payload })

/**
 * Get Oils vehicle models
 * @param {number} id value of brand
 * @returns 
 */
export const getOilModels = async (payload) => await apiClient.get('/api/oilVehicleModels/', { manufacturerId: payload })

/**
 * Get Oils vehicle engines
 * @param {number} id value of model
 * @returns 
 */
export const getOilEngines = async (payload) => await apiClient.get('/api/oilVehicleTypes/', { modelId: payload })
